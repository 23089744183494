
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import { Container, Button, Image, Form, DateTimePicker, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText, NumberInput, NumberInputDisabled, FormWithChildren, Select, FileInput, Alert } from 'library';
import format_date_string from 'base/date_string_format.js';
import { CSVLink, CSVDownload } from "react-csv";
import { ImCircleRight, ImCircleDown } from "react-icons/im";

function numberWithCommas(x) {
    if (!x || isNaN(x)) {
        x = 0;
    }

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Request extends Component {
    constructor(props) {
        super(props);
        this.state = {
            price: props.request.price,
            provider_cost: props.provider_cost,
            invoice_url: props.request.invoice_url,
            page_fee: props.invoices.length > 0 && props.invoices.page_fee,
            auto_added_surcharge: props.invoices.length > 0 && props.invoices.auto_added_surcharge,
            saved: false,
            show_invoices: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.setFormState = this.setFormState.bind(this);
        this.toggle_invoices = this.toggle_invoices.bind(this);
    }

    handleChange = (e) => {
        console.log(e.target.value);

        var name = e.target.getAttribute("name");
        var newState = {};
        newState[name] = e.target.value;

        var newCompleteState = this.state;
        newCompleteState[name] = e.target.value;


        if (newState.provider_cost && this.props.request.company_id == 'e5affb01-e750-4738-a806-91ca02442163') {
            var surcharge = Math.floor(newState.provider_cost / 100) * 10;
            if (surcharge > 1000) {
                surcharge = 1000;
            }
            newState['auto_added_surcharge'] = surcharge
        }

        this.setState(newState);
    }

    setFormState(state) {
        this.setState(state);
    }

    save() {
        var data = {}

        if (this.state.provider_cost) {
            data.provider_cost = this.state.provider_cost;
        }
        if (this.state.auto_added_surcharge) {
            data.auto_added_surcharge = this.state.auto_added_surcharge;
        }

        if (this.props.invoices.at(-1).invoice.bill.unicode == 'None') {
            ajaxWrapper('PUT', `/api/home/invoice/${this.props.invoices.at(-1).invoice.id}/`, data, this.props.refreshData);
        }
        else {
            data.request = this.props.request.id;
            ajaxWrapper('POST', `/api/home/invoice/`, data, this.props.refreshData);
        }
        this.setState({ saved: true });
        var comp = this;
        setTimeout(function () { comp.setState({ saved: false }) }, 5000);
    }

    toggle_invoices() {
        this.setState(prevState => ({
            show_invoices: !prevState.show_invoices
        }));
    }

    componentDidMount() {
        this.setState({provider_cost : this.props.invoices.at(-1).invoice.provider_cost})
    }

    render() {
        var button = [<Button type="primary" text="Save" onClick={this.save} />];
        if (this.state.saved) {
            button = [<Button type="success" text="Saved" />];
        }

        var content = <></>
        var payment_status_true = <p className='font-weight-bold text-center text-success'> Paid </p>
        var payment_status_false = <p className='font-weight-bold text-center text-danger'> Not Paid </p>

        var patient = this.props.user;
        var request = this.props.request;
        if (!request.provider) {
            request.provider = {}
        }

        var user = this.props.request.requester
        if (!user) {
            user = {}
        }
        var rush = "No";
        if (request.rush) {
            rush = "Yes";
        }

        if (this.props.print) {
            content = <tr>
                <td>{request.internal_number}</td>
                <td>{user.first_name} {user.last_name}</td>
                <td>{format_date_string(request.created_at, 'mm/dd/yyyy')}</td>
                <td>{patient.first_name} {patient.last_name}</td>
                <td>{request.provider.name}</td>
                <td>{request.request_type}</td>
                <td>{rush}</td>
                <td>{request.status}</td>
                <td>${numberWithCommas(this.props.price.toFixed(2))}</td>
                <td>${numberWithCommas(this.state.provider_cost.toFixed(2))}</td>
                <td>${numberWithCommas(this.state.page_fee.toFixed(2))}</td>
                <td>${numberWithCommas(this.state.auto_added_surcharge.toFixed(2))}</td>
                <td>${numberWithCommas((this.props.price + this.state.provider_cost + this.state.page_fee + this.state.auto_added_surcharge).toFixed(2))}</td>
            </tr>
        }
        else {
            content = <tbody>
                <tr style={{ position: 'relative' }}>
                    <td>
                        <div className='d-flex align-items-center'>
                            {this.props.invoices.length > 1 ?
                                <a className='d-flex mr-1' onClick={this.toggle_invoices}>
                                    {this.state.show_invoices
                                        ? <ImCircleDown />
                                        : <ImCircleRight />
                                    }
                                </a>
                                : ''
                            }
                            {request.internal_number}
                        </div>
                    </td>
                    <td>{this.props.invoices.length > 0 && this.props.invoices.at(-1).invoice.bill.title ? this.props.invoices.at(-1).invoice.bill.title : '-'}</td>
                    <td>{user.first_name} {user.last_name}</td>
                    <td>{format_date_string(request.created_at, 'mm/dd/yyyy')}</td>
                    <td>{request.patient.first_name} {request.patient.last_name}</td>
                    <td>{request.provider.name}</td>
                    <td>{request.request_type}</td>
                    <td>{rush}</td>
                    <td>{request.status}</td>
                    <td>{this.props.invoices.length > 0 && this.props.invoices.at(-1).invoice.price ? this.props.invoices.at(-1).invoice.price : '0'}</td>
                    {
                        this.props.is_admin_user ? <>
                            <td><NumberInput name="provider_cost" value={this.state.provider_cost ? this.state.provider_cost : 0} handlechange={this.handleChange} /></td>
                            <td><NumberInput name="auto_added_surcharge" value={this.state.auto_added_surcharge} handlechange={this.handleChange} /></td>
                        </> : <>
                            <td> {this.state.provider_cost ? this.state.provider_cost : 0} </td>
                            <td> {request.auto_added_surcharge ? request.auto_added_surcharge : 0} </td>
                        </>
                    }
                    <td className='text-center'> {this.props.invoices.length > 0 && this.props.invoices.at(-1).invoice.paid_at ? format_date_string(this.props.invoices.at(-1).invoice.paid_at, 'mm/dd/yyyy') : '-'} </td>
                    <td> {this.props.invoices.length > 0 && this.props.invoices.at(-1).invoice.bill !== null && (this.props.invoices.length > 0 && this.props.invoices.at(-1).invoice.bill.is_paid ? payment_status_true : payment_status_false)} </td>
                    {this.props.is_admin_user && <td> {button} </td>}
                </tr>


                {this.props.invoices.length > 1 &&
                    this.props.invoices.map(iter => (
                        <tr className={(this.state.show_invoices ? '' : 'collapse') + (iter.invoice.bill.id ? ' text-secondary' : '')}>
                            <td></td>
                            <td> {iter.invoice.bill.title} </td>
                            <td></td>
                            <td>{format_date_string(iter.invoice.created_at, 'mm/dd/yyyy')}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{iter.invoice.price}</td>
                            <td>{iter.invoice.provider_cost}</td>
                            <td>{iter.invoice.auto_added_surcharge}</td>
                            <td> {iter.invoice.bill.paid_at ? format_date_string(iter.invoice.bill.paid_at, 'mm/dd/yyyy') : ''} </td>
                            <td> {iter.invoice.bill && iter.invoice.bill.is_paid ? payment_status_true : payment_status_false} </td>
                            {this.props.is_admin_user && <td></td>}
                        </tr>
                    ))}
            </tbody>
        }

        return content;
    }

}

class RequestList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            requests: [],
            page_offset: 1,
            last_page: false,
            total_requests_count: 0,
            limit: 10,
            default_sorting: "Ascending",
            default_name: "status",
            next: "",
            previous: "",
            total_count: 0,
            'users': [],
            start_date: '',
            end_date: '',
            client: '',
            status: 'Closed',
            invoice_title: '',
            provider: '',
            company: { name: '' },
            print: false,
            is_paid: '',
            billing: "not_billed",
            invoice_error: '',
            request_costs: { 'Completed - Documents Arrived': 0, 'Completed - No Documents Available': 0, 'Closed - Cancelled Request': 0, 'Rush': 0 },
            page_count: 0,
            billing_dropdown: {},
        }

        this.objectCallback = this.objectCallback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.companyCallback = this.companyCallback.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.save_fees = this.save_fees.bind(this);
        this.load_fees = this.load_fees.bind(this);
        this.handleInvoiceTitleInput = this.handleInvoiceTitleInput.bind(this);
        this.check_filters = this.check_filters.bind(this);
        this.create_billing = this.create_billing.bind(this);
        this.handleSorting = this.handleSorting.bind(this);
        this.handleLimitChange = this.handleLimitChange.bind(this);
        this.handleApi = this.handleApi.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.load_billing = this.load_billing.bind(this);
    }
    handlePageClick = (event) => {
        this.setState({ page_offset: event.selected + 1 })
        this.handleApi(this.state.limit, event.selected + 1, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client);
    }

    handleApi(limit, page_offset, status, billing, provider, start_date, end_date, client) {
        this.setState({ loader: true })
        ajaxWrapper('GET', `/api/invoices/${this.props.company_id}/?page=${page_offset}&page_size=${limit}&status=${status}&billing=${billing}&provider=${provider}&start_date=${start_date}&end_date=${end_date}&client=${client}`, {}, this.objectCallback);
    }


    componentDidMount() {
        this.setState({ loader: true })
        this.refreshData();
    }

    load_billing(result) {
        this.setState({ billing_dropdown: result });
    }

    handleSorting = (e) => {
        this.setState({ default_sorting: e.target.value });
    };

    handleLimitChange(e) {
        this.setState({ limit: parseInt(e.target.value) });
        if (parseInt(e.target.value) > this.state.total_count) {
            this.setState({ page_offset: 1 });
        }
        if (this.state.page_offset * parseInt(e.target.value) > this.state.total_count) {
            this.setState({ page_offset: parseInt(this.state.total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.total_count / parseInt(e.target.value)) });
        }
    }

    refreshData() {
        this.handleApi(10, this.state.page_offset, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        ajaxWrapper('GET', '/api/home/company/' + this.props.company_id + '/', {}, this.companyCallback)
        ajaxWrapper('GET', '/api/home/price/37efdb0a-58cc-4da2-a03d-58037c38032f/', {}, this.load_fees);
        ajaxWrapper('GET', `/api/invoice_billing/${this.props.company_id}`, {}, this.load_billing);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.limit !== prevState.limit) {
            this.handleApi(this.state.limit, this.state.page_offset, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        }
        if (this.state.client !== "" && prevState.client !== this.state.client) {
            this.setState({ page_offset: 1 });
            this.handleApi(this.state.limit, 1, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        }

        if (this.state.provider !== "" && prevState.provider !== this.state.provider) {
            this.setState({ page_offset: 1 });
            this.handleApi(this.state.limit, 1, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        }

        if (this.state.start_date !== "" && prevState.start_date !== this.state.start_date) {
            this.setState({ page_offset: 1 });
            this.handleApi(this.state.limit, 1, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        }

        if (this.state.end_date !== "" && prevState.end_date !== this.state.end_date) {
            this.setState({ page_offset: 1 });
            this.handleApi(this.state.limit, 1, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        }

        if (prevState.status !== this.state.status) {
            this.setState({ page_offset: 1 });
            this.handleApi(this.state.limit, 1, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        }

        if (prevState.billing !== this.state.billing) {
            this.setState({ page_offset: 1 });
            this.handleApi(this.state.limit, 1, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        }
        if (this.state.client == "" && prevState.client != "") {
            this.handleApi(this.state.limit, this.state.page_offset, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        }

        if (this.state.provider == "" && prevState.provider != "") {
            this.handleApi(this.state.limit, this.state.page_offset, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        }

        if (this.state.start_date == "" && prevState.start_date != "") {
            this.handleApi(this.state.limit, this.state.page_offset, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        }

        if (this.state.end_date == "" && prevState.end_date != "") {
            this.handleApi(this.state.limit, this.state.page_offset, this.state.status, this.state.billing, this.state.provider, this.state.start_date, this.state.end_date, this.state.client)
        }
    }

    objectCallback(result) {
        this.setState({ requests: result.results, 'loaded': true })
        this.setState({ next: result.next, loaded: true });
        this.setState({ previous: result.previous, loaded: true });
        this.setState({ total_count: result.count, loaded: true });
        this.setState({ page_count: Math.ceil(this.state.total_count / this.state.limit) })
        this.setState({ loader: false })
    }

    companyCallback(result) {
        this.setState({ company: result[0]['company'] })
    }

    setGlobalState(name, state) {
        if (name == 'costs') {
            var new_costs = state;
            delete new_costs['required']
            this.setState({ 'request_costs': state })
        }
        else {
            this.setState(state)
        }
    }

    check_filters(request, start = 0, end = 999999999999999) {
        var created_at = Date.parse(request.created_at);
        var is_paid = this.state.is_paid ? (this.state.is_paid === 'true') : 'all';
        var patient = request.patient.first_name.trim() + ' ' + request.patient.last_name.trim();
        var provider = '';
        if (request.provider) {
            provider = request.provider.name;
        }

        if (start > created_at || end < created_at) {
            return false;
        }
        if (this.state.provider !== '' && provider.toLowerCase().indexOf(this.state.provider.toLowerCase()) == -1) {
            return false;
        }
        if (this.state.client !== '' && patient.toLowerCase().indexOf(this.state.client.toLowerCase()) == -1) {
            return false;
        }
        if (this.state.status !== '') {
            if (this.state.status == 'Open') {
                if (request.status.indexOf('Closed') > -1 || request.status.indexOf('Completed') > -1) {
                    return false;
                }
            }
            else if (this.state.status == 'Closed') {
                if (request.status.indexOf('Closed') == -1 && request.status.indexOf('Completed') == -1) {
                    return false;
                }
            }
            else if (request.status.indexOf(this.state.status) == -1) {
                return false;
            }
        }
        if (is_paid != 'all' && request.invoices.every(iter => iter.invoice.is_paid != is_paid)) {
            return false;
        }
        if (this.state.billing !== '') {
            if (this.state.billing == 'not_billed') {
                if (request.invoices.every((iter) => (iter.invoice.bill.unicode != 'None'))) {
                    return false;
                }
            }
            else {
                if (request.invoices.every((iter) => (iter.invoice.bill.id != this.state.billing))) {
                    return false;
                }
            }
        }

        return true;
    }

    create_billing() {
        if (this.state.invoice_title == '') {
            this.setState({ invoice_error: 'Invoice Title is required' });
            return;
        }

        this.setState({ invoice_error: '' });

        if (window.confirm('Are you sure you want to create this billing. \n\nNote: Requests other than closed/completed status will not be included in the billing') == false) {
            return;
        }

        this.setState({ invoice_title: '' });

        var start = 0;
        if (this.state.start_date != '') {
            start = Date.parse(this.state.start_date)
        }

        var end = 999999999999999;
        if (this.state.end_date != '') {
            end = Date.parse(this.state.end_date)
        }
        var invoice_ids = [];
        for (var request_index in this.state.requests) {
            var request = this.state.requests[request_index]['request'];
            console.log(request ,'request====>');


            request.invoices.forEach(iter => {
                if (iter.invoice.bill.unicode == 'None') {
                    invoice_ids.push(iter.invoice.id);
                }
            })
        }

        ajaxWrapper('POST', '/api/home/billing/', { company: this.props.company_id, title: this.state.invoice_title }, (res) => {
            if (res[0].billing.id) {
                ajaxWrapper('PUT', '/api/home/billing/' + res[0].billing.id + '/', { invoices: invoice_ids.join(',') }, () => {
                    window.location = '/billings/';
                });
            }
        })
    }

    save_fees() {
        var request_costs = this.state.request_costs;

        var data = {};

        data['completed_documents_arrived'] = request_costs['Completed - Documents Arrived']
        data['completed_documents_unavailable'] = request_costs['Completed - No Documents Available']
        data['closed_documents_arrived'] = request_costs['Closed - Documents Arrived']
        data['closed_documents_unavailable'] = request_costs['Closed - No Documents Available']
        data['cancelled'] = request_costs['Closed - Cancelled Request']
        data['rush'] = request_costs['Rush']
        data['closed_originally_no_document'] = request_costs['Closed - Originally No Document Found/Now Document Arrived']

        ajaxWrapper('POST', '/api/home/price/37efdb0a-58cc-4da2-a03d-58037c38032f/', data, this.refreshData);
    }

    load_fees(result) {

        var request_costs = result[0]['price'];

        var data = {};

        data['Completed - Documents Arrived'] = request_costs['completed_documents_arrived']
        data['Completed - No Documents Available'] = request_costs['completed_documents_unavailable']
        data['Closed - Documents Arrived'] = request_costs['closed_documents_arrived']
        data['Closed - No Documents Available'] = request_costs['closed_documents_unavailable']
        data['Closed - Cancelled Request'] = request_costs['cancelled']
        data['Rush'] = request_costs['rush']
        data['Closed - Originally No Document Found/Now Document Arrived'] = request_costs['closed_originally_no_document']

        this.setState({ request_costs: data });
    }

    handleInvoiceTitleInput(e) {
        let newTitle = e.target.value;
        this.setState({ invoice_title: newTitle });
    }

    render() {

        var requests = [];
        var total_rush = 0;
        var total_normal = 0;
        var requests_cost = 0;
        var provider_cost = 0;
        var page_fee = 0;
        var auto_added_surcharge = 0;
        var csv_data = [
            ['Id', 'Requestor', 'Date', 'Client', 'Provider', 'Rush', 'Request Fee', 'Provider Fee', 'Page Fee', 'Organization Fee', 'Total Fee'],
        ]
        var billings = {}


        var start = 0;
        if (this.state.start_date != '') {
            start = Date.parse(this.state.start_date)
        }

        var end = 999999999999999;
        if (this.state.end_date != '') {
            end = Date.parse(this.state.end_date)
        }

        Object.keys(this.state.billing_dropdown).forEach((key, index) => {
            const value = this.state.billing_dropdown[key]; // Get the value for the current key
            billings[key] = {
                "text": value,
                "value": key
            };
        });

        for (var request_index in this.state.requests) {
            var request = this.state.requests[request_index]['request'];
            var user = request.patient;

            var rush_cost = 0;
            var rush = 'No';
            request.invoices.forEach((iter) => {
                if (this.state.billing != "") {
                    if (iter.invoice.bill.id && this.state.billing != iter.invoice.bill.id) {
                        return;
                    }
                    else if (!iter.invoice.bill.id && this.state.billing != 'not_billed') {
                        return;
                    }
                }
                requests_cost += iter.invoice.price;
                provider_cost = iter.invoice.provider_cost + provider_cost;
                page_fee += iter.invoice.page_fee;
                auto_added_surcharge += iter.invoice.auto_added_surcharge;
            })

            if (request.rush) {
                total_rush += 1;
                rush = 'Yes'
                rush_cost += this.state.request_costs['Rush'];
            }
            else {
                total_normal += 1;
            }


            var price = parseFloat(this.state.request_costs[request.status]) + parseFloat(rush_cost)

            if (!price || isNaN(price)) {
                price = 0;
            }

            var invoices = request.invoices;
            if (invoices.length > 1) {
                invoices.sort((iterA, iterB) => (
                    new Date(iterA.invoice.created_at) - new Date(iterB.invoice.created_at)
                ));
            }

            requests.push(<Request key={request.id} request={request} provider_cost={request.provider_cost} user={user} refreshData={this.refreshData} print={this.state.print} invoices={invoices} is_admin_user={this.props.is_admin_user} billing={this.state.billing} />)

            if (request.provider) {
                request.invoices.map(iter => {
                    if (this.state.billing !== '') {
                        if (iter.invoice.bill.unicode == 'None') {
                            if (this.state.billing != 'not_billed') {
                                return;
                            }
                        }
                        else if (iter.invoice.bill.id != this.state.billing) {
                            return;
                        }
                    }

                    csv_data.push([
                        request.internal_number,
                        user.first_name + ' ' + user.last_name,
                        format_date_string(request.created_at, 'mm/dd/yyyy'),
                        request.patient.first_name + ' ' + request.patient.last_name,
                        request.provider.name,
                        rush,
                        '$' + numberWithCommas(iter.invoice.price.toFixed(2)),
                        '$' + numberWithCommas(iter.invoice.provider_cost.toFixed(2)),
                        '$' + numberWithCommas(iter.invoice.page_fee.toFixed(2)),
                        '$' + numberWithCommas(iter.invoice.auto_added_surcharge.toFixed(2)),
                        '$' + numberWithCommas((iter.invoice.price + iter.invoice.provider_cost + iter.invoice.page_fee + iter.invoice.auto_added_surcharge).toFixed(2))
                    ])
                })
            }
        }

        var status_options = [
            { 'text': 'All Open', value: 'Open' },
            { 'text': 'Requested By Attorney', value: 'Requested By Lawyer' },
            { 'text': 'Initial Request Sent', value: 'Initial Request Sent' },
            { 'text': 'In Contact With Provider', 'value': 'In Contact With Provider' },
            { 'text': 'Documents en Route', 'value': 'Documents en Route' },
            { 'text': 'Need Assistance', value: 'Need Assistance' },
            { 'text': 'Waiting for Direct Docs Response', value: 'Waiting for Direct Docs Response' },
            { 'text': 'Completed - Documents Arrived', 'value': 'Completed - Documents Arrived' },
            { 'text': 'Completed - No Documents Available', 'value': 'Completed - No Documents Available' },
            { 'text': 'Closed - Documents Arrived', 'value': 'Closed - Documents Arrived' },
            { 'text': 'Closed - No Documents Available', 'value': 'Closed - No Documents Available' },
            { 'text': 'Closed - Originally no document found/now document arrived', 'value': 'Originally no document found/now document arrived' },
            { 'text': 'Closed - Cancelled Request', 'value': 'Closed - Cancelled Request' },
            { 'text': 'All Closed', value: 'Closed' },
        ];

        var paid_options = [
            { 'text': 'Yes', value: 'true' },
            { 'text': 'No', value: 'false' },
        ]

        var billing_options = Object.values(billings);
        billing_options.push({
            "text": 'Not billed',
            "value": 'not_billed'
        })




        var start_date = { 'name': 'start_date', label: 'From:', display_time: false }
        var end_date = { 'name': 'end_date', label: 'To:', display_time: false }
        var client = { 'name': 'client', label: 'Client' }
        var provider = { 'name': 'provider', label: 'Provider' }
        var status = { 'name': 'status', label: 'Status', options: status_options }
        var is_paid = { 'name': 'is_paid', label: 'Paid', options: paid_options }
        var billing = { 'name': 'billing', label: 'Billing', options: billing_options }

        var edit = <Button type='primary' text='Ready To Print' onClick={() => this.setState({ print: true })} />

        var print = null;
        if (this.state.print) {
            edit = <Button type='primary' text='Edit' onClick={() => this.setState({ print: false })} />;
            print = <Button type='success' onClick={() => window.print()} text='Print' />;
        }

        var invoice_div = <></>
        if (this.props.is_admin_user && this.state.billing == 'not_billed') {
            invoice_div = <div>
                <div className='invoice-form-header' style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    {edit}
                    <TextInput name='invoice-title' placeholder='Invoice Title' value={this.state.invoice_title} handlechange={this.handleInvoiceTitleInput} />
                    <Button type='primary' text='Create Billing' style={{ marginLeft: '10px' }} onClick={this.create_billing} />
                </div>
                <div style={{ color: 'red' }}> {this.state.invoice_error} </div>
            </div>
        }

        var fee_form_components = [];
        var fee_form_component_data = [];
        var fee_form_defaults = this.state.request_costs;

        for (var index in this.state.request_costs) {
            fee_form_components.push(NumberInput);
            fee_form_component_data.push({ 'name': index, label: index })

        }

        var content =
            <div className="container-fluid">
                <Header size={2} text={this.state.company.name + ' Requests'} />
                <div className="no-print">
                    {
                        this.props.is_admin_user &&
                        <>
                            {invoice_div}
                            {print}
                        </>
                    }
                    <Form row={true} col_size={2} components={[DateTimePicker, DateTimePicker, TextInput, TextInput, Select, Select]} componentProps={[start_date, end_date, client, provider, status, billing]} autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'form'} defaults={this.state} />

                    {
                        this.props.is_admin_user && <>
                            <h3>Fees</h3>
                            <Form row={true} col_size={2} components={fee_form_components} componentProps={fee_form_component_data} autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'costs'} defaults={fee_form_defaults} submit={this.save_fees} />
                            <div style={{ marginTop: '0.45em', marginLeft: '0.5em' }}>
                                <CSVLink data={csv_data}>Download Invoice Data</CSVLink>
                            </div>
                        </>
                    }
                    <div></div>

                </div>

                <br />
                <div style={{
                    margin: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center"
                }}>
                    <div>
                        Total Records : {this.state.total_count}
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: "center",
                        marginLeft: "20px"
                    }}>
                        {
                            this.state.total_count > 0 ?
                                <select
                                    className="form-control"
                                    style={{ width: "85px", marginRight: "5px" }}
                                    onChange={this.handleLimitChange}
                                >
                                    <option>10</option>
                                    <option>20</option>
                                    <option>30</option>
                                    <option>40</option>
                                    <option>50</option>
                                    <option>100</option>
                                </select>
                                : ""

                        }
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={this.handlePageClick}
                            pageRangeDisplayed={10}
                            marginPagesDisplayed={3}
                            pageCount={this.state.page_count}
                            previousLabel="Previous"
                            renderOnZeroPageCount={null}
                            className="custom-pagination"
                            forcePage={this.state.page_offset - 1}
                        />
                    </div>
                </div>
                <div className='table-container'>
                    <table className="table invoice-table" style={{
                        overflowX: 'auto',
                        display: 'block',
                        maxHeight: '90vh',
                        margin: 'auto',
                        border: '1px solid #e5e5e5'
                    }}>
                        <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                            <th></th>
                            <th></th>
                            <th>Total Requests</th>
                            <th></th>
                            <th>Requests</th>
                            <th></th>
                            <th></th>
                            <th>Rush Requests</th>
                            <th></th>
                            <th>Request Fees</th>

                            <th>Provider Fees</th>
                            <th>Organization Fees</th>

                            <th></th>
                            <th>Total</th>
                            {this.props.is_admin_user && <th></th>}
                        </tr>
                        <tr style={{ borderBottom: '1px solid #dee2e6' }}>

                            <th></th>
                            <th></th>
                            <th>{total_normal + total_rush}</th>
                            <th></th>
                            <th>{total_normal}</th>
                            <th></th>
                            <th></th>
                            <th>{total_rush}</th>
                            <th></th>
                            <th>${numberWithCommas((requests_cost).toFixed(2))}</th>
                            <th>${numberWithCommas(provider_cost.toFixed(2))}</th>
                            <th>${numberWithCommas(auto_added_surcharge.toFixed(2))}</th>
                            <th></th>
                            <th>${numberWithCommas((requests_cost + provider_cost + page_fee + auto_added_surcharge).toFixed(2))}</th>

                            {this.props.is_admin_user && <th></th>}
                        </tr>
                        <tr>
                            <th>ID</th>
                            <th>Billing Title</th>
                            <th>Requestor</th>
                            <th>Date</th>
                            <th>Client</th>
                            <th>Provider</th>
                            <th>Request Type</th>
                            <th>Rush</th>
                            <th>Request Status</th>
                            <th>Request Fee</th>
                            <th>Provider Fee</th>
                            <th>Organization Fee</th>
                            <th>Paid at</th>
                            <th>Payment Status</th>
                            {this.props.is_admin_user && <th>Save Changes</th>}
                        </tr>

                        {requests}
                    </table>
                </div>
            </div>;

        return (
            <Wrapper loaded={this.state.loaded} content={content} />
        );
    }
}

export default RequestList;

import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import sort_objects from 'base/sort_objects.js';
import {
    Container, Button, Image, Form, TextInput, Navbar, List, Select, Link,
    Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText,
    PageBreak, ListWithChildren, FormWithChildren, Icon
} from 'library';
import format_date from 'base/date_format.js';
import format_date_string from 'base/date_string_format.js';
import DataTable from 'pages/data_dashboard/data_table.js';
import EmployeeDashboard from 'pages/data_dashboard/employee_dashboard.js';
import LawFirmDashboard from 'pages/data_dashboard/law_firm_dashboard.js';
import EmployeeLast from './data_dashboard/employee_last';

var admin_users = ["3f46c2b5-4a69-47de-9df0-cdd898658c30", "57d0be71-b98c-4360-8a23-1e5ba4a0808b", "5a023ce8-5652-45df-a377-4ea484315d1f", "61bafeb0-c323-49e6-bd60-7fa42348c742"]

var prices = {
    'Completed - Documents Arrived': 35,
    'Completed - No Documents Available': 17.5,
    'Closed - Documents Arrived': 35,
    'Closed - No Documents Available': 17.5,
    'Closed - Cancelled Request': 17.5,
    'Rush': 7,
}

class MonthlyTables extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var cols = [];
        var new_requests = [];
        var completed_requests = [];
        var fees = [];
        var average_turnaround_times = [];

        for (const [key, value] of Object.entries(this.props.data)) {
            cols.push(<th> {key} </th>);
            new_requests.push(<td> {value.created} </td>);
            completed_requests.push(<td> {value.completed} </td>);
            fees.push(<td> ${value.fees} </td>);
            average_turnaround_times.push(<td> {Math.floor(value.completed / value.request_received)} </td>);
        }

        return (
            <div className="container-fluid" style={{ overflowX: 'scroll' }}>
                <table className="table">
                    <tbody>
                        <tr>
                            <th></th>
                            {cols}
                        </tr>
                        <tr>
                            <th>New Requests</th>
                            {new_requests}
                        </tr>
                        <tr>
                            <th>Completed Requests</th>
                            {completed_requests}
                        </tr>
                        <tr>
                            <th>DD Fees</th>
                            {fees}
                        </tr>
                    </tbody>
                </table>
            </div>
        )

    }
}

class Provider extends Component {
    constructor(props) {
        super(props);

        this.open_provider_report = this.open_provider_report.bind(this);
    }

    open_provider_report() {
        this.props.open_provider_report(this.props.provider)
    }

    render() {

        var provider = this.props.provider;

        return (
            <div className="card fancy_card col-xl-2 col-xs-12">
                <h4><a onClick={this.open_provider_report} style={{ color: '#000094', textDecoration: 'underline' }}>{provider.provider_name} : {provider.request_name}</a></h4>
                <h1>{provider.request_list_length}</h1>
            </div>
        )
    }
}

class Employee extends Component {
    constructor(props) {
        super(props);

        this.open_employee_last = this.open_employee_last.bind(this);
        if (props.employee.id != 'unassigned') {
            this.open_employee_report = this.open_employee_report.bind(this);
        }

        if (props.link) {
            this.link = props.link;
        }
        else {
            this.link = this.open_employee_report;
        }
    }

    open_employee_report() {
        this.props.open_employee_report(this.props.employee.id)
    }

    open_employee_last(period) {
        let employee_id = '';
        if (this.props.employee.id != 'unassigned') {
            employee_id = this.props.employee.id;
        }
        this.props.open_employee_last(employee_id, period)
    }

    render() {

        var employee = this.props.employee;

        var closed_requests_in_last_day = employee.closed_requests_in_last_day
        var fees_in_last_day = employee.fees_last_day

        var closed_requests_in_last_week = employee.closed_requests_in_last_week
        var fees_in_last_week = employee.fees_last_week

        var closed_requests_in_last_month = employee.closed_requests_in_last_month
        var fees_in_last_month = employee.fees_last_month

        var fees = <table className="table">
            <tbody>
                <tr>
                    <th colSpan="3">Total Closed Requests In Last</th>
                </tr>
                <tr>
                    <th><a className='closed_day' onClick={() => { this.open_employee_last("last_day") }}>Day</a></th>
                    <th> <a className='closed_week' onClick={() => { this.open_employee_last("last_week") }}>Week</a></th>
                    <th><a className='closed_month' onClick={() => { this.open_employee_last("last_month") }}>Month</a></th>
                </tr>
                <tr>
                    <td><a className='closed_day' onClick={() => { this.open_employee_last("last_day") }}>{closed_requests_in_last_day} (${closed_requests_in_last_day ? (fees_in_last_day / closed_requests_in_last_day).toFixed(2) : 0})</a></td>
                    <td> <a className='closed_week' onClick={() => { this.open_employee_last("last_week") }}>{closed_requests_in_last_week} (${closed_requests_in_last_week ? (fees_in_last_week / closed_requests_in_last_week).toFixed(2) : 0})</a></td>
                    <td><a className='closed_month' onClick={() => { this.open_employee_last("last_month") }}>{closed_requests_in_last_month} (${closed_requests_in_last_month ? (fees_in_last_month / closed_requests_in_last_month).toFixed(2) : 0})</a></td>
                </tr>
            </tbody>
        </table>;

        if (admin_users.indexOf(this.props.user.id) > -1) {
            fees = <table className="table">
                <tbody>
                    <tr>
                        <th colSpan="3">Total Closed Request (Total Fees) In Last</th>
                    </tr>
                    <tr>
                        <th><a className='closed_day' onClick={() => { this.open_employee_last("last_day") }}>Day</a></th>
                        <th> <a className='closed_week' onClick={() => { this.open_employee_last("last_week") }}>Week</a></th>
                        <th><a className='closed_month' onClick={() => { this.open_employee_last("last_month") }}>Month</a></th>
                    </tr>
                    {
                        this.props.employee.id ?
                            <tr>
                                <td><a className='closed_day' onClick={() => { this.open_employee_last("last_day") }}>{closed_requests_in_last_day} (${fees_in_last_day})</a></td>
                                <td> <a className='closed_week' onClick={() => { this.open_employee_last("last_week") }}>{closed_requests_in_last_week} (${fees_in_last_week})</a></td>
                                <td> <a className='closed_month' onClick={() => { this.open_employee_last("last_month") }}>{closed_requests_in_last_month} (${fees_in_last_month})</a></td>
                            </tr> :
                            <tr>
                                <td><a className='closed_day' onClick={() => { this.open_employee_last("last_day") }}>{closed_requests_in_last_day} (${fees_in_last_day})</a></td>
                                <td> <a className='closed_week' onClick={() => { this.open_employee_last("last_week") }}>{closed_requests_in_last_week} (${fees_in_last_week})</a></td>
                                <td> <a className='closed_month' onClick={() => { this.open_employee_last("last_month") }}>{closed_requests_in_last_month} (${fees_in_last_month})</a></td>
                            </tr>
                    }
                </tbody>
            </table>;
        }

        return (
            <div className="col-xl-4 col-xs-12"> <div className="card fancy_card" style={{ height: '95%' }}>
                <h4><a style={{ color: '#000094', textDecoration: 'underline' }} onClick={this.link}>{employee['name']} Requests</a></h4>
                <h1>{employee['total_employee_requests']}</h1>
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Open</th>
                            <th>Closed</th>
                            <th>Overdue Reminders</th>
                            <th>Average Turnaround</th>
                        </tr>
                        <tr>
                            <td>{employee['open']}</td>
                            <td>{employee['closed']}</td>
                            <td>{employee['overdue_requests']}</td>
                            <td>{employee['total_received'] ? (employee['total_turnaround'] / employee['total_received']).toFixed(2) : 0} days</td>
                        </tr>
                    </tbody>
                </table>
                {fees}

                {
                    employee.name.toLowerCase() != 'unassigned' && (<table className="table">
                        <tbody>
                            <tr>
                                <th colSpan="3">Total Notes Created In Last</th>
                            </tr>
                            <tr>
                                <th>Day</th>
                                <th>Week</th>
                                <th>Month</th>
                            </tr>
                            <tr>
                                <td>{employee.notes_created_last_day}</td>
                                <td>{employee.notes_created_last_week}</td>
                                <td>{employee.notes_created_last_month}</td>
                            </tr>
                        </tbody>
                    </table>
                    )}
            </div> </div>
        )
    }
}

class LawFirm extends Component {
    constructor(props) {
        super(props);

        this.open_law_firm_report = this.open_law_firm_report.bind(this);
    }

    open_law_firm_report() {
        this.props.open_law_firm_report(this.props.law_firm.id)
    }

    render() {

        var law_firm = this.props.law_firm;

        var closed_requests_in_last_day = law_firm.closed_requests_in_last_day
        var fees_in_last_day = law_firm.fees_last_day

        var closed_requests_in_last_week = law_firm.closed_requests_in_last_week
        var fees_in_last_week = law_firm.fees_last_week

        var closed_requests_in_last_month = law_firm.closed_requests_in_last_month
        var fees_in_last_month = law_firm.fees_last_month

        var fees = <table className="table">
            <tbody>
                <tr>
                    <th colSpan="3">Closed Requests In Last</th>
                </tr>
                <tr>
                    <th>Day</th>
                    <th>Week</th>
                    <th>Month</th>
                </tr>
                <tr>
                    <td>{closed_requests_in_last_day}</td>
                    <td>{closed_requests_in_last_week}</td>
                    <td>{closed_requests_in_last_month}</td>
                </tr>
            </tbody>
        </table>

        if (admin_users.indexOf(this.props.user.id) > -1) {
            fees = <table className="table">
                <tbody>
                    <tr>
                        <th colSpan="3">Completed Requests (Average Fees) In Last</th>
                    </tr>
                    <tr>
                        <th>Day</th>
                        <th>Week</th>
                        <th>Month</th>
                    </tr>
                    <tr>
                        <td>{closed_requests_in_last_day} (${closed_requests_in_last_day ? (fees_in_last_day / closed_requests_in_last_day).toFixed(2) : 0})</td>
                        <td>{closed_requests_in_last_week} (${closed_requests_in_last_week ? (fees_in_last_week / closed_requests_in_last_week).toFixed(2) : 0})</td>
                        <td>{closed_requests_in_last_month} (${closed_requests_in_last_month ? (fees_in_last_month / closed_requests_in_last_month).toFixed(2) : 0})</td>
                    </tr>
                </tbody>
            </table>
        }

        return (
            <div className="card fancy_card col-xl-3 col-xs-12">
                <h4><a style={{ color: '#000094', textDecoration: 'underline' }} onClick={this.open_law_firm_report}>{law_firm['name']} Requests</a></h4>
                <h1>{law_firm['total_firm_requests']} (${law_firm['total_price']})</h1>
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Open</th>
                            <th>Closed</th>
                            <th>Average Turnaround</th>
                        </tr>
                        <tr>
                            <td>{law_firm['open']}</td>
                            <td>{law_firm['closed']}</td>
                            <td>{law_firm['total_received'] ? (law_firm['total_turnaround'] / law_firm['total_received']).toFixed(2) : 0} days</td>
                        </tr>
                    </tbody>
                </table>
                {fees}
                <table className="table">
                    <tbody>
                        <tr>
                            <th colSpan="3">New Requests In Last</th>
                        </tr>
                        <tr>
                            <th>Day</th>
                            <th>Week</th>
                            <th>Month</th>
                        </tr>
                        <tr>
                            <td>{law_firm.requests_in_last_day}</td>
                            <td>{law_firm.requests_in_last_week}</td>
                            <td>{law_firm.requests_in_last_month}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default class DataDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dashboard_data: {},
            loaded: false,
            show_tables: false,
            got_closed: '',
            view: '',
            data_table_data: [],
            data_table_type: '',
            closed_request_loading_counter: 0,
        }

        // this.get_requests = this.get_requests.bind(this);
        this.open_employee_last = this.open_employee_last.bind(this);
        this.open_employee_report = this.open_employee_report.bind(this);
        this.open_law_firm_report = this.open_law_firm_report.bind(this);
        this.get_data = this.get_data.bind(this);
        this.back = this.back.bind(this);
        this.open_provider_report = this.open_provider_report.bind(this);
        this.get_error = this.get_error.bind(this);
        this.get_closed = this.get_closed.bind(this);
        this.get_closed_callback = this.get_closed_callback.bind(this);
        this.get_open_callback = this.get_open_callback.bind(this);
    }

    back() {
        this.setState({ view: 'Dashboard' })
    }

    componentDidMount() {
        this.get_data();
        // var t=setInterval(this.get_data,30000);

    }


    get_data() {
        if (!this.props.user.is_staff) {
            ajaxWrapper('GET', '/api/home/dashboard/?status_open&related=working_on,patient,patient__company&working_on=' + this.props.user.id, {}, this.get_open_callback, this.get_error)
        }
        else {
            ajaxWrapper('GET', '/api/home/dashboard/?status_open&related=working_on,patient,patient__company', {}, this.get_open_callback, this.get_error)
        }

    }

    get_closed() {
        this.setState({ got_closed: 'Loading' })
        let xhr ;
        let param = "";
        if(this.state.closed_request_loading_counter >= 5) {
            param = "&load_from_db"
        }
        if (!this.props.user.is_staff) {
            xhr = ajaxWrapper('GET', `/api/home/dashboard/?related=working_on,patient,patient__company${param}&working_on=` + this.props.user.id, {}, this.get_closed_callback, this.get_error)
        }
        else {
              xhr =  ajaxWrapper('GET', `/api/home/dashboard/?related=working_on,patient,patient__company${param}`, {}, this.get_closed_callback, this.get_error )
        }
        setTimeout(() => {
            if(this.state.got_closed != "Loaded") {
                this.setState({closed_request_loading_counter : this.state.closed_request_loading_counter + 1})
                xhr.abort();
                this.get_closed();
            }
            },16000)
    }

    get_open_callback(result) {
        this.setState({
            dashboard_data: result,
            loaded: true
        });
    }

    get_closed_callback(result) {
        this.setState({
            got_closed: 'Loaded',
            dashboard_data: result,
            loaded: true
        });
    }


    get_error(result) {
        console.log("Error", result)
    }

    see_table(type) {
        console.log("see_table type", type);
        this.setState({ 'view': 'Table', data_table_type: type })
    }

    open_provider_report(provider) {
        this.setState({ 'view': 'Table', data_table_data: provider.request_list, data_table_type: provider.provider_name + ' : ' + provider.request_name })
    }

    open_employee_report(employee_id) {
        if (this.state.dashboard_data.employees[employee_id]) {
            this.setState({
                'view': 'Employee',
                'data_table_data': this.state.dashboard_data.employees[employee_id]
            })
            this.setState({})
        }
    }

    open_employee_last(employee_id, period) {
        this.setState({
            'view': "EmployeeLast",
            "data_table_data": this.state.dashboard_data.employees[employee_id],
            "data_limit": period
        })
    }

    open_law_firm_report(law_firm_id) {
        if (this.state.dashboard_data.law_firms[law_firm_id]) {
            this.setState({
                'view': 'LawFirm',
                'data_table_data': this.state.dashboard_data.law_firms[law_firm_id]
            })
        }
    }

    render() {

        if (this.state.show_tables) {
            var employees = []
            for (var employee of Object.values(this.state.dashboard_data.employees)) {
                employees.push(<div style={{ marginTop: '50px' }}>
                    <h4>{employee.name}</h4>
                    <MonthlyTables data={employee.table_data} />
                </div>)
            }

            var law_firm_list = Object.values(this.state.dashboard_data.law_firms);

            sort_objects(law_firm_list, 'total_firm_requests', false);

            var law_firms = [];
            for (var law_firm of law_firm_list) {
                law_firms.push(<div style={{ marginTop: '50px' }}>
                    <h4>{law_firm.name}</h4>
                    <MonthlyTables data={law_firm.table_data} />
                </div>)
            }

            var content = <div>
                <h2>Direct Docs Data</h2>
                <Button type='primary no-print' text='Hide Tables' onClick={() => this.setState({ show_tables: false })} />
                <PageBreak />
                <MonthlyTables data={this.state.dashboard_data.table_data} />

                <h3 style={{ marginTop: '150px' }}>Direct Docs Employees</h3>
                <PageBreak />
                {employees}

                <h3 style={{ marginTop: '150px' }}>Law Firms</h3>
                <PageBreak />
                {law_firms}
            </div>

        }
        else {
            var today = new Date()
            var beginning = new Date(2019, 4, 29)
            var total_days_since_begin = Math.ceil((today - beginning) / (1000 * 60 * 60 * 24));
            console.log("Days", today - beginning, total_days_since_begin)

            var name_lookup = {
                new_requests: "New Requests",
                rush_requests: "Rush Requests",
                need_your_attention_requests: "Need Your Attention",
                request_older_than_fifteen: "Older Than 15 days",
                request_older_than_thirty: "Older Than 30 days",
                request_older_than_fortyfive: "Older Than 45 days",
                un_assigned_requests: "Un-assigned Requests",
                un_assigned_requests_all: "Un-assigned Requests (All)",
                note_older_than_fifteen: "Last Note Older Than 15 days",
                note_older_than_thirty: "Last Note Older Than 30 days",
                note_older_than_fortyfive: "Last Note Older Than 45 days",
                all_requests: "All Requests",
                todays_reminders: "Today's Reminders",
                old_reminders: "Old Reminders",
                requests_without_reminder: "Requests Without Reminders",
                requests_in_last_day: "Requests Made Within Last 24 Hours",
                requests_in_last_week: "Requests Made Within Last Week",
                requests_in_last_month: "Requests Made Within Last Month",
                closed_request_with_fees: "Completed Requests With Fees",
            }

            var content = null;
            if (this.state.loaded && this.state.view == 'Table') {
                var name = name_lookup[this.state.data_table_type] || this.state.data_table_type;

                var content = <DataTable back={this.back} name={'Direct Docs: ' + name} user={this.props.user} type={this.state.data_table_type} refreshData={this.get_data} employees={this.state.dashboard_data.employees} law_firms={this.state.dashboard_data.law_firms} closed={this.state.got_closed == 'Loaded'} />
            }
            else if (this.state.loaded && this.state.view == 'Employee') {
                var content = <EmployeeDashboard back={this.back} employee={this.state.data_table_data} user={this.props.user} refreshData={this.get_data} closed={this.state.got_closed == 'Loaded'} />
            }

            else if (this.state.loaded && this.state.view == 'EmployeeLast') {
                var content = <EmployeeLast type={this.state.data_limit} user={this.props.user} refreshData={this.get_data} employee={this.state.data_table_data} employees={this.state.dashboard_data.employees} closed={this.state.got_closed == 'Loaded'} data={this.state.data_table_data} />

                // var content = <EmployeeDashboard limit={this.state.data_limit} back={this.back} employee={this.state.data_table_data} user={this.props.user} refreshData={this.get_data} closed={this.state.got_closed == 'Loaded'} />
            }

            else if (this.state.loaded && this.state.view == 'LawFirm') {
                var content = <LawFirmDashboard back={this.back} law_firm={this.state.data_table_data} user={this.props.user} refreshData={this.get_data} closed={this.state.got_closed == 'Loaded'} />
            }
            else if (this.state.loaded) {

                var employees = [];
                var employee_count = 0;
                for (var employee of Object.values(this.state.dashboard_data.employees)) {

                    employees.push(<Employee key={employee.id} employee={employee} open_employee_report={this.open_employee_report} open_employee_last={this.open_employee_last} user={this.props.user} link={false} />)
                    employee_count += 1
                }

                employees.push(<Employee key={this.state.dashboard_data.unassigned_requests.name} employee={this.state.dashboard_data.unassigned_requests} open_employee_report={this.see_table} open_employee_last={this.open_employee_last} user={this.props.user} link={() => this.see_table("un_assigned_requests")} />)

                var law_firms = [];
                var law_firm_count = 0;
                var law_firm_list = [];


                law_firm_list = Object.values(this.state.dashboard_data.law_firms)
                sort_objects(law_firm_list, 'total_firm_requests', false);

                for (var law_firm of law_firm_list) {
                    law_firms.push(<LawFirm key={law_firm.id} law_firm={law_firm} open_law_firm_report={this.open_law_firm_report} user={this.props.user} />)

                    if (((law_firm_count - 2) % 3) != 0) {
                        law_firms.push(<div key={Math.random()} className="col-1"></div>)
                    }
                    law_firm_count += 1
                }

                var fees = null;
                if (admin_users.indexOf(this.props.user.id) > -1) {

                    var closed_requests_in_last_day = this.state.dashboard_data.closed_requests_in_last_day
                    var fees_in_last_day = this.state.dashboard_data.fees_in_last_day

                    var closed_requests_in_last_week = this.state.dashboard_data.closed_requests_in_last_week
                    var fees_in_last_week = this.state.dashboard_data.fees_in_last_week

                    var closed_requests_in_last_month = this.state.dashboard_data.closed_requests_in_last_month
                    var fees_in_last_month = this.state.dashboard_data.fees_in_last_month


                    fees = <div className="card fancy_card col-xl-3 col-xs-12">
                        <h4><a onClick={() => this.see_table("closed_request_with_fees")} style={{ color: '#000094', textDecoration: 'underline' }}>{name_lookup['closed_request_with_fees']}</a></h4>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th colSpan="3">Completed Requests (Average Fees) In Last...</th>
                                </tr>
                                <tr>
                                    <th>Day</th>
                                    <th>Week</th>
                                    <th>Month</th>
                                </tr>
                                <tr>
                                    <td>{closed_requests_in_last_day} (${closed_requests_in_last_day ? (fees_in_last_day / closed_requests_in_last_day).toFixed(2) : 0})</td>
                                    <td>{closed_requests_in_last_week} (${closed_requests_in_last_week ? (fees_in_last_week / closed_requests_in_last_week).toFixed(2) : 0})</td>
                                    <td>{closed_requests_in_last_month} (${closed_requests_in_last_month ? (fees_in_last_month / closed_requests_in_last_month).toFixed(2) : 0})</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>;
                }


                var get_closed = <Button onClick={this.get_closed} text='Load Closed Requests' type='info' />
                if (this.state.got_closed == 'Loading') {
                    get_closed = <Wrapper loaded={false} content={<p>Loading...</p>} />
                }
                else if (this.state.got_closed == 'Loaded') {
                    get_closed = <div><Icon icon='check' style={{ color: 'green' }} size={1} /> Loaded</div>
                }


                content = <div className="container-fluid">
                    <div className="row">
                        <div className="col-4">
                            <h2>Direct Docs Dashboard</h2>
                            <Button type='primary' text='Show Tables' onClick={() => this.setState({ show_tables: true })} />
                        </div>
                        <div className="col-4">
                            <h4>Open Requests</h4>
                            <h5><Icon icon='check' style={{ color: 'green' }} size={1} /> Loaded</h5>
                        </div>
                        <div className="col-4">
                            <h4>Closed Requests</h4>
                            <h5>{get_closed}</h5>
                        </div>
                    </div>
                    <div style={{ height: '50px' }}></div>
                    <div className="row">
                        <div className="col-12">
                            <h3>Totals</h3>
                            <div style={{ 'width': '100%', 'borderBottom': '1px solid #ccc', 'margin': '0px 0px 50px 0px' }}></div>
                        </div>

                        <div className="fancy_card card col-xl-3 col-xs-12" style={{ height: '150px' }}>
                            <h4>Total # of Law Firms</h4>
                            <h1>{law_firm_count}</h1>
                        </div>
                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-3 col-xs-12" style={{ height: '150px' }}>
                            <h4>Average Turnaround Time</h4>
                            <h1>{this.state.dashboard_data.average_time.toFixed(2)} Days</h1>
                        </div>
                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-3 col-xs-12" style={{ height: '150px' }}>
                            <h4>Total # of DD Employees</h4>
                            <h1>{Object.keys(this.state.dashboard_data.employees).length}</h1>
                        </div>

                        <div className="card fancy_card col-xl-3 col-xs-12">
                            <h4><a onClick={() => this.see_table("all_requests")} style={{ color: '#000094', textDecoration: 'underline' }}>Total DD Requests</a></h4>
                            <h1>{this.state.dashboard_data.total_requests}</h1>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th>Open</th>
                                        <th>Closed</th>
                                    </tr>
                                    <tr>
                                        <td>{this.state.dashboard_data.open_requests}</td>
                                        <td>{this.state.dashboard_data.closed_requests}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th colSpan="3">New Requests In Last</th>
                                    </tr>
                                    <tr>
                                        <th>Day</th>
                                        <th>Week</th>
                                        <th>Month</th>
                                    </tr>
                                    <tr>
                                        <td>{this.state.dashboard_data.requests_in_last_day}</td>
                                        <td>{(this.state.dashboard_data.requests_in_last_week)}</td>
                                        <td>{(this.state.dashboard_data.requests_in_last_month)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="col-1"></div>

                        {fees}
                        {
                            admin_users.indexOf(this.props.user.id) > -1 && 
                                <div className="col-1"></div>
                            
                        }
                        <div className="fancy_card card col-xl-3 col-xs-12" style={{ height: '150px' }}>
                            <h4>Total DD Fees for All Requests</h4>
                            <h1>${this.state.dashboard_data.total_price}</h1>
                        </div>


                        <div className="col-12" style={{ marginTop: '50px' }}>
                            <h3>Employees</h3>
                            <div style={{ 'width': '100%', 'borderBottom': '1px solid #ccc', 'margin': '0px 0px 50px 0px' }}></div>
                        </div>
                        {employees}

                        <div className="col-12" style={{ marginTop: '50px' }}>
                            <h3>Law Firms</h3>
                            <div style={{ 'width': '100%', 'borderBottom': '1px solid #ccc', 'margin': '0px 0px 50px 0px' }}></div>
                        </div>
                        {law_firms}

                        <div className="col-12" style={{ marginTop: '50px' }}>
                            <h3>Requests</h3>
                            <div style={{ 'width': '100%', 'borderBottom': '1px solid #ccc', 'margin': '0px 0px 50px 0px' }}></div>
                        </div>

                        <div className="card fancy_card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("new_requests")} style={{ color: '#000094', textDecoration: 'underline' }}>New Requests</a></h4>
                            <h1>{this.state.dashboard_data.new_requests}</h1>
                        </div>
                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("rush_requests")} style={{ color: '#000094', textDecoration: 'underline' }}>Rush Requests</a></h4>
                            <h1>{this.state.dashboard_data.rush_requests}</h1>
                        </div>
                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("un_assigned_requests")} style={{ color: '#000094', textDecoration: 'underline' }}>Unassigned Requests (Open)</a></h4>
                            <h1>{this.state.dashboard_data.unassigned_requests.open}</h1>
                        </div>
                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("need_your_attention_requests")} style={{ color: '#000094', textDecoration: 'underline' }}>Needs Your Attention</a></h4>
                            <h1>{this.state.dashboard_data.need_your_attention_requests}</h1>
                        </div>

                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("request_older_than_fifteen")} style={{ color: '#000094', textDecoration: 'underline' }}>Requests {'>'} 15 Days</a></h4>
                            <h1>{this.state.dashboard_data.requests_fifteen_days_old}</h1>
                        </div>

                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("request_older_than_thirty")} style={{ color: '#000094', textDecoration: 'underline' }}>Requests {'>'} 30 Days</a></h4>
                            <h1>{this.state.dashboard_data.requests_thirty_days_old}</h1>
                        </div>

                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("request_older_than_fortyfive")} style={{ color: '#000094', textDecoration: 'underline' }}>Requests {'>'} 45 Days</a></h4>
                            <h1>{this.state.dashboard_data.requests_fortyfive_days_old}</h1>
                        </div>

                        <div className="col-3"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("note_older_than_fifteen")} style={{ color: '#000094', textDecoration: 'underline' }}>Note {'>'} 15 Days</a></h4>
                            <h1>{this.state.dashboard_data.requests_fifteen_days_old_note}</h1>
                        </div>

                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("note_older_than_thirty")} style={{ color: '#000094', textDecoration: 'underline' }}>Note {'>'} 30 Days</a></h4>
                            <h1>{this.state.dashboard_data.requests_thirty_days_old_note}</h1>
                        </div>

                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("note_older_than_fortyfive")} style={{ color: '#000094', textDecoration: 'underline' }}>Note {'>'} 45 Days</a></h4>
                            <h1>{this.state.dashboard_data.requests_fortyfive_days_old_note}</h1>
                        </div>

                        <div className="col-3"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("todays_reminders")} style={{ color: '#000094', textDecoration: 'underline' }}>Today's Reminders</a></h4>
                            <h1>{this.state.dashboard_data.today_reminder_requests}</h1>
                        </div>

                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("old_reminders")} style={{ color: '#000094', textDecoration: 'underline' }}>Old Reminders</a></h4>
                            <h1>{this.state.dashboard_data.old_reminder_requests}</h1>
                        </div>

                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("requests_without_reminder")} style={{ color: '#000094', textDecoration: 'underline' }}>{name_lookup['requests_without_reminder']}</a></h4>
                            <h1>{this.state.dashboard_data.requests_without_reminders}</h1>
                        </div>

                        <div className="col-3"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("requests_in_last_day")} style={{ color: '#000094', textDecoration: 'underline' }}>{name_lookup['requests_in_last_day']}</a></h4>
                            <h1>{this.state.dashboard_data.requests_in_last_day}</h1>
                        </div>

                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("requests_in_last_week")} style={{ color: '#000094', textDecoration: 'underline' }}>{name_lookup['requests_in_last_week']}</a></h4>
                            <h1>{this.state.dashboard_data.requests_in_last_week}</h1>
                        </div>

                        <div className="col-1"></div>
                        <div className="fancy_card card col-xl-2 col-xs-12">
                            <h4><a onClick={() => this.see_table("requests_in_last_month")} style={{ color: '#000094', textDecoration: 'underline' }}>{name_lookup['requests_in_last_month']}</a></h4>
                            <h1>{this.state.dashboard_data.requests_in_last_month}</h1>
                        </div>



                    </div>
                </div>
            }
        }

        return (
            <Wrapper loaded={this.state.loaded} content={content} />
        )
    }

}

import React, { Component } from 'react';
import resolveVariables from 'base/resolver.js';
import ajaxWrapper from "base/ajax.js";
import {Alert, Button, PageBreak} from 'library';
//Example
//var answerProps = {'name':'response', 'value':''}
//var defaults = {'response':'', 'question':this.props.question_id, 'sid':this.props.user_id}
//var submitUrl = '/api/home/answer/';
//var redirectUrl = '/referenceGuide/' + this.props.question_id + '/';
//<Form components={[TextArea]} first={true} componentProps={[answerProps]} submitUrl={submitUrl} defaults={defaults} redirectUrl={redirectUrl}/>

class Form extends Component {
    constructor(props) {
        super(props);
        
        this.state = this.props.defaults;
        this.state.required = "";

        this.handleChange = this.handleChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.formDelete = this.formDelete.bind(this);
        this.formSubmitCallback = this.formSubmitCallback.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.setFormState = this.setFormState.bind(this);
        this.refreshDataCallback = this.refreshDataCallback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        if (this.props.dataUrl) {
            this.refreshData();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps.defaults)
    }

    setGlobalState(state) {
        
        if (this.props.autoSetGlobalState == true) {
            this.props.setGlobalState(this.props.globalStateName,state)
        }
    }

    handleChange = (e) => {
       var name = e.target.getAttribute("name");
       var newState = {};
       newState[name] = e.target.value;

        var newCompleteState = this.state;
        newCompleteState[name] = e.target.value;
       this.setState(newState, this.setGlobalState(newCompleteState));
    }

    setFormState(state) {
        
        var newState = this.state;
        for (var index in state) {
          newState[index] = state[index];
        }
        this.setState(state,  this.setGlobalState(newState));
    }

    refreshData() {
        
        if (this.props.dataUrl) {
            ajaxWrapper("GET",this.props.dataUrl, {}, this.refreshDataCallback);
        }
    }

    formSubmit() {
        
        var data = this.state;

        var failed = false;
        var required = '';
        for (var index in this.props.componentProps) {
          var prop = this.props.componentProps[index];
          if (prop.required == true) {
            if (this.state[prop.name] == '') {
              required += "The field " + prop.label + " must be filled out to submit the form. Fill out with NA if you don't know. ";
              failed = true;
            }
          }
        }
        if (failed == true) {
          this.setState({required: required})
        }
        else {

          for (var item in data) {
              if (item.endsWith('[]')) {
                  
                  data[item] = JSON.stringify(data[item]);
                  
              }
          }

          if (this.props.submit) {
            this.props.submit(data)
          }
          else {
            ajaxWrapper("POST",this.props.submitUrl, data, this.formSubmitCallback);
            this.setState({saving: true});
          }
        }
    }

    refreshDataCallback(value) {
        
        var newValue = value;
        if (this.props.first == true) {
            if (this.props.objectName) {
                newValue = value[0][this.props.objectName]
            }
            else {
                newValue = value[0];
            }
        }
        if (this.props.dataMapping) {
            if (value.length != undefined) {
                newValue = {'photos[]':[]}
                for (var index in value) {
                    
                    var tempValue = resolveVariables(this.props.dataMapping, value[index]);
                    
                    newValue['photos[]'].push(tempValue['photos[]']);
                }
            }
            else {
                newValue = resolveVariables(this.props.dataMapping, value);
            }
            
        }


        this.setState(newValue)

    }

    formSubmitCallback (value) {
        

        if (value['error']) {
            this.setState({error: value['error']})
        }

        else {

            if (typeof(value[0]) != 'undefined'){
                if (this.props.setGlobalState) {
                    if (this.props.globalStateName) {
                      var returnObj = value[0][this.props.objectName];
                      
                        this.setState(value[0][this.props.objectName], () => this.props.setGlobalState(this.props.globalStateName,value[0][this.props.objectName]));
                    } else {
                        this.setState(value[0][this.props.objectName], this.props.setGlobalState('Form',this.state));
                    }
                }
                else if (value['success'] == true) {
                  //do nothing
                }
                else {
                  if (value[0]) {
                    this.setState(value[0][this.props.objectName]);
                  }

                }
            }

            if (this.props.deleteRedirectUrl && value['success'] == true) {
              
              window.location.href = this.props.deleteRedirectUrl;

            }
            else if (this.props.redirectUrl && value['success'] == true) {
              window.location.href = this.props.redirectUrl;
            }
            else if (this.props.redirectUrl) {
              
                if (this.props.objectName) {
                     var redirectUrl = resolveVariables({'redirectUrl':this.props.redirectUrl}, value[0][this.props.objectName]);
                }
                else {
                    var redirectUrl = resolveVariables({'redirectUrl':this.props.redirectUrl}, value);
                }

                window.location.href = redirectUrl['redirectUrl'];
            }

            if (this.props.redirect) {
                value['form_state'] = this.state;
                this.props.redirect(value);
            }
            else if (this.props.refreshData) {
                this.props.refreshData();
            }

            this.setState({saving: false});
        }
    }

    formDelete() {
        ajaxWrapper("POST",this.props.deleteUrl, {}, this.formSubmitCallback.bind(this));
    }

    handleKeyPress = (event) => {
      if (this.props.submit_on_enter != false) {
        if(event.key == 'Enter') {
          
          this.formSubmit()
        }
      }
    }

    render() {
      
        var layout = "";
        if (typeof(this.props.layout) != 'undefined') {
            layout = this.props.layout
        }

        var classCss = "form";
        if (this.props.row == true) {
            classCss ="form-row";
            layout = 'row'
        }
        if (this.props.classCss != undefined) {
            classCss = this.props.classCss;
        }


        let components = [];

        for (var index in this.props.components) {
            var Component = this.props.components[index];
            var props = this.props.componentProps[index];
            if (index == 0) {
                if (this.props.autoFocus != false) {
                    props['autoFocus'] = true;
                }

            }

            if (props['names']) {
                var values = {}
                for (var nameIndex in props['names']) {
                    var name = props['names'][nameIndex];
                    values[name] = this.state[name]
                }
                if (this.props.row == true) {
                  if (props['header']) {
                    components.push(<div className="col-12"><Component {...props} handlechange={this.handleChange} setFormState={this.setFormState} {...values} /></div>)
                  }
                  else {

                      var col_size = this.props.col_size || (props && props.col_size) || '3';
                    components.push(<div className={"col-" + col_size}><Component {...props} handlechange={this.handleChange} setFormState={this.setFormState} {...values} /></div>)
                  }

                  if (props['break']) {
                    components.push(<div className="col-12"><PageBreak /></div>)
                  }
                }
                else {
                  components.push(<Component {...props} handlechange={this.handleChange} setFormState={this.setFormState} {...values} />)
                }

            }
            else {
              if (this.props.row == true) {
                if (props['header']) {
                  components.push(<div className="col-12"><Component {...props} handlechange={this.handleChange} setFormState={this.setFormState} value={this.state[props['name']]} /></div>)
                }
                else {
                  var col_size = this.props.col_size || (props && props.col_size) || '3';
                  components.push(<div className={"col-" + col_size}><Component {...props} handlechange={this.handleChange} setFormState={this.setFormState} value={this.state[props['name']]} /></div>)
                }

                if (props['break']) {
                  components.push(<div className="col-12"><PageBreak /></div>)
                }
              }
              else {
                components.push(<Component {...props} handlechange={this.handleChange} setFormState={this.setFormState} value={this.state[props['name']]} />)
              }

            }
        }

        var buttons = [];
        if (this.props.submitUrl || this.props.submit) {
            var classes = "btn btn-primary";
            if (this.props.submitButtonType) {
              classes = "btn btn-" + this.props.submitButtonType;
            }

            var clickProp = {onClick: this.formSubmit};


            if (this.state.saving){
                classes += ' disabled';
                clickProp = null;
            }
            var float = {'float':'left'}

            var deleteType = false;
            if (this.props.deleteType) {
                deleteType = true;
            }

            var submitButton = <Button css={float} type='primary' {...clickProp} deleteType={deleteType} text='Save' />
            if (this.state.uploading_from_file_input) {
                
                submitButton = <button css={float} className={classes + ' disabled'} >Waiting for File Upload...</button>
            }

            buttons.push(submitButton);
        }

        if (this.props.deleteUrl) {
            var float = {'float':'right'};
            var deleteButton = <Button css={float} type={"danger"} onClick={this.formDelete} deleteType={true} text={"Delete"} />
            buttons.push(deleteButton);
        }

        var failed = <div></div>;
        if (this.state.required != '') {
          
          failed = <Alert type={"danger"} text={this.state.required} />
        }

        // need to add in formsubmit, delete, and handle change functions to components.
        return(
            <div  style={this.props.css} onKeyPress={this.handleKeyPress}>
              <div className={layout}>
                {components}
              </div>
                {failed}
                {buttons}
            </div>
        )
    }
}

export default Form;

import React, { Component } from 'react';
import ajaxWrapper from "base/ajax.js";
import Wrapper from 'base/wrapper.js';

import {Form, TextInput, Select, PasswordInput, Header, TextArea, NumberInput, DateTimePicker, FileInput} from 'library';

class EditCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {'name' : '', 'admin_name' : '', 'admin_email' : '', 'admin_phone' : '', 'admin_fax' : '', 'address_1' : '', 'address_2' : '', 'city' : '', 'state' : '', 'zip_code' : '', 'phone_number' : '', 'fax_number' : '', 'release_form_url' : ''};

        this.objectCallback = this.objectCallback.bind(this);
    }

    componentDidMount(value) {
        if(this.props.company_id) {
          console.log("Url", '/api/home/company/' + this.props.company_id + '/')
          ajaxWrapper('GET','/api/home/company/' + this.props.company_id + '/', {}, this.objectCallback);
        }
        else {
          this.setState({loaded:true})
        }
    }

    objectCallback(result) {
      console.log("Result",result)
      var company = result[0]['company'];
      company['loaded'] = true;
      this.setState(company)
    }

    render() {

			var Components = [TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, NumberInput, FileInput ];

			var name = {'name': 'name', 'label': 'Company Name', 'placeholder': 'Name', 'value': ''};
			var admin_name = {'name': 'admin_name', 'label': 'Company Administrators Name', 'placeholder': 'Name', 'value': ''};
			var admin_email = {'name': 'admin_email', 'label': 'Company Administrator Email', 'placeholder': 'admin@...', 'value': ''};
			var admin_phone = {'name': 'admin_phone', 'label': 'Company Administrator Phone Number', 'placeholder': '(303) 123-4567', 'value': ''};
			var admin_fax = {'name': 'admin_fax', 'label': 'Company Fax Number', 'placeholder': '(303) 123-4567', 'value': ''};
			var address_1 = {'name': 'address_1', 'label': 'Address 1', 'placeholder': '123 Street', 'value': ''};
			var address_2 = {'name': 'address_2', 'label': 'Address 2', 'placeholder': 'Apt 102', 'value': ''};
			var city = {'name': 'city', 'label': 'City', 'placeholder': 'City', 'value': ''};
			var state = {'name': 'state', 'label': 'State', 'placeholder': 'State', 'value': ''};
			var zip_code = {'name': 'zip_code', 'label': 'Zip Code', 'placeholder': '', 'value': ''};
			var phone_number = {'name': 'phone_number', 'label': 'Company Main Number', 'placeholder': '(303) 123-4567', 'value': ''};
            var request_price = {'name': 'request_price', 'label': 'Request Price', 'placeholder': '35', 'value': 35};

			var release_form_url = {bucket_name: 'companyforms', 'name': 'release_form_url', 'label': 'Signed Release Form For Gathering Documents', 'placeholder': 'Release_Form_Url', 'value': ''};
            var ComponentProps = [name, admin_name, admin_email, admin_phone, admin_fax, phone_number, address_1, address_2, city, state, zip_code, request_price, release_form_url];
      if (this.props.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684') {
          Components = [TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, TextInput, NumberInput, FileInput];

          ComponentProps = [name, admin_name, admin_email, admin_phone, admin_fax, phone_number, address_1, address_2, city, state, zip_code, request_price, release_form_url];
      }




        var defaults = this.state;

        var submitUrl = "/api/home/company/";
        if (this.props.company_id) {
          submitUrl += this.props.company_id + '/';
        }

        var deleteUrl = undefined;
        if (this.props.company_id) {
          deleteUrl = "/api/home/company/" + this.props.company_id + "/delete/";
        }


        var title = <Header text={'Create New Company'} size={2} />
        if (this.props.company_id) {
          title = <Header text={'Edit Company: ' + this.state.name} size={2} />
        }

        var redirectUrl = '/inviteUsers/{id}/'

        if (this.props.company_id && this.props.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684') {
          redirectUrl = '/companyList/';
        }
        else if (this.props.company_id) {
            redirectUrl = '/dashboard/';
        }


        var content = <div className="container-fluid">
                {title}
                <Form components={Components} redirectUrl={redirectUrl} objectName={'company'} componentProps={ComponentProps} deleteUrl={deleteUrl} submitUrl={submitUrl} defaults={defaults} />
                <br />
        </div>;

        return (
          <div>
            <Wrapper loaded={this.state.loaded} content={content} />
          </div>
             );
    }
}
export default EditCompany;

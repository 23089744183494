import React, { Component } from 'react';
import {Image} from 'library';

class Wrapper extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        var saving = <div style={{
            textAlign:'center', position:'absolute',top:'0px',
            width:'100%', height:'100%', background: 'rgba(0,0,0,.4)',
            padding: '10%'
        }}>
            <h2 style={{color:'white'}}>Saving... Please do not turn off console</h2>
            <Image style={{'width':'100px'}} src={'/static/images/loading.gif'} />
            <br/><br/>
            {this.props.saving_content}
        </div>;

        var loading = <div style={{'textAlign':'center'}}> <Image css={{'width':'100px'}} src={'/static/images/loading.gif'} /></div>;
        var style = {'paddingLeft':'0px','paddingRight':'0px', paddingTop: '0px'};
        if (this.props.css) {
            style = this.props.css;
        } else if (this.props.style) {
            style = this.props.style;
        }

        style['paddingLeft'] = '0px'
        style['paddingRight'] = '0px'

        if (!this.props.loaded) {
          style['marginTop'] = '50px'
        }

        return (
                <div className="container-fluid" style={style}>
                    {(this.props.saving) ? saving : null}
                    {(this.props.loaded) ? this.props.content : loading}
                </div>
        );
    }
}

export default Wrapper;

import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Header, Button} from 'library';

class Footer extends React.Component {

    render() {
        return (
          <div style={{backgroundImage: 'linear-gradient(30deg, ' + this.props.startingColor + ' 0%, ' + this.props.endingColor + ' 100%)', paddingTop:'100px', paddingBottom:'100px'}}>
            <div className="container" style={{'display':'flex', justifyContent:'space-between'}}>
              <h1 style={{'fontWeight':'bold', color:'white', paddingRight:'20px', paddingLeft:'20px'}}>{this.props.title}</h1>
              <Button text={this.props.cta} href={this.props.href} type={'outline-light'} style={{whiteSpace:'normal', fontWeight:'bold', fontSize:'14px', padding: '15px 40px 15px 40px', width:'270px'}}/>
            </div>
          </div>
        );
    }
}


export default Footer;

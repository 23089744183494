import React, { Component } from 'react';
import ajaxWrapper from "base/ajax.js";
import Wrapper from 'base/wrapper.js';

import {Form, TextInput, Select, PasswordInput, Header, TextArea, NumberInput, DateTimePicker, FileInput, Alert, Button} from 'library';

class Div extends Component {
    render() {
        return (<div></div>)
    }
}

class EditPatient extends Component {
    constructor(props) {
        super(props);

        this.state = {new_patient: {}, company: this.props.company, 'created_at' : '', 'updated_at' : '', 'first_name' : '', 'last_name' : '', 'maiden_name' : '', 'date_of_birth' : '', 'custom_code' : '', 'address_1' : '', 'address_2' : '', 'city' : '', 'state' : '', 'zip_code' : '', 'email' : '', 'phone_number' : '', 'fax_number' : '', 'hipaa_consent_url':'', ssn:'', match:null, error: ''};

        this.objectCallback = this.objectCallback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.save = this.save.bind(this);
        this.saveCallback = this.saveCallback.bind(this);
        this.selectMatch = this.selectMatch.bind(this);
        this.patient_callback = this.patient_callback.bind(this);
        this.related_info_callback = this.related_info_callback.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    componentDidMount(value) {
        if(this.props.patient_id) {
          ajaxWrapper('GET','/api/home/patient/' + this.props.patient_id + '/', {}, this.objectCallback);
        }
        else {
          this.setState({loaded:true})
        }
    }

    objectCallback(result) {
      var patient = result[0]['patient'];
      patient['loaded'] = true;
      this.setState(patient)

      ajaxWrapper('POST','/get_related_info/', {patient_id: patient.id}, this.related_info_callback)

    }

    related_info_callback(result) {
        this.setState({ssn: result['related_info']})
    }

    setGlobalState(name,state) {
        this.setState(state);
    }

    save() {
        console.log("Saving")
        var fields = ['first_name','last_name','maiden_name','date_of_birth','custom_code','ssn','address_1','address_2','city','state','zip_code','hipaa_consent_url'];
        var error = '';
        for (var index in fields) {
            if (this.state[fields[index]] == '') {
                error += fields[index] + ' is a required field. Please fill it out or if you dont know it, enter NA. ';
            }
        }

        var ssn = this.state['ssn'];
        if (ssn.toLowerCase() != 'none') {
            var ssn_regex = /\d\d\d-\d\d-\d\d\d\d/g
            if (!ssn.match(ssn_regex)) {
                error += 'SSN must match the following format ddd-dd-dddd, where d is any number 0-9. Ex. 123-45-6789'
            }
        }


        if (error == '') {
            if (this.props.patient_id) {
                var data = Object.assign({}, this.state);
                delete data['ssn']
                ajaxWrapper('POST','/api/home/patient/' + this.props.patient_id + '/', data, this.patient_callback)
            }
            else {
                if (ssn.toLowerCase() != 'none') {
                    ajaxWrapper('GET','/api/home/patient/?company=' + this.props.company + '&ssn=' + this.state.ssn, {}, this.saveCallback)
                }
                else {
                    var data = Object.assign({}, this.state);
                    delete data['ssn']
                    ajaxWrapper('POST','/api/home/patient/', data, this.patient_callback)
                }
            }

        }
        else {
            this.setState({error:error})
        }

    }

    patient_callback(result) {
        this.setState({new_patient: result[0].patient})
        ajaxWrapper('POST','/save_related_info/', {'patient_id': result[0].patient.id, 'ssn': this.state.ssn}, this.redirect)
    }

    saveCallback(result) {
        console.log("Results", result)
        if (result.length > 0) {
            console.log("Match")
            this.setState({match: result[0]['patient']})
        }
        else {
            var data = Object.assign({}, this.state);
            delete data['ssn']
            ajaxWrapper('POST','/api/home/patient/', data, this.patient_callback)
        }
    }

    selectMatch() {
        this.props.redirect([{'patient':this.state.match}])
    }

    redirect(result) {
        var patient = this.state.new_patient;
        patient['ssn'] = this.state.ssn;
        this.props.redirect([{'patient':patient}])
    }

    render() {

			var Components = [ Header, TextInput, TextInput, TextInput, DateTimePicker, TextInput, TextInput, Header, TextInput, TextInput, TextInput, TextInput, TextInput, Header, FileInput, Div, Alert]

      var name_header = {'text':'Basic Info', size: 5, header:true}
			var first_name = {'name': 'first_name', 'label': 'First Name', 'placeholder': '', 'value': '', required:true};
			var last_name = {'name': 'last_name', 'label': 'Last Name', 'placeholder': '', 'value': '', required:true,};
			var maiden_name = {'name': 'maiden_name', 'label': 'Maiden Name', 'placeholder': '', 'value': '', required: true, };
			var date_of_birth = {'name': 'date_of_birth', 'label': 'Date Of Birth', 'placeholder': '', 'value': false, 'display_time': false, required: true, };
			var custom_code = {'name': 'custom_code', 'label': 'Custom Code', 'placeholder': '', 'value': '', required: true, };
            var ssn = {'name':'ssn', label:'SSN (Write "None" if have no SSN)', 'placeholder':'', value:'', required: true, }
      var address_header = {'text':'Address', size: 5, header:true}
			var address_1 = {'name': 'address_1', 'label': 'Address 1', 'placeholder': '', 'value': '', required: true};
			var address_2 = {'name': 'address_2', 'label': 'Address 2', 'placeholder': '', 'value': '', required: true};
			var city = {'name': 'city', 'label': 'City', 'placeholder': '', 'value': '', required: true};
			var state = {'name': 'state', 'label': 'State', 'placeholder': '', 'value': '', required: true};
			var zip_code = {'name': 'zip_code', 'label': 'Zip Code', 'placeholder': '', 'value': '', required: true};
      var contact_header = {'text':'Contact Info', size: 5, header:true}
			var email = {'name': 'email', 'label': 'Email', 'placeholder': '', 'value': '', required: true};
			var phone_number = {'name': 'phone_number', 'label': 'Phone Number', 'placeholder': '', 'value': '', required: true};
			var fax_number = {'name': 'fax_number', 'label': 'Fax Number', 'placeholder': '', 'value': '', required: true};
            var hipaa_consent_url = {'bucket_name': 'hipaaforms', 'name': 'hipaa_consent_url', 'label': 'Signed Release Form For HIPAA', 'placeholder': 'Release_Form_Url', 'value': '', required:true, className: 'col-4'};
            var alert = {'text':'Make sure your HIPAA Form is signed and dated.', 'type':'primary', className:'col-8'}
			var ComponentProps = [name_header, first_name, last_name, maiden_name, date_of_birth, custom_code, ssn, address_header,
                            address_1, address_2, city, state, zip_code, contact_header, hipaa_consent_url, {}, alert]

        var defaults = this.state;

        var submitUrl = "/api/home/patient/";
        if (this.props.patient_id) {
          submitUrl += this.props.patient_id + '/';
        }

        var deleteUrl = undefined;
        if (this.props.patient_id) {
          deleteUrl = "/api/home/patient/" + this.props.patient_id + "/delete/";
        }


        var title = <Header text={'Create New Client'} size={2} />
        if (this.props.patient_id) {
          title = <Header text={'Edit Client Info'} size={2} />
        }

        var match = null;
        if (this.state.match) {
            match = <Alert type='success' text={<p>This SSN matches another client, {this.state.match.first_name} {this.state.match.last_name}, if you meant to add this client, <Button type='primary' text='click here' onClick={this.selectMatch} />. Otherwise, double check the SSN.</p>} />
        }

        var error = null;
        if (this.state.error != '') {
            error = <Alert type='danger' text={this.state.error} />
        }

        var save_button = <Button type='primary' text='Save' onClick={this.save} />
        if (this.state.uploading_from_file_input) {
            save_button = <Button type='dark' text='Uploading File...' />
        }

        var content = <div className="container">
                {title}
                <Form row={true} col_size={2} components={Components} objectName={'patient'} componentProps={ComponentProps} defaults={defaults} autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'patient_form'} />
                {save_button}
                <br />
                {match}
                {error}
        </div>;

        return (
          <div>
            <Wrapper loaded={this.state.loaded} content={content} />
          </div>
             );
    }
}
export default EditPatient;

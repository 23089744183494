import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Navbar, Link} from 'library';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class Nav extends React.Component {
    constructor(props) {
      super(props);
      this.state = {'pages':[], loaded:true}

    }

    render() {
        var links = [];
        var name = <div><img style={{'marginRight':'10px'}} src='/static/images/logo.png' height="125" /></div>;

        if (this.props.user.id) {
          var links = [['/dashboard/', 'Dashboard']];
        }
        else {
          links = [['/','Home'],['/about-us/','About Us'],['/contact-us/','Contact Us'],['/login/','Log In']]
        }

        if (this.props.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684') {
          links.push(['/invoices/', 'Invoices']);
          links.push(['/billings/', 'Billings']);
          links.push(['/companyList/','Company List']);
          links.push(['/providerList/','Provider List']);
          links.push(['/list_request_types/','Request Types']);
          links.push(['/formlist/','Edit Mappings']);
        }
        else if (this.props.user.is_staff) {
          console.log("Props", this.props);
          links.push(['/invoice/' + this.props.user.company_id, 'Invoice']);
          links.push(['/billings/', 'Billings']);
          links.push(['/editCompany/' + this.props.user.company_id + '/','Edit Company Details']);
          links.push(['/inviteUsers/' + this.props.user.company_id + '/','Invite Users']);
        }

        if (this.props.user.id) {
          links.push(['/logOut/', 'Log Out']);
        }

        var linkList = [];
        for (var index in links) {
          linkList.push(<Link href={links[index][0]} text={links[index][1]} style={{'color':'black', fontWeight:'bold'}} hoverStyle={{'color':'#3957ff', borderBottom:'2px solid #3957ff', fontWeight:'bold'}} />)
        }


        return (
          <nav class="navbar navbar-light justify-content-between" style={{'backgroundColor':'#f8f8f8', height:'80px', borderBottom:'1px solid #ccc'}}>
            <div className="container-fluid" style={{'backgroundColor':'#f8f8f8'}}>
              <a class="navbar-brand" style={{'fontWeight':'bold', fontSize:'32px'}} href={'/'}><img src={'https://companyforms.s3.us-east-2.amazonaws.com/transparent_dd_logo.png'} style={{'height':'65px'}}/></a>

              <BrowserView>

              <ul class="nav navbar-nav navbar-right" style={{'textAlign':'center', flexDirection:'row'}}>
                {linkList}
              </ul>

              </BrowserView>
              <MobileView>
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="nav navbar-nav navbar-right">
                    {linkList}
                  </ul>
                </div>
              </MobileView>
            </div>
          </nav>
        );
    }
}


export default Nav;

import React, { Component } from "react";
import ReactPaginate from 'react-paginate';
import Wrapper from "base/wrapper.js";
import ajaxWrapper from "base/ajax.js";
import {
  Container,
  Button,
  Image,
  Form,
  DateTimePicker,
  TextInput,
  Navbar,
  List,
  Link,
  Accordion,
  Paragraph,
  RadioButton,
  TextArea,
  Header,
  Card,
  MultiLineText,
} from "library";

class RequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [], start_date: "", end_date: "", direction: "Ascending",
      page_offset: 1,
      last_page: false,
      total_requests_count: 0,
      limit: 10,
      default_sorting: "Ascending",
      default_name: "status",
      next: "",
      previous: "",
      total_count: 0,
      loader: true,
      page_count: 0,
    };

    this.objectCallback = this.objectCallback.bind(this);
    this.setGlobalState = this.setGlobalState.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handleApi = this.handleApi.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
  }
  handlePageClick = (event) => {
    this.setState({ page_offset: event.selected + 1 })
    this.handleApi(this.state.limit, event.selected + 1)
  };

  handleApi(limit, page_offset) {
    this.setState({ loader: true })
    ajaxWrapper("GET", `/api/invoices/?page=${page_offset}&page_size=${limit}`, {}, this.objectCallback);
  }

  handleSorting = (e) => {
    this.setState({ default_sorting: e.target.value });
  };

  handleLimitChange(e) {
    this.setState({ limit: parseInt(e.target.value) });
    if (parseInt(e.target.value) > this.state.total_count) {
      this.setState({ page_offset: 1 });
    }
    if (this.state.page_offset * parseInt(e.target.value) > this.state.total_count) {
      this.setState({ page_offset: parseInt(this.state.total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.total_count / parseInt(e.target.value)) });
    }
  }

  componentDidMount() {
    this.handleApi(10, this.state.page_offset)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.limit !== prevState.limit) {
      this.handleApi(this.state.limit, this.state.page_offset)
    }
  }

  objectCallback(result) {
    var requests = [];
    for (var index in result) {
      var request = result[index];
      requests.push(request);
    }
    this.setState({ companies: requests[3], loaded: true });
    this.setState({ next: requests[1], loaded: true });
    this.setState({ previous: requests[2], loaded: true });
    this.setState({ total_count: requests[0], loaded: true });
    this.setState({ page_count: Math.ceil(this.state.total_count / this.state.limit) })
    this.setState({ loader: false })
  }

  setGlobalState(name, state) {
    this.setState(state);
  }

  render() {
    var requests = [];

    var start = 0;
    if (this.state.start_date != "") {
      start = Date.parse(this.state.start_date);
    }

    var end = 99999999999999999999999999999999;
    if (this.state.end_date != "") {
      end = Date.parse(this.state.end_date);
    }

    for (var index in this.state.companies) {
      var company = this.state.companies[index];

      var rush_requests = 0;
      var normal_requests = 0;

      for (var patient_index in company["patients"]) {
        var patient = company["patients"][patient_index]["patient"];
        for (var request_index in patient["requests"]) {
          var request = patient["requests"][request_index]["request"];
          var created_at = Date.parse(request.created_at);

          if (start < created_at && end > created_at && request.active) {
            if (request.rush) {
              rush_requests += 1;
            } else {
              normal_requests += 1;
            }
          }
        }
      }
      requests.push(
        <tr>
          <td style={{ width: "60%" }}>
            <a href={"/invoice/" + company.company_id + "/"}>
              {company.company}
            </a>
          </td>
          <td>{company.normal}</td>
          <td>{company.rush}</td>
        </tr>
      );
    }

    var start_date = {
      name: "start_date",
      label: "From:",
      display_time: false,
    };
    var end_date = { name: "end_date", label: "To:", display_time: false };

    var content = (
      <div className="container-fluid">
        <Header size={2} text={"Requests"} />
        <Form
          components={[DateTimePicker, DateTimePicker]}
          componentProps={[start_date, end_date]}
          autoSetGlobalState={true}
          setGlobalState={this.setGlobalState}
          globalStateName={"form"}
          defaults={this.state}
        />
        <br />
        <div style={{
          margin: "20px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center"
        }}>
          <div>
            Total Records : {this.state.total_count}
          </div>
          <div style={{
            display: 'flex',
            alignItems: "center",
            marginLeft: "20px"
          }}>
            {
              this.state.total_count > 0 ?
                <select
                  className="form-control"
                  style={{ width: "85px", marginRight: "5px" }}
                  onChange={this.handleLimitChange}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>40</option>
                  <option>50</option>
                </select> : ""
            }
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={this.handlePageClick}
              pageRangeDisplayed={10}
              marginPagesDisplayed={3}
              pageCount={this.state.page_count}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
              className="custom-pagination"
              forcePage={this.state.page_offset - 1}
            />
          </div>
        </div>
        <table className="table">
          <tr>
            <th>Company</th>
            <th>Normal Requests</th>
            <th>Rush Requests</th>
          </tr>

          {requests}
        </table>
      </div>
    );

    return <Wrapper loaded={this.state.loaded} content={content} />;
  }
}

export default RequestList;

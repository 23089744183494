import React, { Component } from 'react';
import resolveVariables from 'base/resolver.js';
import ajaxWrapper from "base/ajax.js";
import {Button} from 'library';


//Example
// var gender =  {'value':'','name':'gender','label':'Gender','options':[{'value':'Pick One','text':'Pick One'}, {'value':'Male', 'text':'Male'}, {'value':'Female','text':'Female'},{'value':'Other','text':"I don't identify as either"}]}
class MultiSelectOption extends Component {
    constructor(props) {
        super(props);

        this.removeSelection = this.removeSelection.bind(this);
    }

    removeSelection() {
        this.props.removeSelection(this.props.value);
    }

    render() {
        return (
            <Button onClick={this.removeSelection} type={'danger'} text={this.props.text} />
        )
    }
}


class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {options:[]}

        this.optionsCallback = this.optionsCallback.bind(this);
        this.handlechange = this.handlechange.bind(this);
        this.removeSelection = this.removeSelection.bind(this);
    }

    componentDidMount() {
        if (this.props.optionsUrl) {
          console.log("Options Url", this.props.optionsUrl);
            ajaxWrapper("GET", this.props.optionsUrl, {}, this.optionsCallback.bind(this));
        }
    }

    optionsCallback(value) {
        var options = [];
        for (var index in value) {
            var textValue = value[index];
            var valueValue = value[index];

            if (this.props.optionsUrlMap) {
                var resolvedValue = resolveVariables(this.props.optionsUrlMap, textValue);
                textValue = resolvedValue['text']
                valueValue = resolvedValue['value']
            }
            options.push({'text':textValue,'value':valueValue});

        }
        console.log("Options",options);
        this.setState({options:options})
    }

    handlechange = (e) => {

      var selection = e.target.value;
      console.log(selection);
      var newState = {}
      if (this.props.multiple == true) {
        var value = this.props.value;
        var index = value.indexOf(selection);
        if (index == -1) {
          value.push(selection)
        }
        newState[this.props.name] = value;
      }
      else {
        newState[this.props.name] = selection;
      }
      console.log("New State",newState);
       this.props.setFormState(newState);
    }

    removeSelection(selection) {
      var value = this.props.value;
      var index = value.indexOf(selection);
      console.log("Value", typeof value, value)
      value.splice(index,1);
      console.log("Value",value)
      var newState = {}
      newState[this.props.name] = value;
      this.props.setFormState(newState);
    }

    render() {
        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        if (this.props.multiple == true) {
            var value = this.props.value;
            if (this.props.value) {
              if (value.length == 0) {
                value = this.props.defaultoption;
              }
            }
        }
        else {
          var value = this.props.value;
          if (value == '') {
              value = this.props.defaultoption;
          }
        }

        var optionDict = this.props.options;
        if (!(this.props.options)){
            optionDict = this.state.options;
        }

        if (this.props.defaultoption in optionDict) {
            var options = [];
        }
        else {
            var options = [<option key={-1}>{this.props.defaultoption}</option>];
        }

        for (var index in optionDict) {
            var text = optionDict[index]['text'];
            if (text.length > 110) {
                text = text.substring(0,107) + '...';
            }
            options.push(<option key={index} selected={optionDict[index] && optionDict[index]["selected"]} value={optionDict[index]['value']}>{text}</option>)
        }

        var multipleSelections = []

        if (this.props.multiple == true) {
            multipleSelections.push(<p><b>Selections Below (Click selected items to deselect)</b></p>)

          var optionsDict = {}
          for (var index in optionDict) {
            optionsDict[optionDict[index]['value']] = optionDict[index]['text']
          }

          var empty = true;
          for (var index in value) {
              empty = false;
            multipleSelections.push(<MultiSelectOption key={this.props.name + '-' + index} removeSelection={this.removeSelection} value={value[index]} text={optionsDict[value[index]]} />)
          }

          if (empty) {
              multipleSelections.push(<p>No current selections</p>)
          }
        }

        var label = [];
        if (this.props.label) {
            label.push(<label>{this.props.label}</label>);
            label.push(<br />);
        }

        return (
            <div className={"form-group " + this.props.layout} style={this.props.style} >
                {label}
                <select className="form-control" name={this.props.name} onChange={this.handlechange} value={value} disabled={this.props.disabled}>
                    {options}
                </select>
                {multipleSelections}
            </div>
        )
    }

}

export default Select;


import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import sort_objects from 'base/sort_objects.js';
import {Container, Button, Image, Form, TextInput, Navbar, List, Select, Link,
    Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText,
    PageBreak, ListWithChildren, FormWithChildren} from 'library';
import format_date from 'base/date_format.js';
import format_date_string from 'base/date_string_format.js';
import Firms from './firms.js';
import Paralegals from './paralegals.js';
import AllRequests from './all_requests.js';

function compare(a, b) {
    if (a['patient']['last_name'] > b['patient']['last_name']) return 1;
    if (a['patient']['last_name'] < b['patient']['last_name']) return -1;
    return 0;
}

class ToDo extends Component {
    constructor(props) {
        super(props);
        this.claimTask = this.claimTask.bind(this);
        this.releaseTask = this.releaseTask.bind(this);
    }

    claimTask() {
        ajaxWrapper('POST','/api/home/request/' + this.props.id + '/', {'working_on':this.props.logged_in_user.id}, this.props.refreshData);
    }

    releaseTask() {
        ajaxWrapper('POST','/api/home/request/' + this.props.id + '/', {'working_on':null}, this.props.refreshData);
    }

    render() {
        var request = this.props;
        var backgroundColor = '#fff'
        var rush = '';
        if (request.rush) {
            var backgroundColor = '#ff8f8f'
            rush = 'Yes';
        }

        var working_button = <Button type='success' text='Claim' onClick={this.claimTask} />;
        console.log("Request", request)
        if (request.working_on && request.working_on.unicode != "None") {
            if (request.working_on.id == this.props.logged_in_user.id) {
                var working_button = <Button type='danger' text='Release' onClick={this.releaseTask} />;
            }
            else {
                var working_button = <p>{request.working_on.first_name} {request.working_on.last_name}</p>;
            }
        }

        var reminder_date = null;
        if (request.next_todo_date && request.next_todo_date.length) {
            reminder_date = format_date_string(request.next_todo_date,'mm/dd/yyyy')
        }

        return (
            <tr style={{backgroundColor: backgroundColor}}>
              <td>{working_button}</td>
              <td><a href={"/request/" + request.id + '/'} target={'_blank'} >View</a></td>
              <td>{request.patient.first_name} {request.patient.last_name}</td>
              <td>{request.provider.name}</td>
              <td>{this.props.patient.company.name}</td>
              <td>{request.user.first_name} {request.user.last_name}</td>
              <td>{request.requester.first_name} {request.requester.last_name}</td>
              <td>{this.props.status}</td>
              <td>{format_date_string(request.created_at,'mm/dd/yyyy HH:MM', false)}</td>
              <td>{reminder_date}</td>
              <td>{rush}</td>
              </tr>
        )
    }
}

class RequestList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requests:[],
            reminders:[],
            companies: [],
            companyLookup: {},
            request_status_filter: 'open',
            todo:true,
            claimed_by: '',
            client_sorted: null,
            created_at_sorted: false,
            next_todo_sorted: false,
            recent_sort: 'date',
        }

        this.form_defaults = {
            patient: '',
            lawyer: '',
            provider: '',
            status: '',
            company: '',
            requester: '',
            claimed_by: '',
        };

        this.reminderCallback = this.reminderCallback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.companyCallback = this.companyCallback.bind(this);
        this.employeeCallback = this.employeeCallback.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.setCompany = this.setCompany.bind(this);
        this.setParalegal = this.setParalegal.bind(this);
        this.todo_toggle = this.todo_toggle.bind(this);

        this.client_sort = this.client_sort.bind(this);
        this.created_at_sort = this.created_at_sort.bind(this);
        this.next_todo_sort = this.next_todo_sort.bind(this);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    setCompany(name) {
        this.setState({company_filter: name});
    }

    setParalegal(name, status) {
        this.setState({lawyer_filter: name, request_status_filter: status});
    }

    componentDidMount() {
      this.refreshData();

      window.cmState.setGlobalState('filter_form', this.form_defaults);
      //setInterval(this.refreshData, 30000);
    }

    todo_toggle() {
        this.setState({todo: !this.state.todo});
    }

    refreshData() {
        var today = format_date(new Date(), 'yyyy-mm-dd');
        console.log("Today",today);

        ajaxWrapper('GET','/api/user/user/?company_id=e4f3c0a1-5e41-4541-98e4-8288353c7684', {}, this.employeeCallback);
        //ajaxWrapper('GET','/api/home/notes/?related=request,request__provider,request__patient,request__user,request__requester,request__working_on,request__notes&reminder_date__lte=' + today, {}, this.reminderCallback)
    }

    employeeCallback(result) {
        var employees = [];
        for (var index in result) {
            var user = result[index]['user'];
            employees.push({'text': user.full_name, 'value':user.id});
        }
        this.setState(
            {'employees':employees},
            function(){ajaxWrapper('GET','/api/home/company/', {}, this.companyCallback);}
        );
    }

    companyCallback(result) {
        var companies = []
        var companyLookup = {}
        for (var index in result) {
          var request = result[index]['company'];
          companies.push(request)
          companyLookup[request.id.toString()] = request;
        }
        this.setState({'companies':companies, companyLookup: companyLookup, loaded:true});
    }

    reminderCallback(result) {
      console.log("Redminder Result", result);
      var reminders = [];
      var request_ids = [];
      for (var index in result) {
        var request = result[index]['notes']['request'];

        var ordered_notes = sort_objects(request.notes, ['notes','created_at']);
        var last_note = ordered_notes[ordered_notes.length - 1]['notes'];

        if (last_note['id'] == result[index]['notes']['id'] && last_note['reminder_date'] != null) {
            if (request['status'].indexOf('Closed') == -1) {
                if (request_ids.indexOf(request.id) == -1) {
                    request_ids.push(request.id);
                    request['reminder_date'] = last_note['reminder_date'];
                    reminders.push(request);
                }
            }

        }
      }
      this.setState({'reminders':reminders});
    }


    client_sort() {
        this.setState({client_sorted: !this.state.client_sorted, recent_sort:'client'})
    }

    created_at_sort() {
        this.setState({created_at_sorted: !this.state.created_at_sorted, recent_sort:'date'})
    }

    next_todo_sort() {
        this.setState({next_todo_sorted: !this.state.next_todo_sorted, recent_sort:'todo'})
    }

    render() {
      console.log("Here");
      var content = null;

      if (this.state.loaded) {
        var today = new Date();

        var company_options = [];
        for (var index in this.state.companyLookup) {
            var company = this.state.companyLookup[index];
            company_options.push({'text':company.name, 'value':company.id});
        }

        var status_options = [
            {'text':'Requested By Attorney', value:'Requested By Lawyer'},
            {'text':'Initial Request Sent', value:'Initial Request Sent'},
            {'text': 'In Contact With Provider', 'value': 'In Contact With Provider'},
            {'text': 'Documents en Route', 'value': 'Documents en Route'},
            {'text': 'Need Assistance', value: 'Need Assistance'},
            {'text': 'Waiting for Direct Docs Response', value: 'Waiting for Direct Docs Response'},
            {'text': 'Completed - Documents Arrived', 'value': 'Completed - Documents Arrived'},
            {'text': 'Completed - No Documents Available', 'value': 'Completed - No Documents Available'},
            {'text': 'Closed - Documents Arrived', 'value': 'Closed - Documents Arrived'},
            {'text': 'Closed - No Documents Available', 'value': 'Closed - No Documents Available'},
            {'text': 'Closed - Cancelled Request', 'value': 'Closed - Cancelled Request'},
        ];
        var patient_filter = {'name':'patient', label:'Client'};
        var lawyer_filter = {'name':'lawyer', label:'Paralegal'};
        var provider_filter = {'name':'provider', label:'Provider'};
        var status_filter = {'name':'status', label:'Status', options: status_options};
        var company_filter = {'name':'company', label:'Law Firm', options: company_options};
        var requester_filter = {'name':'requester', label:'Requestor'};
        var claimed_by = {'name':'claimed_by', label:'Claimed By', options: this.state.employees};

        var filter_form = <FormWithChildren autoSetGlobalState={true} globalStateName={'filter_form'} defaults={this.form_defaults} className={'row'} >
            <Select {...company_filter} layout={'col-sm-2'} />
            <TextInput {...patient_filter} layout={'col-sm-2'} />
            <TextInput {...provider_filter} layout={'col-sm-2'} />
            <TextInput {...lawyer_filter} layout={'col-sm-2'} />
            <TextInput {...requester_filter} layout={'col-sm-2'} />
            <Select {...status_filter} layout={'col-sm-2'} />
            <Select {...claimed_by} layout={'col-sm-2'} />
        </FormWithChildren>;

        var header = <Header size={2} text={'All Requests'} />;

        var data_url = '/api/home/request/?related=provider,patient,user,requester,working_on,patient__company';

        if (this.state.todo){
          header = <Header size={2} text={'To Do List'} />;
          var today = format_date(new Date(), 'yyyy-mm-dd');
          data_url += '&next_todo_date__lt=' + today;
        }

        data_url += '&patient__company={filter_form.company}';
        data_url += '&patient__full_name__icontains={filter_form.patient}';
        data_url += '&provider__name__icontains={filter_form.provider}';
        data_url += '&status__icontains={filter_form.status}';
        data_url += '&user__full_name__icontains={filter_form.lawyer}';
        data_url += '&requester__full_name__icontains={filter_form.requester}';
        data_url += '&working_on={filter_form.claimed_by}';

        var order_by = '';
        var client_sort = null;
        if (this.state.client_sorted && this.state.recent_sort == 'client') {
          client_sort = '(Asc)';
          order_by = 'patient__last_name,';
        }
        else if (typeof(this.state.client_sorted) == 'boolean' && this.state.recent_sort == 'client') {
          client_sort = '(Desc)';
          order_by = '-patient__last_name,';
        }

        var created_at_sort = null;
        if (this.state.created_at_sorted && this.state.recent_sort == 'date') {
            created_at_sort = '(Asc)';
            order_by += 'created_at';
        }
        else if (this.state.recent_sort == 'date') {
          var created_at_sort = '(Desc)';
          order_by += '-created_at';
        }

        var next_todo_sort = null;
        if (this.state.next_todo_sorted && this.state.recent_sort == 'todo') {
          next_todo_sort = '(Asc)';
          order_by = 'next_todo_date,';
        }
        else if (typeof(this.state.next_todo_sorted) == 'boolean' && this.state.recent_sort == 'todo') {
          next_todo_sort = '(Desc)';
          order_by = '-next_todo_date,';
        }

        data_url += '&order_by=' + order_by;
        console.log("Data Url", data_url)

        var todo_list = <ListWithChildren dataUrl={data_url} objectName={'request'} nowrapper={true} >
            <ToDo logged_in_user={this.props.user} />
        </ListWithChildren>;

        var todo_content = <div>
        {header}
          <div style={{height:'20px'}}></div>
          {filter_form}
          <table className="table">
            <tr>
              <th>Working</th>
              <th>View</th>
              <th><div onClick={this.client_sort} style={{cursor:'pointer'}}>Client {client_sort}</div></th>
              <th>Provider</th>
              <th>Law Firm</th>
              <th>Paralegal</th>
              <th>Requestor</th>
              <th>Status</th>
              <th><div onClick={this.created_at_sort} style={{cursor:'pointer'}}>Created At Date {created_at_sort}</div></th>
              <th><div onClick={this.next_todo_sort} style={{cursor:'pointer'}}>Next Reminder Date {next_todo_sort}</div></th>
              <th>Rush</th>
            </tr>
          {todo_list}
          </table>
          </div>;

        var allrequest_content = null;

        var toggle_todo = "Show All Requests"
        if (!this.state.todo) {
            toggle_todo = "Show ToDo Tasks"
        }

        var content = <div className="container-fluid" style={{fontSize:'14px'}}>
            <Button text={toggle_todo} onClick={this.todo_toggle} type='primary' style={{'marginBottom': '15px'}} />
            {todo_content}
          </div>;
        }

        return (
            <Wrapper loaded={this.state.loaded}  content={content} />
        );
    }
}

export default RequestList;


import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import { Container, Button, Image, Form, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText } from 'library';

class PDFList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'provider_forms': [],
      'company_forms': [],
      'name': '',
      company_requests: [],
      provider_requests: [],
      company_page_offset: 1,
      provider_page_offset: 1,
      last_page: false,
      total_requests_count: 0,
      company_limit: 10,
      provider_limit: 10,
      default_sorting: "Ascending",
      default_name: "status",
      company_next: "",
      company_previous: "",
      provider_next: "",
      provider_previous: "",
      company_total_count: 0,
      provider_total_count: 0,
      company_loader: true,
      provider_loader: true,
      company_page_count: 0,
      provider_page_count: 0,
    }

    this.companyObjectCallback = this.companyObjectCallback.bind(this);
    this.providerObjectCallback = this.providerObjectCallback.bind(this);
    this.setGlobalState = this.setGlobalState.bind(this);
    this.handleProviderLimitChange = this.handleProviderLimitChange.bind(this);
    this.handleCompanyLimitChange = this.handleCompanyLimitChange.bind(this);
    this.handleCompanyApi = this.handleCompanyApi.bind(this)
    this.handleProviderApi = this.handleProviderApi.bind(this)
    this.handlePageCompanyClick = this.handlePageCompanyClick.bind(this);
    this.handlePageProviderClick = this.handlePageProviderClick.bind(this);
    this.filterName = this.filterName.bind(this);
  }
  filterName = (e) => {
    this.setState({ name: e.target.value })
    this.handleCompanyApi(this.state.company_limit, this.state.company_page_offset, e.target.value);
    this.handleProviderApi(this.state.provider_limit, this.state.provider_page_offset, e.target.value);
  }

  handlePageCompanyClick = (event) => {
    this.setState({ company_page_offset: event.selected + 1 })
    this.handleCompanyApi(this.state.company_limit, event.selected + 1, this.state.name);
  }

  handlePageProviderClick = (event) => {
    this.setState({ provider_page_offset: event.selected + 1 })
    this.handleProviderApi(this.state.provider_limit, event.selected + 1, this.state.name);
  }

  handleCompanyApi(limit, page_offset, name) {
    this.setState({ provider_loader: true })
    ajaxWrapper('GET', `/api/pdfform/?company=True&page=${page_offset}&page_size=${limit}&name=${name}`, {}, this.companyObjectCallback);
  }

  handleProviderApi(limit, page_offset, name) {
    this.setState({ provider_loader: true })
    ajaxWrapper('GET', `/api/pdfform/?provider=True&page=${page_offset}&page_size=${limit}&name=${name}`, {}, this.providerObjectCallback);
  }

  componentDidMount() {
    this.handleCompanyApi(10, this.state.company_page_offset, this.state.name)
    this.handleProviderApi(10, this.state.provider_page_offset, this.state.name)
  }

  handleCompanyLimitChange(e) {
    this.setState({ company_limit: parseInt(e.target.value) });
    if (parseInt(e.target.value) > this.state.company_total_count) {
      this.setState({ company_page_offset: 1 });
    }
    if (this.state.company_page_offset * parseInt(e.target.value) > this.state.company_total_count) {
      this.setState({ company_page_offset: parseInt(this.state.company_total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.company_total_count / parseInt(e.target.value)) });
    }

    if (this.state.company_page_offset * parseInt(e.target.value) > this.state.company_total_count) {
      this.setState({ company_page_offset: parseInt(this.state.company_total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.company_total_count / parseInt(e.target.value)) });
    }
  }

  handleProviderLimitChange(e) {
    this.setState({ provider_limit: parseInt(e.target.value) });
    if (parseInt(e.target.value) > this.state.provider_total_count) {
      this.setState({ provider_page_offset: 1 });
    }

    if (this.state.provider_page_offset * parseInt(e.target.value) > this.state.provider_total_count) {
      this.setState({ provider_page_offset: parseInt(this.state.provider_total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.provider_total_count / parseInt(e.target.value)) });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.company_limit !== prevState.company_limit) {
      this.handleCompanyApi(this.state.company_limit, this.state.company_page_offset, this.state.name)
    }
    if (this.state.provider_limit !== prevState.provider_limit) {
      this.handleProviderApi(this.state.provider_limit, this.state.provider_page_offset, this.state.name)
    }

    if (this.state.name !== "" && prevState.name !== this.state.name) {
      this.setState({ company_page_offset: 1 });
      this.setState({ provider_page_offset: 1 });
      this.handleCompanyApi(this.state.limit, 1, this.state.name);
      this.handleProviderApi(this.state.provider_limit, 1, this.state.name)
    }
  }

  companyObjectCallback(result) {
    this.setState({ "company_forms": result.results, 'loaded': true })
    this.setState({ company_next: result.next, loaded: true });
    this.setState({ company_previous: result.previous, loaded: true });
    this.setState({ company_total_count: result.count, loaded: true });
    this.setState({ company_page_count: Math.ceil(result.count / this.state.company_limit) })
    this.setState({ company_loader: false })
  }
  providerObjectCallback(result) {
    this.setState({ "provider_forms": result.results, 'loaded': true })
    this.setState({ provider_next: result.next, loaded: true });
    this.setState({ provider_previous: result.previous, loaded: true });
    this.setState({ provider_total_count: result.count, loaded: true });
    this.setState({ provider_page_count: Math.ceil(result.count / this.state.provider_limit) })
    this.setState({ provider_loader: false })
  }

  setGlobalState(name, state) {
    this.setState(state);
  }

  render() {

    var company_forms = [];
    for (var index in this.state.company_forms) {
      var form = this.state.company_forms[index]["pdfform"];
      company_forms.push(<tr>
        <td style={{ width: "70%" }}>{form.company.name}</td>
        <td style={{ width: "25%" }}>{form.name}</td>
        <td><a href={'/editform/company/' + form.id + '/'}>Edit</a></td>
      </tr>);
    }

    var provider_forms = [];
    for (var index in this.state.provider_forms) {
      var form = this.state.provider_forms[index]["pdfform"];
      provider_forms.push(<tr>
        <td style={{ width: "70%" }}>{form.provider.name}</td>
        <td style={{ width: "25%" }}>{form.name}</td>
        <td><a href={'/editform/provider/' + form.id + '/'}>Edit</a></td>
      </tr>);
    }

    var content =
      <div className="container-fluid">
        <Header size={2} text={'PDFs'} />
        <br />
        <label>Name</label>
        <input className='custom-input-filter' name='name' onChange={this.filterName} />

        <Header size={3} text={'Company Forms'} />
        <Button type={'success'} text={'Add New Company Form'} href={'/editform/company/'} style={{ marginBottom: '20px' }} />
        <br />
        <div style={{
          margin: "20px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center"
        }}>
          <div>
            Total Records : {this.state.company_total_count}
          </div>
          <div style={{
            display: 'flex',
            alignItems: "center",
            marginLeft: "20px"
          }}>
            {
              this.state.company_total_count > 0 ?
                <select
                  className="form-control"
                  style={{ width: "85px", marginRight: "5px" }}
                  onChange={this.handleCompanyLimitChange}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>40</option>
                  <option>50</option>
                  <option>100</option>
                </select> : ""
            }
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={this.handlePageCompanyClick}
              pageRangeDisplayed={10}
              marginPagesDisplayed={3}
              pageCount={this.state.company_page_count}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
              className="custom-pagination"
              forcePage={this.state.company_page_offset - 1}
            />
          </div>
        </div>
        <table className="table"><tbody>
          <tr>
            <th>Company</th>
            <th>Name</th>
            <th>Edit</th>
          </tr>
          {company_forms}
        </tbody></table>

        <br /><br />

        <Header size={3} text={'Provider Forms'} />
        <Button type={'success'} text={'Add New Provider Form'} href={'/editform/provider/'} style={{ marginBottom: '20px' }} />
        <br />
        <div style={{
          margin: "20px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center"
        }}>
          <div>
            Total Records : {this.state.provider_total_count}
          </div>
          <div style={{
            display: 'flex',
            alignItems: "center",
            marginLeft: "20px"
          }}>
            {
              this.state.provider_total_count > 0 ?
                <select
                  className="form-control"
                  style={{ width: "85px", marginRight: "5px" }}
                  onChange={this.handleProviderLimitChange}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>40</option>
                  <option>50</option>
                  <option>100</option>
                </select>
                : ""
            }
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={this.handlePageProviderClick}
              pageRangeDisplayed={10}
              marginPagesDisplayed={3}
              pageCount={this.state.provider_page_count}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
              className="custom-pagination"
              forcePage={this.state.provider_page_offset - 1}
            />
          </div>
        </div>
        <table className="table"><tbody>
          <tr>
            <th>Provider</th>
            <th>Name</th>
            <th>Edit</th>
          </tr>
          {provider_forms}
        </tbody></table>
      </div>;

    return (
      <Wrapper loaded={this.state.loaded} content={content} />
    );
  }
}

export default PDFList;

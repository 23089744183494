import React, { Component } from 'react';
import ajaxWrapper from "base/ajax.js";
import Wrapper from 'base/wrapper.js';
import ReactDataSheet from 'react-datasheet';
import HelpVideo from 'projectLibrary/help_video.js';
import {Form, TextInput, Select, PasswordInput, Header, TextArea, NumberInput, DateTimePicker, FileInput, Button, Alert} from 'library';

class GhostUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {users:[], emailList:[], emailIdList:[], loaded:false, success: false, error: []};

        this.objectCallback = this.objectCallback.bind(this);
        this.refresh = this.refresh.bind(this);

    }

    refresh() {
      ajaxWrapper('GET','/api/user/User/?company_id=' + this.props.company_id, {}, this.objectCallback);
    }

    componentDidMount(value) {
        this.refresh()
    }

    objectCallback(result) {
      console.log("Result",result)
      var users = [];

      for (var index in result) {
          users.push(result[index]['User'])
      }


      this.setState({users:users, loaded:true})
    }

    render() {
        var users = [];
        for (var index in this.state.users) {
            console.log("Users", this.state.users)
            var user = this.state.users[index];
            console.log(user)
            users.push(<tr>
                <td>{user.first_name} {user.last_name}</td>
                <td><Button type='primary' text='View' href={'/dashboard/' + this.props.company_id + '/' + user.id + '/'} /></td>
            </tr>)
        }


        var content = <div className="container-fluid" style={{position:'relative'}}>
                <br />

                <div style={{marginBottom:'20px'}}>
                    <h2>Company User List</h2>
                    <table className="table">
                        <tr>
                            <th>Name</th>
                            <th>See Their Dashboard View</th>
                        </tr>
                        {users}
                    </table>

                </div>
        </div>;

        return (
          <div>
            <Wrapper loaded={this.state.loaded} content={content} />
          </div>
             );
    }
}
export default GhostUsers;

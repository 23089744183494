import React, { Component } from 'react';
import ajaxWrapper from "base/ajax.js";
import Wrapper from 'base/wrapper.js';

import {FormWithChildren, TextInput, CheckGroup, PasswordInput, Header, TextArea, NumberInput, DateTimePicker, FileInput, Button, Alert, Div} from 'library';
import AddProvider from 'pages/editProvider.js';

class StartsWithButton extends Component {
    constructor(props) {
        super(props);

        this.select = this.select.bind(this);
    }

    select() {
        if (this.props.letter != this.props.provider_starts_with) {
            this.props.setGlobalState('letter', {provider_starts_with: this.props.letter})
        }
        else {
            this.props.setGlobalState('letter', {provider_starts_with: null})
        }
    }

    render () {
        if (this.props.letter != this.props.provider_starts_with) {
            return (
                <Button text={this.props.letter} onClick={this.select} type={'primary'} style={{margin:'0px', padding:'5px'}} />
            )
        }
        else {
            return (
                <Button text={this.props.letter} onClick={this.select} type={'success'} style={{margin:'0px', padding:'5px'}} />
            )
        }
    }

}

class Provider extends Component {
    constructor(props) {
        super(props);

        this.add_provider = this.add_provider.bind(this);
    }

    add_provider() {
        this.props.add_provider(this.props.provider)
    }

    render() {
        return (
            <tr>
                <td>{this.props.provider.name}</td>
                <td><Button type='primary' text='Select' onClick={this.add_provider} /></td>
            </tr>
        )
    }
}

class RequestProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {provider_name:'', selected_provider: null, new_provider: false, request_types: [], special_instructions:'', special: false}

        this.setGlobalState = this.setGlobalState.bind(this);
        this.add_provider = this.add_provider.bind(this);
        this.toggle_new_provider = this.toggle_new_provider.bind(this);
        this.new_provider_added = this.new_provider_added.bind(this);
        this.save = this.save.bind(this);
        this.toggle_request_types = this.toggle_request_types.bind(this);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    add_provider(provider) {
        this.setState({selected_provider: provider})
    }

    toggle_new_provider() {
        this.setState({new_provider: !this.state.new_provider})
    }

    new_provider_added(result) {
      var provider = result[0].provider;

      this.setState({
          selected_provider:provider,
      });
    }

    save() {
        var provider_request = {'provider':this.state.selected_provider, request_types: this.state.request_types, special_instructions:this.state.special_instructions, intake_form_url: this.state.intake_form_url}
        this.props.addProvider(provider_request)
        this.setState({provider:null, request_types: [], special_instructions:'', new_provider:false, intake_form_url: ''})
    }

    toggle_request_types() {
        this.setState({special: !this.state.special})
    }

    render() {

        var providers = []
        for (var index in this.props.providers) {
            var provider = this.props.providers[index];
            if (!this.state.provider_starts_with || provider.name.toLowerCase().startsWith(this.state.provider_starts_with.toLowerCase())) {
                if (this.state.provider_name == '' || provider.name.toLowerCase().indexOf(this.state.provider_name.toLowerCase()) > -1) {
                    providers.push(<Provider add_provider={this.add_provider} provider={provider} />);
                }
            }
        }
        if (providers.length < 10) {
            providers.push(<tr>
                <td>Add New Provider</td>
                <td><Button type='success' text='Add New Provider' onClick={this.toggle_new_provider} /></td>
            </tr>)
        }

        var starts_with = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
        var starts_with_buttons = [];
        for (var index in starts_with) {
            var letter = starts_with[index];
            starts_with_buttons.push(<StartsWithButton letter={letter} provider_starts_with={this.state.provider_starts_with} setGlobalState={this.setGlobalState} />)
        }

        var content = null;
        if (!this.state.selected_provider) {
            if (this.state.new_provider) {
                var content = <AddProvider redirect={this.new_provider_added} back={this.toggle_new_provider} />
            }
            else {
                var content = <div>
                    <h2>Choose Your Provider</h2>
                    <FormWithChildren autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'filter'}>
                        <TextInput name='provider_name' label="Provider Name" />
                    </FormWithChildren>
                    <p>Provider Starts With</p>
                    {starts_with_buttons}
                    <table className="table">
                        <tr   style={{display: "flex",justifyContent: "space-between",width: "95%"}}>
                            <th>Provider Name</th>
                            <th>Select</th>
                        </tr>
                        <tbody>
                            {providers}
                        </tbody>
                    </table>
                </div>
            }

        }
        else if (!this.state.special) {
            var request_types = [];
            var request_type_length = this.props.request_types.length;
            var request_type_col_length = Math.floor(request_type_length/3)

            for (var index = 0; index < request_type_col_length; index++) {
                var request_type = this.props.request_types[index];
                request_types.push({name: request_type.name, id:request_type.name})

                if (index + request_type_col_length < request_type_length) {
                    var request_type = this.props.request_types[index + request_type_col_length];
                    request_types.push({name: request_type.name, id:request_type.name})
                }

                if (index + 2 * request_type_col_length < request_type_length) {
                    var request_type = this.props.request_types[index + 2 * request_type_col_length];
                    request_types.push({name: request_type.name, id:request_type.name})
                }
            }

            var content = <div style={{paddingLeft:'80px', overflowX:'hidden'}}>
                <h2>Provider: {this.state.selected_provider.name}</h2>
                <p>{this.state.selected_provider.address_1}, {this.state.selected_provider.city} {this.state.selected_provider.state} {this.state.selected_provider.zip_code}</p>
                <Button type='danger' text='Back' onClick={() => this.setState({selected_provider:null})} />
                <h3>Choose The Types Of Requests</h3>
                <FormWithChildren autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'filter'}>
                    <CheckGroup name='request_types' label="Reqest Types" options={request_types} default={[]} />
                </FormWithChildren>
                <Button type='success' text='Next Step' onClick={this.toggle_request_types} />
            </div>
        }
        else {
            var content = <div style={{paddingLeft:'80px', overflowX:'hidden'}}>
                <h2>Provider: {this.state.selected_provider.name}</h2>
                <h3>Request Types: {this.state.request_types.join(', ')}</h3>
                <Button type='danger' text='Back' onClick={this.toggle_request_types} />
                <h3>Any Special Instructions or Extra Info? (Optional)</h3>
                <FormWithChildren autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'filter'}>
                    <TextArea name='special_instructions' label="Special Instructions" default='' />
                    <FileInput name='intake_form_url' label='Upload An Intake Form (Optional)' bucket_name='hipaaforms' />
                </FormWithChildren>
                <Button type='success' text='Save Provider' onClick={this.save} />
            </div>
        }


        return (
          <div>
            <Wrapper loaded={true} content={content} />
          </div>
             );
    }
}
export default RequestProvider;

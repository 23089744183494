import React, { Component } from 'react';
import resolveVariables from 'base/resolver.js';

class NumberInput extends Component {
    render() {

        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        var label = <label>{this.props.label}</label>;
        if (!this.props.label) {
            var label = null;
        }

        return (
              <div className={"form-group " + this.props.layout}>
                {label}
                
                
            <input type="number" className="form-control" name={this.props.name}
                    onChange={this.props.handlechange} value={this.props.value}
                    placeholder={this.props.placeholder}
                    onKeyPress={this.props.handleKeyPress}
                    onBlur={this.props.onBlur}
                />
                
                
              </div>
        )


    }
}

export default NumberInput;

import React, { Component } from 'react';
import ajaxWrapper from "base/ajax.js";
import Wrapper from 'base/wrapper.js';

import {Form, TextInput, Select, PasswordInput, Header, TextArea, NumberInput, DateTimePicker, FileInput, Button, Alert, Div} from 'library';

class EditProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {'created_at' : '', 'updated_at' : '', 'name' : '', 'email' : '', 'phone' : '', 'fax' : '', 'address_1' : '', 'address_2' : '', 'city' : '', 'state' : '', 'zip_code' : '', error: '', merge_provider:'', merging:''};

        this.objectCallback = this.objectCallback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.check_provider = this.check_provider.bind(this);
        this.check_callback = this.check_callback.bind(this);
        this.provider_merge = this.provider_merge.bind(this);
        this.provider_merge_callback = this.provider_merge_callback.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    componentDidMount(value) {
        if(this.props.provider_id) {
          ajaxWrapper('GET','/api/home/provider/' + this.props.provider_id + '/', {}, this.objectCallback);
        }
        else {
          this.setState({loaded:true})
        }
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    check_provider() {
        if (this.state.id) {
            ajaxWrapper('POST','/api/home/provider/' + this.state.id + '/', this.state, this.redirect)
        }
        else {
            var name = encodeURI(this.state.name.trim())
            console.log("Name", name)
            name = name.replace('&','%26')
            ajaxWrapper('GET', '/api/home/provider/?name=' + name, {}, this.check_callback)
        }

    }

    redirect(result) {
        console.log("Result Redirect", result)
        window.location = '/editprovider/' + this.props.provider_id + '/';
    }

    check_callback(result) {
        console.log("Check Provider Callback Result", result)
        if (result.length > 0) {
            var error = <p>There is already a provider by that name. Either <Button type='primary' text={'Assign Provider: ' + result[0]['provider']['name']} onClick={() => this.props.redirect(result)} /> or change the provider name.</p>
            this.setState({error:error})
        }
        else {
            var data = this.state;
            data['name'] = data['name'].trim();
            if (this.props.redirect) {
                ajaxWrapper('POST','/api/home/provider/', this.state, this.props.redirect)
            }
            else {
                ajaxWrapper('POST','/api/home/provider/', this.state, () => window.location.href = '/providerList/')
            }
        }
    }

    objectCallback(result) {
      var provider = result[0]['provider'];
      provider['loaded'] = true;
      this.setState(provider)
    }

    provider_merge(state) {
        if (state['provider_merge'] != '') {
            this.setState({'merge_provider': state['provider_merge'], merging: "Merging..."})
            ajaxWrapper('GET','/api/home/request/?provider=' + this.props.provider_id, {}, this.provider_merge_callback)
        }
        else {
            this.setState({'merging': 'Error, No Provider Chosen'})
        }
    }

    provider_merge_callback(result) {
        for (var index in result) {
            var request = result[index]['request'];
            ajaxWrapper('POST','/api/home/request/' + request.id + '/', {'provider': this.state.merge_provider}, console.log)
        }

        ajaxWrapper('POST','/api/home/provider/' + this.props.provider_id + '/delete/', {}, console.log)

        this.setState({merging:'Merge Complete'})
    }

    render() {

			var Components = [Header, TextInput, TextInput, TextInput, TextInput, Header, TextInput, TextInput, TextInput, TextInput, TextInput, TextArea, FileInput];
      var contact_header = {'text':'Contact Info', size: 5, header:true}
			var name = {'name': 'name', 'label': 'Name', 'placeholder': 'Name', 'value': '', required:true};
			var email = {'name': 'email', 'label': 'Email', 'placeholder': 'Email', 'value': ''};
			var phone = {'name': 'phone', 'label': 'Phone', 'placeholder': 'Phone', 'value': ''};
			var fax = {'name': 'fax', 'label': 'Fax', 'placeholder': 'Fax', 'value': ''};
      var address_header = {'text':'Address', size: 5, header:true}
			var address_1 = {'name': 'address_1', 'label': 'Address 1', 'placeholder': '', 'value': ''};
			var address_2 = {'name': 'address_2', 'label': 'Address 2', 'placeholder': '', 'value': ''};
			var city = {'name': 'city', 'label': 'City', 'placeholder': '', 'value': ''};
			var state = {'name': 'state', 'label': 'State', 'placeholder': '', 'value': ''};
			var zip_code = {'name': 'zip_code', 'label': 'Zip Code', 'placeholder': '', 'value': ''};
      var form_header = {'text':'Form Upload', size: 5, header:true}
      var medical_request_url = {'bucket_name':'providerforms','name': 'medical_request_url', 'label': 'Medical Request Form', 'placeholder': 'Release_Form_Url', 'value': ''};
      var notes_header = {'text':'Additional Notes', size:5, header:true}
      var notes = {'name':'notes', label:'Additional Notes', header:true}
			var ComponentProps = [contact_header, name, email, phone, fax, address_header, address_1, address_2, city, state, zip_code, notes, medical_request_url];

        var defaults = this.state;

        var submitUrl = "/api/home/provider/";
        if (this.props.provider_id) {
          submitUrl += this.props.provider_id + '/';
        }

        var deleteUrl = undefined;
        if (this.props.provider_id) {
          deleteUrl = "/api/home/provider/" + this.props.provider_id + "/delete/";
        }


        var title = <Header text={'Create New Provider'} size={2} />
        if (this.props.provider_id) {
          title = <Header text={'Edit Provider: ' + this.state.name} size={2} />
        }

        var redirectUrl = '/providerList/'// + this.props.provider_id + '/';
        var redirect = null;
        if (this.props.redirect) {
          redirectUrl = null;
          redirect = this.props.redirect;
        }
        else if (this.props.request_id) {
          redirectUrl = '/request/' + this.props.request_id + '/';
        }

        var error = null;
        if (this.state.error != '') {
            var error = <Alert type={'danger'} text={this.state.error} />
        }


        var merge = null;
        if (this.props.provider_id) {

            var merge_alert = null;
            if (this.state.merging != '') {
                merge_alert = <div style={{marginTop:'50px'}}><Alert type='info' text={this.state.merging} /></div>
            }

            var merge = <div style={{marginTop:'100px'}}>
            <h3>Merge Into Another Provider</h3>
            <Form components={[Select]} componentProps={[{'name':'provider_merge', label:'Choose A Provider To Merge Into', 'optionsUrl':'/api/home/provider/', 'optionsUrlMap':{'text':'{provider.name}', 'value':'{provider.id}'}}]} submit={this.provider_merge} defaults={{provider_merge:''}}/>
            {merge_alert}
            </div>
        }

        var back = null;
        if (this.props.back) {
            back = <Button type='danger' text='Back' onClick={this.props.back} />
        }

        var content = <div className="container-fluid">
                {back}
                {title}
                <p>Name required. Otherwise add what you can.</p>
                <Form col_size={'2'} row={true} components={Components} redirectUrl={redirectUrl} redirect={redirect} objectName={'provider'} componentProps={ComponentProps} deleteUrl={deleteUrl} submit={this.check_provider} defaults={defaults} autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'provider'} />
                <br />
                {error}
                {merge}
        </div>;

        return (
          <div>
            <Wrapper loaded={this.state.loaded} content={content} />
          </div>
             );
    }
}
export default EditProvider;


import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import {Alert, Select, Container, Button, EmptyModal, Image, Form, ImageInput, TextInput, PageBreak, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText, DateTimePicker, FormWithChildren, NumberInput} from 'library';
import format_date from 'base/date_format.js';
import format_date_string from 'base/date_string_format.js';
import Patient from 'pages/editPatient.js';
import DateFormat from '../base/date_format_split';


function compare_note_dates(a, b) {
    if (a['notes']['created_at'] > b['notes']['created_at']) return -1;
    if (a['notes']['created_at'] < b['notes']['created_at']) return 1;
    return 0;
}

function compare_invoice_dates(a, b) {
  if (a['invoice']['created_at'] > b['invoice']['created_at']) return 1;
  if (a['invoice']['created_at'] < b['invoice']['created_at']) return -1;
  return 0;
}

class FileDownload extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.verifyFile = this.verifyFile.bind(this);
    }

    onClick() {
        this.props.fileDownload(this.props.file)
    }

    removeFile() {
        ajaxWrapper('POST','/api/home/file/' + this.props.file.id + '/', {'archived':true}, this.props.refreshData)
    }

    verifyFile() {
        
        ajaxWrapper('POST','/api/home/file/' + this.props.file.id + '/', {'verified_by': this.props.user.id}, this.props.refreshData)
    }

    render() {

        var downloaded = <p>This File Hasn't Been Downloaded Yet.</p>
        for (var index in this.props.file['file_accesses']) {
            var access = this.props.file['file_accesses'][index]['fileaccess'];
            if (access.user) {
                if (access['action'] == 'DOWNLOAD') {
                    var date_split = access['created_at'].split('T')[0].split('-');
                    var download_date = date_split[1] + '/' + date_split[2] + '/' + date_split[0];
                    downloaded = <p>Downloaded on {download_date} by {access['user']['first_name']} {access['user']['last_name']}</p>
                }
            }
        }
        var remove = null;
        if (this.props.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684') {
            remove = <p><a onClick={this.removeFile} style={{'color':'red'}}>Remove</a></p>
        }

        var verified = <Button type='primary' text='Verify This Is The Correct File' deleteType={true} onClick={this.verifyFile} />
        if (this.props.file.verified_by && this.props.file.verified_by.id) {
            verified = <p>Verified By {this.props.file.verified_by.first_name} {this.props.file.verified_by.last_name}</p>
        }
        if (this.props.supporting_file) {
            verified = null;
        }

        if (this.props.file['archived'] == true) {
            return (null);
        }
        else {
            return (
                <div>
                    <a onClick={this.onClick} style={{'color':'blue'}}>{this.props.file['name'].substring(6,this.props.file['name'].length-4)}</a>
                    {remove}
                    {verified}
                    {downloaded}
                </div>
            )
        }

    }
}

class Request extends Component {
    constructor(props) {
        super(props);
        this.state = {'internal_number': '', files:[],notes:[], patient:{}, user:{}, modal: false, invoices: [], 'created_at' : '', 'updated_at' : '', 'user' : '', 'start_date' : '', 'end_date' : '', 'rush' : 'false', 'status' : '', 'provider' : '', 'request_type' : '', 'special_instructions' : '', message_sent:false, tracking_saved:false, new_provider:false, provider_details:false, provider_cost:0}

        this.show_provider_details = this.show_provider_details.bind(this);

        this.objectCallback = this.objectCallback.bind(this);
        this.refresh = this.refresh.bind(this);

        this.docSent = this.docSent.bind(this);
        this.docSendCallback = this.docSendCallback.bind(this);

        this.cancelRequest = this.cancelRequest.bind(this);
        this.cancelRequestCallback = this.cancelRequestCallback.bind(this);

        this.newNote = this.newNote.bind(this);
        this.endReminder = this.endReminder.bind(this);
        this.sendRequestToLawyer = this.sendRequestToLawyer.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.modal = this.modal.bind(this);
        this.logFileAccess = this.logFileAccess.bind(this);
        this.fileDownload = this.fileDownload.bind(this);
        this.getIp = this.getIp.bind(this);
        this.getFileUrl = this.getFileUrl.bind(this);
        this.requestSent = this.requestSent.bind(this);
        this.savedTracking = this.savedTracking.bind(this);

        this.getPatientForm = this.getPatientForm.bind(this);
        this.getProviderForm = this.getProviderForm.bind(this);

        this.deleteRequest = this.deleteRequest.bind(this);
        this.submitRequisitionForm = this.submitRequisitionForm.bind(this);

        this.get_provider_callback = this.get_provider_callback.bind(this);
        this.check_if_patient_complete = this.check_if_patient_complete.bind(this);

        this.get_related_info = this.get_related_info.bind(this);
    }

    refresh() {
        ajaxWrapper('GET','/api/home/request/' + this.props.request_id + '/?related=user,notes,notes__user,provider,patient,patient__company,files,files__file_accesses,files__file_accesses__user,files__verified_by,invoices,invoices__bill', {}, this.objectCallback);
    }

    modal() {
      this.setState({modal: !this.state.modal})
    }

    show_provider_details() {
        this.setState({provider_details: !this.state.provider_details})
    }

    componentDidMount() {
      this.refresh()
      ajaxWrapper('GET','/api/ip/',{},this.getIp)
    }

    getIp(result) {
        this.setState({ip: result['ip']})
    }

    setGlobalState(name, state) {
      var files = this.state.files;
      if (name == 'files' || name == 'supporting_files') {
        var new_files = state['files']
        for (var index in new_files) {

          var supporting_file = false;
          if (name == 'supporting_files') {
              supporting_file = true;
          }
          var file = new_files[index]
          var file_data = {name: file['filename'], url: file['url'], request:this.props.request_id, supporting_file: supporting_file}
          ajaxWrapper('POST','/api/home/file/', file_data, this.logFileAccess)
        }
      }
    }

    fileDownload(file) {
        var file_id = file['id'];
        
        ajaxWrapper('POST','/api/home/fileaccess/', {'action':'DOWNLOAD', ip: this.state.ip, file: file_id, user: this.props.user.id}, ()=> this.getFileUrl(file['url']))
    }

    getFileUrl(url) {
        ajaxWrapper("POST", "/getFileUrl/", {"url":url}, this.goToFile)
    }

    goToFile(result) {
        window.open(result['url'], '_blank')
    }

    logFileAccess(result) {
        var file_id = result[0]['file']['id'];
        var files = this.state.files;
        files.push(result[0])
        this.setState({files:files})
        ajaxWrapper('POST','/api/home/fileaccess/', {'action':'UPLOAD', ip: this.state.ip, file: file_id, user: this.props.user.id}, console.log)
    }

    objectCallback(result) {
      var request = result[0]['request'];
      request['loaded'] = false;
      request['loaded'] = true;

      request['patient']['ssn'] = ''

      request['notes'].sort(compare_note_dates)
      request['invoices'].sort(compare_invoice_dates);

      if (request.provider) {
          ajaxWrapper('GET','/api/home/request/?count&provider=' + request.provider.id, {}, this.get_provider_callback)
      }

      request['provider_cost'] = request['invoices'].at(-1).invoice.provider_cost;

      ajaxWrapper('POST','/get_related_info/', {patient_id: request['patient']['id']}, this.get_related_info)

      this.setState(request);
    }

    get_related_info(result) {
        var patient = this.state.patient;
        patient['ssn'] = result['related_info']
        this.setState({patient: patient})
    }

    get_provider_callback(result) {
        if (result['count'] == 1) {
            this.setState({new_provider: true})
        }
    }

    docSent() {
      //this.modal();
      ajaxWrapper('POST','/api/home/request/' + this.state.id + '/', {'status':'Medical Records Request Sent'}, this.docSendCallback);
    }
    docSendCallback(value){
      
      ajaxWrapper('POST','/api/home/notes/', {'text': 'Sent the Initial Medical Records Request', update_status: 'Medical Records Request Sent', previous_status: this.state.status, user: this.props.user.id, request: this.props.request_id}, this.refresh);
    }

    deleteRequest() {
        ajaxWrapper('POST','/api/home/request/' + this.state.id + '/delete/', {}, () => window.location = '/dashboard/')
    }

    cancelRequest() {
      ajaxWrapper('POST','/api/home/request/' + this.state.id + '/', {'status':'Closed - Cancelled Request'}, this.cancelRequestCallback);
    }

    cancelRequestCallback(value) {
      
      ajaxWrapper('POST','/api/home/notes/', {'text': 'Request is Cancelled', update_status: 'Closed - Cancelled Request', previous_status: this.state.status, user: this.props.user.id, request: this.props.request_id}, this.refresh);
    }

    newNote(result) {
      var note = result[0]['notes'];

      if (note.update_status == 'Need Assistance') {
          var user_name = this.props.user.first_name + ' ' + this.props.user.last_name;
          var text = user_name + " has asked for your assistance regarding a request for:<br/>";
          text += "Provider: "+ this.state.provider.name +"<br/>";
          text += "Patient: "+ this.state.patient.full_name + "<br/>";
          //text += '"' + note.text + '"<br/>';
          text += '<a href="https://directdocs.com/request/'+this.state.id+'/" >View It Here</a>';

          var data = {
            "text": text,
            "subject":"DirectDocs: A request needs your assistance: " + this.state.patient.full_name + " : " + this.state.provider.name,
            "to_email": this.state.user.email,
            "from_email":'support@directdocs.com'
          }
          ajaxWrapper('POST', '/api/email/', data);
      }
      else if(["Completed - Documents Arrived", "Completed - No Documents Available", "Closed - Documents Arrived", "Closed - No Documents Available", "Closed - Cancelled Request"].includes(note.update_status)) {
        var user_name = this.props.user.first_name + ' ' + this.props.user.last_name;
        var text = user_name + " has has added a note to a request for:<br/>";
        text += "Provider: "+ this.state.provider.name +"<br/>";
        text += "Patient: "+ this.state.patient.full_name + "<br/>";
        text += '"' + note.text + '"<br/>';
        text += '<a href="https://directdocs.com/request/'+this.state.id+'/" >View It Here</a>';

        var data = {
          "text": text,
          "subject":"DirectDocs: A request has received a new note: " + this.state.patient.full_name + " : " + this.state.provider.name,
          "to_email": this.state.user.email,
          "from_email":'support@directdocs.com'
        }
        ajaxWrapper('POST', '/api/email/', data);
      }
      else {
          var user_name = this.props.user.first_name + ' ' + this.props.user.last_name;
          var text = user_name + " has has added a note to a request for:<br/>";
          text += "Provider: "+ this.state.provider.name +"<br/>";
          text += "Patient: "+ this.state.patient.full_name + "<br/>";
          text += '"' + note.text + '"<br/>';
          text += '<a href="https://directdocs.com/request/'+this.state.id+'/" >View It Here</a>';

          var data = {
            "text": text,
            "subject":"DirectDocs: A request has received a new note: " + this.state.patient.full_name + " : " + this.state.provider.name,
            "to_email": this.state.user.email,
            "from_email":'support@directdocs.com'
          }
          //ajaxWrapper('POST', '/api/email/', data);
      }

      if (note.update_status != this.state.status) {
        ajaxWrapper('POST','/api/home/request/' + this.props.request_id + '/', {'status': note.update_status}, this.refresh);
        if (note.update_status.indexOf('Closed') > -1) {
            ajaxWrapper('GET','/api/home/request/?patient=' + this.state.patient.id, {}, this.check_if_patient_complete)
        }
      }
      else {
        this.refresh();
      }
    }

    check_if_patient_complete(result) {
        var complete = true;
        for (var index in result) {
            var request = result[index]['request']
            if (request.id != this.props.request_id && request.status.indexOf('Closed') == -1) {
                complete = false;
            }
        }

        if (complete) {
            
            var patient_name = this.state.patient.first_name + ' ' + this.state.patient.last_name;
            var data = {
                to_email: this.state.user.email,
                from_email:'support@directdocs.com',
                subject: 'All Requests For ' + patient_name + ' Are Complete',
                text:'Your medical records requests for ' + patient_name + ' are complete. <a href="https://directdocs.com/dashboard/">Visit Your Dashboard.</a>'
            };

            ajaxWrapper('POST','/api/email/', data, console.log);
        }
        else {
            console.log("Sending Completed Message!!!! NOT.")
        }
    }

    endReminder() {
      var today = format_date(new Date(), 'yyyy-mm-dd')
      for (var index in this.state.notes) {
        var note = this.state.notes[index]['notes'];

        if (note['reminder_date'] == today) {
          ajaxWrapper('POST','/api/home/notes/' + note.id + '/', {reminder_date:null}, this.refresh)
        }
      }
    }

    sendRequestToLawyer() {

        var patient_name = this.state.patient['first_name'] + ' ' + this.state.patient['last_name'];
        var data = {
            to_email: this.state.user.email,
            from_email:'support@directdocs.com',
            subject: 'Medical Records Request Ready: ' + patient_name + ' for the Provider: ' + this.state.provider.name,
            text:'Your medical records request is ready for your review. This is concerning your client: ' + patient_name +'. <a href="https://directdocs.com/request/' + this.props.request_id + '/">Check it out.</a>'
        };

        ajaxWrapper('POST','/api/email/', data, this.requestSent);
    }

    requestSent() {
        this.setState({'message_sent':true})
        ajaxWrapper('POST','/api/home/notes/', {'text': 'Documents Sent To Law Firm', update_status: 'Completed - Documents Arrived', previous_status: this.state.status, user: this.props.user.id, request: this.props.request_id, reminder_date:null}, this.refresh)
    }

    savedTracking() {
        this.refresh();
        
        this.setState({tracking_saved:true});
    }

    getPatientForm() {
        ajaxWrapper('GET', '/writeDataToPDF/' + this.props.request_id + '/company/', {}, this.download);
    }

    getProviderForm() {
        ajaxWrapper('GET', '/writeDataToPDF/' + this.props.request_id + '/provider/', {}, this.download);
    }

    download(value){
        for (var i in value['urls']){
            var url = value['urls'][i];
            window.open(url);
        }
    }

    submitRequisitionForm(data){
      
      delete data['price'];

      ajaxWrapper('POST','/api/home/request/' + this.props.request_id + '/', data, console.log);

      ajaxWrapper('GET', `/api/home/invoice/?request=${this.props.request_id}&order_by=created_at` , {}, (res)=>{
        if(res.at(-1).invoice.bill_id == null){
          ajaxWrapper('PUT', `/api/home/invoice/${res.at(-1).invoice.id}/`, data, this.props.refresh);
        }
        else{
          data.request = this.props.request_id;
          ajaxWrapper('POST', `/api/home/invoice/`, data, this.props.refresh);
        }
      });
      
    }

    render() {
        var personal_info_form = <Button href={this.state.patient.hipaa_consent_url} text={'Patient Request'} type='primary' />;
        var provider_form = <Button onClick={this.getProviderForm} text={'Provider Request'} type='primary' />;


      var content = null;

      var medical_request_url = '/medical_request/' + this.props.request_id + '/'
      if (this.state.provider.medical_request_url != '') {
        medical_request_url = this.state.provider.medical_request_url
      }

      var delete_button = null;
      var edit_button = null;
      var split_button = null;
      var law_firm_release = null;
      if (this.props.user.is_staff && this.props.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684' && this.state.loaded) {
          law_firm_release = <Button href={this.state.patient.company.release_form_url} text='Law Firm Release' type='primary' />
          edit_button = <Button type='info' text='Edit Request' href={'/editRequest/' + this.state.patient.company.id + '/' + this.state.id + '/'} />
          delete_button = <Button type='danger' text='Delete Request' onClick={this.deleteRequest} deleteType={true} />
          split_button = <Button type='info' text='Split Request' href={'/split_request/' + this.state.id + '/'} />
      }
      else if (this.state.status == 'Requested By Lawyer' && this.state.loaded) {
          law_firm_release = <Button href={this.state.patient.company.release_form_url} text='Law Firm Release' type='primary' />
          edit_button = <Button type='info' text='Edit Request' href={'/editRequest/' + this.state.patient.company.id + '/' + this.state.id + '/'} />
          delete_button = <Button type='danger' text='Delete Request' onClick={this.deleteRequest} deleteType={true} />
      }

      if (this.state.notes.length == 0 && this.state.loaded && this.props.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684') {
        var provider_email = this.state.provider.email;
        if (provider_email == '') {
          provider_email = <a href={'/editProvider/' + this.state.provider.id + '/' + this.state.id + '/'}>Add Provider Email</a>
        }

        var rush = 'No';
        if (this.state.rush) {
            rush = 'Yes';
        }

        var intake_form = null;
        if (this.state.intake_form_url != '') {
            intake_form = <p><b>Intake Form: </b><a href={this.state.intake_form_url}>Intake Form</a></p>
        }

        var new_provider = null;
        if (this.state.new_provider) {
            new_provider = <Button text='New Provider. Merge?' href={'/editProvider/' + this.state.provider.id + '/'} type='success' target='_blank' />
        }

        var edit_provider = null;
        if (this.props.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684') {
          edit_provider = <Button type='primary' text='Edit Provider' href={'/editProvider/' + this.state.provider.id + '/'} target="_blank"/>
      }

        var content =
          <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-6">
                    <Header size={4} text={'Send The Medical Request'} />
                    <p><b>To Email: </b> {provider_email}</p>
                    <p><b>Subject:</b> Medical Records Request For Injury Claim</p>
                    <p><b>Message:</b></p>
                    <p>To Whom It May Concern,</p>
                    <p>We are representing a law firm in an injury claim for one of your previous patients, {this.state.patient['first_name']} {this.state.patient['last_name']}.</p>
                    <p>We've attached all the pertinent forms to this email. Let us know how we can help expedite the process. Thank you!</p>
                    <p>And if you need anything, contact me with the information below.</p>
                    <p><b>Name:</b> {this.props.user.first_name} {this.props.user.last_name}</p>
                    <p><b>Email:</b> {this.props.user.email}</p>
                    <p><b>Phone:</b> {this.props.user.phone_number}</p>
                    <br />
                    <Header size={4} text={'Attachments'} />
                    <p><a href={this.state.patient.company.release_form_url}>Law Firm Release Document</a></p>
                    <p><a href={this.state.patient.hipaa_consent_url}>Patient HIPAA Release Form</a></p>
                    <p><a style={{color:'#007bff'}} onClick={this.getProviderForm}>Provider Request Form</a></p>
                </div>
                <div className="col-12 col-md-6">
                    <br />
                    <Header size={3} text={'Request Details: #' + this.state.internal_number} />
                    <p>
                        <span><b>Client Name: </b>{this.state.patient.first_name} {this.state.patient.last_name}</span>
                        <span style={{marginLeft:'20px'}}><b>Birthday: </b>{format_date_string(this.state.patient.date_of_birth, 'mm/dd/yyyy')}</span>
                        <span style={{marginLeft:'20px'}}><b>SS#: </b>{this.state.patient.ssn}</span>
                    </p>
                    <Button onClick={this.modal} text={'Patient Info'} type='warning' />
                    <EmptyModal onHide={this.modal} show={this.state.modal}>
                      <Patient patient_id={this.state.patient.id} user={this.props.user} redirect={this.modal} company={this.state.patient.company.id} />
                    </EmptyModal>


                    <p><b>Provider Name: </b><a onClick={this.show_provider_details} style={{color:'blue'}}>{this.state.provider.name}</a></p>
                    <EmptyModal onHide={this.show_provider_details} show={this.state.provider_details}>
                      <p><b>Email: </b>{this.state.provider.email}</p>
                      <p><b>Phone: </b>{this.state.provider.phone} <b>Fax: </b>{this.state.provider.fax}</p>
                      <p><b>Address: </b>{this.state.provider.address_1} {this.state.provider.address_2}, {this.state.provider.city} {this.state.provider.state} {this.state.provider.zip_code}</p>
                      <p><b>Additional Notes: </b>{this.state.provider.notes}</p>
                      {edit_provider}

                    </EmptyModal>
                    {new_provider}
                    <p><b>Main Contact: </b>{this.state.user.first_name} {this.state.user.last_name}</p>
                    <p><b>Request Types: </b>{this.state.request_type}</p>
                    <p><b>Request Start Date: </b>{format_date_string(this.state.start_date, 'mm/dd/yyyy')} <b>Request End Date: </b>{format_date_string(this.state.end_date, 'mm/dd/yyyy')}</p>
                    <p><b>Request Rush?: </b>{rush}</p>
                    <p><b>Request Special Instructions: </b>{this.state.special_instructions}</p>
                    {intake_form}
                    <br />
                    <Button type={'success'} deleteType={true} text={"I've sent the email"} onClick={this.docSent} />
                    <Button type={'danger'} deleteType={true} text={"Cancel The Request"} onClick={this.cancelRequest} />
                    {edit_button}
                    {delete_button}
                    {split_button}
                </div>
            </div>
          </div>;
      }
      else if (this.state.loaded) {
        var notes = [];

        var reminder = null;
        var today = format_date(new Date(), 'yyyy-mm-dd');
        var next_reminder_date = '';
        var next_reminder_date_jsx = null;

        for (var index in this.state.notes) {
          var note = this.state.notes[index]['notes'];

          if (note['reminder_date'] == today) {
            reminder = <Alert type={'info'} text={<div>
            <p><strong>Reminder Text: </strong>{note.text}</p>
            <Button onClick={this.endReminder} type={'outline-danger'} text={'End Reminder'} />
            </div>} />
          }

          if (note.active == true && note.user) {
              notes.push(<tr>
                <td>{note.user.first_name} {note.user.last_name}</td>
                <td>{note.text}</td>
                <td>{DateFormat(note.created_at)}</td>
              </tr>);
          }

        }

        if (this.state.next_todo_date) {
            next_reminder_date_jsx = format_date_string(this.state.next_todo_date, 'mm/dd/yyyy');
        }


        var rush = 'No';
        if (this.state.rush) {
            rush = 'Yes';
        }

        var status_options = [
          
          {'text': 'In Contact With Provider', 'value': 'In Contact With Provider'},
          {'text': 'Documents en Route', 'value': 'Documents en Route'},
          {'text': 'Need Assistance', value: 'Need Assistance'},
          {'text': 'Completed - Documents Arrived', 'value': 'Completed - Documents Arrived'},
          {'text': 'Completed - No Documents Available', 'value': 'Completed - No Documents Available'},
          {'text': 'Closed - Documents Arrived', 'value': 'Closed - Documents Arrived'},
          {'text': 'Closed - No Documents Available', 'value': 'Closed - No Documents Available'},
          {'text': 'Closed - Cancelled Request', 'value': 'Closed - Cancelled Request'},
          {'text': 'Closed - Originally no document found/now document arrived.', 'value': 'Closed - Originally no document found/now document arrived.'},
        ]

        var NotesComponents = [DateTimePicker, Select, TextArea];

        var notes_defaults = {
            reminder_date:'',
            text:'',
            update_status:this.state.status,
            previous_status: this.state.status,
            user: this.props.user.id,
            request:this.props.request_id
        }
		var reminder_date = {'name': 'reminder_date', 'label': 'Remind Me To Follow Up On', 'placeholder': 'Reminder Date', 'value': false, 'display_time': false, col_size: '6'};
		var text = {'name': 'text', 'label': 'Note', 'placeholder': 'Text', 'value': '', col_size: '12'};
		var update_status = {'name': 'update_status', 'label': 'New Status Of Request', 'placeholder': 'Update_Status', 'options': status_options, col_size: '6'};
		var NotesComponentProps = [reminder_date, update_status, text];

        var formDefaults = {'files': [], request: this.props.request_id};
        var FileComponents = [ImageInput];
        var file_urls = {'name': 'files', multiple:true, preview: false, bucket_name:'providerrecords'};
        var FileComponentProps = [file_urls];

        var files = [];
        var supporting_files = [];
        for (var index in this.state.files) {
          
          var file = this.state.files[index]['file']
          if (file.supporting_file) {
              supporting_files.push(<FileDownload fileDownload={this.fileDownload} file={file} user={this.props.user} refreshData={this.refresh} />)
          }
          else {
              files.push(<FileDownload fileDownload={this.fileDownload} file={file} user={this.props.user} refreshData={this.refresh} />)
          }

        }

        var add_note = null;
        var upload_files = null;
        var document_header = 'Medical Documents';
        var message_sent = null;
        if (this.state.message_sent) {
            message_sent = <Alert type='success' text='Email Sent!' />
        }

        var edit_provider = null;
        var tracking_form = null;
        if (this.props.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684') {
          var note_form = <Form defaults={notes_defaults} row={true} components={NotesComponents} componentProps={NotesComponentProps} submitUrl={'/api/home/notes/'} redirect={this.newNote} deleteType={true} />;

          var add_note = <Card name={'Add Note'} description={note_form} />;


          var send_request = <Button onClick={this.sendRequestToLawyer} text={'Send Request To Lawyer'} type={'danger'} deleteType={true} style={{marginBottom:'20px'}} />;
          
          for (var file_index in this.state.files) {
              var file = this.state.files[file_index].file;
              if (!file.archived && !file.supporting_file && (!file.verified_by || !file.verified_by.id)) {
                  send_request = <Button text={'Verify All Files Before Sending Out Request'} type={'danger'} style={{marginBottom:'20px'}} disabled={true} />;;
              }
          }

          var upload_files = <div>
            {send_request}
            {message_sent}
            <Form defaults={formDefaults} components={FileComponents} componentProps={FileComponentProps} autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'files'}/>
            </div>;
          var document_header = 'Add Documents';
          edit_provider = <Button type='primary' text='Edit Provider' href={'/editProvider/' + this.state.provider.id + '/'} target="_blank"/>
          
          tracking_form = <Form defaults={{requisition_id: this.state.requisition_id, provider_cost: this.state.provider_cost}} components={[TextInput, NumberInput]} componentProps={[{name:'requisition_id', label:'Requisition Id'},{name:'provider_cost', label:'Provider Fee'}]} submit={this.submitRequisitionForm} redirect={this.savedTracking} row={true} col_size={6} />
        }
        else {
            update_status['style'] = {display:'none'};
            reminder_date['style'] = {display:'none'};
            notes_defaults['update_status'] = 'Waiting for Direct Docs Response';
            var note_form = <Form defaults={notes_defaults} row={true} components={NotesComponents} componentProps={NotesComponentProps} submitUrl={'/api/home/notes/'} redirect={this.newNote} />;
            var add_note = <Card name={'Add Note'} description={note_form} />;
        }

        var tracking_alert = null;
        if (this.state.tracking_saved) {
            var tracking_alert = <Alert type='success' text='Saved' />
        }

        var intake_form = null;
        if (this.state.intake_form_url != '') {
            intake_form = <p><b>Intake Form: </b><a href={this.state.intake_form_url}>Intake Form</a></p>
        }

        var new_provider = null;
        if (this.state.new_provider) {
            new_provider = <Button text='New Provider. Merge?' href={'/editProvider/' + this.state.provider.id + '/'} type='success' target='_blank' />
        }

        var archived_status = <p>Archived Status: Active</p>
        if (this.state.archived) {
            archived_status = <p><b>Archived Status: Archived</b></p>
        }

        var content =
          <div className="container-fluid">
          {reminder}

          <div className="row">
            <div className="col-4" style={{border: '2px solid black', padding:'10px', backgroundColor:'#77dd77'}}>
              <Header size={3} text={'Request Details Center: #' + this.state.internal_number} />
              {split_button}
              {edit_button}

              <Button onClick={this.modal} text={'Patient Info'} type='warning' />
              <div style={{height:'10px'}}></div>
              {personal_info_form}
              {provider_form}
              {intake_form}
            {law_firm_release}

            </div>
            <div className="col-4" style={{border: '2px solid black', padding:'10px'}}>
                {archived_status}
                {tracking_form}
                {tracking_alert}

            </div>
            <div className="col-4" style={{border: '2px solid black', padding:'10px'}}>
                <h2><b>Law Firm:</b> {this.state.patient.company.name}</h2>
                <h5><b>Main Contact:</b> {this.state.user.first_name} {this.state.user.last_name}</h5>
                <h5><b>Request Status:</b> {this.state.status}</h5>
                <h5><b>Follow Up Date:</b> {next_reminder_date_jsx}</h5>
            </div>
            <div className="col-8" style={{border: '2px solid black', paddingTop:'20px'}}>
                <p>
                    <span><b>Client Name: </b>{this.state.patient.first_name} {this.state.patient.last_name}</span>
                    <span style={{marginLeft:'20px'}}><b>Birthday: </b>{format_date_string(this.state.patient.date_of_birth, 'mm/dd/yyyy')}</span>
                    <span style={{marginLeft:'20px'}}><b>SS#: </b>{this.state.patient.ssn}</span>
                </p>
                <p><b>Provider Name: </b><a onClick={this.show_provider_details} style={{color:'blue'}}>{this.state.provider.name}</a></p>
                <EmptyModal onHide={this.show_provider_details} show={this.state.provider_details}>
                  <p><b>Email: </b>{this.state.provider.email}</p>
                  <p><b>Phone: </b>{this.state.provider.phone} <b>Fax: </b>{this.state.provider.fax}</p>
                  <p><b>Address: </b>{this.state.provider.address_1} {this.state.provider.address_2}, {this.state.provider.city} {this.state.provider.state} {this.state.provider.zip_code}</p>
                  <p><b>Additional Notes: </b>{this.state.provider.notes}</p>
                  {edit_provider}

                </EmptyModal>
                {new_provider}
                <p><b>Request Types: </b>{this.state.request_type}</p>
                <p>
                    <span><b>Request Start Date: </b>{format_date_string(this.state.start_date, 'mm/dd/yyyy')}</span>
                    <span style={{marginLeft:'20px'}}><b>Request End Date: </b>{format_date_string(this.state.end_date, 'mm/dd/yyyy')}</span>
                    <span style={{marginLeft:'20px'}}><b>Rush: </b>{rush}</span></p>
                <p><b>Request Special Instructions: </b>{this.state.special_instructions}</p>
                <Header size={4} text={'Additional Documents'} />
                <div>
                  <Form defaults={formDefaults} components={FileComponents} componentProps={FileComponentProps} autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'supporting_files'}/>
                </div>
                <div className="files">
                  {supporting_files}
                </div>
            </div>
            <div className="col-4" style={{border: '2px solid black', paddingTop:'20px'}}>
                <Header size={3} text={document_header} />
                {upload_files}
                <div className="files">
                  {files}
                </div>
            </div>
              <EmptyModal onHide={this.modal} show={this.state.modal}>
                <Patient patient_id={this.state.patient.id} user={this.props.user} redirect={this.modal} company={this.state.patient.company.id}/>
              </EmptyModal>


          </div>


          {add_note}
          <br />
          <Header size={5} text={'All Notes'} />
          <table className="table">
            <tr>
              <th>Name</th>
              <th>Notes</th>
              <th>Date</th>
            </tr>
            {notes}
          </table>

          <div style={{textAlign:'right'}}>
            {delete_button}
        </div>


          </div>;
      }



        return (
            <Wrapper loaded={this.state.loaded}  content={content} />
        );
    }
}

export default Request;

import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import Wrapper from "base/wrapper.js";
import ajaxWrapper from "base/ajax.js";
import sort_objects from "base/sort_objects.js";
import {
  Container,
  Button,
  Image,
  Form,
  TextInput,
  Navbar,
  List,
  Select,
  Link,
  Accordion,
  Paragraph,
  RadioButton,
  TextArea,
  Header,
  Card,
  MultiLineText,
  PageBreak,
  ListWithChildren,
  FormWithChildren,
  EmptyModal,
} from "library";
import format_date from "base/date_format.js";
import format_date_string from "base/date_string_format.js";
import DateFormat from "../../base/date_format_split";

class Request extends Component {
  constructor(props) {
    super(props);

    this.state = { assign: false };

    this.toggle_assign = this.toggle_assign.bind(this);
    this.submit = this.submit.bind(this);
    this.submit_callback = this.submit_callback.bind(this);
    this.releaseTask = this.releaseTask.bind(this);
  }

  toggle_assign() {
    this.setState({ assign: !this.state.assign });
  }

  releaseTask() {
    ajaxWrapper(
      "POST",
      "/api/home/request/" + this.props.request.id + "/",
      { working_on: null },
      this.submit_callback
    );
  }

  submit(state) {
    ajaxWrapper(
      "POST",
      "/api/home/request/" + this.props.request.id + "/",
      { working_on: state.assignee },
      this.submit_callback
    );
  }

  submit_callback() {
    this.props.refreshPage();
    this.props.refreshData();
    this.setState({ assign: false });
  }

  render() {
    var request = this.props.request;

    var patient = request.patient;
    var patient_name = patient.full_name;

    request.next_todo_date = request.next_todo_date
      ? format_date_string(request.next_todo_date, "mm/dd/yyyy")
      : "";

    var lawyer_name = "";
    if (request.user) {
      lawyer_name = request.user.full_name;
    }

    var requester_name = "";
    if (request.requester) {
      requester_name = request.requester.full_name;
    }

    var rush = "";
    if (request.rush) {
      rush = "Rush";
    }
    var company = request.patient.company.name;

    if (
      request.working_on &&
      request.working_on.unicode == "None" &&
      this.props.user
    ) {
      // if (this.props.user.role_id == process.env.REACT_APP_ADMIN_ROLE_ID) {
        var working_button = (
          <Button
            css={{ width: "90px" }}
            type="success"
            text="Assign"
            onClick={this.toggle_assign}
          />
        );
      // } else {
      //   var working_button = (
      //     <p>
      //       {request.working_on.first_name} {request.working_on.last_name}
      //     </p>
      //   );
      // }
    }

    if (
      request.working_on &&
      request.working_on.unicode != "None" &&
      this.props.user
    ) {
      if (this.props.user.role_id == process.env.REACT_APP_ADMIN_ROLE_ID) {
        var working_button = (
          <Button
            css={{ width: "90px" }}
            type="danger"
            text="Release"
            onClick={this.releaseTask}
          />
        );
      } else {
        var working_button = (
          <p>
            {request.working_on.first_name} {request.working_on.last_name}
          </p>
        );
      }
    }

    if (this.state.assign) {
      return (
        <EmptyModal show={true} onHide={this.toggle_assign}>
          <h4>
            Request For: {patient_name} at {request.provider.name}
          </h4>
          <Form
            submit={this.submit}
            defaults={{ assignee: "" }}
            components={[Select]}
            componentProps={[
              {
                required: true,
                label: "Who do you want to assign this to?",
                optionsUrl:
                  "/api/user/user/?company_id=e4f3c0a1-5e41-4541-98e4-8288353c7684",
                optionsUrlMap: {
                  text: "{user.first_name} {user.last_name}",
                  value: "{user.id}",
                },
                name: "assignee",
              },
            ]}
          />
        </EmptyModal>
      );
    }

    var provider_name = "";
    if (request.provider) {
      provider_name = request.provider.name;
    }

    return (
      <tr>
        <td>{working_button}</td>
        <td>{request.internal_number}</td>
        <td>
          <a href={"/request/" + request.id + "/"} target="_blank">
            View
          </a>
        </td>
        <td>{company}</td>
        <td>{patient_name}</td>
        <td>{provider_name}</td>
        <td>{request.request_type}</td>
        <td>{lawyer_name}</td>
        <td>{requester_name}</td>
        <td>{request.status}</td>
        <td>{rush}</td>
        <td>{DateFormat(request.created_at)}</td>
        <td>{request.next_todo_date}</td>
      </tr>
    );
  }
}

class SortableTitle extends Component {
  constructor(props) {
    super(props);

    this.sort = this.sort.bind(this);
  }

  sort() {
    this.props.sort(this.props.name);
  }

  render() {
    var sorting = null;
    if (this.props.name == this.props.sort_by) {
      sorting = <span>({this.props.direction})</span>;
    }

    return (
      <th style={{ pointer: "cursor" }}>
        <a onClick={this.sort}>
          {this.props.name} {sorting}
        </a>
      </th>
    );
  }
}

export default class DataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patient: "",
      lawyer: "",
      provider: "",
      status: "",
      company: "",
      requester: "",
      claimed_by: "",
      sort_by: "",
      requests: [],
      direction: "Ascending",
      page_offset: 0,
      last_page: false,
      total_requests_count: 0,
      limit: 100,
      default_sorting: "-",
      default_name: "status",
      total_count: 0,
      completed_limit: 10,
      completed_page_offset: 1,
      law_firm_filter: "",
      client_filter: "",
      provider_filter: "",
      paralegal_filter: "",
      requester_filter: "",
      status_filter: "",
      claimed_by_filter: "",
      internal_number_filter: "",
      order_by_filter: "",
    };

    this.back = this.back.bind(this);
    this.setGlobalState = this.setGlobalState.bind(this);
    this.sort = this.sort.bind(this);
    this.fetchRequests = this.fetchRequests.bind(this);
    this.fetchRequestsError = this.fetchRequestsError.bind(this);
    this.fetchRequestsCallback = this.fetchRequestsCallback.bind(this);
    this.fetchRequestsCount = this.fetchRequestsCount.bind(this);
    this.fetchRequestsCountError = this.fetchRequestsCountError.bind(this);
    this.fetchRequestsCountCallback =
      this.fetchRequestsCountCallback.bind(this);
    this.changePage = this.changePage.bind(this);
    this.createUrl = this.createUrl.bind(this);
    this.searchRequests = this.searchRequests.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handleCompletedLimitChange =
      this.handleCompletedLimitChange.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
    this.handleName = this.handleName.bind();
  }

  handleSorting = (e) => {
    this.setState({ default_sorting: e.target.value });
  };

  handleName = (e) => {
    this.setState({ default_name: e.target.value });
  };

  handleLimitChange(e) {
    this.setState({ limit: parseInt(e.target.value) });
  }

  handleApi(
    page_offset,
    limit,
    law_firm_filter,
    client_filter,
    provider_filter,
    paralegal_filter,
    requester_filter,
    status_filter,
    claimed_by_filter,
    internal_number_filter,
    order_by_filter
  ) {
    let url = `/api/completed-requests-with-fees/?page=${page_offset}&page_size=${limit}&law_firm=${law_firm_filter}&client=${client_filter}&provider=${provider_filter}&paralegal=${paralegal_filter}&requester=${requester_filter}&status=${status_filter}&claimed_by=${claimed_by_filter}&internal_number=${internal_number_filter}&order_by=${order_by_filter}`;
    ajaxWrapper(
      "GET",
      url,
      {},
      this.fetchRequestsCallback,
      this.fetchRequestsError
    );
  }

  componentDidMount() {
    this.fetchRequests();
    this.fetchRequestsCount();
  }

  handlePageClick = (event) => {
    this.setState({ completed_page_offset: event.selected + 1 });
    this.handleApi(
      event.selected + 1,
      this.state.completed_limit,
      this.state.law_firm_filter,
      this.state.client_filter,
      this.state.provider_filter,
      this.state.paralegal_filter,
      this.state.requester_filter,
      this.state.status_filter,
      this.state.claimed_by_filter,
      this.state.internal_number_filter,
      this.state.order_by_filter
    );
  };

  handleCompletedLimitChange(e) {
    this.setState({ completed_limit: parseInt(e.target.value) });
    if (parseInt(e.target.value) > this.state.total_count) {
      this.setState({ completed_page_offset: 1 });
    }
    if (
      this.state.completed_page_offset * parseInt(e.target.value) >
      this.state.total_count
    ) {
      this.setState({
        completed_page_offset:
          parseInt(this.state.total_count / parseInt(e.target.value)) == 0
            ? 1
            : parseInt(this.state.total_count / parseInt(e.target.value)),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type == "closed_request_with_fees") {
      if (this.state.completed_limit !== prevState.completed_limit) {
        this.handleApi(
          this.state.completed_page_offset,
          this.state.completed_limit,
          this.state.law_firm_filter,
          this.state.client_filter,
          this.state.provider_filter,
          this.state.paralegal_filter,
          this.state.requester_filter,
          this.state.status_filter,
          this.state.claimed_by_filter,
          this.state.internal_number_filter,
          this.state.order_by_filter
        );
      }
    } else {
      if (
        prevState.page_offset != this.state.page_offset ||
        prevState.limit != this.state.limit ||
        prevState.default_name != this.state.default_name ||
        prevState.default_sorting != this.state.default_sorting
      ) {
        this.fetchRequests();
      }
    }
  }

  back() {
    this.props.back();
  }

  setGlobalState(name, state) {
    if (this.props.type == "closed_request_with_fees") {
      this.setState({
        law_firm_filter: state.company ? decodeURIComponent(state.company) : "",
      });
      this.setState({
        client_filter: state.patient ? decodeURIComponent(state.patient) : "",
      });
      this.setState({
        provider_filter: state.provider
          ? decodeURIComponent(state.provider)
          : "",
      });
      this.setState({
        paralegal_filter: state.lawyer ? state.lawyer : "",
      });
      this.setState({
        requester_filter: state.requester
          ? decodeURIComponent(state.requester)
          : "",
      });
      this.setState({
        status_filter: state.status ? decodeURIComponent(state.status) : "",
      });
      this.setState({
        claimed_by_filter: state.claimed_by
          ? decodeURIComponent(state.claimed_by)
          : "",
      });
      this.setState({
        internal_number_filter: state.internal_number
          ? decodeURIComponent(state.internal_number)
          : "",
      });
    } else {
      this.setState(state);
    }
  }

  sort(name) {
    if (this.props.type == "closed_request_with_fees") {
      let order_by = "";
      let direction = "";
      let opposite_direction = "-";
      if (name == "Internal #") {
        order_by = "internal_number";
      }
      if (name == "Law Firm") {
        order_by = "patient__company__name";
      }

      if (name == "Client") {
        order_by = "patient__full_name";
      }

      if (name == "Provider") {
        order_by = "provider__name";
      }

      if (name == "Request Types") {
        order_by = "request_type";
      }

      if (name == "Paralegal") {
        order_by = "user__full_name";
      }

      if (name == "Requestor") {
        order_by = "requester__full_name";
      }

      if (name == "Status") {
        order_by = "status";
      }

      if (name == "Created At Date") {
        order_by = "created_at";
      }

      if (name == "Next Reminder Date") {
        order_by = "next_todo_date";
      }

      if (this.state.sort_by != name) {
        this.setState({ sort_by: name, direction: "Ascending" });
      } else {
        if (this.state.direction == "Ascending") {
          this.setState({ direction: "Descending" });
          direction = "-";
        } else {
          this.setState({ direction: "Ascending" });
        }
      }

      if (order_by != "") {
        this.setState({ order_by_filter: direction + order_by });
        this.setState({ completed_page_offset: 1 });
        this.handleApi(
          1,
          this.state.completed_limit,
          this.state.law_firm_filter,
          this.state.client_filter,
          this.state.provider_filter,
          this.state.paralegal_filter,
          this.state.requester_filter,
          this.state.status_filter,
          this.state.claimed_by_filter,
          this.state.internal_number_filter,
          direction + order_by
        );
      }
    } else {
      if (this.state.sort_by != name) {
        this.setState({ sort_by: name, direction: "Ascending" });
      } else {
        if (this.state.direction == "Ascending") {
          this.setState({ direction: "Descending" });
        } else {
          this.setState({ direction: "Ascending" });
        }
      }
    }
  }

  createUrl(get_total_count = false) {
    if (this.props.type == "closed_request_with_fees") {
      this.handleApi(
        this.state.completed_page_offset,
        this.state.completed_limit,
        this.state.law_firm_filter,
        this.state.client_filter,
        this.state.provider_filter,
        this.state.paralegal_filter,
        this.state.requester_filter,
        this.state.status_filter,
        this.state.claimed_by_filter,
        this.state.internal_number_filter,
        this.state.order_by_filter
      );
      window.scrollTo(0, 0);
    } else {
      let url =
        "/api/home/request/?related=working_on,patient,patient__company,files,requester,user,provider";

      let filters = {};

      filters.exclude_admins_and_users = true;

      if (!this.props.user.is_staff) {
        filters.working_on = this.props.user.id;
      }

      if (this.props.type && this.props.type != "all_requests") {
        filters.type = this.props.type;
      }

      if (this.props.employee) {
        filters.working_on = this.props.employee.id;
      } else if (this.props.law_firm) {
        filters.patient__company = this.props.law_firm.id;
      }

      filters.status_open = true;

      if (this.props.closed) {
        if (this.props.employee) {
          delete filters.status_open;

          if (["today_reminders", "old_reminders"].includes(this.props.type)) {
            filters.status_open = true;
          } else if (
            [
              "all_requests",
              "rush_requests",
              "need_your_attention_requests",
            ].includes(this.props.type) == false
          ) {
            filters.status_completed = true;
          }
        } else if (this.props.law_firm) {
          delete filters.status_open;

          if (
            [
              "all_requests",
              "rush_requests",
              "need_your_attention_requests",
            ].includes(this.props.type) == false
          ) {
            filters.status_completed = true;
          }
        } else {
          if (
            [
              "all_requests",
              "closed_request_with_fees",
              "un_assigned_requests_all",
              "requests_in_last_day",
              "requests_in_last_week",
              "requests_in_last_month",
            ].includes(this.props.type)
          ) {
            delete filters.status_open;
          }
        }
      }

      // Form Filters
      if (this.state.company) {
        filters.patient__company = this.state.company;
      }
      if (this.state.patient) {
        filters.patient__full_name__icontains = this.state.patient;
      }
      if (this.state.provider) {
        filters.provider__name__icontains = this.state.provider;
      }
      if (this.state.user) {
        filters.user__full_name__icontains = this.state.user;
      }
      if (this.state.requester) {
        filters.requester__full_name__icontains = this.state.requester;
      }
      if (this.state.internal_number) {
        filters.internal_number__contains = this.state.internal_number;
      }
      if (this.state.claimed_by) {
        filters.working_on = this.state.claimed_by;
      }
      if (this.state.status) {
        if (this.state.status == "Open") {
          filters.status_open = true;
        } else {
          filters.status = this.state.status;
        }
      }

      if (get_total_count) {
        filters.count = true;
      } else {
        filters.limit = this.state.limit;
        filters.offset = this.state.page_offset;
      }

      filters.order_by = `${this.state.default_sorting}${this.state.default_name}`;

      for (const key in filters) {
        url += `&${key}=${filters[key]}`;
      }

      return url;
    }
  }

  fetchRequests() {
    let url = this.createUrl();
    ajaxWrapper(
      "GET",
      url,
      {},
      this.fetchRequestsCallback,
      this.fetchRequestsError
    );
  }

  fetchRequestsCallback(data) {
    this.setState({ total_count: data.count });
    let formatted_data;
    if (this.props.type == "closed_request_with_fees") {
      formatted_data = data.results.map((rq) => rq.request);
    } else {
      formatted_data = data.map((rq) => rq.request);
    }
    this.setState({ requests: formatted_data });

    if (data.length < this.state.limit) {
      this.setState({ last_page: true });
    } else {
      this.setState({ last_page: false });
    }
  }

  fetchRequestsError(result) {
    this.setState({ last_page: true });
    console.log("fetchRequestsError", result);
  }

  fetchRequestsCount() {
    let url = this.createUrl(true);
    ajaxWrapper(
      "GET",
      url,
      {},
      this.fetchRequestsCountCallback,
      this.fetchRequestsCountError
    );
  }

  fetchRequestsCountCallback(data) {
    this.setState({ total_requests_count: data.count });
  }

  fetchRequestsCountError(result) {
    console.log("fetchRequestsCountError", result);
  }

  changePage(str) {
    let offset = this.state.page_offset;
    if (str == "prev") {
      offset -= this.state.limit;
      if (offset <= 0) {
        offset = 0;
      }
    } else {
      if (!this.state.last_page) {
        offset += this.state.limit;
      }
    }
    this.setState({ page_offset: offset });
  }

  searchRequests() {
    this.setState({ page_offset: 0 });
    this.fetchRequests();
    this.fetchRequestsCount();
    if (this.props.type == "closed_request_with_fees") {
      this.setState({ completed_limit: 10 });
      this.setState({ completed_page_offset: 1 });
      this.handleApi(
        1,
        10,
        this.state.law_firm_filter,
        this.state.client_filter,
        this.state.provider_filter,
        this.state.paralegal_filter,
        this.state.requester_filter,
        this.state.status_filter,
        this.state.claimed_by_filter,
        this.state.internal_number_filter,
        this.state.order_by_filter
      );
    }
  }

  render() {
    var company_options = [];
    for (var index in this.props.law_firms) {
      var company = this.props.law_firms[index];
      company_options.push({ text: company.name, value: company.name });
    }

    var employee_options = [];
    for (var index in this.props.employees) {
      var employee = this.props.employees[index];
      employee_options.push({ text: employee.name, value: employee.name });
    }

    var status_options = [
      { text: "All Open", value: "Open" },
      { text: "Requested By Attorney", value: "Requested By Lawyer" },
      { text: "Initial Request Sent", value: "Initial Request Sent" },
      { text: "In Contact With Provider", value: "In Contact With Provider" },
      { text: "Documents en Route", value: "Documents en Route" },
      { text: "Need Assistance", value: "Need Assistance" },
      {
        text: "Waiting for Direct Docs Response",
        value: "Waiting for Direct Docs Response",
      },
      {
        text: "Completed - Documents Arrived",
        value: "Completed - Documents Arrived",
      },
      {
        text: "Completed - No Documents Available",
        value: "Completed - No Documents Available",
      },
      {
        text: "Closed - Documents Arrived",
        value: "Closed - Documents Arrived",
      },
      {
        text: "Closed - No Documents Available",
        value: "Closed - No Documents Available",
      },
      {
        text: "Closed - Cancelled Request",
        value: "Closed - Cancelled Request",
      },
      { text: "All Closed", value: "Closed" },
    ];
    var patient_filter = { name: "patient", label: "Client" };
    var lawyer_filter = { name: "lawyer", label: "Paralegal" };
    var provider_filter = { name: "provider", label: "Provider" };
    var status_filter = {
      name: "status",
      label: "Status",
      options: status_options,
    };
    var company_filter = {
      name: "company",
      label: "Law Firm",
      options: company_options,
    };
    var requester_filter = { name: "requester", label: "Requestor" };
    var claimed_by = {
      name: "claimed_by",
      label: "Claimed By",
      options: employee_options,
    };
    var number_filter = { name: "internal_number", label: "Internal #" };

    var filter_form = (
      <FormWithChildren
        autoSetGlobalState={true}
        globalStateName={"filter_form"}
        defaults={{}}
        className={"row"}
        setGlobalState={this.setGlobalState}
      >
        <Select {...company_filter} layout={"col-sm-2"} />
        <TextInput {...patient_filter} layout={"col-sm-2"} />
        <TextInput {...provider_filter} layout={"col-sm-2"} />
        <TextInput {...lawyer_filter} layout={"col-sm-2"} />
        <TextInput {...requester_filter} layout={"col-sm-2"} />
        <Select {...status_filter} layout={"col-sm-2"} />
        <Select {...claimed_by} layout={"col-sm-2"} />
        <TextInput {...number_filter} layout={"col-sm-2"} />

        <Button
          style={{ padding: "6px 20px", margin: "auto", marginRight: "15px" }}
          type="primary"
          text="Search"
          onClick={this.searchRequests}
        />
      </FormWithChildren>
    );

    var requests = [];
    var request_list = this.state.requests;

    if (this.props.type != "closed_request_with_fees") {
      if (this.state.sort_by != "") {
        var reverse = "";
        if (this.state.direction == "Descending") {
          reverse = "-";
        }

        if (this.state.sort_by == "Working") {
          sort_objects(request_list, ["working_on", reverse + "last_name"]);
        }
        if (this.state.sort_by == "Internal #") {
          sort_objects(request_list, [reverse + "internal_number"]);
        }
        if (this.state.sort_by == "Law Firm") {
          sort_objects(request_list, ["patient", "company", reverse + "name"]);
        }
        if (this.state.sort_by == "Client") {
          sort_objects(request_list, ["patient", reverse + "full_name"]);
        }
        if (this.state.sort_by == "Provider") {
          sort_objects(request_list, ["provider", reverse + "name"]);
        }
        if (this.state.sort_by == "Paralegal") {
          sort_objects(request_list, ["user", reverse + "full_name"]);
        }
        if (this.state.sort_by == "Requestor") {
          sort_objects(request_list, ["requester", reverse + "full_name"]);
        }
        if (this.state.sort_by == "Status") {
          sort_objects(request_list, [reverse + "status"]);
        }
        if (this.state.sort_by == "Created At Date") {
          sort_objects(request_list, [reverse + "created_at"]);
        }
        if (this.state.sort_by == "Next Reminder Date") {
          sort_objects(request_list, [reverse + "next_todo_date"]);
        }
      }
    }

    var internal_number = null;
    if (this.state.internal_number) {
      internal_number = parseInt(this.state.internal_number);
    }

    for (var index in request_list) {
      var request = request_list[index];

      var patient = request.patient;
      var patient_name = patient.full_name;

      var lawyer_name = "";
      if (request.user) {
        lawyer_name = request.user.full_name;
      }

      var requester_name = "";
      if (request.requester) {
        requester_name = request.requester.full_name;
      }

      var rush = "";
      if (request.rush) {
        rush = "Rush";
      }
      var company = request.patient.company.id;

      var provider = "";
      if (request.provider) {
        provider = request.provider.name;
      }

      var claimed_by = "None";
      if (
        request.working_on &&
        request.working_on.unicode != "None" &&
        this.props.user
      ) {
        var claimed_by = request.working_on.id;
      }
      var status = request.status;

      if (
        this.state.internal_number &&
        String(request.internal_number).indexOf(this.state.internal_number) ==
          -1
      ) {
        continue;
      }
      if (
        this.state.patient != "" &&
        patient_name.toLowerCase().indexOf(this.state.patient.toLowerCase()) ==
          -1
      ) {
        continue;
      }
      if (
        this.state.company != "" &&
        company.indexOf(this.state.company) == -1
      ) {
        continue;
      }
      if (
        this.state.provider != "" &&
        provider.toLowerCase().indexOf(this.state.provider.toLowerCase()) == -1
      ) {
        continue;
      }
      if (
        this.state.claimed_by != "" &&
        claimed_by.indexOf(this.state.claimed_by) == -1
      ) {
        continue;
      }
      if (
        this.state.lawyer != "" &&
        lawyer_name.toLowerCase().indexOf(this.state.lawyer.toLowerCase()) == -1
      ) {
        continue;
      }
      if (
        this.state.requester != "" &&
        requester_name
          .toLowerCase()
          .indexOf(this.state.requester.toLowerCase()) == -1
      ) {
        continue;
      }
      if (
        this.state.status == "Open" &&
        status.indexOf("Closed") == -1 &&
        request.status.indexOf("Completed") == -1
      ) {
      } else if (
        this.state.status != "" &&
        status.toLowerCase() != this.state.status.toLowerCase()
      ) {
        continue;
      }

      requests.push(
        <Request
          request={request}
          refreshData={this.props.refreshData}
          refreshPage={this.fetchRequests}
          user={this.props.user}
        />
      );
    }

    var titles = [];
    var title_names = [
      "Working",
      "Internal #",
      "View",
      "Law Firm",
      "Client",
      "Provider",
      "Request Types",
      "Paralegal",
      "Requestor",
      "Status",
      "Rush?",
      "Created At Date",
      "Next Reminder Date",
    ];
    for (var name in title_names) {
      if (title_names[name] == "View" || title_names[name] == "Rush?") {
        titles.push(<th>{title_names[name]}</th>);
      } else {
        titles.push(
          <SortableTitle
            sort={this.sort}
            sort_by={this.state.sort_by}
            direction={this.state.direction}
            name={title_names[name]}
          />
        );
      }
    }

    var content = (
      <div className="container-fluid">
        <h2>{this.props.name}</h2>
        <Button
          text="Back To Full Report"
          onClick={() => {
            this.back();
          }}
          type="info"
        />
        <div style={{ height: "50px" }}></div>
        {filter_form}
        {this.props.type != "closed_request_with_fees" ? (
          <div
            className="pagination"
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <select
              style={{ width: "180px", marginRight: "10px" }}
              className="form-control"
              value={this.state.default_name}
              onChange={this.handleName}
            >
              <option value={"company__name"}>Law Firm</option>
              <option value={"patient__full_name"}>Client</option>
              <option value={"provider__name"}>Provider</option>
              <option value={"user__full_name"}>Paralegal</option>
              <option value={"requester__full_name"}>Requestor</option>
              <option value={"status"}>Status</option>
              <option value={"claimed_by"}>Claimed By</option>
              <option value={"internal_number"}>Internal #</option>
              <option value={"working_on__full_name"}>Working</option>
              <option value={"created_at"}>Created At Date</option>
              <option value={"next_todo_date"}>Next Reminder Date</option>
            </select>
            <select
              style={{ width: "180px", marginRight: "10px" }}
              className="form-control"
              value={this.state.default_sorting}
              onChange={this.handleSorting}
            >
              <option value={""}>Ascending</option>
              <option value={"-"}>Descending</option>
            </select>
            <select
              className="form-control"
              style={{ width: "200px", marginRight: "5px" }}
              onChange={this.handleLimitChange}
            >
              <option value={"100"}>100</option>
              <option value={"200"}>200</option>
              <option value={"300"}>300</option>
              <option value={"400"}>400</option>
              <option value={"500"}>500</option>
            </select>
            <button
              className={
                "btn" + (this.state.page_offset ? " btn-info" : " btn-disabled")
              }
              onClick={() => this.changePage("prev")}
            >
              {" "}
              Prev{" "}
            </button>
            <button
              className={
                "btn" + (this.state.last_page ? " btn-disabled" : "  btn-info")
              }
              onClick={() => this.changePage("next")}
            >
              {" "}
              Next{" "}
            </button>
          </div>
        ) : (
          <>
            {" "}
            <br />
            <div
              style={{
                margin: "20px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div>Total Records : {this.state.total_count}</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                {this.state.total_count > 0 ? (
                  <select
                    className="form-control"
                    style={{ width: "85px", marginRight: "5px" }}
                    onChange={this.handleCompletedLimitChange}
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                    <option>100</option>
                  </select>
                ) : (
                  ""
                )}
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={this.handlePageClick}
                  pageRangeDisplayed={10}
                  marginPagesDisplayed={3}
                  pageCount={Math.ceil(
                    this.state.total_count / this.state.completed_limit
                  )}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className="custom-pagination"
                  forcePage={this.state.completed_page_offset - 1}
                />
              </div>
            </div>
          </>
        )}
        {this.props.type != "closed_request_with_fees" ? (
          <div
            className="pagination-count"
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {`Showing ${this.state.page_offset} - ${
              this.state.page_offset + this.state.requests.length
            } results. (${this.state.total_requests_count} total)`}
          </div>
        ) : (
          ""
        )}
        <table className="table">
          <thead>
            <tr> {titles} </tr>
          </thead>
          <tbody>{requests}</tbody>
        </table>
      </div>
    );

    return content;
  }
}

import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Header, Button} from 'library';

class TopContent extends React.Component {

    render() {
        var style = {'backgroundImage':'url("' + this.props.image + '")',
                      height:'500px', backgroundPositionY:'-500px'}

        return (
          <div style={{'background':'rgba(0,0,0,.5)'}}>
          <section style={style}>
            <div style={{height:'100%', width:'100%', backgroundColor:'rgba(0,0,0,.1)', display:'flex', alignItems:'center'}}>
              <div className="container" style={{'textAlign':'center'}}>
                <h1 style={{'color':'white', fontSize:'52px', fontWeight:'bold'}}>{this.props.quote}</h1>
                <p style={{'fontSize':'18px', fontWeight:'bold', color:'white', margin:'40px', letterSpacing:'5px'}}>{this.props.who}</p>
              </div>
            </div>
          </section>
          </div>
        );
    }
}


export default TopContent;

import React, { Component } from 'react';
import resolveVariables from 'base/resolver.js';
import Datetime from 'react-datetime';

class DateTimePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {error:''}
    this.handlechange = this.handlechange.bind(this);
  }

    handlechange(e) {
      var value = '';
      var error = '';
      try {
        if (this.props.display_time == false) {
          value = e.format("YYYY-MM-DD");
        }
        else if (this.props.display_date == false) {
          value = e.format("hh:mm A");
        }
        else {
          value = e.format("MM/DD/YYYY hh:mm A");
        }
      }
      catch(err) {
        value = e;
        error = 'Date format needs to be in format mm/dd/yyyy. Ex. 07/03/2020';
      }

      var newState = {}
      newState[this.props.name] = value;
      this.props.setFormState(newState);

      this.setState({error:error})
    }

    render() {
        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }
        //<input type="text" className="form-control" name={this.props.name} onChange={this.props.handlechange} value={this.props.value} placeholder={this.props.placeholder} />

        var display_time = true;

        if (this.props.display_time == false) {

          display_time = false;
        }

        var display_date = true;
        if (this.props.display_date == false) {
          display_date = false;
        }

        var value = this.props.value;
        var value_split = value.split('-');
        if (value && value != '' && value_split.length == 3) {
            value = value_split[1] + '/' + value_split[2] + '/' + value_split[0];
        }

        var error = null;
        if (this.state.error != '') {
            error = <p style={{color:'red'}}>{this.state.error}</p>
        }

        return (
              <div className={"form-group " + this.props.layout} style={this.props.style}>
                <label>{this.props.label}</label>
            <Datetime defaultValue={this.props.defaultValue} timeFormat={display_time} dateFormat={display_date}  onChange={this.handlechange} value={value}/>
                {error}
              </div>
        )
    }
}

export default DateTimePicker;


import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import {Container, Button, Image, Form, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText} from 'library';

class NotesList extends Component {
    constructor(props) {
        super(props);
        this.state = {'notess':[]}

        this.objectCallback = this.objectCallback.bind(this);
    }

    componentDidMount() {
      ajaxWrapper('GET','/api/home/notes/', {}, this.objectCallback);
    }

    objectCallback(result) {
      var notess = []
      for (var index in result) {
        var notes = result[index]['notes'];
        notess.push(notes)
      }
      this.setState({'notess':notess, 'loaded':true})
    }

    render() {
      console.log("Here");

      var notess = [];
      for (var index in this.state.notess) {
        var notes = this.state.notess[index];
        notess.push(<Card name={notes.name} description={notes.description} button_type={'primary'} button={'View'} link={'/notes/' + notes.id + '/'} />)
      }

      var content =
        <div className="container">
          <Header size={2} text={'Notess'} />
          <br />
          <Button type={'success'} text={'Add New Notes'} href={'/editNotes/'} />
          <br />
          {notess}
        </div>;

        return (
            <Wrapper loaded={this.state.loaded}  content={content} />
        );
    }
}

export default NotesList;

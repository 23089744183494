import React, { Component } from 'react';
import './App.css';
import ajaxWrapper from "./base/ajax.js";
import setGlobalState from "./base/setGlobalState.js";

//Component Madness
import ComponentList from './pages/admin/componentList.js';
import ComponentManager from './pages/admin/componentManager.js';
import PageList from './pages/admin/pageList.js';
import PageManager from './pages/admin/pageManager.js';
import PageBuilder from './pages/admin/pageBuilder.js';


//Admin
import AppList from './pages/admin/appList.js';
import ModelList from './pages/admin/modelsList.js';
import InstanceList from './pages/admin/modelInstances.js';
import Instance from './pages/admin/instance.js';
import InstanceTable from './pages/admin/modelInstancesTable.js';

//Scaffolding
import Header from './base/header.js';
import Wrapper from './base/wrapper.js';
import LogIn from './pages/scaffold/logIn.js';
import SignUp from './pages/scaffold/signUp.js';
import LoggedIn from './pages/scaffold/loggedIn.js';
import PasswordResetRequest from './pages/scaffold/passwordResetRequest.js';
import PasswordReset from './pages/scaffold/passwordReset.js';

import Home from './pages/scaffold/home.js';
import Nav from 'projectLibrary/nav.js';
import Footer from 'projectLibrary/footer.js';
import ModelMaker from 'djangoModelMaker.js';

//API Querying
import APIDocs from './pages/admin/apiDocs.js';

import Test from './pages/modelEditAndView/WYSIWYG.js';

import Dashboard from './pages/nonStaffDashboard.js';
import UserDashboard from './pages/userDashboard.js';
import CreateCompany from './pages/editCompany.js';
import CompanyList from './pages/listCompany.js'
import Intake from './pages/editRequest.js';
import RequestNotes from './pages/listNotes.js';
import Invoices from './pages/listInvoices.js';
import Invoice from './pages/invoice.js'
import Billings from './pages/listBillings.js';
import CreateProvider from './pages/editProvider.js';
import ProviderList from './pages/listProvider.js';
import InviteUsers from './pages/inviteUsers.js';
import Request from './pages/Request.js';
import DefaultMedicalRequest from './pages/DefaultMedicalRequest.js';
import RequestList from './pages/listRequest.js';

import PDFList from './pages/listPDF.js';
import EditPDFForm from './pages/editPDFForm.js';

import About from './pages/about.js';
import Contact from './pages/contact.js';

import DiskUsage from './pages/diskUsage.js';

import SplitRequest from './pages/split_request.js';
import ListRequestTypes from './pages/listRequestType.js'

import DataDashboard from './pages/data_dashboard.js';
import GhostUsers from './pages/ghostUsers.js';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            csrfmiddlewaretoken: undefined,
            user:{}
        };

        this.ajaxCallback = this.ajaxCallback.bind(this);
        this.loadUser = this.loadUser.bind(this);
    }

    componentDidMount() {
        ajaxWrapper("GET", "/api/csrfmiddlewaretoken/", {}, this.ajaxCallback);

        var path = this.getURL()[0].toLowerCase();

        var token = localStorage.getItem('token');

        var loginNoRedirects = ['login','signup','passwordresetrequest', 'passwordreset', 'admin', 'about-us', 'contact-us'];

        if (token) {
            ajaxWrapper("GET", "/users/user/", {}, this.loadUser.bind(this));
            this.setState({token: token});
            if (path.indexOf('login') > -1) {
                window.location.href = '/dashboard/';
            }
        } else if (loginNoRedirects.indexOf(path) == -1 && window.location.pathname != "/") {
            var redirect = localStorage.setItem('redirect', window.location.pathname);
            window.location.href = '/login/';
        }
        else {
          this.setState({'loaded':true})
        }
    }

    loadUser(result){
      console.log("Load User Result", result.first_name);
      
      this.setState({
        user: result, loaded: true,
      });
      localStorage.setItem('timezone' , result.timezone)
    }

    logOut() {
        console.log("Log Out");
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_time');
        localStorage.removeItem('timezone')
        window.location.href = '/login/';
    }

    ajaxCallback(value) {

        window.secretReactVars["csrfmiddlewaretoken"] = value.csrfmiddlewaretoken;

        this.setState({
            csrfmiddlewaretoken: value.csrfmiddlewaretoken
        });
    }


    getURL() {
        var url = window.location.pathname;
        if (url[0] == '/'){ url = url.substring(1);}
        if (url[url.length - 1] == '/'){ url = url.substring(0,url.length-1);}

        return url.split('/');
    }

    render() {
        console.log(this.props);
        var params = this.getURL();
        console.log("Params", params);

        var adminPages = [

        ];

        var is_admin_user = this.state.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684';

        var loggedInPages = [];
        var route = params[0].toLowerCase();

        var loading = <h1>Loading . . . </h1>;
        var content = null;
        var navbar = null;
        var padding = '30px'
        if (this.state.loaded) {
          var navbar = <Nav user={this.state.user} logOut={this.logOut} />
          if (adminPages.indexOf(route) > -1 && this.state.loaded && !(this.state.user.is_staff)){
              window.location = '/';
              console.log("Not an admin", this.state.loaded, this.state.user)
          } else if (loggedInPages.indexOf(route) > -1 && this.state.loaded && typeof(this.state.user.id) != 'undefined') {
              var redirect = localStorage.setItem('redirect', '/' + route + '/');
              window.location = '/login/';
              console.log("Need to be logged in");
          }
          else if (route === "admin") {
              //List components
              content = <Home admin={'admin'} />;
          }
          else if (route == 'data_dashboard') {
              content = <DataDashboard user={this.state.user} />
          }
           else if (route === "components") {
              //List components
              content = <ComponentList />;
          }
          else if (route == "applist") {
              content = <AppList user_id={this.state.token} />;
          }
          else if (route == "models") {
              content = <ModelList app={params[1]} user_id={this.state.token}/>;
          }
          else if (route == "modelinstances") {
              content = <InstanceList app={params[1]} model={params[2]} user_id={this.state.token}/>;
          }
          else if (route == "modelinstancestable") {
              content = <InstanceTable app={params[1]} model={params[2]}/>;
          }
          else if (route == "modelmaker") {
              content = <ModelMaker user_id={this.state.token}/>;
          }
          else if (route == "instance") {
              content = <Instance app={params[1]} model={params[2]} id={params[3]} user_id={this.state.token}/>;
          }
          else if (route == "login") {
              content = <LogIn />;
          }
          else if (route == "signup") {
              content = <SignUp />;
          }
          else if (route == "loggedin") {
              content = <LoggedIn  />;
          }
          else if (route == "passwordresetrequest") {
              content = <PasswordResetRequest />;
          }
          else if (route == "passwordreset") {
              content = <PasswordReset user_id={params[1]} />;
          }
          else if (route == "test") {
              content = <Test id={params[1]} />;
          }
          else if (route == 'apidocs') {
            content = <APIDocs />
          }
          else if (route == 'pagebuilder') {
            content = <PageBuilder page_id={params[1]} />
          }
          else if (route == 'requestlist') {
            content = <RequestList user={this.state.user} />
          }
          else if (route == '') {
              padding = '0px';
            content = <Home user={this.state.user} />
          }
          else if (route == 'about-us') {
              padding = '0px';
            content = <About user={this.state.user} />
          }

          else if (route == 'dashboard') {
            if (params[1]) {
                if (params[2]) {
                    content = <UserDashboard user={{id: params[2], is_staff:false, company_id: params[1]}} />
                }
                else {
                    if (this.state.user.is_staff) {
                        content = <Dashboard user={this.state.user} company_id={params[1]} />
                    }
                    else {
                        content = <UserDashboard user={this.state.user} company_id={params[1]} />
                    }

                }

            }
            else if (this.state.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684') {
              content = <DataDashboard user={this.state.user} />
            }
            else {
                  content = <UserDashboard user={this.state.user} company_id={params[1]} />
            }
          }

          else if (route == 'old_dashboard') {
            if (params[1]) {
              content = <Dashboard user={this.state.user} company_id={params[1]} />
            }
            else if (this.state.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684') {
              content = <RequestList user={this.state.user} />
            }
            else {
              content = <Dashboard user={this.state.user} />
            }
          }
          else if (route == 'editrequest') {
            content = <Intake user={this.state.user} company_id={params[1]} request_id={params[2]}/>
          }
          else if (route == 'companylist') {

              if (this.state.user.company_id != 'e4f3c0a1-5e41-4541-98e4-8288353c7684') {
                window.location = '/dashboard/';
              }

            content = <CompanyList user={this.state.user} />
          }
          else if (route == 'editcompany') {
            content = <CreateCompany user={this.state.user} company_id={params[1]} />
          }
          else if (route == 'invoice') {
            if (params[2]) {
              content = <Invoice user={this.state.user} company_id={params[1]} billing_id={params[2]} is_admin_user={is_admin_user} />
            }
            else{
              content = <Invoice user={this.state.user} company_id={params[1]} is_admin_user={is_admin_user} />
            }
          }
          else if (route == 'notelist') {
            content = <RequestNotes user={this.state.user} />
          }
          else if (route == 'editprovider') {
            content = <CreateProvider user={this.state.user} provider_id={params[1]} request_id={params[2]} />
          }
          else if (route == 'providerlist') {
            content = <ProviderList user={this.state.user} />
          }
          else if (route == 'inviteusers') {
            content = <InviteUsers user={this.state.user} company_id={params[1]} />
          }
          else if (route == 'ghostusers') {
              content = <GhostUsers user={this.state.user} company_id={params[1]} />
          }
          else if (route == 'request') {
            content = <Request user={this.state.user} request_id={params[1]} />
          }
          else if (route == 'medical_request') {
            content = <DefaultMedicalRequest user={this.state.user} request_id={params[1]} />
          }
          else if (route == 'pagelist') {
            content = <PageList user={this.state.user} />
         }
          else if (route == 'invoices') {
            content = <Invoices user={this.state.user} />
          }
          else if (route == 'billings') {
            content = <Billings user={this.state.user} />
          }

          else if (route == 'formlist') {
            content = <PDFList user={this.state.user} />
          }
          else if (route == 'editform') {
            content = <EditPDFForm user={this.state.user} type={params[1]} form_id={params[2]} />
          }
          else if (route == 'diskusage') {
              content = <DiskUsage />
          }
          else if (route == 'split_request') {
              content = <SplitRequest request_id={params[1]} user={this.state.user} />
          }
          else if (route == 'list_request_types') {
              content = <ListRequestTypes user={this.state.user} />
          }

          else if (route == 'logout') {
            content = this.logOut();
          }
          else {
           var formatRoute = "/" + route + "/"
           if (route == "") {
             formatRoute = "/"
           }
           content = <PageBuilder show={true} route={formatRoute} user={this.state.user} />
           padding = '0px';
         }
        }

        if (this.state.loaded == false) {
          return (
              <div className="App">
                  <Wrapper content={<div></div>} loaded={this.state.loaded} />
              </div>
          );
        }
        else {
          return (
              <div className="App">
                  {navbar}
                  <Wrapper style={{paddingTop: padding}} content={content} loaded={this.state.loaded} />
                  <br />
            </div>
          );
        }
    }
}

export default App;

import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Header, Button} from 'library';
import CSVtoArray from 'base/csvToArray.js';

class CTA1 extends React.Component {

    render() {

        var labels = CSVtoArray(this.props.labels)

        var cards = []
        for (var index in this.props.images) {
          cards.push(<div className="col-4" style={{'position':'relative'}}>
            <img src={this.props.images[index]['url']} style={{width:'100%', objectFit:'contain'}}/>
            <div style={{width:'100%', backgroundColor:'rgba(0,0,0,.7)'}}>
              <h2 style={{'color':'white'}}>{labels[index] || ''}</h2>
            </div>
          </div>)
        }

        return (
          <div className="container" style={{'textAlign':'center', marginTop:'200px', marginBottom:'200px'}}>
            <h2 style={{'fontSize':'14px', fontWeight:'600', letterSpacing:'8px', color:'#888', margin:'20px'}}>
              {this.props.lead}
            </h2>
            <h2 style={{'fontSize':'52px', fontWeight:'bold', letterSpacing:'-2px', margin:'20px'}}>
              {this.props.title}
            </h2>
            <p style={{color:'#7f7f7f', fontSize:'16px', fontWeight:'400', margin:'20px'}}>
              {this.props.description}
            </p>

            <div className="row">
              {cards}
            </div>

          </div>
        );
    }
}


export default CTA1;

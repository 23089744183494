import React, { Component } from 'react';
import ajaxWrapper from "base/ajax.js";
import Wrapper from 'base/wrapper.js';
import ReactDataSheet from 'react-datasheet';
import HelpVideo from 'projectLibrary/help_video.js';
import {Form, TextInput, Select, PasswordInput, Header, TextArea, NumberInput, DateTimePicker, FileInput, Button, Alert} from 'library';

class InviteUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {users:[], emailList:[], emailIdList:[], loaded:false, success: false, error: []};

        this.objectCallback = this.objectCallback.bind(this);
        this.inviteUsers = this.inviteUsers.bind(this);
        this.refresh = this.refresh.bind(this);
        this.userExists = this.userExists.bind(this);
        this.userCallback = this.userCallback.bind(this);
    }

    refresh() {
      ajaxWrapper('GET','/api/user/User/?company_id=' + this.props.company_id, {}, this.objectCallback);
    }

    componentDidMount(value) {
        this.refresh()
    }

    objectCallback(result) {
      console.log("Result",result)
      var users = [[{value: 'First Name', readOnly:true},{value: 'Last Name', readOnly:true},{value: 'Email', readOnly:true},{value: 'Phone Number', readOnly:true},{value: 'Admin Access (Write TRUE or leave blank)', readOnly:true},{value: 'Active (Write FALSE or leave blank)', readOnly:true}]];
      var emailList = [];
      var emailIdList = [];
      for (var index in result) {
        var user = result[index]['User']
        var is_staff = "";
        if (user.is_staff) {
          is_staff = "TRUE"
        }
        var active = "";
        if (!user.is_active) {
            active = "FALSE"
        }

        users.push([{value: user.first_name},{value: user.last_name},{value: user.email}, {value:user.phone_number}, {value: is_staff}, {value: active}])
        emailIdList.push([user.email, user.id])
        emailList.push(user.email)
      }

      var currentLength = result.length;
      for (var i = 0; i + currentLength < 300; i++) {
        users.push([{value: ''},{value: ''},{value: ''}, {value: ''}, {value:''}, {value:''}])
      }


      this.setState({users:users, emailList:emailList, emailIdList:emailIdList, loaded:true})
    }

    inviteUsers() {
        //ajaxWrapper('POST','/api/user/user/c1ffff5d-93d1-4648-b7f5-68b64067d97d/', {company_id: this.props.company_id}, this.userCallback)
        this.setState({error:[]})
        var emails = [];
        for (var index in this.state.users) {
          var row = this.state.users[index];
          if (!row[0]['readOnly'] && row[2]['value'] != '') {
            var first_name = row[0]['value']
            var last_name = row[1]['value']
            var email = row[2]['value']
            var phone_number = row[3]['value']
            var is_staff = (row[4]['value'] == 'TRUE')
            var active = (row[5]['value'] != 'FALSE')
            emails.push(email);
            console.log("Email Check", this.state.emailList, email, this.state.emailList.indexOf(email))
            if (this.state.emailList.indexOf(email) == -1) {
              console.log("Here");
              //ajaxWrapper('GET','/api/user/user/?email=' + email, {}, this.userExists)
              ajaxWrapper('POST','/api/user/user/', {first_name:first_name, last_name:last_name, email:email, phone_number: phone_number, company_id: this.props.company_id, is_staff: is_staff}, this.userCallback);
            }
            else {
              var user_id = this.state.emailIdList[this.state.emailList.indexOf(email)][1];
              ajaxWrapper('POST','/api/user/user/' + user_id + '/', {first_name:first_name, last_name:last_name, email:email,  phone_number: phone_number,is_staff: is_staff, is_active: active}, console.log)
            }
          }
        }

        var refresh = true;
        for (var index in this.state.emailIdList) {
            console.log("Emails", emails, this.state.emailIdList[index][0], emails.indexOf(this.state.emailIdList[index][0]))
          if (emails.indexOf(this.state.emailIdList[index][0]) == -1) {
            ajaxWrapper('POST','/api/user/user/' + this.state.emailIdList[index][1] + '/delete/', {}, this.refresh)
            refresh = false;
          }
        }


    }

    userExists(result) {
        if (result.length > 0) {
            var error = this.state.error;
            error.push(result[0]['user'].email + ' already exists at another law firm.')
            this.setState({'error': error}, this.refresh)
        }
    }


    userCallback(result) {
      console.log("Result", result)
      var user = result[0]['user'];
      var data = {
          to_email: user.email,
          from_email: 'support@directdocs.com',
          subject: 'You are invited to Direct Docs',
          text: 'Direct Docs is a platform to make medical records requests. Your company has invited you. Please <a href="https://directdocs.com/passwordReset/' + user.id + '/">set up your password here.</a>'
      };

      ajaxWrapper('POST','/api/email/', data, console.log);
      this.setState({'success':true}, this.refresh);
    }

    render() {

        var success = null;
        if (this.state.success) {
          success = <Alert type={'success'} text={'Users Successfully Invited'} />
        }
        if (this.state.error.length > 0) {
            success = [];
            for (var index in this.state.error) {
                success.push(<Alert type={'danger'} text={this.state.error} />)
            }
        }


        var content = <div className="container-fluid" style={{position:'relative'}}>
                <br />
                <HelpVideo src="https://www.youtube.com/embed/4jzrXk39xFY" />


                <div style={{marginBottom:'20px'}}>
                <h2>Company User List</h2>
                <p>Type in your users or copy from excel. If you remove them from here and click invite, they will be removed.</p>
                {success}
                </div>
                <Button type={'success'} text={'Invite Users/Save'} onClick={this.inviteUsers} style={{marginBottom:'20px'}} />
                <br />
                <ReactDataSheet
                  data={this.state.users}
                  valueRenderer={(cell) => cell.value}
                  onCellsChanged={changes => {
                    const grid = this.state.users.map(row => [...row])
                    changes.forEach(({cell, row, col, value}) => {
                      grid[row][col] = {...grid[row][col], value}
                    })
                    console.log("Changes", changes)
                    this.setState({users:grid})
                  }}
                />
        </div>;

        return (
          <div>
            <Wrapper loaded={this.state.loaded} content={content} />
          </div>
             );
    }
}
export default InviteUsers;

import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Header, Form, TextInput, TextArea, Button, Alert} from 'library';

class ContactForm extends React.Component {
    constructor(props) {
      super(props);

      this.state = {name:'','number':'','email':'', industry:'', message:'', sent:false, error:false}

      this.email = this.email.bind(this);
      this.setGlobalState = this.setGlobalState.bind(this);
    }

    setGlobalState(name, state) {
      this.setState(state)
    }

    email() {
        if (this.state.email != '') {
            var text = "<div><p>Contact Details Below</p><h3>Name</h3><p>" + this.state.name + "</p><h3>Number</h3><p>" +
            this.state.number + "</p><h3>Email</h3><p>" + this.state.email + "</p><h3>Industry</h3><p>" +
            this.state.industry + "</p><h3>Message</h3><p>" + this.state.message + "</p></div>";

            var data = {
                from_email: 'support@directdocs.com',
                to_email: this.props.to_email,
                subject: this.props.subject,
                text:text
            };
            console.log("Email Data", data);
            ajaxWrapper('POST','/api/email/', data, () => this.setState({sent:true, error:false}));
        }
        else {
            this.setState({'error':true});
        }
    }

    render() {

        var components = [TextInput,TextInput,TextInput,TextInput,TextArea]

        var componentProps = [
          {'label':'Name', name:'name'},
          {'label':'Phone Number', name:'number'},
          {'label':'Email', name:'email', required:true},
          {'label':'Industry', name:'industry'},
          {'label':'Message', name:'message'},
        ]

        var sent = null;
        if (this.state.sent) {
          sent = <Alert type={'success'} text={'Message Sent'} />
        }

        var error = null;
        if (this.state.error) {
          sent = <Alert type={'danger'} text={'Email is a required field. Please add your email to the form above.'} />
        }

        return (
          <div className="container">
            <Form defaults={this.state} components={components} componentProps={componentProps} autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'form'} />
            <Button type={'primary'} text={'Submit'} onClick={this.email} />
            {sent}
          </div>
        );
    }
}


export default ContactForm;

import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Header, Button, Icon} from 'library';
import CSVtoArray from 'base/csvToArray.js';

class ServicesList extends React.Component {

    render() {
      var titles = CSVtoArray(this.props.titles)
      var descriptions =  CSVtoArray(this.props.descriptions)
      var icons = CSVtoArray(this.props.icons)

      var columns = []
      for (var index in titles) {
        columns.push(<div className="col-4">
          <div className="row">
            <div className="col-3">
              <Icon size={4} icon={icons[index]} style={{'color': '#314ff7'}} />
            </div>
            <div className="col-9">
              <h3 style={{'fontSize':'14px', letterSpacing:'1px', fontWeight:'bold'}}>{titles[index] || ''}</h3>
              <p style={{color:'#999'}}>{descriptions[index]}</p>
            </div>
          </div>
        </div>)
      }

        return (
          <div className="container" style={{'textAlign':'center', marginTop:'50px', marginBottom:'50px'}}>
            <h2 style={{'fontSize':'52px', fontWeight:'bold', letterSpacing:'-2px', margin:'20px'}}>
              {this.props.title}
            </h2>
            <h2 style={{'fontSize':'14px', fontWeight:'600', letterSpacing:'8px', color:'#888', margin:'20px'}}>
              {this.props.lead}
            </h2>
            <div className="row" style={{marginTop:'100px'}}>
              {columns}
            </div>
          </div>
        );
    }
}


export default ServicesList;

import React, { Component } from 'react';
import resolveVariables from 'base/resolver.js';
import ajaxWrapper from "base/ajax.js";
import {Button, Header, TextInput} from 'library';

class EmojiDisplay extends Component {
  constructor(props) {
      super(props);
      this.click = this.click.bind(this);

  }

  click() {
    var newState = {}
    newState[this.props.name] = this.props.value;
    this.props.setFormState(newState)
  }

  render() {
    return (
      <div className='col-1' onClick={this.click} style={{'textAlign':'center'}}>
        <p style={{'marginBottom':'0px','fontSize':'50px','display':'block'}} className={this.props.value}></p>
        <small>{this.props.text}</small>
      </div>
    )

  }
}


class EmojiSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {search:''}

        this.handlechange = this.handlechange.bind(this);
        this.setFormState = this.setFormState.bind(this);

    }

    handlechange = (e) => {
      this.setState({'search':e.target.value})
    }

    setFormState(newState) {
      this.props.setFormState(newState)
    }

    render() {

        var emojiList = [];
        for (var index in emojiData) {
          var add = false;
          if (this.state.search == '') {
            add = true;
          }
          else {
            if (emojiData[index].text.toLowerCase().indexOf(this.state.search) > -1) {
              add = true;
            }
          }
          if (add == true && emojiList.length < 24) {
            emojiList.push(<EmojiDisplay key={index} {...emojiData[index]} setFormState={this.setFormState} name={this.props.name} />)
          }
        }

        return (
            <div>
              <Header size={4} text={'Emojis'} />
              <TextInput name={'emoji-search'} value={this.state.search} handlechange={this.handlechange} placeholder={'Search For An Emoji (Most Are Hidden Before Search)'} />
              <div className='row'>
              {emojiList}
              </div>
            </div>
        )
    }

}

export default EmojiSelect;


const emojiData = [{'text':'Grinning Face','value':'grinning-face'},
{'text':'Grinning Face With Smiling Eyes','value':'grinning-face-with-smiling-eyes'},
{'text':'Face With Tears Of Joy','value':'face-with-tears-of-joy'},
{'text':'Rolling On The Floor Laughing','value':'rolling-on-the-floor-laughing'},
{'text':'Smiling Face With Open Mouth','value':'smiling-face-with-open-mouth'},
{'text':'Smiling Face With Open Mouth And Smiling Eyes','value':'smiling-face-with-open-mouth-and-smiling-eyes'},
{'text':'Smiling Face With Open Mouth And Cold Sweat','value':'smiling-face-with-open-mouth-and-cold-sweat'},
{'text':'Smiling Face With Open Mouth And Tightly Closed Eyes','value':'smiling-face-with-open-mouth-and-tightly-closed-eyes'},
{'text':'Winking Face','value':'winking-face'},
{'text':'Smiling Face With Smiling Eyes','value':'smiling-face-with-smiling-eyes'},
{'text':'Face Savouring Delicious Food','value':'face-savouring-delicious-food'},
{'text':'Smiling Face With Sunglasses','value':'smiling-face-with-sunglasses'},
{'text':'Smiling Face With Heart Shaped Eyes','value':'smiling-face-with-heart-shaped-eyes'},
{'text':'Face Throwing A Kiss','value':'face-throwing-a-kiss'},
{'text':'Kissing Face','value':'kissing-face'},
{'text':'Kissing Face With Smiling Eyes','value':'kissing-face-with-smiling-eyes'},
{'text':'Kissing Face With Closed Eyes','value':'kissing-face-with-closed-eyes'},
{'text':'White Smiling Face','value':'white-smiling-face'},
{'text':'Slightly Smiling Face','value':'slightly-smiling-face'},
{'text':'Hugging Face','value':'hugging-face'},
{'text':'Grinning Face With Star Eyes','value':'grinning-face-with-star-eyes'},
{'text':'Thinking Face','value':'thinking-face'},
{'text':'Face With One Eyebrow Raised','value':'face-with-one-eyebrow-raised'},
{'text':'Neutral Face','value':'neutral-face'},
{'text':'Expressionless Face','value':'expressionless-face'},
{'text':'Face Without Mouth','value':'face-without-mouth'},
{'text':'Face With Rolling Eyes','value':'face-with-rolling-eyes'},
{'text':'Smirking Face','value':'smirking-face'},
{'text':'Persevering Face','value':'persevering-face'},
{'text':'Disappointed But Relieved Face','value':'disappointed-but-relieved-face'},
{'text':'Face With Open Mouth','value':'face-with-open-mouth'},
{'text':'Zipper Mouth Face','value':'zipper-mouth-face'},
{'text':'Hushed Face','value':'hushed-face'},
{'text':'Sleepy Face','value':'sleepy-face'},
{'text':'Tired Face','value':'tired-face'},
{'text':'Sleeping Face','value':'sleeping-face'},
{'text':'Relieved Face','value':'relieved-face'},
{'text':'Face With Stuck Out Tongue','value':'face-with-stuck-out-tongue'},
{'text':'Face With Stuck Out Tongue And Winking Eye','value':'face-with-stuck-out-tongue-and-winking-eye'},
{'text':'Face With Stuck Out Tongue And Tightly Closed Eyes','value':'face-with-stuck-out-tongue-and-tightly-closed-eyes'},
{'text':'Drooling Face','value':'drooling-face'},
{'text':'Unamused Face','value':'unamused-face'},
{'text':'Face With Cold Sweat','value':'face-with-cold-sweat'},
{'text':'Pensive Face','value':'pensive-face'},
{'text':'Confused Face','value':'confused-face'},
{'text':'Upside Down Face','value':'upside-down-face'},
{'text':'Money Mouth Face','value':'money-mouth-face'},
{'text':'Astonished Face','value':'astonished-face'},
{'text':'White Frowning Face','value':'white-frowning-face'},
{'text':'Slightly Frowning Face','value':'slightly-frowning-face'},
{'text':'Confounded Face','value':'confounded-face'},
{'text':'Disappointed Face','value':'disappointed-face'},
{'text':'Worried Face','value':'worried-face'},
{'text':'Face With Look Of Triumph','value':'face-with-look-of-triumph'},
{'text':'Crying Face','value':'crying-face'},
{'text':'Loudly Crying Face','value':'loudly-crying-face'},
{'text':'Frowning Face With Open Mouth','value':'frowning-face-with-open-mouth'},
{'text':'Anguished Face','value':'anguished-face'},
{'text':'Fearful Face','value':'fearful-face'},
{'text':'Weary Face','value':'weary-face'},
{'text':'Shocked Face With Exploding Head','value':'shocked-face-with-exploding-head'},
{'text':'Grimacing Face','value':'grimacing-face'},
{'text':'Face With Open Mouth And Cold Sweat','value':'face-with-open-mouth-and-cold-sweat'},
{'text':'Face Screaming In Fear','value':'face-screaming-in-fear'},
{'text':'Flushed Face','value':'flushed-face'},
{'text':'Grinning Face With One Large And One Small Eye','value':'grinning-face-with-one-large-and-one-small-eye'},
{'text':'Dizzy Face','value':'dizzy-face'},
{'text':'Pouting Face','value':'pouting-face'},
{'text':'Angry Face','value':'angry-face'},
{'text':'Serious Face With Symbols Covering Mouth','value':'serious-face-with-symbols-covering-mouth'},
{'text':'Face With Medical Mask','value':'face-with-medical-mask'},
{'text':'Face With Thermometer','value':'face-with-thermometer'},
{'text':'Face With Head Bandage','value':'face-with-head-bandage'},
{'text':'Nauseated Face','value':'nauseated-face'},
{'text':'Face With Open Mouth Vomiting','value':'face-with-open-mouth-vomiting'},
{'text':'Sneezing Face','value':'sneezing-face'},
{'text':'Smiling Face With Halo','value':'smiling-face-with-halo'},
{'text':'Face With Cowboy Hat','value':'face-with-cowboy-hat'},
{'text':'Clown Face','value':'clown-face'},
{'text':'Lying Face','value':'lying-face'},
{'text':'Face With Finger Covering Closed Lips','value':'face-with-finger-covering-closed-lips'},
{'text':'Smiling Face With Smiling Eyes And Hand Covering Mouth','value':'smiling-face-with-smiling-eyes-and-hand-covering-mouth'},
{'text':'Face With Monocle','value':'face-with-monocle'},
{'text':'Nerd Face','value':'nerd-face'},
{'text':'Smiling Face With Horns','value':'smiling-face-with-horns'},
{'text':'Imp','value':'imp'},
{'text':'Japanese Ogre','value':'japanese-ogre'},
{'text':'Japanese Goblin','value':'japanese-goblin'},
{'text':'Skull','value':'skull'},
{'text':'Ghost','value':'ghost'},
{'text':'Extraterrestrial Alien','value':'extraterrestrial-alien'},
{'text':'Robot Face','value':'robot-face'},
{'text':'Pile Of Poo','value':'pile-of-poo'},
{'text':'Smiling Cat Face With Open Mouth','value':'smiling-cat-face-with-open-mouth'},
{'text':'Grinning Cat Face With Smiling Eyes','value':'grinning-cat-face-with-smiling-eyes'},
{'text':'Cat Face With Tears Of Joy','value':'cat-face-with-tears-of-joy'},
{'text':'Smiling Cat Face With Heart Shaped Eyes','value':'smiling-cat-face-with-heart-shaped-eyes'},
{'text':'Cat Face With Wry Smile','value':'cat-face-with-wry-smile'},
{'text':'Kissing Cat Face With Closed Eyes','value':'kissing-cat-face-with-closed-eyes'},
{'text':'Weary Cat Face','value':'weary-cat-face'},
{'text':'Crying Cat Face','value':'crying-cat-face'},
{'text':'Pouting Cat Face','value':'pouting-cat-face'},
{'text':'Baby','value':'baby'},
{'text':'Boy','value':'boy'},
{'text':'Girl','value':'girl'},
{'text':'Man','value':'man'},
{'text':'Woman','value':'woman'},
{'text':'Older Man','value':'older-man'},
{'text':'Older Woman','value':'older-woman'},
{'text':'Male Health Worker','value':'male-health-worker'},
{'text':'Female Health Worker','value':'female-health-worker'},
{'text':'Male Student','value':'male-student'},
{'text':'Female Student','value':'female-student'},
{'text':'Male Judge','value':'male-judge'},
{'text':'Female Judge','value':'female-judge'},
{'text':'Male Farmer','value':'male-farmer'},
{'text':'Female Farmer','value':'female-farmer'},
{'text':'Male Cook','value':'male-cook'},
{'text':'Female Cook','value':'female-cook'},
{'text':'Male Mechanic','value':'male-mechanic'},
{'text':'Female Mechanic','value':'female-mechanic'},
{'text':'Male Factory Worker','value':'male-factory-worker'},
{'text':'Female Factory Worker','value':'female-factory-worker'},
{'text':'Male Office Worker','value':'male-office-worker'},
{'text':'Female Office Worker','value':'female-office-worker'},
{'text':'Male Scientist','value':'male-scientist'},
{'text':'Female Scientist','value':'female-scientist'},
{'text':'Male Technologist','value':'male-technologist'},
{'text':'Female Technologist','value':'female-technologist'},
{'text':'Male Singer','value':'male-singer'},
{'text':'Female Singer','value':'female-singer'},
{'text':'Male Artist','value':'male-artist'},
{'text':'Female Artist','value':'female-artist'},
{'text':'Male Pilot','value':'male-pilot'},
{'text':'Female Pilot','value':'female-pilot'},
{'text':'Male Astronaut','value':'male-astronaut'},
{'text':'Female Astronaut','value':'female-astronaut'},
{'text':'Male Firefighter','value':'male-firefighter'},
{'text':'Female Firefighter','value':'female-firefighter'},
{'text':'Police Officer','value':'police-officer'},
{'text':'Male Police Officer','value':'male-police-officer'},
{'text':'Female Police Officer','value':'female-police-officer'},
{'text':'Sleuth Or Spy','value':'sleuth-or-spy'},
{'text':'Male Sleuth','value':'male-sleuth'},
{'text':'Female Sleuth','value':'female-sleuth'},
{'text':'Guardsman','value':'guardsman'},
{'text':'Male Guard','value':'male-guard'},
{'text':'Female Guard','value':'female-guard'},
{'text':'Construction Worker','value':'construction-worker'},
{'text':'Male Construction Worker','value':'male-construction-worker'},
{'text':'Female Construction Worker','value':'female-construction-worker'},
{'text':'Prince','value':'prince'},
{'text':'Princess','value':'princess'},
{'text':'Man With Turban','value':'man-with-turban'},
{'text':'Man Wearing Turban','value':'man-wearing-turban'},
{'text':'Woman Wearing Turban','value':'woman-wearing-turban'},
{'text':'Man With Gua Pi Mao','value':'man-with-gua-pi-mao'},
{'text':'Person With Headscarf','value':'person-with-headscarf'},
{'text':'Bearded Person','value':'bearded-person'},
{'text':'Person With Blond Hair','value':'person-with-blond-hair'},
{'text':'Blond Man','value':'blond-man'},
{'text':'Blonde Woman','value':'blonde-woman'},
{'text':'Man In Tuxedo','value':'man-in-tuxedo'},
{'text':'Bride With Veil','value':'bride-with-veil'},
{'text':'Pregnant Woman','value':'pregnant-woman'},
{'text':'Breast Feeding','value':'breast-feeding'},
{'text':'Baby Angel','value':'baby-angel'},
{'text':'Father Christmas','value':'father-christmas'},
{'text':'Mother Christmas','value':'mother-christmas'},
{'text':'Woman Mage','value':'woman-mage'},
{'text':'Man Mage','value':'man-mage'},
{'text':'Woman Fairy','value':'woman-fairy'},
{'text':'Man Fairy','value':'man-fairy'},
{'text':'Woman Vampire','value':'woman-vampire'},
{'text':'Man Vampire','value':'man-vampire'},
{'text':'Merwoman','value':'merwoman'},
{'text':'Merman','value':'merman'},
{'text':'Woman Elf','value':'woman-elf'},
{'text':'Man Elf','value':'man-elf'},
{'text':'Woman Genie','value':'woman-genie'},
{'text':'Man Genie','value':'man-genie'},
{'text':'Woman Zombie','value':'woman-zombie'},
{'text':'Man Zombie','value':'man-zombie'},
{'text':'Person Frowning','value':'person-frowning'},
{'text':'Man Frowning','value':'man-frowning'},
{'text':'Woman Frowning','value':'woman-frowning'},
{'text':'Person With Pouting Face','value':'person-with-pouting-face'},
{'text':'Man Pouting','value':'man-pouting'},
{'text':'Woman Pouting','value':'woman-pouting'},
{'text':'Face With No Good Gesture','value':'face-with-no-good-gesture'},
{'text':'Man Gesturing Not Ok','value':'man-gesturing-not-ok'},
{'text':'Woman Gesturing Not Ok','value':'woman-gesturing-not-ok'},
{'text':'Face With Ok Gesture','value':'face-with-ok-gesture'},
{'text':'Man Gesturing Ok','value':'man-gesturing-ok'},
{'text':'Woman Gesturing Ok','value':'woman-gesturing-ok'},
{'text':'Information Desk Person','value':'information-desk-person'},
{'text':'Man Tipping Hand','value':'man-tipping-hand'},
{'text':'Woman Tipping Hand','value':'woman-tipping-hand'},
{'text':'Happy Person Raising One Hand','value':'happy-person-raising-one-hand'},
{'text':'Man Raising Hand','value':'man-raising-hand'},
{'text':'Woman Raising Hand','value':'woman-raising-hand'},
{'text':'Person Bowing Deeply','value':'person-bowing-deeply'},
{'text':'Man Bowing Deeply','value':'man-bowing-deeply'},
{'text':'Woman Bowing Deeply','value':'woman-bowing-deeply'},
{'text':'Face Palm','value':'face-palm'},
{'text':'Man Facepalming','value':'man-facepalming'},
{'text':'Woman Facepalming','value':'woman-facepalming'},
{'text':'Shrug','value':'shrug'},
{'text':'Man Shrugging','value':'man-shrugging'},
{'text':'Woman Shrugging','value':'woman-shrugging'},
{'text':'Face Massage','value':'face-massage'},
{'text':'Man Getting Face Massage','value':'man-getting-face-massage'},
{'text':'Woman Getting Face Massage','value':'woman-getting-face-massage'},
{'text':'Haircut','value':'haircut'},
{'text':'Man Getting Haircut','value':'man-getting-haircut'},
{'text':'Woman Getting Haircut','value':'woman-getting-haircut'},
{'text':'Pedestrian','value':'pedestrian'},
{'text':'Man Walking','value':'man-walking'},
{'text':'Woman Walking','value':'woman-walking'},
{'text':'Runner','value':'runner'},
{'text':'Man Running','value':'man-running'},
{'text':'Woman Running','value':'woman-running'},
{'text':'Dancer','value':'dancer'},
{'text':'Man Dancing','value':'man-dancing'},
{'text':'Woman With Bunny Ears','value':'woman-with-bunny-ears'},
{'text':'Men With Bunny Ears Partying','value':'men-with-bunny-ears-partying'},
{'text':'Women With Bunny Ears Partying','value':'women-with-bunny-ears-partying'},
{'text':'Woman In Steamy Room','value':'woman-in-steamy-room'},
{'text':'Man In Steamy Room','value':'man-in-steamy-room'},
{'text':'Man In Business Suit Levitating','value':'man-in-business-suit-levitating'},
{'text':'Speaking Head In Silhouette','value':'speaking-head-in-silhouette'},
{'text':'Bust In Silhouette','value':'bust-in-silhouette'},
{'text':'Busts In Silhouette','value':'busts-in-silhouette'},
{'text':'Man And Woman Holding Hands','value':'man-and-woman-holding-hands'},
{'text':'Two Men Holding Hands','value':'two-men-holding-hands'},
{'text':'Two Women Holding Hands','value':'two-women-holding-hands'},
{'text':'Kiss','value':'kiss'},
{'text':'Kiss Man Man','value':'kiss-man-man'},
{'text':'Kiss Woman Woman','value':'kiss-woman-woman'},
{'text':'Couple With Heart','value':'couple-with-heart'},
{'text':'Couple With Heart Man Man','value':'couple-with-heart-man-man'},
{'text':'Couple With Heart Woman Woman','value':'couple-with-heart-woman-woman'},
{'text':'Family','value':'family'},
{'text':'Family Man Woman Boy','value':'family-man-woman-boy'},
{'text':'Family Man Woman Girl','value':'family-man-woman-girl'},
{'text':'Family Man Woman Girl Boy','value':'family-man-woman-girl-boy'},
{'text':'Family Man Woman Boy Boy','value':'family-man-woman-boy-boy'},
{'text':'Family Man Woman Girl Girl','value':'family-man-woman-girl-girl'},
{'text':'Family Man Man Boy','value':'family-man-man-boy'},
{'text':'Family Man Man Girl','value':'family-man-man-girl'},
{'text':'Family Man Man Girl Boy','value':'family-man-man-girl-boy'},
{'text':'Family Man Man Boy Boy','value':'family-man-man-boy-boy'},
{'text':'Family Man Man Girl Girl','value':'family-man-man-girl-girl'},
{'text':'Family Woman Woman Boy','value':'family-woman-woman-boy'},
{'text':'Family Woman Woman Girl','value':'family-woman-woman-girl'},
{'text':'Family Woman Woman Girl Boy','value':'family-woman-woman-girl-boy'},
{'text':'Family Woman Woman Boy Boy','value':'family-woman-woman-boy-boy'},
{'text':'Family Woman Woman Girl Girl','value':'family-woman-woman-girl-girl'},
{'text':'Family Man Boy','value':'family-man-boy'},
{'text':'Family Man Boy Boy','value':'family-man-boy-boy'},
{'text':'Family Man Girl','value':'family-man-girl'},
{'text':'Family Man Girl Boy','value':'family-man-girl-boy'},
{'text':'Family Man Girl Girl','value':'family-man-girl-girl'},
{'text':'Family Woman Boy','value':'family-woman-boy'},
{'text':'Family Woman Boy Boy','value':'family-woman-boy-boy'},
{'text':'Family Woman Girl','value':'family-woman-girl'},
{'text':'Family Woman Girl Boy','value':'family-woman-girl-boy'},
{'text':'Family Woman Girl Girl','value':'family-woman-girl-girl'},
{'text':'Selfie','value':'selfie'},
{'text':'Flexed Biceps','value':'flexed-biceps'},
{'text':'White Left Pointing Backhand Index','value':'white-left-pointing-backhand-index'},
{'text':'White Right Pointing Backhand Index','value':'white-right-pointing-backhand-index'},
{'text':'White Up Pointing Index','value':'white-up-pointing-index'},
{'text':'White Up Pointing Backhand Index','value':'white-up-pointing-backhand-index'},
{'text':'Reversed Hand With Middle Finger Extended','value':'reversed-hand-with-middle-finger-extended'},
{'text':'White Down Pointing Backhand Index','value':'white-down-pointing-backhand-index'},
{'text':'Victory Hand','value':'victory-hand'},
{'text':'Hand With Index And Middle Fingers Crossed','value':'hand-with-index-and-middle-fingers-crossed'},
{'text':'Raised Hand With Part Between Middle And Ring Fingers','value':'raised-hand-with-part-between-middle-and-ring-fingers'},
{'text':'Sign Of The Horns','value':'sign-of-the-horns'},
{'text':'Raised Hand With Fingers Splayed','value':'raised-hand-with-fingers-splayed'},
{'text':'Raised Hand','value':'raised-hand'},
{'text':'Ok Hand Sign','value':'ok-hand-sign'},
{'text':'Thumbs Up Sign','value':'thumbs-up-sign'},
{'text':'Thumbs Down Sign','value':'thumbs-down-sign'},
{'text':'Raised Fist','value':'raised-fist'},
{'text':'Fisted Hand Sign','value':'fisted-hand-sign'},
{'text':'Left Facing Fist','value':'left-facing-fist'},
{'text':'Right Facing Fist','value':'right-facing-fist'},
{'text':'Raised Back Of Hand','value':'raised-back-of-hand'},
{'text':'Waving Hand Sign','value':'waving-hand-sign'},
{'text':'I Love You Hand Sign','value':'i-love-you-hand-sign'},
{'text':'Writing Hand','value':'writing-hand'},
{'text':'Clapping Hands Sign','value':'clapping-hands-sign'},
{'text':'Open Hands Sign','value':'open-hands-sign'},
{'text':'Person Raising Both Hands In Celebration','value':'person-raising-both-hands-in-celebration'},
{'text':'Palms Up Together','value':'palms-up-together'},
{'text':'Person With Folded Hands','value':'person-with-folded-hands'},
{'text':'Handshake','value':'handshake'},
{'text':'Nail Polish','value':'nail-polish'},
{'text':'Ear','value':'ear'},
{'text':'Nose','value':'nose'},
{'text':'Footprints','value':'footprints'},
{'text':'Eyes','value':'eyes'},
{'text':'Eye','value':'eye'},
{'text':'Brain','value':'brain'},
{'text':'Tongue','value':'tongue'},
{'text':'Mouth','value':'mouth'},
{'text':'Kiss Mark','value':'kiss-mark'},
{'text':'Eyeglasses','value':'eyeglasses'},
{'text':'Dark Sunglasses','value':'dark-sunglasses'},
{'text':'Necktie','value':'necktie'},
{'text':'T Shirt','value':'t-shirt'},
{'text':'Jeans','value':'jeans'},
{'text':'Scarf','value':'scarf'},
{'text':'Gloves','value':'gloves'},
{'text':'Coat','value':'coat'},
{'text':'Socks','value':'socks'},
{'text':'Dress','value':'dress'},
{'text':'Kimono','value':'kimono'},
{'text':'Bikini','value':'bikini'},
{'text':'Womans Clothes','value':'womans-clothes'},
{'text':'Purse','value':'purse'},
{'text':'Handbag','value':'handbag'},
{'text':'Pouch','value':'pouch'},
{'text':'School Satchel','value':'school-satchel'},
{'text':'Mans Shoe','value':'mans-shoe'},
{'text':'Athletic Shoe','value':'athletic-shoe'},
{'text':'High Heeled Shoe','value':'high-heeled-shoe'},
{'text':'Womans Sandal','value':'womans-sandal'},
{'text':'Womans Boots','value':'womans-boots'},
{'text':'Crown','value':'crown'},
{'text':'Womans Hat','value':'womans-hat'},
{'text':'Top Hat','value':'top-hat'},
{'text':'Graduation Cap','value':'graduation-cap'},
{'text':'Billed Cap','value':'billed-cap'},
{'text':'Helmet With White Cross','value':'helmet-with-white-cross'},
{'text':'Lipstick','value':'lipstick'},
{'text':'Ring','value':'ring'},
{'text':'Closed Umbrella','value':'closed-umbrella'},
{'text':'Umbrella','value':'umbrella'},
{'text':'Briefcase','value':'briefcase'},
{'text':'See No Evil Monkey','value':'see-no-evil-monkey'},
{'text':'Hear No Evil Monkey','value':'hear-no-evil-monkey'},
{'text':'Speak No Evil Monkey','value':'speak-no-evil-monkey'},
{'text':'Collision Symbol','value':'collision-symbol'},
{'text':'Splashing Sweat Symbol','value':'splashing-sweat-symbol'},
{'text':'Dash Symbol','value':'dash-symbol'},
{'text':'Dizzy Symbol','value':'dizzy-symbol'},
{'text':'Monkey Face','value':'monkey-face'},
{'text':'Monkey','value':'monkey'},
{'text':'Gorilla','value':'gorilla'},
{'text':'Dog Face','value':'dog-face'},
{'text':'Dog','value':'dog'},
{'text':'Poodle','value':'poodle'},
{'text':'Wolf Face','value':'wolf-face'},
{'text':'Fox Face','value':'fox-face'},
{'text':'Cat Face','value':'cat-face'},
{'text':'Cat','value':'cat'},
{'text':'Lion Face','value':'lion-face'},
{'text':'Tiger Face','value':'tiger-face'},
{'text':'Tiger','value':'tiger'},
{'text':'Leopard','value':'leopard'},
{'text':'Horse Face','value':'horse-face'},
{'text':'Horse','value':'horse'},
{'text':'Unicorn Face','value':'unicorn-face'},
{'text':'Zebra Face','value':'zebra-face'},
{'text':'Cow Face','value':'cow-face'},
{'text':'Ox','value':'ox'},
{'text':'Water Buffalo','value':'water-buffalo'},
{'text':'Cow','value':'cow'},
{'text':'Pig Face','value':'pig-face'},
{'text':'Pig','value':'pig'},
{'text':'Boar','value':'boar'},
{'text':'Pig Nose','value':'pig-nose'},
{'text':'Ram','value':'ram'},
{'text':'Sheep','value':'sheep'},
{'text':'Goat','value':'goat'},
{'text':'Dromedary Camel','value':'dromedary-camel'},
{'text':'Bactrian Camel','value':'bactrian-camel'},
{'text':'Giraffe Face','value':'giraffe-face'},
{'text':'Elephant','value':'elephant'},
{'text':'Rhinoceros','value':'rhinoceros'},
{'text':'Mouse Face','value':'mouse-face'},
{'text':'Mouse','value':'mouse'},
{'text':'Rat','value':'rat'},
{'text':'Hamster Face','value':'hamster-face'},
{'text':'Rabbit Face','value':'rabbit-face'},
{'text':'Rabbit','value':'rabbit'},
{'text':'Chipmunk','value':'chipmunk'},
{'text':'Hedgehog','value':'hedgehog'},
{'text':'Bat','value':'bat'},
{'text':'Bear Face','value':'bear-face'},
{'text':'Koala','value':'koala'},
{'text':'Panda Face','value':'panda-face'},
{'text':'Paw Prints','value':'paw-prints'},
{'text':'Turkey','value':'turkey'},
{'text':'Chicken','value':'chicken'},
{'text':'Rooster','value':'rooster'},
{'text':'Hatching Chick','value':'hatching-chick'},
{'text':'Baby Chick','value':'baby-chick'},
{'text':'Front Facing Baby Chick','value':'front-facing-baby-chick'},
{'text':'Bird','value':'bird'},
{'text':'Penguin','value':'penguin'},
{'text':'Dove Of Peace','value':'dove-of-peace'},
{'text':'Eagle','value':'eagle'},
{'text':'Duck','value':'duck'},
{'text':'Owl','value':'owl'},
{'text':'Frog Face','value':'frog-face'},
{'text':'Crocodile','value':'crocodile'},
{'text':'Turtle','value':'turtle'},
{'text':'Lizard','value':'lizard'},
{'text':'Snake','value':'snake'},
{'text':'Dragon Face','value':'dragon-face'},
{'text':'Dragon','value':'dragon'},
{'text':'Sauropod','value':'sauropod'},
{'text':'T Rex','value':'t-rex'},
{'text':'Spouting Whale','value':'spouting-whale'},
{'text':'Whale','value':'whale'},
{'text':'Dolphin','value':'dolphin'},
{'text':'Fish','value':'fish'},
{'text':'Tropical Fish','value':'tropical-fish'},
{'text':'Blowfish','value':'blowfish'},
{'text':'Shark','value':'shark'},
{'text':'Octopus','value':'octopus'},
{'text':'Spiral Shell','value':'spiral-shell'},
{'text':'Crab','value':'crab'},
{'text':'Shrimp','value':'shrimp'},
{'text':'Squid','value':'squid'},
{'text':'Snail','value':'snail'},
{'text':'Butterfly','value':'butterfly'},
{'text':'Bug','value':'bug'},
{'text':'Ant','value':'ant'},
{'text':'Honeybee','value':'honeybee'},
{'text':'Lady Beetle','value':'lady-beetle'},
{'text':'Cricket','value':'cricket'},
{'text':'Spider','value':'spider'},
{'text':'Spider Web','value':'spider-web'},
{'text':'Scorpion','value':'scorpion'},
{'text':'Bouquet','value':'bouquet'},
{'text':'Cherry Blossom','value':'cherry-blossom'},
{'text':'White Flower','value':'white-flower'},
{'text':'Rosette','value':'rosette'},
{'text':'Rose','value':'rose'},
{'text':'Wilted Flower','value':'wilted-flower'},
{'text':'Hibiscus','value':'hibiscus'},
{'text':'Sunflower','value':'sunflower'},
{'text':'Blossom','value':'blossom'},
{'text':'Tulip','value':'tulip'},
{'text':'Seedling','value':'seedling'},
{'text':'Evergreen Tree','value':'evergreen-tree'},
{'text':'Deciduous Tree','value':'deciduous-tree'},
{'text':'Palm Tree','value':'palm-tree'},
{'text':'Cactus','value':'cactus'},
{'text':'Ear Of Rice','value':'ear-of-rice'},
{'text':'Herb','value':'herb'},
{'text':'Shamrock','value':'shamrock'},
{'text':'Four Leaf Clover','value':'four-leaf-clover'},
{'text':'Maple Leaf','value':'maple-leaf'},
{'text':'Fallen Leaf','value':'fallen-leaf'},
{'text':'Leaf Fluttering In Wind','value':'leaf-fluttering-in-wind'},
{'text':'Mushroom','value':'mushroom'},
{'text':'Chestnut','value':'chestnut'},
{'text':'Earth Globe Europe Africa','value':'earth-globe-europe-africa'},
{'text':'Earth Globe Americas','value':'earth-globe-americas'},
{'text':'Earth Globe Asia Australia','value':'earth-globe-asia-australia'},
{'text':'Globe With Meridians','value':'globe-with-meridians'},
{'text':'New Moon Symbol','value':'new-moon-symbol'},
{'text':'Waxing Crescent Moon Symbol','value':'waxing-crescent-moon-symbol'},
{'text':'First Quarter Moon Symbol','value':'first-quarter-moon-symbol'},
{'text':'Waxing Gibbous Moon Symbol','value':'waxing-gibbous-moon-symbol'},
{'text':'Full Moon Symbol','value':'full-moon-symbol'},
{'text':'Waning Gibbous Moon Symbol','value':'waning-gibbous-moon-symbol'},
{'text':'Last Quarter Moon Symbol','value':'last-quarter-moon-symbol'},
{'text':'Waning Crescent Moon Symbol','value':'waning-crescent-moon-symbol'},
{'text':'Crescent Moon','value':'crescent-moon'},
{'text':'New Moon With Face','value':'new-moon-with-face'},
{'text':'First Quarter Moon With Face','value':'first-quarter-moon-with-face'},
{'text':'Last Quarter Moon With Face','value':'last-quarter-moon-with-face'},
{'text':'Black Sun With Rays','value':'black-sun-with-rays'},
{'text':'Full Moon With Face','value':'full-moon-with-face'},
{'text':'Sun With Face','value':'sun-with-face'},
{'text':'White Medium Star','value':'white-medium-star'},
{'text':'Glowing Star','value':'glowing-star'},
{'text':'Shooting Star','value':'shooting-star'},
{'text':'Cloud','value':'cloud'},
{'text':'Sun Behind Cloud','value':'sun-behind-cloud'},
{'text':'Thunder Cloud And Rain','value':'thunder-cloud-and-rain'},
{'text':'White Sun With Small Cloud','value':'white-sun-with-small-cloud'},
{'text':'White Sun Behind Cloud','value':'white-sun-behind-cloud'},
{'text':'White Sun Behind Cloud With Rain','value':'white-sun-behind-cloud-with-rain'},
{'text':'Cloud With Rain','value':'cloud-with-rain'},
{'text':'Cloud With Snow','value':'cloud-with-snow'},
{'text':'Cloud With Lightning','value':'cloud-with-lightning'},
{'text':'Cloud With Tornado','value':'cloud-with-tornado'},
{'text':'Fog','value':'fog'},
{'text':'Wind Blowing Face','value':'wind-blowing-face'},
{'text':'Rainbow','value':'rainbow'},
{'text':'Umbrella With Rain Drops','value':'umbrella-with-rain-drops'},
{'text':'High Voltage Sign','value':'high-voltage-sign'},
{'text':'Snowflake','value':'snowflake'},
{'text':'Snowman','value':'snowman'},
{'text':'Snowman Without Snow','value':'snowman-without-snow'},
{'text':'Comet','value':'comet'},
{'text':'Fire','value':'fire'},
{'text':'Droplet','value':'droplet'},
{'text':'Water Wave','value':'water-wave'},
{'text':'Christmas Tree','value':'christmas-tree'},
{'text':'Sparkles','value':'sparkles'},
{'text':'Tanabata Tree','value':'tanabata-tree'},
{'text':'Pine Decoration','value':'pine-decoration'},
{'text':'Grapes','value':'grapes'},
{'text':'Melon','value':'melon'},
{'text':'Watermelon','value':'watermelon'},
{'text':'Tangerine','value':'tangerine'},
{'text':'Lemon','value':'lemon'},
{'text':'Banana','value':'banana'},
{'text':'Pineapple','value':'pineapple'},
{'text':'Red Apple','value':'red-apple'},
{'text':'Green Apple','value':'green-apple'},
{'text':'Pear','value':'pear'},
{'text':'Peach','value':'peach'},
{'text':'Cherries','value':'cherries'},
{'text':'Strawberry','value':'strawberry'},
{'text':'Kiwifruit','value':'kiwifruit'},
{'text':'Tomato','value':'tomato'},
{'text':'Coconut','value':'coconut'},
{'text':'Avocado','value':'avocado'},
{'text':'Aubergine','value':'aubergine'},
{'text':'Potato','value':'potato'},
{'text':'Carrot','value':'carrot'},
{'text':'Ear Of Maize','value':'ear-of-maize'},
{'text':'Hot Pepper','value':'hot-pepper'},
{'text':'Cucumber','value':'cucumber'},
{'text':'Broccoli','value':'broccoli'},
{'text':'Peanuts','value':'peanuts'},
{'text':'Bread','value':'bread'},
{'text':'Croissant','value':'croissant'},
{'text':'Baguette Bread','value':'baguette-bread'},
{'text':'Pretzel','value':'pretzel'},
{'text':'Pancakes','value':'pancakes'},
{'text':'Cheese Wedge','value':'cheese-wedge'},
{'text':'Meat On Bone','value':'meat-on-bone'},
{'text':'Poultry Leg','value':'poultry-leg'},
{'text':'Cut Of Meat','value':'cut-of-meat'},
{'text':'Bacon','value':'bacon'},
{'text':'Hamburger','value':'hamburger'},
{'text':'French Fries','value':'french-fries'},
{'text':'Slice Of Pizza','value':'slice-of-pizza'},
{'text':'Hot Dog','value':'hot-dog'},
{'text':'Sandwich','value':'sandwich'},
{'text':'Taco','value':'taco'},
{'text':'Burrito','value':'burrito'},
{'text':'Cooking','value':'cooking'},
{'text':'Pot Of Food','value':'pot-of-food'},
{'text':'Bowl With Spoon','value':'bowl-with-spoon'},
{'text':'Green Salad','value':'green-salad'},
{'text':'Popcorn','value':'popcorn'},
{'text':'Canned Food','value':'canned-food'},
{'text':'Bento Box','value':'bento-box'},
{'text':'Rice Cracker','value':'rice-cracker'},
{'text':'Rice Ball','value':'rice-ball'},
{'text':'Cooked Rice','value':'cooked-rice'},
{'text':'Curry And Rice','value':'curry-and-rice'},
{'text':'Steaming Bowl','value':'steaming-bowl'},
{'text':'Spaghetti','value':'spaghetti'},
{'text':'Roasted Sweet Potato','value':'roasted-sweet-potato'},
{'text':'Oden','value':'oden'},
{'text':'Sushi','value':'sushi'},
{'text':'Fried Shrimp','value':'fried-shrimp'},
{'text':'Fish Cake With Swirl Design','value':'fish-cake-with-swirl-design'},
{'text':'Dango','value':'dango'},
{'text':'Dumpling','value':'dumpling'},
{'text':'Fortune Cookie','value':'fortune-cookie'},
{'text':'Takeout Box','value':'takeout-box'},
{'text':'Soft Ice Cream','value':'soft-ice-cream'},
{'text':'Shaved Ice','value':'shaved-ice'},
{'text':'Ice Cream','value':'ice-cream'},
{'text':'Doughnut','value':'doughnut'},
{'text':'Cookie','value':'cookie'},
{'text':'Birthday Cake','value':'birthday-cake'},
{'text':'Shortcake','value':'shortcake'},
{'text':'Pie','value':'pie'},
{'text':'Chocolate Bar','value':'chocolate-bar'},
{'text':'Candy','value':'candy'},
{'text':'Lollipop','value':'lollipop'},
{'text':'Custard','value':'custard'},
{'text':'Honey Pot','value':'honey-pot'},
{'text':'Baby Bottle','value':'baby-bottle'},
{'text':'Glass Of Milk','value':'glass-of-milk'},
{'text':'Hot Beverage','value':'hot-beverage'},
{'text':'Teacup Without Handle','value':'teacup-without-handle'},
{'text':'Sake Bottle And Cup','value':'sake-bottle-and-cup'},
{'text':'Bottle With Popping Cork','value':'bottle-with-popping-cork'},
{'text':'Wine Glass','value':'wine-glass'},
{'text':'Cocktail Glass','value':'cocktail-glass'},
{'text':'Tropical Drink','value':'tropical-drink'},
{'text':'Beer Mug','value':'beer-mug'},
{'text':'Clinking Beer Mugs','value':'clinking-beer-mugs'},
{'text':'Clinking Glasses','value':'clinking-glasses'},
{'text':'Tumbler Glass','value':'tumbler-glass'},
{'text':'Cup With Straw','value':'cup-with-straw'},
{'text':'Chopsticks','value':'chopsticks'},
{'text':'Fork And Knife With Plate','value':'fork-and-knife-with-plate'},
{'text':'Fork And Knife','value':'fork-and-knife'},
{'text':'Spoon','value':'spoon'},
{'text':'Alien Monster','value':'alien-monster'},
{'text':'Woman Climbing','value':'woman-climbing'},
{'text':'Man Climbing','value':'man-climbing'},
{'text':'Woman In Lotus Position','value':'woman-in-lotus-position'},
{'text':'Man In Lotus Position','value':'man-in-lotus-position'},
{'text':'Horse Racing','value':'horse-racing'},
{'text':'Skier','value':'skier'},
{'text':'Snowboarder','value':'snowboarder'},
{'text':'Golfer','value':'golfer'},
{'text':'Man Golfing','value':'man-golfing'},
{'text':'Woman Golfing','value':'woman-golfing'},
{'text':'Surfer','value':'surfer'},
{'text':'Man Surfing','value':'man-surfing'},
{'text':'Woman Surfing','value':'woman-surfing'},
{'text':'Rowboat','value':'rowboat'},
{'text':'Man Rowing Boat','value':'man-rowing-boat'},
{'text':'Woman Rowing Boat','value':'woman-rowing-boat'},
{'text':'Swimmer','value':'swimmer'},
{'text':'Man Swimming','value':'man-swimming'},
{'text':'Woman Swimming','value':'woman-swimming'},
{'text':'Person With Ball','value':'person-with-ball'},
{'text':'Man With Ball','value':'man-with-ball'},
{'text':'Woman With Ball','value':'woman-with-ball'},
{'text':'Weight Lifter','value':'weight-lifter'},
{'text':'Man Weight Lifting','value':'man-weight-lifting'},
{'text':'Woman Weight Lifting','value':'woman-weight-lifting'},
{'text':'Bicyclist','value':'bicyclist'},
{'text':'Man Biking','value':'man-biking'},
{'text':'Woman Biking','value':'woman-biking'},
{'text':'Mountain Bicyclist','value':'mountain-bicyclist'},
{'text':'Man Mountain Biking','value':'man-mountain-biking'},
{'text':'Woman Mountain Biking','value':'woman-mountain-biking'},
{'text':'Person Doing Cartwheel','value':'person-doing-cartwheel'},
{'text':'Man Doing Cartwheel','value':'man-doing-cartwheel'},
{'text':'Woman Doing Cartwheel','value':'woman-doing-cartwheel'},
{'text':'Wrestlers','value':'wrestlers'},
{'text':'Men Wrestling','value':'men-wrestling'},
{'text':'Women Wrestling','value':'women-wrestling'},
{'text':'Water Polo','value':'water-polo'},
{'text':'Man Playing Water Polo','value':'man-playing-water-polo'},
{'text':'Woman Playing Water Polo','value':'woman-playing-water-polo'},
{'text':'Handball','value':'handball'},
{'text':'Man Playing Handball','value':'man-playing-handball'},
{'text':'Woman Playing Handball','value':'woman-playing-handball'},
{'text':'Juggling','value':'juggling'},
{'text':'Man Juggling','value':'man-juggling'},
{'text':'Woman Juggling','value':'woman-juggling'},
{'text':'Circus Tent','value':'circus-tent'},
{'text':'Reminder Ribbon','value':'reminder-ribbon'},
{'text':'Admission Tickets','value':'admission-tickets'},
{'text':'Ticket','value':'ticket'},
{'text':'Military Medal','value':'military-medal'},
{'text':'Trophy','value':'trophy'},
{'text':'Sports Medal','value':'sports-medal'},
{'text':'First Place Medal','value':'first-place-medal'},
{'text':'Second Place Medal','value':'second-place-medal'},
{'text':'Third Place Medal','value':'third-place-medal'},
{'text':'Soccer Ball','value':'soccer-ball'},
{'text':'Baseball','value':'baseball'},
{'text':'Basketball And Hoop','value':'basketball-and-hoop'},
{'text':'Volleyball','value':'volleyball'},
{'text':'American Football','value':'american-football'},
{'text':'Rugby Football','value':'rugby-football'},
{'text':'Tennis Racquet And Ball','value':'tennis-racquet-and-ball'},
{'text':'Bowling','value':'bowling'},
{'text':'Cricket Bat And Ball','value':'cricket-bat-and-ball'},
{'text':'Field Hockey Stick And Ball','value':'field-hockey-stick-and-ball'},
{'text':'Ice Hockey Stick And Puck','value':'ice-hockey-stick-and-puck'},
{'text':'Table Tennis Paddle And Ball','value':'table-tennis-paddle-and-ball'},
{'text':'Badminton Racquet And Shuttlecock','value':'badminton-racquet-and-shuttlecock'},
{'text':'Boxing Glove','value':'boxing-glove'},
{'text':'Martial Arts Uniform','value':'martial-arts-uniform'},
{'text':'Flag In Hole','value':'flag-in-hole'},
{'text':'Ice Skate','value':'ice-skate'},
{'text':'Fishing Pole And Fish','value':'fishing-pole-and-fish'},
{'text':'Running Shirt With Sash','value':'running-shirt-with-sash'},
{'text':'Ski And Ski Boot','value':'ski-and-ski-boot'},
{'text':'Sled','value':'sled'},
{'text':'Curling Stone','value':'curling-stone'},
{'text':'Direct Hit','value':'direct-hit'},
{'text':'Billiards','value':'billiards'},
{'text':'Video Game','value':'video-game'},
{'text':'Slot Machine','value':'slot-machine'},
{'text':'Game Die','value':'game-die'},
{'text':'Performing Arts','value':'performing-arts'},
{'text':'Artist Palette','value':'artist-palette'},
{'text':'Musical Score','value':'musical-score'},
{'text':'Microphone','value':'microphone'},
{'text':'Headphone','value':'headphone'},
{'text':'Saxophone','value':'saxophone'},
{'text':'Guitar','value':'guitar'},
{'text':'Musical Keyboard','value':'musical-keyboard'},
{'text':'Trumpet','value':'trumpet'},
{'text':'Violin','value':'violin'},
{'text':'Drum With Drumsticks','value':'drum-with-drumsticks'},
{'text':'Clapper Board','value':'clapper-board'},
{'text':'Bow And Arrow','value':'bow-and-arrow'},
{'text':'Racing Car','value':'racing-car'},
{'text':'Racing Motorcycle','value':'racing-motorcycle'},
{'text':'Silhouette Of Japan','value':'silhouette-of-japan'},
{'text':'Snow Capped Mountain','value':'snow-capped-mountain'},
{'text':'Mountain','value':'mountain'},
{'text':'Volcano','value':'volcano'},
{'text':'Mount Fuji','value':'mount-fuji'},
{'text':'Camping','value':'camping'},
{'text':'Beach With Umbrella','value':'beach-with-umbrella'},
{'text':'Desert','value':'desert'},
{'text':'Desert Island','value':'desert-island'},
{'text':'National Park','value':'national-park'},
{'text':'Stadium','value':'stadium'},
{'text':'Classical Building','value':'classical-building'},
{'text':'Building Construction','value':'building-construction'},
{'text':'House Buildings','value':'house-buildings'},
{'text':'Derelict House Building','value':'derelict-house-building'},
{'text':'House Building','value':'house-building'},
{'text':'House With Garden','value':'house-with-garden'},
{'text':'Office Building','value':'office-building'},
{'text':'Japanese Post Office','value':'japanese-post-office'},
{'text':'European Post Office','value':'european-post-office'},
{'text':'Hospital','value':'hospital'},
{'text':'Bank','value':'bank'},
{'text':'Hotel','value':'hotel'},
{'text':'Love Hotel','value':'love-hotel'},
{'text':'Convenience Store','value':'convenience-store'},
{'text':'School','value':'school'},
{'text':'Department Store','value':'department-store'},
{'text':'Factory','value':'factory'},
{'text':'Japanese Castle','value':'japanese-castle'},
{'text':'European Castle','value':'european-castle'},
{'text':'Wedding','value':'wedding'},
{'text':'Tokyo Tower','value':'tokyo-tower'},
{'text':'Statue Of Liberty','value':'statue-of-liberty'},
{'text':'Church','value':'church'},
{'text':'Mosque','value':'mosque'},
{'text':'Synagogue','value':'synagogue'},
{'text':'Shinto Shrine','value':'shinto-shrine'},
{'text':'Kaaba','value':'kaaba'},
{'text':'Fountain','value':'fountain'},
{'text':'Tent','value':'tent'},
{'text':'Foggy','value':'foggy'},
{'text':'Night With Stars','value':'night-with-stars'},
{'text':'Cityscape','value':'cityscape'},
{'text':'Sunrise Over Mountains','value':'sunrise-over-mountains'},
{'text':'Sunrise','value':'sunrise'},
{'text':'Cityscape At Dusk','value':'cityscape-at-dusk'},
{'text':'Sunset Over Buildings','value':'sunset-over-buildings'},
{'text':'Bridge At Night','value':'bridge-at-night'},
{'text':'Milky Way','value':'milky-way'},
{'text':'Carousel Horse','value':'carousel-horse'},
{'text':'Ferris Wheel','value':'ferris-wheel'},
{'text':'Roller Coaster','value':'roller-coaster'},
{'text':'Steam Locomotive','value':'steam-locomotive'},
{'text':'Railway Car','value':'railway-car'},
{'text':'High Speed Train','value':'high-speed-train'},
{'text':'High Speed Train With Bullet Nose','value':'high-speed-train-with-bullet-nose'},
{'text':'Train','value':'train'},
{'text':'Metro','value':'metro'},
{'text':'Light Rail','value':'light-rail'},
{'text':'Station','value':'station'},
{'text':'Tram','value':'tram'},
{'text':'Monorail','value':'monorail'},
{'text':'Mountain Railway','value':'mountain-railway'},
{'text':'Tram Car','value':'tram-car'},
{'text':'Bus','value':'bus'},
{'text':'Oncoming Bus','value':'oncoming-bus'},
{'text':'Trolleybus','value':'trolleybus'},
{'text':'Minibus','value':'minibus'},
{'text':'Ambulance','value':'ambulance'},
{'text':'Fire Engine','value':'fire-engine'},
{'text':'Police Car','value':'police-car'},
{'text':'Oncoming Police Car','value':'oncoming-police-car'},
{'text':'Taxi','value':'taxi'},
{'text':'Oncoming Taxi','value':'oncoming-taxi'},
{'text':'Automobile','value':'automobile'},
{'text':'Oncoming Automobile','value':'oncoming-automobile'},
{'text':'Delivery Truck','value':'delivery-truck'},
{'text':'Articulated Lorry','value':'articulated-lorry'},
{'text':'Tractor','value':'tractor'},
{'text':'Bicycle','value':'bicycle'},
{'text':'Scooter','value':'scooter'},
{'text':'Motor Scooter','value':'motor-scooter'},
{'text':'Bus Stop','value':'bus-stop'},
{'text':'Railway Track','value':'railway-track'},
{'text':'Fuel Pump','value':'fuel-pump'},
{'text':'Police Cars Revolving Light','value':'police-cars-revolving-light'},
{'text':'Horizontal Traffic Light','value':'horizontal-traffic-light'},
{'text':'Vertical Traffic Light','value':'vertical-traffic-light'},
{'text':'Construction Sign','value':'construction-sign'},
{'text':'Anchor','value':'anchor'},
{'text':'Sailboat','value':'sailboat'},
{'text':'Speedboat','value':'speedboat'},
{'text':'Passenger Ship','value':'passenger-ship'},
{'text':'Ferry','value':'ferry'},
{'text':'Motor Boat','value':'motor-boat'},
{'text':'Ship','value':'ship'},
{'text':'Airplane','value':'airplane'},
{'text':'Small Airplane','value':'small-airplane'},
{'text':'Airplane Departure','value':'airplane-departure'},
{'text':'Airplane Arriving','value':'airplane-arriving'},
{'text':'Seat','value':'seat'},
{'text':'Helicopter','value':'helicopter'},
{'text':'Suspension Railway','value':'suspension-railway'},
{'text':'Mountain Cableway','value':'mountain-cableway'},
{'text':'Aerial Tramway','value':'aerial-tramway'},
{'text':'Satellite','value':'satellite'},
{'text':'Rocket','value':'rocket'},
{'text':'Flying Saucer','value':'flying-saucer'},
{'text':'Umbrella On Ground','value':'umbrella-on-ground'},
{'text':'Fireworks','value':'fireworks'},
{'text':'Firework Sparkler','value':'firework-sparkler'},
{'text':'Moon Viewing Ceremony','value':'moon-viewing-ceremony'},
{'text':'Banknote With Yen Sign','value':'banknote-with-yen-sign'},
{'text':'Banknote With Dollar Sign','value':'banknote-with-dollar-sign'},
{'text':'Banknote With Euro Sign','value':'banknote-with-euro-sign'},
{'text':'Banknote With Pound Sign','value':'banknote-with-pound-sign'},
{'text':'Moyai','value':'moyai'},
{'text':'Passport Control','value':'passport-control'},
{'text':'Customs','value':'customs'},
{'text':'Baggage Claim','value':'baggage-claim'},
{'text':'Left Luggage','value':'left-luggage'},
{'text':'Skull And Crossbones','value':'skull-and-crossbones'},
{'text':'Bath','value':'bath'},
{'text':'Sleeping Accommodation','value':'sleeping-accommodation'},
{'text':'Love Letter','value':'love-letter'},
{'text':'Bomb','value':'bomb'},
{'text':'Hole','value':'hole'},
{'text':'Shopping Bags','value':'shopping-bags'},
{'text':'Prayer Beads','value':'prayer-beads'},
{'text':'Gem Stone','value':'gem-stone'},
{'text':'Hocho','value':'hocho'},
{'text':'Amphora','value':'amphora'},
{'text':'World Map','value':'world-map'},
{'text':'Barber Pole','value':'barber-pole'},
{'text':'Oil Drum','value':'oil-drum'},
{'text':'Bellhop Bell','value':'bellhop-bell'},
{'text':'Hourglass','value':'hourglass'},
{'text':'Hourglass With Flowing Sand','value':'hourglass-with-flowing-sand'},
{'text':'Watch','value':'watch'},
{'text':'Alarm Clock','value':'alarm-clock'},
{'text':'Stopwatch','value':'stopwatch'},
{'text':'Timer Clock','value':'timer-clock'},
{'text':'Mantelpiece Clock','value':'mantelpiece-clock'},
{'text':'Thermometer','value':'thermometer'},
{'text':'Balloon','value':'balloon'},
{'text':'Party Popper','value':'party-popper'},
{'text':'Confetti Ball','value':'confetti-ball'},
{'text':'Japanese Dolls','value':'japanese-dolls'},
{'text':'Carp Streamer','value':'carp-streamer'},
{'text':'Wind Chime','value':'wind-chime'},
{'text':'Ribbon','value':'ribbon'},
{'text':'Wrapped Present','value':'wrapped-present'},
{'text':'Crystal Ball','value':'crystal-ball'},
{'text':'Joystick','value':'joystick'},
{'text':'Frame With Picture','value':'frame-with-picture'},
{'text':'Postal Horn','value':'postal-horn'},
{'text':'Studio Microphone','value':'studio-microphone'},
{'text':'Level Slider','value':'level-slider'},
{'text':'Control Knobs','value':'control-knobs'},
{'text':'Radio','value':'radio'},
{'text':'Mobile Phone','value':'mobile-phone'},
{'text':'Mobile Phone With Rightwards Arrow At Left','value':'mobile-phone-with-rightwards-arrow-at-left'},
{'text':'Black Telephone','value':'black-telephone'},
{'text':'Telephone Receiver','value':'telephone-receiver'},
{'text':'Pager','value':'pager'},
{'text':'Fax Machine','value':'fax-machine'},
{'text':'Battery','value':'battery'},
{'text':'Electric Plug','value':'electric-plug'},
{'text':'Personal Computer','value':'personal-computer'},
{'text':'Desktop Computer','value':'desktop-computer'},
{'text':'Printer','value':'printer'},
{'text':'Keyboard','value':'keyboard'},
{'text':'Three Button Mouse','value':'three-button-mouse'},
{'text':'Trackball','value':'trackball'},
{'text':'Minidisc','value':'minidisc'},
{'text':'Floppy Disk','value':'floppy-disk'},
{'text':'Optical Disc','value':'optical-disc'},
{'text':'Dvd','value':'dvd'},
{'text':'Movie Camera','value':'movie-camera'},
{'text':'Film Frames','value':'film-frames'},
{'text':'Film Projector','value':'film-projector'},
{'text':'Television','value':'television'},
{'text':'Camera','value':'camera'},
{'text':'Camera With Flash','value':'camera-with-flash'},
{'text':'Video Camera','value':'video-camera'},
{'text':'Videocassette','value':'videocassette'},
{'text':'Left Pointing Magnifying Glass','value':'left-pointing-magnifying-glass'},
{'text':'Right Pointing Magnifying Glass','value':'right-pointing-magnifying-glass'},
{'text':'Candle','value':'candle'},
{'text':'Electric Light Bulb','value':'electric-light-bulb'},
{'text':'Electric Torch','value':'electric-torch'},
{'text':'Izakaya Lantern','value':'izakaya-lantern'},
{'text':'Notebook With Decorative Cover','value':'notebook-with-decorative-cover'},
{'text':'Closed Book','value':'closed-book'},
{'text':'Open Book','value':'open-book'},
{'text':'Green Book','value':'green-book'},
{'text':'Blue Book','value':'blue-book'},
{'text':'Orange Book','value':'orange-book'},
{'text':'Books','value':'books'},
{'text':'Notebook','value':'notebook'},
{'text':'Page With Curl','value':'page-with-curl'},
{'text':'Scroll','value':'scroll'},
{'text':'Page Facing Up','value':'page-facing-up'},
{'text':'Newspaper','value':'newspaper'},
{'text':'Rolled Up Newspaper','value':'rolled-up-newspaper'},
{'text':'Bookmark Tabs','value':'bookmark-tabs'},
{'text':'Bookmark','value':'bookmark'},
{'text':'Label','value':'label'},
{'text':'Money Bag','value':'money-bag'},
{'text':'Money With Wings','value':'money-with-wings'},
{'text':'Credit Card','value':'credit-card'},
{'text':'Envelope','value':'envelope'},
{'text':'E Mail Symbol','value':'e-mail-symbol'},
{'text':'Incoming Envelope','value':'incoming-envelope'},
{'text':'Envelope With Downwards Arrow Above','value':'envelope-with-downwards-arrow-above'},
{'text':'Outbox Tray','value':'outbox-tray'},
{'text':'Inbox Tray','value':'inbox-tray'},
{'text':'Package','value':'package'},
{'text':'Closed Mailbox With Raised Flag','value':'closed-mailbox-with-raised-flag'},
{'text':'Closed Mailbox With Lowered Flag','value':'closed-mailbox-with-lowered-flag'},
{'text':'Open Mailbox With Raised Flag','value':'open-mailbox-with-raised-flag'},
{'text':'Open Mailbox With Lowered Flag','value':'open-mailbox-with-lowered-flag'},
{'text':'Postbox','value':'postbox'},
{'text':'Ballot Box With Ballot','value':'ballot-box-with-ballot'},
{'text':'Pencil','value':'pencil'},
{'text':'Black Nib','value':'black-nib'},
{'text':'Lower Left Fountain Pen','value':'lower-left-fountain-pen'},
{'text':'Lower Left Ballpoint Pen','value':'lower-left-ballpoint-pen'},
{'text':'Lower Left Paintbrush','value':'lower-left-paintbrush'},
{'text':'Lower Left Crayon','value':'lower-left-crayon'},
{'text':'Memo','value':'memo'},
{'text':'File Folder','value':'file-folder'},
{'text':'Open File Folder','value':'open-file-folder'},
{'text':'Card Index Dividers','value':'card-index-dividers'},
{'text':'Calendar','value':'calendar'},
{'text':'Tear Off Calendar','value':'tear-off-calendar'},
{'text':'Spiral Note Pad','value':'spiral-note-pad'},
{'text':'Spiral Calendar Pad','value':'spiral-calendar-pad'},
{'text':'Card Index','value':'card-index'},
{'text':'Chart With Upwards Trend','value':'chart-with-upwards-trend'},
{'text':'Chart With Downwards Trend','value':'chart-with-downwards-trend'},
{'text':'Bar Chart','value':'bar-chart'},
{'text':'Clipboard','value':'clipboard'},
{'text':'Pushpin','value':'pushpin'},
{'text':'Round Pushpin','value':'round-pushpin'},
{'text':'Paperclip','value':'paperclip'},
{'text':'Linked Paperclips','value':'linked-paperclips'},
{'text':'Straight Ruler','value':'straight-ruler'},
{'text':'Triangular Ruler','value':'triangular-ruler'},
{'text':'Black Scissors','value':'black-scissors'},
{'text':'Card File Box','value':'card-file-box'},
{'text':'File Cabinet','value':'file-cabinet'},
{'text':'Wastebasket','value':'wastebasket'},
{'text':'Lock','value':'lock'},
{'text':'Open Lock','value':'open-lock'},
{'text':'Lock With Ink Pen','value':'lock-with-ink-pen'},
{'text':'Closed Lock With Key','value':'closed-lock-with-key'},
{'text':'Key','value':'key'},
{'text':'Old Key','value':'old-key'},
{'text':'Hammer','value':'hammer'},
{'text':'Pick','value':'pick'},
{'text':'Hammer And Pick','value':'hammer-and-pick'},
{'text':'Hammer And Wrench','value':'hammer-and-wrench'},
{'text':'Dagger Knife','value':'dagger-knife'},
{'text':'Crossed Swords','value':'crossed-swords'},
{'text':'Pistol','value':'pistol'},
{'text':'Shield','value':'shield'},
{'text':'Wrench','value':'wrench'},
{'text':'Nut And Bolt','value':'nut-and-bolt'},
{'text':'Gear','value':'gear'},
{'text':'Compression','value':'compression'},
{'text':'Scales','value':'scales'},
{'text':'Link Symbol','value':'link-symbol'},
{'text':'Chains','value':'chains'},
{'text':'Alembic','value':'alembic'},
{'text':'Microscope','value':'microscope'},
{'text':'Telescope','value':'telescope'},
{'text':'Satellite Antenna','value':'satellite-antenna'},
{'text':'Syringe','value':'syringe'},
{'text':'Pill','value':'pill'},
{'text':'Door','value':'door'},
{'text':'Bed','value':'bed'},
{'text':'Couch And Lamp','value':'couch-and-lamp'},
{'text':'Toilet','value':'toilet'},
{'text':'Shower','value':'shower'},
{'text':'Bathtub','value':'bathtub'},
{'text':'Smoking Symbol','value':'smoking-symbol'},
{'text':'Coffin','value':'coffin'},
{'text':'Funeral Urn','value':'funeral-urn'},
{'text':'Potable Water Symbol','value':'potable-water-symbol'},
{'text':'Eye In Speech Bubble','value':'eye-in-speech-bubble'},
{'text':'Heart With Arrow','value':'heart-with-arrow'},
{'text':'Heavy Black Heart','value':'heavy-black-heart'},
{'text':'Beating Heart','value':'beating-heart'},
{'text':'Broken Heart','value':'broken-heart'},
{'text':'Two Hearts','value':'two-hearts'},
{'text':'Sparkling Heart','value':'sparkling-heart'},
{'text':'Growing Heart','value':'growing-heart'},
{'text':'Blue Heart','value':'blue-heart'},
{'text':'Green Heart','value':'green-heart'},
{'text':'Yellow Heart','value':'yellow-heart'},
{'text':'Orange Heart','value':'orange-heart'},
{'text':'Purple Heart','value':'purple-heart'},
{'text':'Black Heart','value':'black-heart'},
{'text':'Heart With Ribbon','value':'heart-with-ribbon'},
{'text':'Revolving Hearts','value':'revolving-hearts'},
{'text':'Heart Decoration','value':'heart-decoration'},
{'text':'Heavy Heart Exclamation Mark Ornament','value':'heavy-heart-exclamation-mark-ornament'},
{'text':'Sleeping Symbol','value':'sleeping-symbol'},
{'text':'Anger Symbol','value':'anger-symbol'},
{'text':'Speech Balloon','value':'speech-balloon'},
{'text':'Right Anger Bubble','value':'right-anger-bubble'},
{'text':'Thought Balloon','value':'thought-balloon'},
{'text':'Hot Springs','value':'hot-springs'},
{'text':'Octagonal Sign','value':'octagonal-sign'},
{'text':'Clock Face Twelve Oclock','value':'clock-face-twelve-oclock'},
{'text':'Clock Face Twelve Thirty','value':'clock-face-twelve-thirty'},
{'text':'Clock Face One Oclock','value':'clock-face-one-oclock'},
{'text':'Clock Face One Thirty','value':'clock-face-one-thirty'},
{'text':'Clock Face Two Oclock','value':'clock-face-two-oclock'},
{'text':'Clock Face Two Thirty','value':'clock-face-two-thirty'},
{'text':'Clock Face Three Oclock','value':'clock-face-three-oclock'},
{'text':'Clock Face Three Thirty','value':'clock-face-three-thirty'},
{'text':'Clock Face Four Oclock','value':'clock-face-four-oclock'},
{'text':'Clock Face Four Thirty','value':'clock-face-four-thirty'},
{'text':'Clock Face Five Oclock','value':'clock-face-five-oclock'},
{'text':'Clock Face Five Thirty','value':'clock-face-five-thirty'},
{'text':'Clock Face Six Oclock','value':'clock-face-six-oclock'},
{'text':'Clock Face Six Thirty','value':'clock-face-six-thirty'},
{'text':'Clock Face Seven Oclock','value':'clock-face-seven-oclock'},
{'text':'Clock Face Seven Thirty','value':'clock-face-seven-thirty'},
{'text':'Clock Face Eight Oclock','value':'clock-face-eight-oclock'},
{'text':'Clock Face Eight Thirty','value':'clock-face-eight-thirty'},
{'text':'Clock Face Nine Oclock','value':'clock-face-nine-oclock'},
{'text':'Clock Face Nine Thirty','value':'clock-face-nine-thirty'},
{'text':'Clock Face Ten Oclock','value':'clock-face-ten-oclock'},
{'text':'Clock Face Ten Thirty','value':'clock-face-ten-thirty'},
{'text':'Clock Face Eleven Oclock','value':'clock-face-eleven-oclock'},
{'text':'Clock Face Eleven Thirty','value':'clock-face-eleven-thirty'},
{'text':'Cyclone','value':'cyclone'},
{'text':'Black Spade Suit','value':'black-spade-suit'},
{'text':'Black Heart Suit','value':'black-heart-suit'},
{'text':'Black Diamond Suit','value':'black-diamond-suit'},
{'text':'Black Club Suit','value':'black-club-suit'},
{'text':'Playing Card Black Joker','value':'playing-card-black-joker'},
{'text':'Mahjong Tile Red Dragon','value':'mahjong-tile-red-dragon'},
{'text':'Flower Playing Cards','value':'flower-playing-cards'},
{'text':'Speaker With Cancellation Stroke','value':'speaker-with-cancellation-stroke'},
{'text':'Speaker','value':'speaker'},
{'text':'Speaker With One Sound Wave','value':'speaker-with-one-sound-wave'},
{'text':'Speaker With Three Sound Waves','value':'speaker-with-three-sound-waves'},
{'text':'Public Address Loudspeaker','value':'public-address-loudspeaker'},
{'text':'Cheering Megaphone','value':'cheering-megaphone'},
{'text':'Bell','value':'bell'},
{'text':'Bell With Cancellation Stroke','value':'bell-with-cancellation-stroke'},
{'text':'Musical Note','value':'musical-note'},
{'text':'Multiple Musical Notes','value':'multiple-musical-notes'},
{'text':'Automated Teller Machine','value':'automated-teller-machine'},
{'text':'Put Litter In Its Place Symbol','value':'put-litter-in-its-place-symbol'},
{'text':'Wheelchair Symbol','value':'wheelchair-symbol'},
{'text':'Mens Symbol','value':'mens-symbol'},
{'text':'Womens Symbol','value':'womens-symbol'},
{'text':'Restroom','value':'restroom'},
{'text':'Baby Symbol','value':'baby-symbol'},
{'text':'Water Closet','value':'water-closet'},
{'text':'Warning Sign','value':'warning-sign'},
{'text':'Children Crossing','value':'children-crossing'},
{'text':'No Entry','value':'no-entry'},
{'text':'No Entry Sign','value':'no-entry-sign'},
{'text':'No Bicycles','value':'no-bicycles'},
{'text':'No Smoking Symbol','value':'no-smoking-symbol'},
{'text':'Do Not Litter Symbol','value':'do-not-litter-symbol'},
{'text':'Non Potable Water Symbol','value':'non-potable-water-symbol'},
{'text':'No Pedestrians','value':'no-pedestrians'},
{'text':'No One Under Eighteen Symbol','value':'no-one-under-eighteen-symbol'},
{'text':'Radioactive Sign','value':'radioactive-sign'},
{'text':'Biohazard Sign','value':'biohazard-sign'},
{'text':'Upwards Black Arrow','value':'upwards-black-arrow'},
{'text':'North East Arrow','value':'north-east-arrow'},
{'text':'Black Rightwards Arrow','value':'black-rightwards-arrow'},
{'text':'South East Arrow','value':'south-east-arrow'},
{'text':'Downwards Black Arrow','value':'downwards-black-arrow'},
{'text':'South West Arrow','value':'south-west-arrow'},
{'text':'Leftwards Black Arrow','value':'leftwards-black-arrow'},
{'text':'North West Arrow','value':'north-west-arrow'},
{'text':'Up Down Arrow','value':'up-down-arrow'},
{'text':'Left Right Arrow','value':'left-right-arrow'},
{'text':'Leftwards Arrow With Hook','value':'leftwards-arrow-with-hook'},
{'text':'Rightwards Arrow With Hook','value':'rightwards-arrow-with-hook'},
{'text':'Arrow Pointing Rightwards Then Curving Upwards','value':'arrow-pointing-rightwards-then-curving-upwards'},
{'text':'Arrow Pointing Rightwards Then Curving Downwards','value':'arrow-pointing-rightwards-then-curving-downwards'},
{'text':'Clockwise Downwards And Upwards Open Circle Arrows','value':'clockwise-downwards-and-upwards-open-circle-arrows'},
{'text':'Anticlockwise Downwards And Upwards Open Circle Arrows','value':'anticlockwise-downwards-and-upwards-open-circle-arrows'},
{'text':'Back With Leftwards Arrow Above','value':'back-with-leftwards-arrow-above'},
{'text':'End With Leftwards Arrow Above','value':'end-with-leftwards-arrow-above'},
{'text':'On With Exclamation Mark With Left Right Arrow Above','value':'on-with-exclamation-mark-with-left-right-arrow-above'},
{'text':'Soon With Rightwards Arrow Above','value':'soon-with-rightwards-arrow-above'},
{'text':'Top With Upwards Arrow Above','value':'top-with-upwards-arrow-above'},
{'text':'Place Of Worship','value':'place-of-worship'},
{'text':'Atom Symbol','value':'atom-symbol'},
{'text':'Om Symbol','value':'om-symbol'},
{'text':'Star Of David','value':'star-of-david'},
{'text':'Wheel Of Dharma','value':'wheel-of-dharma'},
{'text':'Yin Yang','value':'yin-yang'},
{'text':'Latin Cross','value':'latin-cross'},
{'text':'Orthodox Cross','value':'orthodox-cross'},
{'text':'Star And Crescent','value':'star-and-crescent'},
{'text':'Peace Symbol','value':'peace-symbol'},
{'text':'Menorah With Nine Branches','value':'menorah-with-nine-branches'},
{'text':'Six Pointed Star With Middle Dot','value':'six-pointed-star-with-middle-dot'},
{'text':'Aries','value':'aries'},
{'text':'Taurus','value':'taurus'},
{'text':'Gemini','value':'gemini'},
{'text':'Cancer','value':'cancer'},
{'text':'Leo','value':'leo'},
{'text':'Virgo','value':'virgo'},
{'text':'Libra','value':'libra'},
{'text':'Scorpius','value':'scorpius'},
{'text':'Sagittarius','value':'sagittarius'},
{'text':'Capricorn','value':'capricorn'},
{'text':'Aquarius','value':'aquarius'},
{'text':'Pisces','value':'pisces'},
{'text':'Ophiuchus','value':'ophiuchus'},
{'text':'Twisted Rightwards Arrows','value':'twisted-rightwards-arrows'},
{'text':'Clockwise Rightwards And Leftwards Open Circle Arrows','value':'clockwise-rightwards-and-leftwards-open-circle-arrows'},
{'text':'Clockwise Rightwards And Leftwards Open Circle Arrows With Circled One Overlay','value':'clockwise-rightwards-and-leftwards-open-circle-arrows-with-circled-one-overlay'},
{'text':'Black Right Pointing Triangle','value':'black-right-pointing-triangle'},
{'text':'Black Right Pointing Double Triangle','value':'black-right-pointing-double-triangle'},
{'text':'Black Left Pointing Triangle','value':'black-left-pointing-triangle'},
{'text':'Black Left Pointing Double Triangle','value':'black-left-pointing-double-triangle'},
{'text':'Up Pointing Small Red Triangle','value':'up-pointing-small-red-triangle'},
{'text':'Black Up Pointing Double Triangle','value':'black-up-pointing-double-triangle'},
{'text':'Down Pointing Small Red Triangle','value':'down-pointing-small-red-triangle'},
{'text':'Black Down Pointing Double Triangle','value':'black-down-pointing-double-triangle'},
{'text':'Black Square For Stop','value':'black-square-for-stop'},
{'text':'Eject Symbol','value':'eject-symbol'},
{'text':'Cinema','value':'cinema'},
{'text':'Low Brightness Symbol','value':'low-brightness-symbol'},
{'text':'High Brightness Symbol','value':'high-brightness-symbol'},
{'text':'Antenna With Bars','value':'antenna-with-bars'},
{'text':'Vibration Mode','value':'vibration-mode'},
{'text':'Mobile Phone Off','value':'mobile-phone-off'},
{'text':'Black Universal Recycling Symbol','value':'black-universal-recycling-symbol'},
{'text':'Trident Emblem','value':'trident-emblem'},
{'text':'Name Badge','value':'name-badge'},
{'text':'Japanese Symbol For Beginner','value':'japanese-symbol-for-beginner'},
{'text':'Heavy Large Circle','value':'heavy-large-circle'},
{'text':'White Heavy Check Mark','value':'white-heavy-check-mark'},
{'text':'Ballot Box With Check','value':'ballot-box-with-check'},
{'text':'Heavy Check Mark','value':'heavy-check-mark'},
{'text':'Heavy Multiplication X','value':'heavy-multiplication-x'},
{'text':'Cross Mark','value':'cross-mark'},
{'text':'Negative Squared Cross Mark','value':'negative-squared-cross-mark'},
{'text':'Heavy Plus Sign','value':'heavy-plus-sign'},
{'text':'Heavy Minus Sign','value':'heavy-minus-sign'},
{'text':'Heavy Division Sign','value':'heavy-division-sign'},
{'text':'Curly Loop','value':'curly-loop'},
{'text':'Double Curly Loop','value':'double-curly-loop'},
{'text':'Part Alternation Mark','value':'part-alternation-mark'},
{'text':'Eight Spoked Asterisk','value':'eight-spoked-asterisk'},
{'text':'Eight Pointed Black Star','value':'eight-pointed-black-star'},
{'text':'Sparkle','value':'sparkle'},
{'text':'Double Exclamation Mark','value':'double-exclamation-mark'},
{'text':'Exclamation Question Mark','value':'exclamation-question-mark'},
{'text':'Black Question Mark Ornament','value':'black-question-mark-ornament'},
{'text':'White Question Mark Ornament','value':'white-question-mark-ornament'},
{'text':'White Exclamation Mark Ornament','value':'white-exclamation-mark-ornament'},
{'text':'Heavy Exclamation Mark Symbol','value':'heavy-exclamation-mark-symbol'},
{'text':'Copyright Sign','value':'copyright-sign'},
{'text':'Registered Sign','value':'registered-sign'},
{'text':'Trade Mark Sign','value':'trade-mark-sign'},
{'text':'Keycap Number Sign','value':'keycap-number-sign'},
{'text':'Keycap Digit Zero','value':'keycap-digit-zero'},
{'text':'Keycap Digit One','value':'keycap-digit-one'},
{'text':'Keycap Digit Two','value':'keycap-digit-two'},
{'text':'Keycap Digit Three','value':'keycap-digit-three'},
{'text':'Keycap Digit Four','value':'keycap-digit-four'},
{'text':'Keycap Digit Five','value':'keycap-digit-five'},
{'text':'Keycap Digit Six','value':'keycap-digit-six'},
{'text':'Keycap Digit Seven','value':'keycap-digit-seven'},
{'text':'Keycap Digit Eight','value':'keycap-digit-eight'},
{'text':'Keycap Digit Nine','value':'keycap-digit-nine'},
{'text':'Keycap Ten','value':'keycap-ten'},
{'text':'Hundred Points Symbol','value':'hundred-points-symbol'},
{'text':'Input Symbol For Latin Capital Letters','value':'input-symbol-for-latin-capital-letters'},
{'text':'Input Symbol For Latin Small Letters','value':'input-symbol-for-latin-small-letters'},
{'text':'Input Symbol For Numbers','value':'input-symbol-for-numbers'},
{'text':'Input Symbol For Symbols','value':'input-symbol-for-symbols'},
{'text':'Input Symbol For Latin Letters','value':'input-symbol-for-latin-letters'},
{'text':'Negative Squared Latin Capital Letter A','value':'negative-squared-latin-capital-letter-a'},
{'text':'Negative Squared Ab','value':'negative-squared-ab'},
{'text':'Negative Squared Latin Capital Letter B','value':'negative-squared-latin-capital-letter-b'},
{'text':'Squared Cl','value':'squared-cl'},
{'text':'Squared Cool','value':'squared-cool'},
{'text':'Squared Free','value':'squared-free'},
{'text':'Information Source','value':'information-source'},
{'text':'Squared Id','value':'squared-id'},
{'text':'Circled Latin Capital Letter M','value':'circled-latin-capital-letter-m'},
{'text':'Squared New','value':'squared-new'},
{'text':'Squared Ng','value':'squared-ng'},
{'text':'Negative Squared Latin Capital Letter O','value':'negative-squared-latin-capital-letter-o'},
{'text':'Squared Ok','value':'squared-ok'},
{'text':'Negative Squared Latin Capital Letter P','value':'negative-squared-latin-capital-letter-p'},
{'text':'Squared Sos','value':'squared-sos'},
{'text':'Squared Up With Exclamation Mark','value':'squared-up-with-exclamation-mark'},
{'text':'Squared Vs','value':'squared-vs'},
{'text':'Squared Katakana Koko','value':'squared-katakana-koko'},
{'text':'Squared Katakana Sa','value':'squared-katakana-sa'},
{'text':'Squared Cjk Unified Ideograph 6708','value':'squared-cjk-unified-ideograph-6708'},
{'text':'Squared Cjk Unified Ideograph 6709','value':'squared-cjk-unified-ideograph-6709'},
{'text':'Squared Cjk Unified Ideograph 6307','value':'squared-cjk-unified-ideograph-6307'},
{'text':'Circled Ideograph Advantage','value':'circled-ideograph-advantage'},
{'text':'Squared Cjk Unified Ideograph 5272','value':'squared-cjk-unified-ideograph-5272'},
{'text':'Squared Cjk Unified Ideograph 7121','value':'squared-cjk-unified-ideograph-7121'},
{'text':'Squared Cjk Unified Ideograph 7981','value':'squared-cjk-unified-ideograph-7981'},
{'text':'Circled Ideograph Accept','value':'circled-ideograph-accept'},
{'text':'Squared Cjk Unified Ideograph 7533','value':'squared-cjk-unified-ideograph-7533'},
{'text':'Squared Cjk Unified Ideograph 5408','value':'squared-cjk-unified-ideograph-5408'},
{'text':'Squared Cjk Unified Ideograph 7A7A','value':'squared-cjk-unified-ideograph-7a7a'},
{'text':'Circled Ideograph Congratulation','value':'circled-ideograph-congratulation'},
{'text':'Circled Ideograph Secret','value':'circled-ideograph-secret'},
{'text':'Squared Cjk Unified Ideograph 55B6','value':'squared-cjk-unified-ideograph-55b6'},
{'text':'Squared Cjk Unified Ideograph 6E80','value':'squared-cjk-unified-ideograph-6e80'},
{'text':'Black Small Square','value':'black-small-square'},
{'text':'White Small Square','value':'white-small-square'},
{'text':'White Medium Square','value':'white-medium-square'},
{'text':'Black Medium Square','value':'black-medium-square'},
{'text':'White Medium Small Square','value':'white-medium-small-square'},
{'text':'Black Medium Small Square','value':'black-medium-small-square'},
{'text':'Black Large Square','value':'black-large-square'},
{'text':'White Large Square','value':'white-large-square'},
{'text':'Large Orange Diamond','value':'large-orange-diamond'},
{'text':'Large Blue Diamond','value':'large-blue-diamond'},
{'text':'Small Orange Diamond','value':'small-orange-diamond'},
{'text':'Small Blue Diamond','value':'small-blue-diamond'},
{'text':'Up Pointing Red Triangle','value':'up-pointing-red-triangle'},
{'text':'Down Pointing Red Triangle','value':'down-pointing-red-triangle'},
{'text':'Diamond Shape With A Dot Inside','value':'diamond-shape-with-a-dot-inside'},
{'text':'Black Square Button','value':'black-square-button'},
{'text':'White Square Button','value':'white-square-button'},
{'text':'Medium White Circle','value':'medium-white-circle'},
{'text':'Medium Black Circle','value':'medium-black-circle'},
{'text':'Large Red Circle','value':'large-red-circle'},
{'text':'Large Blue Circle','value':'large-blue-circle'},
{'text':'Chequered Flag','value':'chequered-flag'},
{'text':'Triangular Flag On Post','value':'triangular-flag-on-post'},
{'text':'Crossed Flags','value':'crossed-flags'},
{'text':'Waving Black Flag','value':'waving-black-flag'},
{'text':'Waving White Flag','value':'waving-white-flag'},
{'text':'Rainbow Flag','value':'rainbow-flag'},
{'text':'Pirate Flag','value':'pirate-flag'}];


import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import {Container, Button, Image, Form, TextInput, Navbar, List, Select, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText, PageBreak} from 'library';
import format_date from 'base/date_format.js';

class ParalegalRow extends Component {
    constructor(props) {
        super(props);

        this.setParalegal = this.setParalegal.bind(this);
        this.setParalegalClosed = this.setParalegalClosed.bind(this);
    }

    setParalegal() {
        this.props.setParalegal(this.props.name, 'open')
    }

    setParalegalClosed() {
        this.props.setParalegal(this.props.name, 'closed')
    }

    render() {
        var name = this.props.name;
        var request_dict = this.props.request_dict;

        return (
            <tr>
              <td><Button onClick={this.setParalegal} text={'View Open'} type='success' /></td>
              <td><Button onClick={this.setParalegalClosed} text={'View Closed'} type='danger' /></td>
              <td>{name}</td>
              <td>{request_dict[name][0]}</td>
              <td>{request_dict[name][1]}</td>
            </tr>
        )
    }
}

class Paralegals extends Component {
    constructor(props) {
        super(props);
        this.state = {attorney_filter:''}

        this.setGlobalState = this.setGlobalState.bind(this);
    }

    setGlobalState(name,state) {
        this.setState(state)
    }

    render() {
      console.log("Here");
      var attorney_names = [];
      var all_requests = [];
      var content = null;
      var request_dict = {};
      var today = new Date()

      for (var index in this.props.requests) {

          var request = this.props.requests[index];
          var attorney_name = request.attorney_name;

          if (!request_dict[attorney_name]) {
              request_dict[attorney_name] = [0, 0]
          }

          var open_close = 0;
          if (request.status.indexOf('Closed') > -1) {
              open_close = 1
          }

          request_dict[attorney_name][open_close] += 1;


      }

      for (var name in request_dict) {
          if (this.state.attorney_filter == '' || name.indexOf(this.state.attorney_filter) > -1) {
              all_requests.push(<ParalegalRow name={name} setParalegal={this.props.setParalegal} request_dict={request_dict} />)
          }
      }


          var componentList = [TextInput]
          var company_filter = {'name':'attorney_filter', label:'Paralegal'}


          var filter_form = <Form row={true} autoFocus={false} col_size={3} components={componentList} defaults={this.state} componentProps={[company_filter]}
            setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'filter'} />


        var content = <div>

            {filter_form}
            <table className="table">
              <tr>
                <th>View Open</th>
                <th>View Closed</th>
                <th>Paralegal</th>
                <th>Open Requests</th>
                <th>Closed Requests</th>
              </tr>
            {all_requests}
            </table>
          </div>;


        return (
            <Wrapper loaded={true}  content={content} />
        );
    }
}

export default Paralegals;

import React, { Component } from 'react';
import resolveVariables from 'base/resolver.js';
import ajaxWrapper from 'base/ajax.js';
import resolve_all_children from 'base/resolve_all_children.js';
import get_children from 'base/get_children.js';
import {Alert, Button, TextInput, NumberInput, CSSInput, Select} from 'library';


class FormWithChildren extends Component {
    static component_name = 'FormWithChildren';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'Class'} name={'className'} />,
                <TextInput label={'Submit Button Text'} default="Save" name={'submit_text'} />,
                <TextInput label={'submitUrl'} name={'submitUrl'} />,
                <TextInput label={'redirectUrl'} name={'redirectUrl'} />,
                <TextInput label={'deleteUrl'} name={'deleteUrl'} />,
                <TextInput label={'deleteRedirectUrl'} name={'deleteRedirectUrl'} />,
                <TextInput label={'objectName'} name={'objectName'} />,
                <Select label={'autoSetGlobalState'} name={'autoSetGlobalState'} boolean={true} defaultoption={false} />,
                <Select label={'row'} name={'row'} boolean={true} defaultoption={false} />,
                <Select label={'Reset on Submit'} name={'reset_state_on_submit'} boolean={true} defaultoption={false} />,
                <TextInput label={'globalStateName'} name={'globalStateName'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }

        this.state = {
            form_child_update_key: null,
            required: [],
            uploading_from_file_input: false,
        };

        this.get_form_defaults = this.get_form_defaults.bind(this);
        this.set_global_state = this.set_global_state.bind(this);
        this.handle_change = this.handle_change.bind(this);
        this.set_form_state = this.set_form_state.bind(this);
        this.reset_state_on_submit = this.reset_state_on_submit.bind(this);
        this.form_submit = this.form_submit.bind(this);
        this.form_submit_callback = this.form_submit_callback.bind(this);
        this.form_submit_failure = this.form_submit_failure.bind(this);
        this.reload = this.reload.bind(this);
        this.check_required_children = this.check_required_children.bind(this);
        this.form_delete = this.form_delete.bind(this);
        this.handle_key_press = this.handle_key_press.bind(this);
    }

    componentDidMount() {
        var defaults = this.get_form_defaults();
        this.setState(defaults);
    }

    get_form_defaults(clean) {
        var children = get_children(this.props, true);

        var defaults = {};
        if (this.props && this.props.defaults) {
          defaults = resolveVariables(this.props.defaults, window.cmState.getGlobalState(this))
        }

        for (var index in children) {
            var child = children[index];
            if (child.props && 'default' in child.props && child.props.default) {
                defaults[child.props.name] = child.props.default;
            } else if (clean) {
                defaults[child.props.name] = undefined;
            }
        }

        if (!(this.props.dont_resolve_defaults)){
            defaults = resolveVariables(defaults, window.cmState.getGlobalState());
        }

        for (var key in defaults){
            if (typeof(defaults[key]) == 'string' && defaults[key].indexOf('{') > -1 && defaults[key].indexOf('}') > -1) {
                defaults[key] = undefined;
            }
        }

        defaults['required'] = '';
        return defaults;
    }

    set_global_state(state) {
        if (this.props.autoSetGlobalState == true || this.props.autoSetGlobalState == "true") {
            window.cmState.setGlobalState(this.props.globalStateName, state);
            if (this.props.setGlobalState) {
                this.props.setGlobalState(this.props.globalStateName,state)
            }
        }
    }

    handle_change(e) {
        var name = e.target.getAttribute("name");
        var newState = {};
        newState[name] = e.target.value;

        var newCompleteState = this.state;
        newCompleteState[name] = e.target.value;
        this.setState(newState, this.set_global_state(newCompleteState));
    }

    set_form_state(state) {
        var newState = this.state;
        for (var index in state) {
          newState[index] = state[index];
        }

        this.setState(state);
        this.set_global_state(newState)
    }

    reset_state_on_submit() {
        var defaults = this.get_form_defaults(true);
        defaults['form_is_saving_right_now'] = false;

        // Reset key values for all children in order to fully clear states and rerender
        var date = Date.now();
        defaults['form_child_update_key'] = date;

        this.setState(defaults);
    }

    form_submit() {
        var data = Object.assign({},this.state);
        delete data['children'];
        delete data['form_state'];

        var required = this.check_required_children([], this.props.children);
        if (required.length > 0) {
            this.setState({required: required});
        }
        else {
            for (var item in data) {
                if (item.endsWith('[]')) {
                    data[item] = JSON.stringify(data[item]);
                }
            }

            if (this.props.submit) {
                this.props.submit(data);
                this.setState({form_is_saving_right_now: true});
            }
            else {
                if (this.props.submitUrl) {
                    var submitUrl = resolveVariables({'submitUrl':this.props.submitUrl}, window.cmState.getGlobalState(this))['submitUrl'];
                    ajaxWrapper("POST", submitUrl, data, this.form_submit_callback, this.form_submit_failure);
                    this.setState({form_is_saving_right_now: true});
                }
            }
        }
    }

    form_submit_callback(value) {
        if (typeof(value[0]) != 'undefined'){
            if (this.props.setGlobalState) {
                if (this.props.globalStateName) {
                  var returnObj = value[0][this.props.objectName];
                    this.setState(value[0][this.props.objectName], () => this.props.setGlobalState(this.props.globalStateName,value[0][this.props.objectName]));
                } else {
                    this.setState(value[0][this.props.objectName], this.props.setGlobalState('Form',this.state));
                }
            }
            else if (value['success'] == true) {
              //do nothing
            }
            else {
              if (value[0]) {
                this.setState(value[0][this.props.objectName]);
              }

            }
        }

        if (this.props.deleteRedirectUrl && value['success'] == true) {
          window.location.href = this.props.deleteRedirectUrl;
        }
        else if (this.props.redirectUrl) {
            if (this.props.objectName) {
                 var redirectUrl = resolveVariables({'redirectUrl':this.props.redirectUrl}, value[0][this.props.objectName]);
            }
            else {
                var redirectUrl = resolveVariables({'redirectUrl':this.props.redirectUrl}, value);
            }

            window.location.href = redirectUrl['redirectUrl'];
        }

        if (this.props.redirect) {
            value['form_state'] = this.state;
            this.props.redirect(value);
        }
        else if (this.props.functions) {
            var functions = this.props.functions;
            if (this.props.objectName) {
                 functions = resolveVariables(functions, value[0][this.props.objectName]);
            }
            else {
                functions = resolveVariables(functions, value);
            }

            //run_functions(functions, this.setState, this.props.setGlobalState);
        }
        else if (this.props.refreshData) {
            this.props.refreshData();
        }

        if (this.props.reset_state_on_submit) {
            this.reset_state_on_submit();
        }
        else {
            this.setState({form_is_saving_right_now: false});
        }
    }

    form_submit_failure(value) {
        if (this.props.submit_failure){
            this.props.submit_failure(value);
        }
        this.setState({form_is_saving_right_now: false});
    }

    reload(value){
        window.location.reload();
    }

    check_required_children(required, context) {
        for (var index in context) {
            var child = context[index];
            var props = child.props;

            if (props.required == true) {
                if (!(props.name in this.state) || !(this.state[props.name]) || this.state[props.name] == '') {
                    var field_name = props.label;
                    // Fallback behavior in case no label was applied to the input
                    if (!(field_name) || field_name == ''){
                        field_name = props.name;
                    }

                    required.push("The field " + field_name + " must be filled out to submit the form. ");
                }
            }

            required = this.check_required_children(required, child.props.children);
        }

        return required;
    }

    form_delete() {
        ajaxWrapper("POST",this.props.deleteUrl, {}, this.form_submit_callback, this.form_submit_failure);
    }

    handle_key_press(event) {
      if (this.props.submit_on_enter != false) {
        if(event.key == 'Enter') {
          this.form_submit()
        }
      }
    }

    render() {
        var layout = "";
        if (typeof(this.props.className) != 'undefined') {
            layout = this.props.className
        }

        if (this.props.row == true || this.props.row == "true") {
            layout +=" form-row row";
        }
        else {
            layout += " form";
        }

        var newProps = {
            setFormState:this.set_form_state,
            handlechange:this.handle_change,
            handleKeyPress: this.handle_key_press
        }

        let components = resolve_all_children(this, newProps, this.state, true);
        if (this.state.form_child_update_key){
            var new_components = [];
            for (var i in components){
                var component = components[i];
                component = React.cloneElement(component, {key: this.state.form_child_update_key + '_' + i});
                new_components.push(component);
            }

            components = new_components;
        }

        var buttons = [];
        if (this.props.submitUrl || this.props.submit) {
            var classes = "btn btn-primary";
            if (this.props.submitButtonType) {
              classes = "btn btn-" + this.props.submitButtonType;
            }
            var float = {'float':'left'};

            var submitButton = <button css={float} className={classes} onClick={this.form_submit}>{this.props.submit_text || "Save"}</button>
            // Anti-mash behavior for form.  This will force users to wait until callback functions have completed
            // and ensure the form is submitted properly
            if (this.state.form_is_saving_right_now){
                submitButton = <button css={float} className={classes + ' disabled'} >{this.props.submit_text || "Save"}</button>
            }
            else if (this.state.uploading_from_file_input) {
                console.log("Uploading From File input button")
                submitButton = <button css={float} className={classes + ' disabled'} >Waiting for File Upload...</button>
            }
            buttons.push(submitButton);
        }

        if (this.props.deleteUrl) {
            var float = {'float':'right'}
            var deleteButton = <Button css={float} type={"danger"} onClick={this.form_delete} deleteType={true} text={"Delete"} />
            buttons.push(deleteButton);
        }

        var failed = [];
        if (this.state.required != []) {
            for (var i in this.state.required){
                failed.push(<Alert type={"danger"} text={this.state.required[i]} />);
            }
        }

        //need to add in form_submit, delete, and handle change functions to components.
        return(
            <div className={layout} style={this.props.style} onKeyPress={this.handle_key_press}>
                {components}
                {failed}
                {buttons}
            </div>
        )
    }
}

export default FormWithChildren;


import React, { Component } from 'react';
import sort_objects from 'base/sort_objects.js';
import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import { Container, Button, Image, Form, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText, Select, Icon, EmptyModal, NumberInput } from 'library';
import format_date from 'base/date_format.js';
import format_date_string from 'base/date_string_format.js';
import HelpVideo from 'projectLibrary/help_video.js';
import ReactPaginate from 'react-paginate';


function compare(a, b) {
    if (a['patient']['last_name'] > b['patient']['last_name']) return 1;
    if (a['patient']['last_name'] < b['patient']['last_name']) return -1;
    return 0;
}

class FilesToDownload extends Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false, downloadable_files: [] }
        this.request_callback = this.request_callback.bind(this);
        this.get_data = this.get_data.bind(this);
    }

    componentDidMount() {
        this.get_data();
    }

    get_data() {
        console.log("Getting Data For DOwnload")
        ajaxWrapper('GET', '/api/home/request/?company=' + this.props.company_id + '&related=files,files__file_accesses,patient,provider', {}, this.request_callback)
    }

    request_callback(result) {
        console.log("In the request callback", result)
        var downloadable_files = [];

        for (var index in result) {
            var request = result[index]['request']

            if (request.patient.company_id == this.props.company_id) {
                var downloaded = '';

                for (var file_index in request.files) {
                    var file = request.files[file_index].file;
                    for (var access_index in file.file_accesses) {
                        var access = file.file_accesses[access_index]['fileaccess'];
                        if (access.action == 'DOWNLOAD') {
                            if (access.user_id == '3f46c2b5-4a69-47de-9df0-cdd898658c30') {
                                console.log("Downloaded By Harlo", access)
                            }
                            else {
                                downloaded = access.created_at;
                            }
                        }
                    }


                    if (file.archived != true) {

                        var provider_name = '';
                        if (request.provider) {
                            provider_name = request.provider.name;
                        }
                        if (downloaded == '') {
                            downloadable_files.push(
                                <tr>
                                    <td><DownloadButton file={file} user={this.props.user} ip={this.props.ip} /></td>
                                    <td>{request.patient.first_name} {request.patient.last_name}</td>
                                    <td>{provider_name}</td>
                                </tr>
                            )
                        }
                    }
                }
            }


        }

        this.setState({ downloadable_files: downloadable_files, loaded: true, result: result })
    }

    render() {

        var content = <table className="table">
            <tr>
                <th>Download</th>
                <th>Patient</th>
                <th>Provider</th>
            </tr>
            {this.state.downloadable_files}
        </table>;

        return (
            <Wrapper loaded={this.state.loaded} content={content} />
        )
    }
}


class SortableTitle extends Component {
    constructor(props) {
        super(props);

        this.sort = this.sort.bind(this);
    }

    sort() {
        this.props.sort(this.props.name);
    }

    render() {

        var sorting = null;
        if (this.props.name == this.props.sort_by) {
            sorting = <span>({this.props.direction})</span>
        }

        return (
            <th style={{ pointer: 'cursor' }}><a onClick={this.sort}>{this.props.name} {sorting}</a></th>
        )
    }
}

class DownloadButton extends Component {
    constructor(props) {
        super(props);
        this.state = { downloaded: false };

        this.fileDownload = this.fileDownload.bind(this);
        this.goToFile = this.goToFile.bind(this);
        this.getFileUrl = this.getFileUrl.bind(this);
    }

    fileDownload() {
        var file_id = this.props.file['id'];
        console.log("User Id", this.props.user.id);
        if (window.location.pathname == '/dashboard/e4f3c0a1-5e41-4541-98e4-8288353c7684/') {
            this.getFileUrl(this.props.file['url'])
        }
        else {
            ajaxWrapper('POST', '/api/home/fileaccess/', { 'action': 'DOWNLOAD', ip: this.props.ip, file: file_id, user: this.props.user.id }, () => this.getFileUrl(this.props.file['url']))
        }
    }

    getFileUrl(url) {
        ajaxWrapper("POST", "/getFileUrl/", { "url": url }, this.goToFile)
    }

    goToFile(result) {
        this.setState({ downloaded: true })
        window.open(result['url'], '_blank')
    }

    render() {
        if (!this.state.downloaded) {
            return (
                <Button type={'primary'} text='Download' onClick={this.fileDownload} />
            )
        }

        else {
            return (
                <Button type={'danger'} text='Downloaded' disabled={true} />
            )
        }

    }
}

class ViewLawyerRequests extends Component {
    constructor(props) {
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
        this.closedHandler = this.closedHandler.bind(this);
    }

    clickHandler() {
        this.props.setGlobalState('form', { selectedUser: this.props.index, request_status_filter: 'open' })
    }

    closedHandler() {
        this.props.setGlobalState('form', { selectedUser: this.props.index, request_status_filter: 'closed' })
    }

    render() {
        if (this.props.status == 'open') {
            return (
                <Button type='primary' text='View Open' onClick={this.clickHandler} />
            )
        }
        else {
            return (
                <Button type='danger' text='View Closed' onClick={this.closedHandler} />
            )
        }
    }
}

class RequestList extends Component {
    constructor(props) {
        super(props);

        var selectedUser = ''


        this.state = {
            requests_download_attention: [], download_page_offset: 1, download_total_count: 0, download_page_count: 0, download_limit: 10 , downloadable_files:[] ,company: {}, loaded: false, selectedUser: selectedUser, lawyer_filter: '', patient_filter: '', provider_filter: '', status_filter: '', request_status_filter: 'open', files_to_download: [], ip: '', days_old_filter: 0, sort_by: '', direction: 'Ascending', all_requests: false, page_count: 0, next: "",
            previous: "", total_count: 0, limit: 10, page_offset: 1, open_requests: [], requests_needing_attention: [], need_page_offset: 1, need_total_count: 0, need_page_count: 0, need_limit: 10, all_requests_open: "Yes", sort_column: '' , loading : false,
        }

        this.objectCallback = this.objectCallback.bind(this);
        this.objectCallbackNew = this.objectCallbackNew.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.download_files = this.download_files.bind(this);
        this.fileDownload = this.fileDownload.bind(this);
        this.getFileUrl = this.getFileUrl.bind(this);
        this.goToFile = this.goToFile.bind(this);
        this.getIp = this.getIp.bind(this);
        this.sort = this.sort.bind(this);
        this.get_data = this.get_data.bind(this);
        this.toggle_all_requests = this.toggle_all_requests.bind(this);
        this.toggle_all_requests_open = this.toggle_all_requests_open.bind(this);
        this.handleLimitChange = this.handleLimitChange.bind(this);
        this.handleNeedLimitChange = this.handleNeedLimitChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleNeedPageClick = this.handleNeedPageClick.bind(this);
        this.handleApi = this.handleApi.bind(this);
        this.getNeedAttention = this.getNeedAttention.bind(this);
        this.handleDownloadPageClick = this.handleDownloadPageClick.bind(this);
        this.handleDownloadLimitChange = this.handleDownloadLimitChange.bind(this);
        this.request_callback = this.request_callback.bind(this);
        this.getDownloadApi = this.getDownloadApi.bind(this);
    }

    sort(name) {

        console.log('>>>>>>>>>>>>', name);
        let order_by = '';
        let direction = '';
        let opposite_direction = "-";
        if (name == 'Date Contacted') {
            order_by = 'created_at';
        }

        if (name == 'Paralegal') {
            order_by = 'user__full_name';
        }

        if (name == 'Client') {
            order_by = 'patient__full_name';
        }

        if (name == 'Provider') {
            order_by = 'provider__name';
        }

        if (name == 'Request Type') {
            order_by = 'request_type';
        }

        if (name == 'Status') {
            order_by = 'status';
        }

        if (name == 'Days Since Initial Request') {
            order_by = 'days_old';
        }

        if (name == 'Days To Receive') {
            order_by = 'days_to_receive';
        }

        if (name == 'Received Documents') {
            order_by = 'received_document';
        }

        if (name == 'Days To Download') {
            order_by = 'days_to_download';
        }

        if (name == 'Internal Request #') {
            order_by = 'internal_number';
        }

        console.log('>>>>>>>>>>>>', order_by);

        if (this.state.sort_by != name) {
            this.setState({ sort_by: name, direction: 'Ascending' })
        }
        else {
            if (this.state.direction == 'Ascending') {
                    this.setState({ direction: 'Descending' })
                    direction = '-';
            }
            else {
              
                    this.setState({ direction: 'Ascending' })
            }
        }

        if (order_by != '') {
                this.setState({ sort_column: direction + order_by })
                this.setState({page_offset : 1})
                this.handleApi(this.state.limit, 1, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open, direction + order_by);
        }
    }

    handleDownloadPageClick = (event) => {
        this.setState({ download_page_offset: event.selected + 1 })
        this.getDownloadApi(event.selected + 1, this.state.download_limit)
    }

    handleDownloadLimitChange(e) {
        this.setState({ download_limit: parseInt(e.target.value) });
        this.setState({ download_page_offset: 1 });
    }

    handlePageClick = (event) => {
        this.setState({ page_offset: event.selected + 1 })
        this.handleApi(this.state.limit, event.selected + 1, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open);
    }

    handleNeedPageClick = (event) => {
        this.setState({ need_page_offset: event.selected + 1 })
        this.get_need_attention(this.state.need_limit, event.selected + 1, this.state.all_requests_open, this.state.lawyer_filter);
    }

    handleLimitChange(e) {
        this.setState({ limit: parseInt(e.target.value) });
        if (parseInt(e.target.value) > this.state.total_count) {
            this.setState({ page_offset: 1 });
        }
        if (this.state.page_offset * parseInt(e.target.value) > this.state.total_count) {
            this.setState({ page_offset: parseInt(this.state.total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.total_count / parseInt(e.target.value)) });
        }
    }

    handleNeedLimitChange(e) {
        this.setState({ need_limit: parseInt(e.target.value) });
        if (parseInt(e.target.value) > this.state.need_total_count) {
            this.setState({ need_page_offset: 1 });
            this.get_need_attention(parseInt(e.target.value), 1, this.state.all_requests_open, this.state.lawyer_filter);
        }
        if (this.state.need_page_offset * parseInt(e.target.value) > this.state.need_total_count) {
            this.setState({ need_page_offset: parseInt(this.state.need_total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.need_total_count / parseInt(e.target.value)) });
            this.get_need_attention(parseInt(e.target.value),parseInt(this.state.need_total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.need_total_count / parseInt(e.target.value)), this.state.all_requests_open, this.state.lawyer_filter);
        }
        else {
            this.setState({ need_page_offset: 1 });
            this.get_need_attention(parseInt(e.target.value), 1, this.state.all_requests_open, this.state.lawyer_filter);
        }
    }

    toggle_all_requests() {
        this.setState({ all_requests_open: "Yes" })
        this.handleApi(this.state.limit, this.state.page_offset, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, 'Yes');
        this.get_need_attention(this.state.need_limit, this.state.need_page_offset, "Yes", this.state.lawyer_filter);
        this.setState({ all_requests: !this.state.all_requests, loaded: false })
        this.get_data(!this.state.all_requests)
    }

    toggle_all_requests_open() {
        this.setState({ all_requests_open: "No" })
        this.handleApi(this.state.limit, this.state.page_offset, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, 'No');
        this.get_need_attention(this.state.need_limit, this.state.need_page_offset, "No", this.state.lawyer_filter);
        this.setState({ all_requests: !this.state.all_requests, loaded: false })
        this.get_data(!this.state.all_requests)
    }

    getDownloadApi(page_offset , page_limit) {
        ajaxWrapper('GET', `/api/download-request/?page=${page_offset}&page_size=${page_limit}`, {}, this.request_callback)
    }


    request_callback(result) {
        console.log("In the request callback",result)
        this.setState({download_total_count : result.count})
        var downloadable_files = [];

        for (var index in result.results) {
            var request = result.results[index]['file']
                            downloadable_files.push(
                                <tr>
                                  <td><DownloadButton file={request} user={this.props.user} ip={this.props.ip} /></td>
                                  <td>{request.request.patient.first_name} {request.request.patient.last_name}</td>
                                  <td>{request.request.provider.name}</td>
                                </tr>
                            )
                        }

        this.setState({downloadable_files:downloadable_files, loaded:true, result:result})
    }


    get_need_attention(need_page_count, need_page_offset, open_request , parallegal) {
        ajaxWrapper('GET', `/api/requests-company/${this.props.company_id}?page=${need_page_offset}&page_size=${need_page_count}&status=Need Assistance&open_request=${open_request}&paralegal=${parallegal}&client=&provider=&days_old=0`, {}, this.getNeedAttention);
    }

    getNeedAttention(result) {
        this.setState({ need_total_count: result.count })
        this.setState({ requests_needing_attention: result.results })
    }

    get_data(all_requests) {
        var url = '/api/home/company/';

        if (all_requests) {
            var related = '?related=patients,patients__requests,patients__requests__user,patients__requests__provider,patients__requests__requester,patients__requests__files,patients__requests__files__file_accesses'
        }
        else {
            var related = '?related=patients,patients__requests,patients__requests__user,patients__requests__provider,patients__requests__requester,patients__requests__files,patients__requests__files__file_accesses&related__patients__related__requests__closed_date=None'
        }


        if (this.props.company_id) {
            url += this.props.company_id + '/';
        }
        else {
            url += this.props.user.company_id + '/';
        }

        url += related;
        url += '&order_by=created_at';

        ajaxWrapper('GET', "", {}, this.objectCallback);
    }

    handleApi(limit, page_offset, days_old, patient, lawyer, status, provider, open_request, order_by = '') {
        this.setState({loading : true})
        this.setState({ loader: true })
        if (order_by != '') {
            ajaxWrapper('GET', `/api/requests-company/${this.props.company_id}/?page=${decodeURIComponent(page_offset)}&page_size=${limit}&open_request=${open_request}&paralegal=${decodeURIComponent(lawyer)}&client=${decodeURIComponent(patient)}&provider=${decodeURIComponent(provider)}&status=${decodeURIComponent(status)}&days_old=${days_old}&order_by=${order_by}`, {}, this.objectCallbackNew);
        } else if (this.state.sort_column != '') {
            ajaxWrapper('GET', `/api/requests-company/${this.props.company_id}/?page=${decodeURIComponent(page_offset)}&page_size=${limit}&open_request=${open_request}&paralegal=${decodeURIComponent(lawyer)}&client=${decodeURIComponent(patient)}&provider=${decodeURIComponent(provider)}&status=${decodeURIComponent(status)}&days_old=${days_old}&order_by=${this.state.sort_column}`, {}, this.objectCallbackNew);
        } else {
            ajaxWrapper('GET', `/api/requests-company/${this.props.company_id}/?page=${decodeURIComponent(page_offset)}&page_size=${limit}&open_request=${open_request}&paralegal=${decodeURIComponent(lawyer)}&client=${decodeURIComponent(patient)}&provider=${decodeURIComponent(provider)}&status=${decodeURIComponent(status)}&days_old=${days_old}`, {}, this.objectCallbackNew);
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.limit !== prevState.limit) {
            this.handleApi(this.state.limit, this.state.page_offset, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open)
        }
        if(this.state.need_limit !== prevState.need_limit) {
            this.get_need_attention(this.state.need_limit, this.state.need_page_offset, this.state.all_requests_open, this.state.lawyer_filter);
        }
        if(this.state.download_limit !== prevState.download_limit) {
            this.getDownloadApi(this.state.download_page_offset , this.state.download_limit)
        }
        if (this.state.name !== "" && prevState.name !== this.state.name) {
            this.setState({ page_offset: 1 });
            this.get_need_attention(this.state.need_limit, 1, this.state.all_requests_open, this.state.lawyer_filter);
            this.handleApi(this.state.limit, 1, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open);
            this.getDownloadApi(1 , this.state.download_limit)
        }
    }

    componentDidMount() {
        this.getDownloadApi(this.state.download_page_offset , this.state.download_limit);
        this.get_data(false)
        this.handleApi(this.state.limit, this.state.page_offset, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open)
        this.get_need_attention(this.state.need_limit, this.state.need_page_offset, this.state.all_requests_open , this.state.lawyer_filter);
        ajaxWrapper('GET', '/api/ip/', {}, this.getIp);
    }

    objectCallbackNew(result) {
        var requests_by_lawyer = [];
        this.setState({ open_requests: result })
        this.setState({ total_count: result.count })
        for (var request_index in result.results) {
            var request = result.results[request_index]['request'];

            request['patient'] = {
                first_name: request['patient']['first_name'],
                last_name: request['patient']['last_name']
            };
            var lawyer = request['user']['full_name'];
            requests_by_lawyer.push(request);
        }
        this.setState({
            requests_by_lawyer: requests_by_lawyer
        })
        this.setState({loading: false})
    }

    getIp(result) {
        this.setState({ ip: result['ip'] });
    }

    setGlobalState(name , state) {
      
        this.setState({
            patient_filter: decodeURIComponent(state.patient_filter),
        })
        this.setState({
            provider_filter: decodeURIComponent(state.provider_filter),
        })
        this.setState({
            status_filter: decodeURIComponent(state.status_filter)
        })
        this.setState({
            days_old_filter: state.days_old_filter
        })
        if(state.lawyer_filter !== this.state.lawyer_filter) {
            this.get_need_attention(this.state.need_limit, this.state.need_page_offset, this.state.all_requests_open , state.lawyer_filter);
            this.setState({
                lawyer_filter: decodeURIComponent(state.lawyer_filter),
            })
        }
        this.handleApi(this.state.limit, 1, state.days_old_filter, state.patient_filter, state.lawyer_filter, state.status_filter, state.provider_filter, this.state.all_requests_open)
    }

    download_files() {
        for (var index in this.state.files_to_download) {
            var file = this.state.files_to_download[index];
            setTimeout(() => this.fileDownload(file), 1000 * index);
        }
    }

    fileDownload(file) {
        var file_id = file['id'];
        ajaxWrapper('POST', '/api/home/fileaccess/', { 'action': 'DOWNLOAD', ip: this.state.ip, file: file_id, user: this.props.user.id }, () => this.getFileUrl(file['url']))
    }

    getFileUrl(url) {
        ajaxWrapper("POST", "/getFileUrl/", { "url": url }, this.goToFile)
    }

    goToFile(result) {
        var link = document.createElement("a");
        link.download = result['url'];
        link.href = result['url'];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    objectCallback(result) {
        var now = new Date()
        var files_to_download = [];
        var company = result[0]['company'];
        var patients = [];
        if (company) {
            patients = company['patients'].sort(compare);
        }

        var requests_by_lawyer = {};
        var patient_dict = {};

        this.setState({
            company: company,
            patient_dict: patient_dict,
            files_to_download: files_to_download,
            loaded: true
        });
    }

    render() {

        var content = null;
        if (this.state.loaded) {

            var requests = [];
            var downloadable_files = [];

            var outstanding_requests = 0
            var total_days_since_request = 0
            var total_requests_received = 0;
            var total_time_to_receive = 0;
                for (var index in this.state.requests_by_lawyer) {
                    var request = this.state.requests_by_lawyer[index]
                    var patient = request['patient']
                    var patient_name = patient.first_name.trim() + ' ' + patient.last_name.trim();
                    var days_to_receive = '';
                    var received_documents = '';
                    var days_to_download = '';
                    var downloaded = '';

                    if (request.files.length > 0) {
                        received_documents = request.files[0]['file'].created_at
                        days_to_receive = ((Date.parse(request.files[0]['file'].created_at) - Date.parse(request.created_at)) / 86400000);


                        var days_since_created = (now - Date.parse(request.created_at)) / 86400000
                        if (days_since_created < 90) {
                            if (request.completed_date && request.completed_date != '') {
                                total_time_to_receive += ((Date.parse(request.completed_date) - Date.parse(request.created_at)) / 86400000);
                                total_requests_received += 1;
                            }
                        }


                        if (request.files[0]['file'].file_accesses.length > 0) {
                            for (var access_index in request.files[0]['file'].file_accesses) {
                                var access = request.files[0]['file'].file_accesses[access_index]['fileaccess'];
                                if (access.action == 'DOWNLOAD') {
                                    if (access.user_id == '3f46c2b5-4a69-47de-9df0-cdd898658c30') {
                                        console.log("Downloaded By Harlo", access)
                                    }
                                    else {
                                        days_to_download = ((Date.parse(access.created_at) - Date.parse(request.files[0]['file'].created_at)) / 86400000);
                                    }
                                }

                            }
                        }



                    }
                    else if (request.status.toLowerCase().indexOf('closed') == -1) {
                        console.log("Request Status 2")
                        outstanding_requests += 1;
                    }

                    if (request.active == true) {
                        var today = new Date();
                        var request_date = Date.parse(request.created_at)
                        var days_old = (today - request_date) / 86400000
                        if (days_old > this.state.days_old_filter) {
                            if (request.provider) {
                                requests.push({
                                    'id': request.id, internal_number : request.internal_number, 'days_old': days_old, 'created_at': request.created_at, days_to_receive: request.days_to_receive, received_documents: request.received_document,
                                    days_to_download: request.days_to_download, downloaded: downloaded, patient_name: patient_name, patient_last_name: patient.last_name, provider_name: request.provider.name, status: request.status, request_type: request.request_type, company: patient.company_id , lawyer_requests_name : request.user.full_name,start_date:request.start_date,end_date:request.end_date
                                })
                            }
                            else {
                                console.log("Request Missing Provider", request)
                            }
                        }
                    }
                }
            // }


            var request_display = [];
            var request_company_check = {}
            for (var index in requests) {
                var request = requests[index];

                if (!request_company_check[request.company]) {
                    request_company_check[request.company] = 0
                }
                request_company_check[request.company] += 1;



                var days_to_receive = request.days_to_receive;
                    days_to_receive = days_to_receive.toFixed(0)

                var days_to_download = request.days_to_download;
                    days_to_download = days_to_download.toFixed(0)

                request_display.push(<tr>
                    <td className="no-print"><Button type='primary' text='View' href={"/request/" + request.id + '/'} target={'_blank'} /></td>
                    <td>{request.internal_number}</td>
                    <td>{format_date_string(request.created_at, 'mm/dd/yyyy', false)}</td>
                    <td>{request.lawyer_requests_name}</td>
                    <td>{request.patient_name}</td>
                    <td>{request.provider_name}</td>
                    <td>{format_date_string(request.start_date,'mm/dd/yyyy', false)}</td>
                    <td>{format_date_string(request.end_date,'mm/dd/yyyy', false)}</td>
                    <td>{request.request_type}</td>
                    <td>{request.status}</td>
                </tr>)

            }

            var request_company_check_list = [];
            if (this.props.user.id == '57d0be71-b98c-4360-8a23-1e5ba4a0808b') {
                for (var index in request_company_check) {
                    request_company_check_list.push(<p>{index}: {request_company_check[index]}</p>)
                }
            }

            if (outstanding_requests == 0) {
                var average_days_since_request = 0
            }
            else {
                var average_days_since_request = Math.floor(total_days_since_request / outstanding_requests)
            }


            var status_options = [
                { 'text': 'All Open', value: 'Open' },
                { 'text': 'Requested By Attorney ', value: 'Requested By Lawyer' },
                { 'text': 'Sent the Initial Medical Records Request', value: 'Sent the Initial Medical Records Request' },
                { 'text': 'In Contact With Provider', 'value': 'In Contact With Provider' },
                { 'text': 'Provider Is Sending Documents', 'value': 'Provider Is Sending Documents' },
                { 'text': 'Need Assistance', value: 'Need Assistance' },
                { 'text': 'Completed - Documents Arrived', 'value': 'Completed - Documents Arrived' },
                { 'text': 'Completed - No Documents Available', 'value': 'Completed - No Documents Available' },
                { 'text': 'Closed - Documents Arrived', 'value': 'Closed - Documents Arrived' },
                { 'text': 'Closed - No Documents Available', 'value': 'Closed - No Documents Available' },
                { 'text': 'Closed - Originally no document found/now document arrived', 'value': 'Originally no document found/now document arrived' },
                { 'text': 'Closed - Cancelled Request', value: 'Closed - Cancelled Request' },
                { 'text': 'All Closed', value: 'Closed' },
            ]

            var componentList = [TextInput, TextInput, TextInput, Select, NumberInput]
            var lawyer_filter = { 'name': 'lawyer_filter', label: 'Paralegal' }
            var patient_filter = { 'name': 'patient_filter', label: 'Client' }
            var provider_filter = { 'name': 'provider_filter', label: 'Provider' }
            var status_filter = { 'name': 'status_filter', label: 'Status', options: status_options }
            var days_old_filter = { 'name': 'days_old_filter', label: 'Days Old' }


            var filter_form = <Form row={true} col_size={3} components={componentList} defaults={this.state} componentProps={[lawyer_filter, patient_filter, provider_filter, status_filter, days_old_filter]}
                setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'filter'} />

            /*
            <div className="col">
              <h3 style={{'fontSize':'20px', letterSpacing:'-1px', fontWeight:'bold'}}>Average Days Since Request</h3>
              <p style={{fontSize:'18px', fontWeight:'bold', letterSpacing:'2px', color:'#a5a5a5'}}>{average_days_since_request} days</p>
            </div>
            */
            var turnaround_time = (total_time_to_receive / total_requests_received);
            if (this.props.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684' || this.props.company_id == '11cc3e0b-ad6d-4ea3-be17-a70d28d23a9e') {
                turnaround_time = 15.26;
            }

            var all_requests_title = "All Open Requests"
            var toggle_all_requests = <Button type='primary' text='See All Requests' onClick={this.toggle_all_requests_open} />
            if (this.state.all_requests) {
                all_requests_title = "All Requests"
                toggle_all_requests = <Button type='primary' text='See Only Open Requests' onClick={this.toggle_all_requests} />
            }

            var edit_request_link = '/editRequest/' + this.props.user.company_id + "/"
            if (this.props.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684' && this.props.company_id) {
                edit_request_link = '/editRequest/' + this.props.company_id + "/"
            }

            var content =
                <div className="container-fluid" style={{ padding: '10px', position: 'relative' }}>
                    <div className="no-print">
                        <HelpVideo src="https://companyforms.s3.us-east-2.amazonaws.com/Law+Firm+-+Company+Dashboard.mp4" />
                        <Button href={edit_request_link} type='success' text='Start New Request' style={{ marginBottom: '20px' }} />
                    </div>
                    <div className="row">

                        <div className="col-5 no-print">
                        <br />
                        <div style={{
                margin: "20px",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "0px"
            }}>
                <div 
                    style={{
                    margin: "0px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "0px",
                    marginBottom: "0px"
                }}>
                    Total Records : {this.state.download_total_count}
                    {
                        this.state.download_total_count > 0 ?
                            <select
                                className="form-control"
                                style={{ width: "85px", marginLeft: "10px" }}
                                onChange={this.handleDownloadLimitChange}
                            >
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                                <option>40</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                            : ""
                    }
                </div>
                
                <div style={{
                    display: 'flex',
                    alignItems: "center",
                    justifyContent:"flex-end"
                }}>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next"
                        onPageChange={this.handleDownloadPageClick}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={3}
                        pageCount={Math.ceil(this.state.download_total_count / this.state.download_limit)}
                        previousLabel="Previous"
                        renderOnZeroPageCount={null}
                        className="custom-pagination-dashboard"
                        forcePage={this.state.download_page_offset - 1}
                    />
                </div>
            </div>
                        <Header size={2} text={'Download Records'} style={{'marginBottom':'20px', marginTop:'20px', borderBottom:'1px solid black', textAlign:'left'}} />
                        <div style={{maxHeight:'500px', overflowY:'scroll'}}>
                            <table className='table'>
                        <tr>
                        <th>Download</th>
                        <th>Patient</th>
                        <th>Provider</th>
                    </tr>
                    {this.state.downloadable_files}
                            </table>

                        </div>

                        </div>
                        <div className="col-2 no-print"></div>
                        <div className="col-5 no-print">
                        <br />
                        <div style={{
                margin: "20px",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "0px"
            }}>
                <div 
                    style={{
                    margin: "0px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "0px",
                    marginBottom: "0px"
                }}>
                    Total Records : {this.state.need_total_count}
                    {
                        this.state.need_total_count > 0 ?
                            <select
                                className="form-control"
                                style={{ width: "85px", marginLeft: "10px" }}
                                onChange={this.handleNeedLimitChange}
                            >
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                                <option>40</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                            : ""
                    }
                </div>
                
                <div style={{
                    display: 'flex',
                    alignItems: "center",
                    justifyContent:"flex-end"
                }}>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next"
                        onPageChange={this.handleNeedPageClick}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        pageCount={Math.ceil(this.state.need_total_count / this.state.need_limit)}
                        previousLabel="Previous"
                        renderOnZeroPageCount={null}
                        className="custom-pagination-dashboard"
                        forcePage={this.state.need_page_offset - 1}
                    />
                </div>
            </div>
                                <Header size={2} text={'Need Your Attention'} style={{ 'marginBottom': '20px', marginTop: '20px', borderBottom: '1px solid black' }} />
                            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                <table className="table">
                                    <tr>
                                        <th>View</th>
                                        <th>Patient</th>
                                        <th>Provider</th>
                                    </tr>
                                    {
                                        this.state.requests_needing_attention && this.state.requests_needing_attention.length > 0 && this.state.requests_needing_attention.map((obj, index) => {
                                            return (
                                                <tr>
                                                    <td><Button type='primary' text='View' href={"/request/" + obj.request.id + '/'} target={'_blank'} /></td>
                                                    <td>{obj.request.patient.first_name} {obj.request.patient.last_name}</td>
                                                    <td>{obj.request.provider.name}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                        </div>

                        <div className="col-12">
                            <Header size={2} text={all_requests_title} style={{ 'marginBottom': '20px', marginTop: '20px', borderBottom: '1px solid black' }} />
                            {toggle_all_requests}
                            <div className="no-print">
                                {filter_form}
                                <br />
                                <div style={{
                                    margin: "20px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center"
                                }}>
                                    <div>
                                        Total Records : {this.state.total_count}
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: "center",
                                        marginLeft: "20px"
                                    }}>
                                        {
                                            this.state.total_count > 0 ?
                                                <select
                                                    className="form-control"
                                                    style={{ width: "85px", marginRight: "5px" }}
                                                    onChange={this.handleLimitChange}
                                                >
                                                    <option>10</option>
                                                    <option>20</option>
                                                    <option>30</option>
                                                    <option>40</option>
                                                    <option>50</option>
                                                    <option>100</option>
                                                </select>
                                                : ""
                                        }
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="Next"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={10}
                                            marginPagesDisplayed={3}
                                            pageCount={Math.ceil(this.state.total_count / this.state.limit)}
                                            previousLabel="Previous"
                                            renderOnZeroPageCount={null}
                                            className="custom-pagination"
                                            forcePage={this.state.page_offset - 1}
                                        />
                                    </div>
                                </div>

                                <div style={{
                                    margin: "20px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center"
                                }}>
                                </div>
                                <Button onClick={() => window.print()} type='success' text='Print' style={{ marginBottom: '20px' }} />
                            </div>

                            <div style={{ height: '600px', overflowY: 'scroll' }} class="print">
                                {request_company_check_list}
                                <table className="table">
                                    <tr>
                                    <th className="no-print">View</th>
                                    <SortableTitle name="Internal Request #" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                                    <SortableTitle name="Date Request Submitted" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                                    <SortableTitle name="Paralegal" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                                    <SortableTitle name="Client" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                                    <SortableTitle name="Provider" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                                    <SortableTitle name="Request From Date" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                                    <SortableTitle name="Request To Date" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                                    <th>Request Type</th>
                                    <SortableTitle name="Status" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                                    </tr>
                                    {
                                        this.state.loading == true ?  <img style={{ width: '100px' , position:"absolute" , left: '50%' , top:"70%"}} src={'/static/images/loading.gif'} /> : request_display
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>;
        }
        else if (this.state.loaded) {
            var requests = [];

            if (!this.state.requests_by_lawyer[this.state.selectedUser]) {
                var lawyer_requests = { 'requests': [], name: this.props.user.first_name + ' ' + this.props.user.last_name };
            }
            else {
                var lawyer_requests = this.state.requests_by_lawyer[this.state.selectedUser]
            }
            var now = new Date()
            for (var index in lawyer_requests['requests']) {
                var request = lawyer_requests['requests'][index]
                var patient = this.state.patient_dict[request['patient_id']]
                var patient_name = patient.first_name.trim() + ' ' + patient.last_name.trim();
                patient_name.replace('\n', '')
                if (request.active == true) {
                    if (this.state.patient_filter == '' || patient_name.toLowerCase().indexOf(this.state.patient_filter.toLowerCase()) > -1) {
                        if (this.state.provider_filter == '' || request.provider.name.toLowerCase().indexOf(this.state.provider_filter.toLowerCase()) > -1) {
                            if (this.state.status_filter == '' || request.status.indexOf(this.state.status_filter) > -1) {
                                requests.push(<tr>
                                    <td><Button type='primary' text='View' href={"/request/" + request.id + '/'} target={'_blank'} /></td>
                                    <td>{patient_name}</td>
                                    <td>{request.provider.name}</td>
                                    <td>{request.status}</td>
                                </tr>)
                            }
                        }
                    }
                }
            }
            //<td>{Math.floor((now.getTime() - Date.parse(request['created_at']))/1000/60/60/24)} days</td>
            var status_options = [
                { 'text': 'Requested By Attorney', value: 'Requested By Lawyer' },
                { 'text': 'Sent the Initial Medical Records Request', value: 'Sent the Initial Medical Records Request' },
                { 'text': 'In Contact With Provider', 'value': 'In Contact With Provider' },
                { 'text': 'Provider Is Sending Documents', 'value': 'Provider Is Sending Documents' },
                { 'text': 'Need Assistance', value: 'Need Assistance' },
                { 'text': 'Completed - Documents Arrived', 'value': 'Completed - Documents Arrived' },
                { 'text': 'Completed - No Documents Available', 'value': 'Completed - No Documents Available' },
                { 'text': 'Closed - Documents Arrived', 'value': 'Closed - Documents Arrived' },
                { 'text': 'Closed - No Documents Available', 'value': 'Closed - No Documents Available' },
                { 'text': 'Closed - Cancelled Request', value: 'Closed - Cancelled Request' },
            ]

            var componentList = [TextInput, TextInput, Select]
            var patient_filter = { 'name': 'patient_filter', label: 'Client' }
            var provider_filter = { 'name': 'provider_filter', label: 'Provider' }
            var status_filter = { 'name': 'status_filter', label: 'Status', options: status_options }


            var filter_form = <Form row={true} col_size={4} components={componentList} defaults={this.state} componentProps={[patient_filter, provider_filter, status_filter]}
                setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'filter'} />

            var go_back = null;
            if (this.props.user.is_staff) {
                var go_back = <Button type={'info'} onClick={() => this.setState({ selectedUser: '' })} text={'Go Back'} />
            }

            var edit_request_link = '/editRequest/' + this.props.user.company_id + "/"
            if (this.props.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684' && this.props.company_id) {
                edit_request_link = '/editRequest/' + this.props.company_id + "/"
            }

            var content =
                <div className="container">
                    <Header size={2} text={'Requests By ' + lawyer_requests['name']} />
                    <p onClick={this.download_files} style={{ color: 'blue', cursor: 'pointer' }}>Download All Files: {this.state.files_to_download.length}</p>
                    {go_back}
                    <Button href={edit_request_link} type='success' text='Start New Request' />
                    <div style={{ marginBottom: '40px' }}></div>
                    {filter_form}
                    <table className="table">
                        <tr>
                            <th>View</th>
                            <th>Client</th>
                            <th>Provider</th>
                            <th>Status</th>
                        </tr>
                        {requests}
                    </table>
                </div>;

        }

        return (
            <Wrapper loaded={this.state.loaded} content={content} />
        );
    }
}

export default RequestList;

import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Header, Button} from 'library';

class TopContent extends React.Component {

    render() {
        var style = {'backgroundImage':'url("' + this.props.image + '")',
                      height:'500px'}

        var header = <h1 style={{'color':'white', fontSize: this.props.fontSize, fontWeight:this.props.fontWeight, letterSpacing:'-3px'}}>{this.props.quote}</h1>
        var paragraph = <p style={{'fontSize':'14px', fontWeight:'bold', color:'white', marginTop:'40px', letterSpacing:'10px'}}>{this.props.who}</p>

        var order = [];
        if (this.props.headerTop == true || this.props.headerTop == 'true') {
          order = [header, paragraph]
        }
        else {
          order = [paragraph, header]
        }

        return (
          <div>
          <section style={style}>
            <div style={{height:'100%', width:'100%', backgroundColor:'rgba(0,0,0,.5)', display:'flex', alignItems:'center'}}>
              <div className="container" style={{'textAlign':'center'}}>
                <p style={{"fontSize": "30px", "fontWeight": "bold", "color": "white", "margin": "40px"}}>We are Document and Record Retrieval Experts</p>
                {order}
              </div>
            </div>
          </section>
          </div>
        );
    }
}


export default TopContent;

import React, { Component } from 'react';
import resolveVariables from 'base/resolver.js';
import Image from '../displayComponents/image.js';
import ajaxWrapperFile from 'base/ajaxFile.js';
import ajaxWrapper from "base/ajax.js";

import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import Card from '../displayComponents/card.js';

class FileInput extends Component {

      constructor(props) {
        super(props);
        this.state = {
          files:null,
          uploaded_files:[],
        }

        this.onChange = this.onChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.fileUploadCallback = this.fileUploadCallback.bind(this);
        this.clear = this.clear.bind(this);
      }

      onChange(e) {
        this.setState({files:e.target.files}, this.fileUpload(e.target.files))
      }

      fileUpload(files) {
        var url = '/api/photoUpload/';
        if (this.props.uploadUrl){
            url = this.props.uploadUrl;
        }

        const formData = new FormData();
        for (var index in files) {
            if (index != 'length' && index != 'item') {
                formData.append('files[]',files[index]);
            }
        }
        formData.append('name', this.props.name);
        formData.append('bucket_name', this.props.bucket_name);
        ajaxWrapperFile("POST", url, formData, this.fileUploadCallback);

        this.props.setFormState({uploading_from_file_input:true})
      }

      fileUploadCallback(value) {
        console.log("Value", value)
        var newState = {uploading_from_file_input: false}
        newState[this.props.name] = value['uploaded_files'][0]['url']
        console.log("New State", newState)
        this.props.setFormState(newState)
      }

      clear() {
          var newState = {}
          newState[this.props.name] = ''
          this.props.setFormState(newState);
      }

    render() {

        if (this.props.multiple == true) {
            var input = <input onChange={this.onChange} type="file" className="form-control-file" id="exampleFormControlFile1" name={this.props.name} multiple />
        } else {
            var input = <input onChange={this.onChange} type="file" className="form-control-file" id="exampleFormControlFile1" name={this.props.name} />
        }

        var current = null;
        if (this.props.value != '') {
          current = <div>
                        <a href={this.props.value} target='_blank'>Currently Uploaded File</a><br/>
                        <a onClick={this.clear} style={{color:'red'}}>Clear File</a>
                        </div>
        }

        return (


           <div className="form-group">
            <label htmlFor="exampleFormControlFile1">{this.props.label}</label>
            <br />
            {current}
            {input}
          </div>

        )
    }
}

export default FileInput;

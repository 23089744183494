import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Header} from 'library';

class WhiteSpace extends React.Component {

    render() {
        return (
          <div style={{height:this.props.height}}></div>
        );
    }
}


export default WhiteSpace;


import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import format_date_string from 'base/date_string_format.js';
import {Container, Button, Image, Form, DateTimePicker, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText, NumberInput, NumberInputDisabled, FormWithChildren, Select, FileInput, Alert} from 'library';

class ListBillings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: true,
            title: '',
            company: '',
            status: '',
            billings: []
        }
        this.filterBillings = this.filterBillings.bind(this)
        this.billingCallback = this.billingCallback.bind(this)
        this.setGlobalState = this.setGlobalState.bind(this)
        this.mark_as_paid = this.mark_as_paid.bind(this)
    }

    componentDidMount() {
        var company_filter = '';
        if(this.props.user.company_id != 'e4f3c0a1-5e41-4541-98e4-8288353c7684'){
            company_filter = '&company__id=' + this.props.user.company_id;
        }
        ajaxWrapper('GET',`/api/home/billing/?related=company${company_filter}&order_by=-created_at`, {}, this.billingCallback);
    }

    setGlobalState(name, state) {
        this.setState(state)
    }

    billingCallback(res){
        this.setState({billings: res});
    }

    filterBillings(row){

        var billing_title = row.billing.title ? row.billing.title.toLowerCase() : 'untitled';
        var state_title = this.state.title.toLowerCase() ;

        var billing_is_paid = row.billing.is_paid;
        var state_is_paid = this.state.status ? this.state.status == 'paid' : '';

        var billing_company = row.billing.company.id;
        var state_company = this.state.company;

        if(state_title !== '' && billing_title.includes(state_title) == false ){
            return false;
        }
        if(state_is_paid !== '' && billing_is_paid != state_is_paid){
            return false;
        }
        if(state_company !== '' && billing_company != state_company){
            return false;
        }

        return true;
    }

    mark_as_paid(billing){
        if(this.props.user.company_id != 'e4f3c0a1-5e41-4541-98e4-8288353c7684'){
            return;
        }
        
        if( window.confirm('Are you sure you want to mark billing as paid') == false ){
            return;
        }
        
        this.setState((prevState)=>({
            billings: prevState.billings.map(iter=>{
                if(iter.billing.id == billing.id){
                    iter.billing.is_paid = true;
                    iter.billing.paid_at = new Date();
                }
                return iter;
            })
        }))
        
        ajaxWrapper('POST',`/api/home/billing/${billing.id}/`, {is_paid: true}, console.log);
        
    }

    render(){
        
        var companies = {}
        var company_options = []
        this.state.billings.forEach(row=>{
            companies[row.billing.company.id] = {
                text: row.billing.company.name,
                value:row.billing.company.id
            }
        });

        company_options = Object.values(companies);

        var status_options = [
            {'text':'Paid', value:'paid'},
            {'text':'Not Paid', value:'not_paid'}
        ]

        var title_filter = {'name':'title', label:'Title'};
        var company_filter = {'name':'company', label:'Law Firm', options: company_options};
        var status_filter = {'name':'status', label:'Status', options: status_options};

        var company_filter_input = <div className='col-sm-4'></div>
        if(this.props.user.company_id == 'e4f3c0a1-5e41-4541-98e4-8288353c7684'){
            company_filter_input = <Select {...company_filter} layout={'col-sm-4'} />
        }
        
        var filters = <div style={{margin: 'auto', width: '80%'}}>
            <FormWithChildren autoSetGlobalState={true} globalStateName={'filter_form'} defaults={{}} className={'row'} setGlobalState={this.setGlobalState} >
                <TextInput {...title_filter} layout={'col-sm-4'} />
                {company_filter_input}
                <Select {...status_filter} layout={'col-sm-4'} />
            </FormWithChildren>
        </div>
        
        var table = <table className='table' style={{margin: 'auto'}}>
            <thead>
                <th width='30%'>Title</th>
                <th width='20%'>Company</th>
                <th className='text-center' width='20%'>Status</th>
                <th className='text-center' width='15%'>Created At</th>
                <th className='text-center' width='15%'>Paid At</th>
            </thead>
            <tbody>
            {
                this.state.billings.filter(this.filterBillings).map((row)=>(
                    <tr key={ row.billing.id }>
                        <td><a href={`/invoice/${row.billing.company.id}/${row.billing.id}`}>
                            { row.billing.title ? row.billing.title : 'Untitled' }
                        </a></td>
                        <td> { row.billing.company.name } </td>
                        <td className='text-center'>
                        { row.billing.is_paid ?
                            <button className="btn btn-success"> Paid </button>
                            :
                            <button className="btn btn-danger" onClick={() => this.mark_as_paid(row.billing)}> Not Paid </button>
                        } 
                        </td>
                        <td className='text-center'> { row.billing.created_at ? format_date_string(row.billing.created_at, 'mm/dd/yyyy') : '' } </td>
                        <td className='text-center'> { row.billing.is_paid ? format_date_string(row.billing.paid_at, 'mm/dd/yyyy') : '-' } </td>
                    </tr>
                )) 
            }
            </tbody>
        </table>
 
        var content = <div>
            {filters}
            <div style={{padding: '10px'}}></div>
            {table}
        </div>

        return (
            <Wrapper loaded={this.state.loaded}  content={content} />
        );
    }
}

export default ListBillings;
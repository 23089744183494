import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Header, Button} from 'library';

class AboutCTA extends React.Component {

    render() {
        var description = <div className="col-6" style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
        <h2 style={{fontSize:'32px', fontWeight:'bold', letterSpacing:'-3px'}}>
          {this.props.title}
        </h2>
        <p>
          {this.props.description}
        </p>
        <Button style={{whiteSpace:'normal', width:'100%', 'fontWeight':'bold', fontSize:'14px', letterSpacing:'5px', padding: '15px 40px 15px 40px'}} text={this.props.cta} type={'primary'} href={this.props.href} />
        </div>

        var image = <div className="col-6">
          <img src={this.props.image} style={{objectFit:'contain', width:'100%'}} />
        </div>

        var order = [];
        if (this.props.pictureRight == true || this.props.pictureRight == 'true') {
          order = [description, image]
        }
        else {
          order = [image, description]
        }

        return (
          <div className="container" style={{'marginTop':'30px', marginBottom:'30px'}}>
            <div className="row">
              {order}
            </div>
          </div>
        );
    }
}


export default AboutCTA;

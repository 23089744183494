import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import MetaTags from 'react-meta-tags';

import {Container, Button, Image, Form, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header} from 'library';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true
        };
    }

    setGlobalState() {

    }

    render() {

        var fontSize = '75px';
        if (isMobile) {
            fontSize = '50px';
        }

      var content = <div className="root">
        <div className="App">

            <div className="container-fluid" style={{'padding':'0px'}}>
                <div>
                    <div style={{"background": "rgba(0, 0, 0, 0.5)"}}>
                        <section style={{"backgroundImage": 'url("https://static1.squarespace.com/static/5afc55e736099b0c7c374971/t/5baa02ed0d9297259b401dbc/1537868531078/Legal-header-1080x718.jpg?format=1500w")', "height": "830px", "backgroundSize": "cover", "backgroundRepeat": "no-repeat"}}>

                            <div style={{"height": "100%", "width": "100%", "backgroundColor": "rgba(0, 0, 0, 0.7)", "display": "flex", "alignItems": "center"}}>
                                <div class="container" style={{"textAlign": "center"}}>
                <p style={{"fontSize": "30px", "fontWeight": "bold", "color": "white", "margin": "10px"}}>We are Document and Record Retrieval Experts</p>
                <h1 style={{"color": "white", "fontSize": fontSize, "fontWeight": "bold"}}>Our main focus is responsiveness and a fast turnaround time.</h1>
                <p style={{"fontSize": "18px", "fontWeight": "bold", "color": "white", "margin": "40px"}}>Easy to use system. Flat rate per request.  No contracts, start up fees, or monthly minimums!</p>
                <a href='/contact-us/' class="btn btn-primary" style={{"fontWeight": "bold", "fontSize": "14px", "letterSpacing": "5px", "padding": "15px 40px", whiteSpace:'normal'}}>GET STARTED WITH DIRECT DOCS TODAY</a>
                </div>
                </div>
                </section>
                </div>
                </div>
            </div>

            <div><section class="container" style={{"paddingTop": "100px", paddingBottom:'100px'}}>
            <h2 style={{'textAlign':'center', paddingBottom:'50px'}}>Let us handle one of your biggest pain points. Retrieving medical records is our specialty and we're easy to work with.</h2>
            <div class="row" style={{"textAlign": "center"}}>
            <div class="col">
                <i class="fas fa-bolt fa-3x"></i>
                <h3 style={{"fontSize": "15px", "letterSpacing": "2px", "fontWeight": "bold"}}>FAST</h3>
                <p style={{"fontSize": "18px", "fontWeight": "300"}}>Three week average turnaround gets you the records you need, when you need them.</p>
            </div>

            <div class="col">
                <i class="fas fa-tachometer-alt fa-3x"></i>
                <h3 style={{"fontSize": "15px", "letterSpacing": "2px", "fontWeight": "bold"}}>EFFICIENT</h3>
                <p style={{"fontSize": "18px", "fontWeight": "300"}}>Request, retrieve, organize, and store all of your clients documents in one place.</p>
            </div>

            <div class="col">
                <i class="fas fa-user-shield fa-3x"></i>
                <h3 style={{"fontSize": "15px", "letterSpacing": "2px", "fontWeight": "bold"}}>RELIABLE</h3>
                <p style={{"fontSize": "18px", "fontWeight": "300"}}>Your Account Manager is there to help when you need them.</p>
            </div>

            <div class="col">
                <i class="fas fa-lock fa-3x"></i>
                <h3 style={{"fontSize": "15px", "letterSpacing": "2px", "fontWeight": "bold"}}>SECURE</h3>
                <p style={{"fontSize": "18px", "fontWeight": "300"}}>All physical, administrative and technical safeguards required by HIPAA are followed.</p>
            </div>
        </div>
    </section>
</div>


            <div>
                <div style={{"backgroundImage": "linear-gradient(30deg, rgb(57, 87, 255) 0%, rgb(242, 41, 91) 100%)", "paddingTop": "100px", "paddingBottom": "100px"}}>
                    <div class="container" style={{"display": "flex", "justifyContent": "space-between"}}>
                        <h1 style={{"fontWeight": "bold", "color": "white", "paddingRight": "20px", "paddingLeft": "20px"}}>GET STARTED WITH DIRECT DOCS TODAY</h1>
                        <a class="btn btn-outline-light" style={{"whiteSpace": "normal", "fontWeight": "bold", "fontSize": "14px", "padding": "15px 40px", "width": "270px", height:'60px'}} href='/contact-us/'>CONTACT US</a>
                    </div>
                </div>
            </div>
        </div>
      </div>;

        return (
            <Wrapper token={this.props.user_id} loaded={this.state.loaded}  content={content} />
        );
    }
}

export default Home;

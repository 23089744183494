import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Header, Button} from 'library';

class TopContent extends React.Component {

    render() {
        var style = {'backgroundImage':'url("' + this.props.image + '")',
                      height:'830px', backgroundSize:'cover', backgroundRepeat: 'no-repeat'}
                      //backgroundPositionY: '-500px'
        return (
          <div style={{'background':'rgba(0,0,0,.5)'}}>
          <section style={style}>
            <div style={{height:'100%', width:'100%', backgroundColor:'rgba(0,0,0,.7)', display:'flex', alignItems:'center'}}>
              <div className="container" style={{'textAlign':'center'}}>
                <h1 style={{'color':'white', fontSize:'75px', fontWeight:'bold'}}>{this.props.title}</h1>
                <p style={{'fontSize':'18px', fontWeight:'bold', color:'white', margin:'40px'}}>{this.props.description}</p>
                <Button text={this.props.cta} href={this.props.href} type={'primary'} style={{'fontWeight':'bold', fontSize:'14px', letterSpacing:'5px', padding: '15px 40px 15px 40px'}}/>

              </div>
            </div>
          </section>
          </div>
        );
    }
}


export default TopContent;

function sort_by_key(array, key) {
  return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        if (x < y) return -1;
        if (y < x) return 1;
        return 0;
    });
}

function sort_by_key_reverse(array, key) {
  return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        if (x < y) return 1;
        if (y < x) return -1;
        return 0;
    });
}

function sort_by_keys(array, keys, secondary_sort, date) {

  return array.sort(function(a, b) {
        var reverse_primary = false;
        var x = a;
        var y = b;
        for (var index in keys) {
          var key = keys[index];
            if (key.startsWith('-')) {
                key = key.replace('-','')
                reverse_primary = true;
            }


            if (key == 'created_at' || key == 'unedited_reminder_date' || key == 'received_documents' || key == 'downloaded') {
                if (!x[key]) {
                    x = 0;
                }
                else {
                    x = Date.parse(x[key])
                }
                if (!y[key]) {
                    y = 0;
                }
                else {
                    y = Date.parse(y[key])
                }

            }
            else {
                x = x[key]
                y = y[key]
            }

        }


        if (x < y) {
            if (reverse_primary) {
                return 1;
            }
            else {
                return -1;
            }
        }
        if (x > y) {
            if (reverse_primary) {
                return -1;
            }
            else {
                return 1;
            }
        }
        if (x == y) {
            var reverse_secondary = false;
            var c = a;
            var d = b;
            for (var index in secondary_sort) {
                var secondary_key = secondary_sort[index];
                  if (secondary_key.startsWith('-')) {
                      secondary_key = secondary_key.replace('-','')
                      reverse_secondary = true;
                  }

                  if (secondary_key == 'created_at') {
                      c = Date.parse(c[secondary_key])
                      d = Date.parse(d[secondary_key])
                  }
                  else {
                      if (c) {
                          c = c[secondary_key]
                      }
                      if (d) {
                          d = d[secondary_key]
                      }

                  }

            }
            if (c < d) {
                if (reverse_secondary) {
                    return 1;
                }
                else {
                    return -1;
                }
            };
            if (c > d) {
                if (reverse_secondary) {
                    return -1;
                }
                else {
                    return 1;
                }
            };
            return 0;
        }


    });
}

function sort_objects(array, keys, order=false, secondary_sort=[]) {
  if (typeof(keys) != 'string') {
    return sort_by_keys(array, keys, secondary_sort);
  }
  else {
      console.log("Sort Keys", keys, order)
    if (order) {
      return sort_by_key(array, keys)
    }
    else {
      return sort_by_key_reverse(array, keys)
    }
  }
}


export default sort_objects;


import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import { Container, Button, Image, Form, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText, Icon } from 'library';

class ProviderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providers: [], name: '', requests: [],
      page_offset: 1,
      last_page: false,
      total_requests_count: 0,
      limit: 10,
      default_sorting: "Ascending",
      default_name: "status",
      next: "",
      previous: "",
      total_count: 0,
      loader: true,
      page_count: 0,
    }

    this.objectCallback = this.objectCallback.bind(this);
    this.setGlobalState = this.setGlobalState.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handleApi = this.handleApi.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.filterName = this.filterName.bind(this);
  }


  filterName = (e) => {
    this.setState({ name: e.target.value })
    this.handleApi(this.state.limit, this.state.page_offset, e.target.value);
  }

  setGlobalState(name, state) {
    this.setState(state);
  }
  handlePageClick = (event) => {
    this.setState({ page_offset: event.selected + 1 })
    this.handleApi(this.state.limit, event.selected + 1, this.state.name);
  }

  handleApi(limit, page_offset, name) {
    this.setState({ loader: true })
    ajaxWrapper('GET', `/api/providers/?page=${page_offset}&page_size=${limit}&name=${name}`, {}, this.objectCallback);
  }
  componentDidMount() {
    this.handleApi(10, this.state.page_offset, this.state.name)
  }

  handleSorting = (e) => {
    this.setState({ default_sorting: e.target.value });
  };

  handleLimitChange(e) {
    this.setState({ limit: parseInt(e.target.value) });
    if (parseInt(e.target.value) > this.state.total_count) {
      this.setState({ page_offset: 1 });
    }
    if (this.state.page_offset * parseInt(e.target.value) > this.state.total_count) {
      this.setState({ page_offset: parseInt(this.state.total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.total_count / parseInt(e.target.value)) });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.limit !== prevState.limit) {
      this.handleApi(this.state.limit, this.state.page_offset, this.state.name)
    }
    if (this.state.name !== "" && prevState.name !== this.state.name) {
      this.setState({ page_offset: 1 });
      this.handleApi(this.state.limit, 1, this.state.name);
    }
  }

  objectCallback(result) {
    this.setState({ providers: result.results, 'loaded': true })
    this.setState({ next: result.next, loaded: true });
    this.setState({ previous: result.previous, loaded: true });
    this.setState({ total_count: result.count, loaded: true });
    this.setState({ page_count: Math.ceil(this.state.total_count / this.state.limit) })
    this.setState({ loader: false })
  }

  render() {

    var providers = [];
    for (var index in this.state.providers) {
      var provider = this.state.providers[index]["provider"];

      var has_email = <Icon icon={'times-circle'} style={{ color: 'red' }} size={2} />
      if (provider['email'] != '') {
        has_email = <Icon icon={'check-circle'} style={{ color: 'green' }} size={2} />
      }

      var has_phone = <Icon icon={'times-circle'} style={{ color: 'red' }} size={2} />
      if (provider['phone'] != '') {
        has_phone = <Icon icon={'check-circle'} style={{ color: 'green' }} size={2} />
      }

      var has_form = <Icon icon={'times-circle'} style={{ color: 'red' }} size={2} />
      if (provider['medical_request_url'] != '') {
        has_form = <Icon icon={'check-circle'} style={{ color: 'green' }} size={2} />
      }


      providers.push(<tr>
        <td style={{ width: "55%" }}>{provider.name}</td>
        <td>{has_email}</td>
        <td>{has_phone}</td>
        <td>{has_form}</td>
        <td><Button href={'/editProvider/' + provider.id + '/'} type='primary' text='Edit' /></td>
      </tr>)
    }


    var content =
      <div className="container-fluid">
        <Header size={2} text={'Providers'} />
        <br />
        <Button type={'success'} text={'Add New Provider'} href={'/editProvider/'} style={{ marginBottom: '20px' }} />
        <br />
        <label>Name</label>
        <input className='custom-input-filter' name='name' onChange={this.filterName} />
        <br />
        <div style={{
          margin: "20px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center"
        }}>
          <div>
            Total Records : {this.state.total_count}
          </div>
          <div style={{
            display: 'flex',
            alignItems: "center",
            marginLeft: "20px"
          }}>
            {
              this.state.total_count > 0 ?
                <select
                  className="form-control"
                  style={{ width: "85px", marginRight: "5px" }}
                  onChange={this.handleLimitChange}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>40</option>
                  <option>50</option>
                  <option>100</option>
                </select>
                : ""
            }
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={this.handlePageClick}
              pageRangeDisplayed={10}
              marginPagesDisplayed={3}
              pageCount={this.state.page_count}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
              className="custom-pagination"
              forcePage={this.state.page_offset - 1}
            />
          </div>
        </div>
        <table className='table'>
          <tr>
            <th>Name</th>
            <th>Has Email</th>
            <th>Has Phone Number</th>
            <th>Has Form</th>
            <th>Edit</th>
          </tr>

          {providers}
        </table>
      </div>;

    return (
      <Wrapper loaded={this.state.loaded} content={content} />
    );
  }
}

export default ProviderList;


import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import format_date_string from 'base/date_string_format.js';
import {Container, Button, Image, Form, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText} from 'library';

class DiskUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'disk_usage_data':[],
            loaded: false,
        }

        this.diskCallback = this.diskCallback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET','/api/home/diskusage/', {}, this.diskCallback);
    }

    diskCallback(result) {
      var disk_usage_data = []
      for (var index in result) {
        var data = result[index]['diskusage'];
        disk_usage_data.push(data);
      }
      this.setState({'disk_usage_data':disk_usage_data, 'loaded':true});
    }


    render() {

      var rows = [];
      for (var index in this.state.disk_usage_data) {
          var data = this.state.disk_usage_data[index];
          rows.push(<tr>
            <td>{format_date_string(data.created_at,'mm/dd/yyyy HH:MM')}</td>
            <td>{(data.total/1000000000).toFixed(2)} gb</td>
            <td>{(data.free/1000000000).toFixed(2)} gb</td>
            <td>{(data.used/1000000000).toFixed(2)} gb</td>
          </tr>);
      }

      var content =
        <div className="container-fluid">
          <Header size={2} text={'Data Usage'} />
          <br />

          <table className="table">
            <tbody>
            <tr>
              <th>Date</th>
              <th>Total</th>
              <th>Free</th>
              <th>Used</th>
            </tr>

              {rows}
              </tbody>
          </table>

        </div>;

        return (
            <Wrapper loaded={this.state.loaded} content={content} />
        );
    }
}

export default DiskUsage;

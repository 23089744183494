import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import sort_objects from 'base/sort_objects.js';
import {Container, Button, Image, Form, TextInput, Navbar, List, Select, Link,
    Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText,
    PageBreak, ListWithChildren, FormWithChildren} from 'library';
import format_date from 'base/date_format.js';
import format_date_string from 'base/date_string_format.js';
import DataTable from "./data_table";

export default class EmployeeDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {'view':'Dashboard', data_table_type:''}

        this.back = this.back.bind(this);
        this.back_to_dd = this.back_to_dd.bind(this);
        this.see_table = this.see_table.bind(this);
    }

    back() {
        this.setState({view:'Dashboard'})
    }

    back_to_dd() {
        this.props.back();
    }

    see_table(type) {
        this.setState({data_table_type: type,view:'Table'})
    }


    render() {
        var employee = this.props.employee;

        var total_requests = employee.total_employee_requests;
        
        var rush_requests = employee.rush_requests;
        var new_requests = employee.new_requests;
        
        var need_your_attention_requests = employee.need_your_attention_requests;
        var request_older_than_five = employee.requests_five_days_old;
        var request_older_than_ten = employee.requests_ten_days_old;
        var request_older_than_fifteen = employee.requests_fifteen_days_old;

        var note_older_than_five = employee.note_five_days_old;
        var note_older_than_ten = employee.note_ten_days_old;
        var note_older_than_fifteen = employee.note_fifteen_days_old;

        var todays_reminders = employee.today_reminder_requests;
        var old_reminders = employee.old_reminder_requests;

        var all_requests = employee.all_requests;


        if (this.state.view == 'Table') {
            var lookup = {
                all_requests:all_requests,
                new_requests: new_requests,
                rush_requests: rush_requests,
                need_your_attention_requests: need_your_attention_requests,
                request_older_than_five: request_older_than_five,
                request_older_than_ten: request_older_than_ten,
                request_older_than_fifteen: request_older_than_fifteen,
                note_older_than_five: note_older_than_five,
                note_older_than_ten: note_older_than_ten,
                note_older_than_fifteen: note_older_than_fifteen,
                todays_reminders: todays_reminders,
                old_reminders: old_reminders,
            }

            var name_lookup = {
                new_requests: "New Requests",
                rush_requests: "Rush Requests",
                need_your_attention_requests: "Need Your Attention",
                request_older_than_five: "Older Than 5 days",
                request_older_than_ten: "Older Than 10 days",
                request_older_than_fifteen: "Older Than 15 days",
                all_requests: "All Requests",
                note_older_than_five: "Note Older Than 5 Days",
                note_older_than_ten: "Note Older Than 10 Days",
                note_older_than_fifteen: "Note Older Than 15 Days",
                todays_reminders: "Today's Reminders",
                old_reminders: "Old Reminders",
            }

            var data = lookup[this.state.data_table_type]
            var content = <DataTable back={this.back} refreshData={this.props.refreshData} name={employee.name + ': ' + name_lookup[this.state.data_table_type]} user={this.props.user} type={this.state.data_table_type} employee={employee} closed={this.props.closed} />
        }
        else {
            var back_button = null;

            if (this.props.user.is_staff) {
                back_button = <Button text='Back To Direct Docs Report' onClick={this.back_to_dd} type='info' />;
            }

            var content = <div className="container-fluid">
                <h2>{employee.name} Dashboard</h2>
                {back_button}

                <div style={{height:'50px'}}></div>
                <div className="row">
                    <div className="col-12">
                        <h3>Totals</h3>
                        <div style={{'width':'100%', 'borderBottom':'1px solid #ccc', 'margin':'0px 0px 50px 0px'}}></div>
                    </div>
                    <div className="card fancy_card col-md-3 col-sm-12">
                        <h4><a onClick={() => this.see_table("all_requests")} style={{color:'#000094', textDecoration:'underline'}}>Total DD Requests</a></h4>
                        <h1>{total_requests}</h1>
                    </div>
                    <div className="col-1"></div>
                    <div className="fancy_card card col-md-3 col-sm-12">
                        <h4>Average Turnaround Time</h4>
                        <h1>{(employee['total_turnaround']/employee['total_received']).toFixed(2)} Days</h1>
                    </div>
                    <div className="col-1"></div>


                    <div className="col-12" style={{marginTop:'50px'}}>
                        <h3>Requests</h3>
                        <div style={{'width':'100%', 'borderBottom':'1px solid #ccc', 'margin':'0px 0px 50px 0px'}}></div>
                    </div>

                    <div className="card fancy_card col-md-2 col-sm-12">
                        <h4><a onClick={() => this.see_table("new_requests")} style={{color:'#000094', textDecoration:'underline'}}>New Requests</a></h4>
                        <h1>{new_requests}</h1>
                    </div>
                    <div className="col-1"></div>
                    <div className="fancy_card card col-md-2 col-sm-12">
                        <h4><a onClick={() => this.see_table("rush_requests")} style={{color:'#000094', textDecoration:'underline'}}>Rush Requests</a></h4>
                        <h1>{rush_requests}</h1>
                    </div>
                    <div className="col-1"></div>
                    <div className="fancy_card card col-md-2 col-sm-12">
                        <h4><a onClick={() => this.see_table("need_your_attention_requests")} style={{color:'#000094', textDecoration:'underline'}}>Needs Your Attention</a></h4>
                        <h1>{need_your_attention_requests}</h1>
                    </div>

                    <div className="col-2"></div>
                    <div className="fancy_card card col-md-2 col-sm-12">
                        <h4><a onClick={() => this.see_table("request_older_than_five")} style={{color:'#000094', textDecoration:'underline'}}>Requests {'>'} 5 Days</a></h4>
                        <h1>{request_older_than_five}</h1>
                    </div>

                    <div className="col-1"></div>
                    <div className="fancy_card card col-md-2 col-sm-12">
                        <h4><a onClick={() => this.see_table("request_older_than_ten")} style={{color:'#000094', textDecoration:'underline'}}>Requests {'>'} 10 Days</a></h4>
                        <h1>{request_older_than_ten}</h1>
                    </div>

                    <div className="col-1"></div>
                    <div className="fancy_card card col-md-2 col-sm-12">
                        <h4><a onClick={() => this.see_table("request_older_than_fifteen")} style={{color:'#000094', textDecoration:'underline'}}>Requests {'>'} 15 Days</a></h4>
                        <h1>{request_older_than_fifteen}</h1>
                    </div>

                    <div className="col-3"></div>
                    <div className="fancy_card card col-md-2 col-sm-12">
                        <h4><a onClick={() => this.see_table("note_older_than_five")} style={{color:'#000094', textDecoration:'underline'}}>Note {'>'} 5 Days</a></h4>
                        <h1>{note_older_than_five}</h1>
                    </div>

                    <div className="col-1"></div>
                    <div className="fancy_card card col-md-2 col-sm-12">
                        <h4><a onClick={() => this.see_table("note_older_than_ten")} style={{color:'#000094', textDecoration:'underline'}}>Note {'>'} 10 Days</a></h4>
                        <h1>{note_older_than_ten}</h1>
                    </div>

                    <div className="col-1"></div>
                    <div className="fancy_card card col-md-2 col-sm-12">
                        <h4><a onClick={() => this.see_table("note_older_than_fifteen")} style={{color:'#000094', textDecoration:'underline'}}>Note {'>'} 15 Days</a></h4>
                        <h1>{note_older_than_fifteen}</h1>
                    </div>

                    <div className="col-3"></div>
                    <div className="fancy_card card col-md-2 col-sm-12">
                        <h4><a onClick={() => this.see_table("todays_reminders")} style={{color:'#000094', textDecoration:'underline'}}>Today's Reminders</a></h4>
                        <h1>{todays_reminders}</h1>
                    </div>

                    <div className="col-1"></div>
                    <div className="fancy_card card col-md-2 col-sm-12">
                        <h4><a onClick={() => this.see_table("old_reminders")} style={{color:'#000094', textDecoration:'underline'}}>Old Reminders</a></h4>
                        <h1>{old_reminders}</h1>
                    </div>
                </div>
            </div>
        }

        return (
                <Wrapper loaded={true} content={content} />

        )
    }

}

import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Header, Button} from 'library';

class CTA1 extends React.Component {

    render() {
        return (
          <div className="container" style={{'textAlign':'center', marginTop:'200px', marginBottom:'200px'}}>
            <h2 style={{'fontSize':'14px', fontWeight:'600', letterSpacing:'8px', color:'#888', margin:'20px'}}>
              {this.props.lead}
            </h2>
            <h2 style={{'fontSize':'52px', fontWeight:'bold', letterSpacing:'-2px', margin:'20px'}}>
              {this.props.title}
            </h2>
            <p style={{color:'#7f7f7f', fontSize:'16px', fontWeight:'400', margin:'20px'}}>
              {this.props.description}
            </p>
            <Button type="primary" text={this.props.cta} href={this.props.href} style={{'fontWeight':'bold', fontSize:'14px', letterSpacing:'5px', padding: '15px 40px 15px 40px'}}/>
          </div>
        );
    }
}


export default CTA1;

import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import {Header} from 'library';

class Service extends React.Component {

    render() {
        var description = <div className="col-6" style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
        <h2 style={{fontSize:'32px', fontWeight:'normal'}}>
          {this.props.title}
        </h2>
        <p>
          {this.props.description}
        </p>
        </div>

        var image = <div className="col-6">
          <img src={this.props.image} style={{'width':'95%', objectFit:'contain',
          padding:'5px', boxShadow: '10px 10px 5px 0px rgba(161,161,161,1)'}} />
        </div>

        var order = [];
        if (this.props.pictureRight == true || this.props.pictureRight == 'true') {
          order = [description, image]
        }
        else {
          order = [image, description]
        }

        return (
          <div className="container" style={{'marginTop':'30px', marginBottom:'30px'}}>
            <div className="row">
              {order}
            </div>
          </div>
        );
    }
}


export default Service;

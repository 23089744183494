import React, { Component } from 'react';
import EmployeeTable from './employee_table'
import Wrapper from 'base/wrapper.js';
import {Button} from "library";


export default class EmployeeLast extends Component {
    constructor(props) {
        super(props);
    }



    render() {
        var content = <EmployeeTable back={this.back} refreshData={this.props.refreshData} name={this.props.employee.name} user={this.props.user} type={this.props.type} employee={this.props.employee} employees={this.props.employees} closed={this.props.closed} />
        return (
            <>
                <Wrapper loaded={true} content={content} />
            </>

        )
    }

}


import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import {Alert, Container, Button, Image, Form, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText, DateTimePicker} from 'library';
import format_date from 'base/date_format.js';

class Request extends Component {
    constructor(props) {
        super(props);
        this.state = {notes:[], 'created_at' : '', 'updated_at' : '', 'user' : '', 'start_date' : '', 'end_date' : '', 'rush' : 'false', 'status' : '', 'provider' : '', 'request_type' : '', 'special_instructions' : ''}

        this.objectCallback = this.objectCallback.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    refresh() {
        ajaxWrapper('GET','/api/home/request/' + this.props.request_id + '/?related=user,notes,notes__user,provider,patient,patient__company', {}, this.objectCallback);
    }

    componentDidMount() {
      this.refresh()
    }

    objectCallback(result) {
      var request = result[0]['request'];
      request['loaded'] = true;
      this.setState(request)
    }

    render() {

      var medical_request_url = '/medical_request/' + this.props.request_id + '/'
      if (this.state.provider.medical_request_url != '') {
        medical_request_url = this.state.provider.medical_request_url
      }

      var content = null;
      if (this.state.loaded) {
        var content =
          <div className="container">
            <div className="no-print">
              <Button type={'primary'} text={'Print'} onClick={() => window.print()} />
            </div>
            <Header size={4} text={'Medical Records Request For ' + this.state.patient.first_name + ' ' + this.state.patient.last_name + ' on behalf of ' + this.state.patient.company.name} />
            <p>We are reaching out to get medical records for a patient of yours. Please send over as soon as you can as this pertains to a lawsuit in progress.</p>
          </div>;
      }

        return (
            <Wrapper loaded={this.state.loaded}  content={content} />
        );
    }
}

export default Request;

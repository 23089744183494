import React, { Component } from 'react';
import resolveVariables from 'base/resolver.js';

class NavLink extends React.Component {
    constructor(props) {
      super(props);
      this.state = {hover: false}

      this.mouseEnter = this.mouseEnter.bind(this);
      this.mouseLeave = this.mouseLeave.bind(this);
    }

    mouseEnter() {
      this.setState({hover:true})
    }

    mouseLeave() {
      this.setState({hover:false})
    }

    render() {
      var target = '_self';
      if (this.props.target) {
        target= this.props.target;
      }

      var selected = false;
      console.log("Selected" ,this.props.href, window.location.pathname)
      if (window.location.pathname == this.props.href) {
        selected = true;
      }
      console.log("Selected Resolved", selected)

      var style = this.props.style;
      if (this.state.hover || selected) {
        var style = this.props.hoverStyle || this.props.style;
      }




      return (
        <li class="nav-item" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} style={{'margin-right':'20px'}}>
          <a class="nav-link" href={this.props.href} style={style || {}}>
            {this.props.text}
          </a>
        </li>
      );
    }
}


export default NavLink;


import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import {Container, Button, Image, Form, TextInput, Navbar, List, Select, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText, PageBreak} from 'library';
import format_date_string from 'base/date_string_format.js';
import sort_objects from 'base/sort_objects.js';

class AllRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {'company_filter':'', requester_filter:'', 'requests':[], reminders:[],  lawyer_filter: '', patient_filter: '', provider_filter:'', status_filter: '', companies:[], companyLookup:{}, request_status_filter: 'open', client_sorted: true, created_at_sorted: true, recent_sort: 'client'}

        this.setGlobalState = this.setGlobalState.bind(this);
        this.client_sort = this.client_sort.bind(this);
        this.created_at_sort = this.created_at_sort.bind(this);
    }

    setGlobalState(name, state) {
      this.setState(state)
    }

    client_sort() {
        console.log("CLicked client sort")
        this.setState({client_sorted: !this.state.client_sorted, recent_sort:'client'})
    }

    created_at_sort() {
        this.setState({created_at_sorted: !this.state.created_at_sorted, recent_sort:'date'})
    }

    render() {
      var todo_request = [];
      var all_requests = [];
      var content = null;




      var sort_patient = ["patient","last_name"]
      if (!this.state.client_sorted) {
          sort_patient = ["-patient","last_name"]
      }

      var sort_date = ["created_at"]
      if (!this.state.created_at_sorted) {
          sort_date = ["-created_at"]
      }

      if (this.state.recent_sort == 'client') {
          var sort_one = sort_patient
          var sort_two = sort_date
      }
      else {
          var sort_one = sort_date
          var sort_two = sort_patient

      }


      var prop_requests = sort_objects(this.props.requests, sort_one, true, sort_two);


        var today = new Date()

        for (var index in prop_requests) {

          var request = prop_requests[index];
          var patient = request.patient;
          var patient_name = patient.first_name + ' ' + patient.last_name
          var lawyer_name = request.user.first_name + ' ' + request.user.last_name;
          var requester_name = request.requester.first_name + ' ' + request.requester.last_name;
          var rush = '';
          if (request.rush) {
              rush = 'Rush'
          }

          var company = this.props.companyLookup[request.user.company_id].name;
          console.log("Company", company, this.state.company_filter)
          if (this.state.company_filter == '' || company == this.state.company_filter) {
          if (this.state.patient_filter == '' || patient_name.toLowerCase().indexOf(this.state.patient_filter.toLowerCase()) > -1) {
            if (this.state.provider_filter == '' || request.provider.name.toLowerCase().indexOf(this.state.provider_filter.toLowerCase()) > -1) {
              if (this.state.status_filter == '' || request.status.indexOf(this.state.status_filter) > -1) {
                if (this.state.lawyer_filter == '' || lawyer_name.toLowerCase().indexOf(this.state.lawyer_filter.toLowerCase()) > -1) {
                    if (this.state.requester_filter == '' || requester_name.toLowerCase().indexOf(this.state.requester_filter.toLowerCase()) > -1) {

                            var backgroundColor = '#fff';
                            if (rush == 'Rush') {
                                backgroundColor = '#ff8f8f'
                            }
                            all_requests.push(<tr style={{backgroundColor: backgroundColor}}>
                              <td><a href={"/request/" + request.id + '/'}>View</a></td>
                              <td>{company}</td>
                              <td>{request.patient.first_name} {request.patient.last_name}</td>
                              <td>{request.provider.name}</td>
                              <td>{request.user.first_name} {request.user.last_name}</td>
                              <td>{request.requester.first_name} {request.requester.last_name}</td>
                              <td>{request.status}</td>
                              <td>{rush}</td>
                              <td>{format_date_string(request.created_at, 'mm/dd/yyyy')}</td>
                              </tr>)

                    }
                }
              }
            }
          }
      }


        }

        var status_options = [
          {'text':'Initial Request Sent', value:'Initial Request Sent'},
          {'text': 'In Contact With Provider', 'value': 'In Contact With Provider'},
          {'text': 'Documents en Route', 'value': 'Documents en Route'},
          {'text': 'Need Assistance', value: 'Need Assistance'},
          {'text': 'Completed - Documents Arrived', 'value': 'Completed - Documents Arrived'},
          {'text': 'Completed - No Documents Available', 'value': 'Completed - No Documents Available'},
          {'text': 'Closed - Documents Arrived', 'value': 'Closed - Documents Arrived'},
          {'text': 'Closed - No Documents Available', 'value': 'Closed - No Documents Available'},
          {'text': 'Closed - Cancelled Request', 'value': 'Closed - Cancelled Request'},
          {'text': 'All Closed', value: 'Closed'},
        ]


        var company_options = []
        for (var index in this.props.companyLookup) {
            var company = this.props.companyLookup[index]
            company_options.push({'text':company.name, 'value':company.name})
        }

          var componentList = [Select, TextInput, TextInput, TextInput, TextInput, Select]
          var patient_filter = {'name':'patient_filter', label:'Client'}
          var lawyer_filter = {'name':'lawyer_filter', label:'Paralegal'}
          var provider_filter = {'name':'provider_filter', label:'Provider'}
          var status_filter = {'name':'status_filter', label:'Status', options: status_options}
          var company_filter = {'name':'company_filter', label:'Law Firm', options: company_options}
          var requester_filter = {'name':'requester_filter', label:'Requestor'}


          var filter_form = <Form row={true} autoFocus={false} col_size={2} components={componentList} defaults={this.state} componentProps={[company_filter, patient_filter, provider_filter, lawyer_filter, requester_filter, status_filter]}
            setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'filter'} />

        var client_sort = 'Asc'
        if (!this.state.client_sorted) {
            client_sort = 'Desc'
        }

        var created_at_sort = 'Asc'
        if (!this.state.created_at_sorted) {
            created_at_sort = 'Desc'
        }

        var content = <div>
            {filter_form}
            <table className="table">
              <tr>
                <th>View</th>
                <th>Law Firm</th>
                <th><div onClick={this.client_sort} style={{pointer:'cursor'}}>Client ({client_sort})</div></th>
                <th>Provider</th>
                <th>Paralegal</th>
                <th>Requestor</th>
                <th>Status</th>
                <th>Rush?</th>
                <th onClick={this.created_at_sort} style={{pointer:'cursor'}}>Created At Date ({created_at_sort})</th>
              </tr>
            {all_requests}
            </table>
          </div>;


        return (
            <Wrapper loaded={true}  content={content} />
        );
    }
}

export default AllRequests;

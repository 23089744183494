import React, { Component } from "react";

import Wrapper from "base/wrapper.js";
import ajaxWrapper from "base/ajax.js";
import sort_objects from "base/sort_objects.js";
import convertFunction from '../../base/timezone_conversion'
import {
  Container,
  Button,
  Image,
  Form,
  TextInput,
  Navbar,
  List,
  Select,
  Link,
  Accordion,
  Paragraph,
  RadioButton,
  TextArea,
  Header,
  Card,
  MultiLineText,
  PageBreak,
  ListWithChildren,
  FormWithChildren,
  EmptyModal,
} from "library";
import format_date from "base/date_format.js";
import format_date_string from "base/date_string_format.js";


class Request extends Component {
  constructor(props) {
    super(props);

    this.state = { assign: false };
    this.state = { 'view': 'Dashboard', data_table_type: '' }

    this.toggle_assign = this.toggle_assign.bind(this);
    this.submit = this.submit.bind(this);
    this.submit_callback = this.submit_callback.bind(this);
    this.releaseTask = this.releaseTask.bind(this);
  }

  toggle_assign() {
    this.setState({ assign: !this.state.assign });
  }

  releaseTask() {
    ajaxWrapper(
      "POST",
      "/api/home/request/" + this.props.request.id + "/",
      { working_on: null },
      this.submit_callback
    );
  }

  submit(state) {
    ajaxWrapper(
      "POST",
      "/api/home/request/" + this.props.request.id + "/",
      { working_on: state.assignee },
      this.submit_callback
    );
  }

  submit_callback() {
    this.props.refreshPage();
    this.props.refreshData();
    this.setState({ assign: false });
  }

  render() {
    var request = this.props.request;

    var patient = request.patient;
    var patient_name = patient.full_name;

    request.next_todo_date = request.next_todo_date ? format_date_string(request.next_todo_date, "mm/dd/yyyy") : "";

    var lawyer_name = "";
    if (request.user) {
      lawyer_name = request.user.full_name;
    }

    var closed_date = ""
    var completed_date = ""
    if (request.user) {
      completed_date = request.completed_date;
    }
    if (request.user) {
      closed_date = request.closed_date;
    }

    var requester_name = "";
    if (request.requester) {
      requester_name = request.requester.full_name;
    }

    var rush = "";
    if (request.rush) {
      rush = "Rush";
    }
    var company = request.patient.company.name;

    var working_button = (
      <Button type="success" text="Assign" onClick={this.toggle_assign} />
    );

    if (
      request.working_on &&
      request.working_on.unicode != "None" &&
      this.props.user
    ) {
      if (
        this.props.user.id == "3f46c2b5-4a69-47de-9df0-cdd898658c30" ||
        request.working_on.id == this.props.user.id
      ) {
        var working_button = (
          <Button type="danger" text="Release" onClick={this.releaseTask} />
        );
      } else {
        var working_button = (
          <p>
            {request.working_on.first_name} {request.working_on.last_name}
          </p>
        );
      }
    }

    if (this.state.assign) {
      return (
        <EmptyModal show={true} onHide={this.toggle_assign}>
          <h4>
            Request For: {patient_name} at {request.provider.name}
          </h4>
          <Form
            submit={this.submit}
            defaults={{ assignee: "" }}
            components={[Select]}
            componentProps={[
              {
                required: true,
                label: "Who do you want to assign this to?",
                optionsUrl:
                  "/api/user/user/?company_id=e4f3c0a1-5e41-4541-98e4-8288353c7684",
                optionsUrlMap: {
                  text: "{user.first_name} {user.last_name}",
                  value: "{user.id}",
                },
                name: "assignee",
              },
            ]}
          />
        </EmptyModal>
      );
    }

    var provider_name = "";
    if (request.provider) {
      provider_name = request.provider.name;
    }

    return (
      <tr>
        <td>{request.internal_number ? request.internal_number : "--"}</td>
        <td>{request.status ? request.status : "--"}</td>
        <td>{requester_name ? requester_name : "--"}</td>
        <td>{completed_date ? format_date_string(completed_date, "mm/dd/yyyy") : "--"}</td>
        <td>{closed_date ? format_date_string(closed_date, "mm/dd/yyyy") : "--"}</td>
        <td>{patient_name ? patient_name : "--"}</td>
      </tr>
    );
  }
}

class SortableTitle extends Component {
  constructor(props) {
    super(props);

    this.sort = this.sort.bind(this);
  }

  sort() {
    this.props.sort(this.props.name);
  }

  render() {
    var sorting = null;
    if (this.props.name == this.props.sort_by) {
      sorting = <span>({this.props.direction})</span>;
    }

    return (
      <th style={{ pointer: "cursor" }}>
        <a onClick={this.sort}>
          {this.props.name} {sorting}
        </a>
      </th>
    );
  }
}


export default class EmployeeTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patient: "",
      lawyer: "",
      provider: "",
      status: "",
      company: "",
      requester: "",
      claimed_by: "",
      sort_by: "",
      employees_data: "",
      requests: [],
      direction: "Ascending",
      page_offset: 0,
      last_page: false,
      total_requests_count: 0,
      limit: 100,
      default_sorting: "-",
      default_name: "status",
      from_date: "",
      to_date: "",
      closed_date_toogle: true,
    };
    const timeZone = convertFunction(localStorage.getItem('timezone'));
    let newDate = new Date(timeZone);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let current = new Date(timeZone);
    let firstday = new Date(current.setDate(current.getDate() - current.getDay()));
    let lastWeekDate = firstday.getDate()+1

    if (this.props.type == "last_day") {
      this.state.from_date = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : date}`;
    }
    else if (this.props.type == "last_week") {
      this.state.from_date = `${year}-${month < 10 ? `0${month}` : `${month}`}-${lastWeekDate < 10 ? `0${lastWeekDate}` : lastWeekDate}`;
    }

    else if (this.props.type == "last_month") {
      this.state.from_date = `${year}-${month < 10 ? `0${month}` : `${month}`}-01`;
    }

    this.state.to_date = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : date}`;

    this.setGlobalState = this.setGlobalState.bind(this);
    this.handlechangeFrom = this.handlechangeFrom.bind(this);
    this.handlechangeTo = this.handlechangeTo.bind(this);
    this.sort = this.sort.bind(this);
    this.fetchRequests = this.fetchRequests.bind(this);
    this.fetchRequestsError = this.fetchRequestsError.bind(this);
    this.fetchRequestsCallback = this.fetchRequestsCallback.bind(this);
    this.fetchRequestsCount = this.fetchRequestsCount.bind(this);
    this.fetchRequestsCountError = this.fetchRequestsCountError.bind(this);
    this.fetchRequestsCountCallback =
      this.fetchRequestsCountCallback.bind(this);
    this.changePage = this.changePage.bind(this);
    this.createUrl = this.createUrl.bind(this);
    this.searchRequests = this.searchRequests.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
    this.handleName = this.handleName.bind();
  }

  handleSorting = (e) => {
    this.setState({ default_sorting: e.target.value });
  };

  handleName = (e) => {
    this.setState({ default_name: e.target.value });
  };

  handleLimitChange(e) {
    this.setState({ limit: parseInt(e.target.value) });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchRequests();
    this.fetchRequestsCount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page_offset != this.state.page_offset ||
      prevState.limit != this.state.limit ||
      prevState.default_name != this.state.default_name ||
      prevState.default_sorting != this.state.default_sorting ||
      prevState.from_date != this.state.from_date ||
      prevState.to_date != this.state.to_date
    ) {
      this.fetchRequests();
    }
  }

  setGlobalState(name, state) {
    this.setState(state);
  }

  sort(name) {
    if (this.state.sort_by != name) {
      this.setState({ sort_by: name, direction: "Ascending" });
    } else {
      if (this.state.direction == "Ascending") {
        this.setState({ direction: "Descending" });
      } else {
        this.setState({ direction: "Ascending" });
      }
    }
  }

  createUrl(get_total_count = false) {
    let url =
      "/api/home/request/?related=working_on,patient,patient__company,files,requester,user,provider";

    let filters = {};

    filters.exclude_admins_and_users = false;

    if (!this.props.user.is_staff) {
      filters.working_on = this.props.user.id;
    }

    if (this.props.employee) {
      filters.working_on = this.props.employee.id;
    } else if (this.props.law_firm) {
      filters.patient__company = this.props.law_firm.id;
    }

    // Form Filters

    if (this.state.from_date) {
      filters.completed_date__gte = this.state.from_date
    }

    if (this.state.to_date) {
      filters.completed_date__lte = this.state.to_date
    }

    if (this.state.from_date) {
      filters.closed_date__gte = this.state.from_date
    }
    if(this.state.closed_date_toogle) {
      filters.get_user_date_data= true
    }
    if (this.state.to_date) {
      filters.closed_date__lte = this.state.to_date
    }
    if (this.state.company) {
      filters.patient__company = this.state.company;
    }
    if (this.state.patient) {
      filters.patient__full_name__icontains = this.state.patient;
    }
    if (this.state.provider) {
      filters.provider__name__icontains = this.state.provider;
    }
    if (this.state.user) {
      filters.user__full_name__icontains = this.state.user;
    }
    if (this.state.requester) {
      filters.requester__full_name__icontains = this.state.requester;
    }
    if (this.state.internal_number) {
      filters.internal_number__contains = this.state.internal_number;
    }
    if (this.state.employees_data) {
      filters.working_on = this.state.employees_data;
    }
    if (this.state.status) {
      if (this.state.status == "Open") {
        filters.status_open = true;
      } else {
        filters.status = this.state.status;
      }
    }

    filters.order_by = `${this.state.default_sorting}${this.state.default_name}`;

    for (const key in filters) {
      url += `&${key}=${filters[key]}`;
    }

    return url;
  }

  fetchRequests() {
    let url = this.createUrl();
    ajaxWrapper(
      "GET",
      url,
      {},
      this.fetchRequestsCallback,
      this.fetchRequestsError
    );
  }

  fetchRequestsCallback(data) {
    let formated_data = data.map((rq) => rq.request);
    this.setState({ requests: formated_data });

    if (data.length < this.state.limit) {
      this.setState({ last_page: true });
    } else {
      this.setState({ last_page: false });
    }
  }

  fetchRequestsError(result) {
    this.setState({ last_page: true });
  }

  fetchRequestsCount() {
    let url = this.createUrl(true);
    ajaxWrapper(
      "GET",
      url,
      {},
      this.fetchRequestsCountCallback,
      this.fetchRequestsCountError
    );
  }

  fetchRequestsCountCallback(data) {
    this.setState({ total_requests_count: data.count });
  }

  fetchRequestsCountError(result) {
  }

  changePage(str) {
    let offset = this.state.page_offset;
    if (str == "prev") {
      offset -= this.state.limit;
      if (offset <= 0) {
        offset = 0;
      }
    } else {
      if (!this.state.last_page) {
        offset += this.state.limit;
      }
    }
    this.setState({ page_offset: offset });
  }

  handlechangeFrom(e) {
    this.setState({ from_date: e.target.value });
  }

  handlechangeTo(e) {
    this.setState({ to_date: e.target.value });
  }

  searchRequests() {
    this.setState({ page_offset: 0 });
    this.fetchRequests();
    this.fetchRequestsCount();
  }

  render() {
    var company_options = [];
    for (var index in this.props.law_firms) {
      var company = this.props.law_firms[index];
      company_options.push({ text: company.name, value: company.id });
    }

    var employee_options = [];
    for (var index in this.props.employees) {
      var employee = this.props.employees[index];
      employee_options.push({ text: employee.name, value: employee.id, selected: employee.name === this.props.employee.name ? true : false });
    }

    var status_options = [
      { text: "All Open", value: "Open" },
      { text: "Requested By Attorney", value: "Requested By Lawyer" },
      { text: "Initial Request Sent", value: "Initial Request Sent" },
      { text: "In Contact With Provider", value: "In Contact With Provider" },
      { text: "Documents en Route", value: "Documents en Route" },
      { text: "Need Assistance", value: "Need Assistance" },
      {
        text: "Waiting for Direct Docs Response",
        value: "Waiting for Direct Docs Response",
      },
      {
        text: "Completed - Documents Arrived",
        value: "Completed - Documents Arrived",
      },
      {
        text: "Completed - No Documents Available",
        value: "Completed - No Documents Available",
      },
      {
        text: "Closed - Documents Arrived",
        value: "Closed - Documents Arrived",
      },
      {
        text: "Closed - No Documents Available",
        value: "Closed - No Documents Available",
      },
      {
        text: "Closed - Cancelled Request",
        value: "Closed - Cancelled Request",
      },
      { text: "All Closed", value: "Closed" },
    ];
    var patient_filter = { name: "patient", label: "Client" };
    var status_filter = {
      name: "status",
      label: "Billing Title",
      options: status_options,
    };
    var employees_filter = {
      name: "employees_data",
      label: "Employees",
      options: employee_options,
    };
    var requester_filter = { name: "requester", label: "Requestor" };
    var claimed_by = {
      name: "claimed_by",
      label: "Claimed By",
      options: employee_options,
    };
    var number_filter = { name: "internal_number", label: "ID" };

    var filter_form = (
      <FormWithChildren
        autoSetGlobalState={true}
        globalStateName={"filter_form"}
        defaults={{}}
        className={"row form_margin"}
        setGlobalState={this.setGlobalState}
      >
        <TextInput {...number_filter} layout={"col-sm-2"} />
        <TextInput {...patient_filter} layout={"col-sm-2"} />
        <TextInput {...requester_filter} layout={"col-sm-2"} />
        <Select {...status_filter} layout={"col-sm-2"} />
        <Select {...employees_filter} layout={"col-sm-2"} />

        <Button
          style={{ height: "38px" }}
          type="primary"
          text="Search"
          onClick={this.searchRequests}
        />
      </FormWithChildren>
    );

    var requests = [];
    var request_list = this.state.requests;

    if (this.state.sort_by != "") {
      var reverse = "";
      if (this.state.direction == "Descending") {
        reverse = "-";
      }
      if (this.state.sort_by == "Internal #") {
        sort_objects(request_list, [reverse + "internal_number"]);
      }
      if (this.state.sort_by == "Created At Date") {
        sort_objects(request_list, [reverse + "created_at"]);
      }
      if (this.state.sort_by == "Requestor") {
        sort_objects(request_list, ["requester", reverse + "full_name"]);
      }
      if (this.state.sort_by == "Status") {
        sort_objects(request_list, [reverse + "status"]);
      }
      if (this.state.sort_by == "Client") {
        sort_objects(request_list, ["patient", reverse + "full_name"]);
      }
    }

    var internal_number = null;
    if (this.state.internal_number) {
      internal_number = parseInt(this.state.internal_number);
    }

    for (var index in request_list) {
      var request = request_list[index];

      var patient = request.patient;
      var patient_name = patient.full_name;

      var lawyer_name = "";
      if (request.user) {
        lawyer_name = request.user.full_name;
      }

      var requester_name = "";
      if (request.requester) {
        requester_name = request.requester.full_name;
      }

      var rush = "";
      if (request.rush) {
        rush = "Rush";
      }
      var company = request.patient.company.id;

      var provider = "";
      if (request.provider) {
        provider = request.provider.name;
      }

      var claimed_by = "None";
      if (
        request.working_on &&
        request.working_on.unicode != "None" &&
        this.props.user
      ) {
        var claimed_by = request.working_on.id;
      }
      var status = request.status;

      if (
        this.state.internal_number &&
        String(request.internal_number).indexOf(this.state.internal_number) ==
        -1
      ) {
        continue;
      }
      if (
        this.state.patient != "" &&
        patient_name.toLowerCase().indexOf(this.state.patient.toLowerCase()) ==
        -1
      ) {
        continue;
      }
      if (
        this.state.company != "" &&
        company.indexOf(this.state.company) == -1
      ) {
        continue;
      }
      if (
        this.state.provider != "" &&
        provider.toLowerCase().indexOf(this.state.provider.toLowerCase()) == -1
      ) {
        continue;
      }
      if (
        this.state.claimed_by != "" &&
        claimed_by.indexOf(this.state.claimed_by) == -1
      ) {
        continue;
      }
      if (
        this.state.lawyer != "" &&
        lawyer_name.toLowerCase().indexOf(this.state.lawyer.toLowerCase()) == -1
      ) {
        continue;
      }
      if (
        this.state.requester != "" &&
        requester_name
          .toLowerCase()
          .indexOf(this.state.requester.toLowerCase()) == -1
      ) {
        continue;
      }
      if (
        this.state.status == "Open" &&
        status.indexOf("Closed") == -1 &&
        request.status.indexOf("Completed") == -1
      ) {
      } else if (
        this.state.status != "" &&
        status.toLowerCase() != this.state.status.toLowerCase()
      ) {
        continue;
      }

      requests.push(
        <Request
          request={request}
          refreshData={this.props.refreshData}
          refreshPage={this.fetchRequests}
          user={this.props.user}
        />
      );
    }

    var titles = [];
    var title_names = [
      "ID",
      "Billing Title",
      "Requestor",
      "Completed Date",
      "Closed Date",
      "Client",
    ];
    for (var name in title_names) {
      if (
        title_names[name] == "View" ||
        title_names[name] == "Rush?" ||
        title_names[name] == "Request Types"
      ) {
        titles.push(<th>{title_names[name]}</th>);
      } else {
        titles.push(
          <SortableTitle
            sort={this.sort}
            sort_by={this.state.sort_by}
            direction={this.state.direction}
            name={title_names[name]}
          />
        );
      }
    }

    var content = (
      <div className="container-fluid">
        {filter_form}
        <div style={{ display: "flex"}}>
          <div style={{ display: "flex" , flexDirection: "column"}}>
            <label>From</label>
            <input className="filter_input" type="date" value={this.state.from_date} onChange={this.handlechangeFrom} />
          </div>
          <div style={{ display: "flex" , flexDirection: "column"}}>
            <label>To</label>
            <input className="filter_input" type="date" value={this.state.to_date} onChange={this.handlechangeTo} />
          </div>
        </div>
        <div
          className="pagination-count"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {`Showing - ${this.state.page_offset + this.state.requests.length} results.`}
        </div>
        <table className="table">
          <thead>
            <tr> {titles} </tr>
          </thead>
          <tbody>{requests}</tbody>
        </table>
      </div>
    );

    return content;
  }
}

import React, { Component } from 'react';
import ajaxWrapper from "base/ajax.js";
import Wrapper from 'base/wrapper.js';
import HelpVideo from 'projectLibrary/help_video.js';
import {Form, TextInput, Select, PasswordInput, Header,
    TextArea, NumberInput, DateTimePicker, FileInput, Image} from 'library';


class EditPDFForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            'name' : '',
            'url': '',
            'fields_to_get': ['Patient', 'Request', 'Provider'],
            'fields': ['Request.request_type', 'Company.name']
        };

        this.objectCallback = this.objectCallback.bind(this);
        this.objectFailback = this.objectFailback.bind(this);
        this.reload = this.reload.bind(this);
        this.save_form = this.save_form.bind(this);
        this.convert_to_images = this.convert_to_images.bind(this);
        this.save_field_names = this.save_field_names.bind(this);
        this.get_type_options = this.get_type_options.bind(this);
    }

    componentDidMount() {
        if(this.props.form_id) {
          this.reload();
        }
        else {
          this.setState({loaded:true})
        }
        ajaxWrapper('GET','/api/home/'+ this.state.fields_to_get[0] +'/fields/', {}, this.save_field_names);
        ajaxWrapper('GET','/api/home/'+ this.props.type +'/', {}, this.get_type_options);
    }

    objectCallback(result) {
      console.log("Result",result)
      var state = result[0]['pdfform'];
      state['loaded'] = true;
      this.setState(state);
    }

    objectFailback(xhr){
        window.location.href = '/formlist/';
    }

    get_type_options(value){
        var options = [];
        for (var i in value){
            var item = value[i][this.props.type];
            var data = {
                'text': item.name,
                'value': item.id
            };
            options.push(data);
        }

        this.setState({
            options: options
        });
    }

    save_field_names(value){
        var name = this.state.fields_to_get[0];
        var fields = this.state.fields;
        for (var i in value){
            if (['id','created_at','updated_at'].indexOf(value[i][0]) == -1 && value[i][1] != "ForeignKey"){
                var field_name = name + '.' + value[i][0];
                fields.push(field_name);
            }
        }
        this.state.fields_to_get.shift();

        this.setState({
            fields: fields,
            fields_to_get: this.state.fields_to_get
        }, function(){
            if (this.state.fields_to_get.length > 0){
                ajaxWrapper('GET','/api/home/'+ this.state.fields_to_get[0] +'/fields/', {}, this.save_field_names);
            }
        });
    }

    save_form(state){
        state[0]['pdfform']['saving'] = true;

        if (this.props.form_id){
            this.setState(state[0]['pdfform'], this.reload);
        }
        else {
            this.setState(state[0]['pdfform'], this.convert_to_images);
        }
    }

    convert_to_images(){
        var form_id = this.props.form_id;
        if (!(form_id)){form_id = this.state['id'];}

        ajaxWrapper('POST','/convertPDFToJPG/' + form_id + '/', {}, this.reload);
    }

    reload(){
        if (this.props.form_id){
            ajaxWrapper('GET','/api/home/pdfform/' + this.props.form_id + '/?related=mapping,pages', {}, this.objectCallback, this.objectFailback);
        }
        else {
            window.location.href = '/editform/'+ this.props.type +'/'+ this.state['id'] +'/';
        }

        this.setState({saving: false});
    }

    render() {
        var Components = [
            TextInput,
            Select,
            FileInput
        ];
        var name = {
            name: 'name',
            label: 'Form Name',
            placeholder: 'Name',
        };
        var select = {
            name: this.props.type,
            options: this.state.options,
        };
        if (this.props.type == 'company'){
            select['label'] = 'Company';
        } else if (this.props.type == 'provider'){
            select['label'] = 'Provider';
        }
        var form_url = {
            bucket_name: 'companyforms',
            name: 'url',
            label: 'PDF Form',
        };

        var ComponentProps = [name, select, form_url];

        var defaults = {
            name: this.state.name,
            url: this.state.url,
        };
        defaults[this.props.type] = this.state[this.props.type + '_id'];

        var submitUrl = "/api/home/pdfform/";
        var deleteUrl = null;
        var deleteRedirectUrl = null;
        var redirect = this.save_form;

        if (this.props.form_id) {
            Components.pop();
            ComponentProps.pop();
            submitUrl += this.props.form_id +'/';
            deleteUrl = "/api/home/pdfform/" + this.props.form_id + "/delete/";
            deleteRedirectUrl = '/formlist/';
        }

        var title = <Header text={'Create New Form'} size={2} />
        if (this.props.form_id) {
          title = <Header text={'Edit Form: ' + this.state.name} size={2} />
        }

        var image_editors = [];
        for (var i in this.state.pages){
            image_editors.push(<ImageEditor url={this.state.url} fields={this.state.fields} reload={this.reload}
                mapping={this.state.mapping} page={this.state.pages[i]['pdfpage']} form_id={this.props.form_id} />);
        }

        var content = <div className="container" style={{position:'relative'}}>
            <HelpVideo src="https://www.youtube.com/embed/9oEueNbKUvo" />
            {title}
            <Form components={Components} componentProps={ComponentProps} objectName={'pdfform'} defaults={defaults}
                redirect={redirect} deleteUrl={deleteUrl} submitUrl={submitUrl} deleteRedirectUrl={deleteRedirectUrl} />
            <br />

            {image_editors}
        </div>;

        return (
            <div>
                <Wrapper saving={this.state.saving} loaded={this.state.loaded} content={content} />
            </div>
        );
    }
}

class ImageEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active_position: null,
            page_offset: {x:0, y:0}
        };

        this.make_active_position = this.make_active_position.bind(this);
        this.image_loaded = this.image_loaded.bind(this);
        this.save_mapping = this.save_mapping.bind(this);
        this.position_ref = React.createRef();
    }

    image_loaded(){
        this.setState({
            page_offset: {
                x:this.position_ref.current.offsetWidth,
                y:this.position_ref.current.offsetHeight
            }
        });
    }

    make_active_position(e){
        console.log(e);
        var x_percent = (e.clientX - this.position_ref.current.offsetLeft + window.scrollX) / this.state.page_offset.x;
        var y_percent = (e.clientY - this.position_ref.current.offsetTop + window.scrollY) / this.state.page_offset.y;

        this.setState({
            active_position: {
                x: x_percent,
                y: y_percent
            }
        });
    }

    save_mapping(){
        this.setState({active_position: null});
        this.props.reload();
    }

    render() {
        var width = window.innerWidth;
        var height = window.innerHeight;
        var image_style = {width: '100%'};

        var mapper = null;
        if (this.state.active_position){
            var options = [];
            for (var i in this.props.fields){
                options.push({'value': this.props.fields[i], 'text': this.props.fields[i]});
            };

            var option_style = {
                position:'absolute',
                padding: '10px',
                boxShadow: '2px 2px 10px rgba(0,0,0,.2)'
            };
            option_style['left'] = this.state.active_position.x * this.state.page_offset.x;
            option_style['top'] = this.state.active_position.y * this.state.page_offset.y;

            var Components = [
                Select,
                TextInput,
                TextInput,
                NumberInput,
                NumberInput,
            ];

            var name = {name: 'name', label: 'Choose Field to Map', options: options};
            var form = {name: 'form', layout: 'hidden'};
            var page = {name: 'page', layout: 'hidden'};
            var x = {name: 'position_x', layout: 'hidden'};
            var y = {name: 'position_y', layout: 'hidden'};
            var ComponentProps = [name, form, page, x, y];

            var defaults = {
                form: this.props.form_id,
                page: this.props.page.id,
                position_x: this.state.active_position.x,
                position_y: this.state.active_position.y
            }

            var submitUrl = "/api/home/pdfformmapping/";
            var redirect = this.save_mapping;

            mapper = <div className='card' style={option_style}>
                <Form components={Components} componentProps={ComponentProps} redirect={redirect}
                    objectName={'pdfformmapping'} submitUrl={submitUrl} defaults={defaults} />
            </div>;
        };

        var mappings = [];
        for (var i in this.props.mapping){
            var mapping = this.props.mapping[i]['pdfformmapping'];
            if (mapping.page_id == this.props.page.id){
                var deleteUrl = "/api/home/pdfformmapping/" + mapping.id + "/delete/";
                var redirect = this.save_mapping;

                var mapping_style = {
                    position:'absolute',
                    padding: '10px',
                    boxShadow: '2px 2px 10px rgba(0,0,0,.2)'
                };
                mapping_style['left'] = mapping.position_x * this.state.page_offset.x;
                mapping_style['top'] = mapping.position_y * this.state.page_offset.y;
                mappings.push(<div className='card' style={mapping_style}>
                    <div style={{marginRight: '75px', padding: '3px'}}>{mapping['name']}</div>
                    <Form css={{position: 'absolute', top: '6px', right: '0px'}} redirect={redirect} objectName={'pdfformmapping'} deleteUrl={deleteUrl} defaults={{}} />
                </div>);
            }
        }

        return (
            <div style={{position:'relative'}} ref={this.position_ref}>
                {mapper}
                {mappings}
                <Image src={this.props.page['url']} css={image_style} onClick={this.make_active_position} onLoad={this.image_loaded} />
            </div>
        );
    }
}



export default EditPDFForm;

import React, { Component } from 'react';
import ajaxWrapper from "base/ajax.js";
import Wrapper from 'base/wrapper.js';
import HelpVideo from 'projectLibrary/help_video.js';
import { Alert, Form, TextInput, Select, PasswordInput, Header, TextArea, NumberInput, DateTimePicker, Button, EmptyModal, FileInput, PageBreak } from 'library';

import AddProvider from 'pages/editProvider.js';

class Split extends Component {
    constructor(props) {
        super(props);

        this.state = { 'form_key': 0 }

        this.state = { show_provider: false }
        this.setGlobalState = this.setGlobalState.bind(this);
        this.removeSplit = this.removeSplit.bind(this);
        this.hideProvider = this.hideProvider.bind(this);
        this.addProvider = this.addProvider.bind(this);
    }

    setGlobalState(name, state) {
        console.log("Split State", state)
        this.props.setSplitState(this.props.id, state)
    }

    hideProvider() {
        this.setState({ show_provider: !this.state.show_provider })
    }

    addProvider(result) {
        console.log("Add Provider Result", result)
        var providers = this.state.provider;
        this.setGlobalState('provider', { 'provider': result[0]['provider']['id'] })
        this.setState({ show_provider: false, 'form_key': this.state.form_key + 1 })
    }

    removeSplit() {
        this.props.removeSplit(this.props.id);
    }

    render() {
        return (

            <div className='card' style={{ margin: '10px', padding: '20px' }}>
                <h5>Choose The Providers This Request is Being Made To</h5>
                <Form row={true} col_size={6} key={this.props.id + '_' + this.state.form_key} components={[Select, Select]} componentProps={[this.props.provider, this.props.request_type]} defaults={this.props.defaults} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'form'} />
                <div className="row">
                    <Button onClick={this.hideProvider} text={'Add Provider'} type={'primary'} style={{ 'width': '160px', display: 'inline-block' }} />
                    <Button type='danger' deleteType={true} text='Remove Split' onClick={this.removeSplit} style={{ 'width': '160px', display: 'inline-block' }} />
                </div>
                <EmptyModal show={this.state.show_provider} onHide={this.hideProvider}>
                    <AddProvider redirect={this.addProvider} />
                </EmptyModal>

            </div>
        )
    }
}


class SplitRequest extends Component {
    constructor(props) {
        super(props);

        this.state = { show_provider: false, show_patient: false, provider_key: 1, patient_key: 0, 'user': this.props.user.id, 'start_date': '', 'end_date': '', 'rush': 'false', 'status': '', 'provider': [], 'request_type': [], 'special_instructions': '', attorney_name: '', requests_submitted: false, error: '', intake_form_url: '', 'splits': [], split_id: 0 };

        this.objectCallback = this.objectCallback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.setSplitState = this.setSplitState.bind(this);
        this.addSplit = this.addSplit.bind(this);
        this.removeSplit = this.removeSplit.bind(this);

        this.firstTryRequests = this.firstTryRequests.bind(this);
        this.add_notes = this.add_notes.bind(this);
        this.createRequests = this.createRequests.bind(this);
        this.submitted = this.submitted.bind(this);

    }

    componentDidMount() {
        ajaxWrapper('GET', '/api/home/request/' + this.props.request_id + '/?related=patient,provider,notes,files', {}, this.objectCallback);
    }

    setSplitState(id, state) {
        console.log("Set Split State Start", id, state)
        var splits = []
        for (var index in this.state.splits) {
            var split = this.state.splits[index];
            if (split.id == id) {
                console.log("Split State Global", split, state)
                for (var key in split) {
                    if (state[key]) {
                        split[key] = state[key]
                    }
                }
                console.log("Split State Global", split, state)
            }
            splits.push(split);
        }
        this.setState({ splits: splits })
    }

    addSplit() {
        var splits = this.state.splits;
        splits.push({ 'provider': this.state.provider.id, 'request_types': [], id: this.state.split_id + 1 })

        this.setState({ splits: splits, split_id: this.state.split_id + 1 })
    }

    removeSplit(id) {
        var splits = []
        for (var index in this.state.splits) {
            var split = this.state.splits[index];
            if (split.id != id) {
                splits.push(split)
            }
        }

        this.setState({ splits: splits })
    }

    objectCallback(result) {
        var request = result[0]['request'];
        request['patient'] = request.patient;
        request['provider'] = request.provider;
        request['request_type'] = request.request_type.split(', ');
        if (request.rush) {
            request['rush'] = 'true';
        }
        else {
            request['rush'] = 'false';
        }

        request['splits'] = [{ 'provider': request.provider.id, 'request_types': [], id: 0 }]

        request['loaded'] = true;
        this.setState(request)
    }

    setGlobalState(name, state) {
        this.setState(state)
    }

    firstTryRequests() {
        var error = '';
        var request_types = [];
        for (var index in this.state.splits) {
            var split = this.state.splits[index];
            if (!split['provider']) {
                error += 'Your Split in Position ' + (parseInt(index) + 1) + ' is missing a provider. '
            }
            if (split['request_types'].length == 0) {
                error += 'Your Split in Position ' + (parseInt(index) + 1) + ' has no request types. '
            }
            request_types.push(...split['request_types'])
        }

        for (var index in this.state.request_type) {
            var request_type = this.state.request_type[index];
            if (request_types.indexOf(request_type) == -1) {
                error += request_type + ' was not included in any of your splits. '
            }
        }

        if (error == '') {
            ajaxWrapper('GET', '/api/home/request/?limit=1&order_by=-internal_number', {}, this.createRequests)
        }
        else {
            var error_html = <div>
                <p>{error}</p>
                <Button text='Override' type='danger' deleteType={true} onClick={() => ajaxWrapper('GET', '/api/home/request/?limit=1&order_by=-internal_number', {}, this.createRequests)} />
            </div>
            this.setState({ 'error': error_html })
        }

    }

    async createRequests(result) {
        console.log(result)
        var latest_internal_number = result[0]['request']['internal_number']
        console.log("Create Requests")

        var current_internal_number = this.state.internal_number
        var request_data = Object.assign({}, this.state);
        request_data['patient'] = this.state.patient.id;
        request_data['working_on'] = this.state.working_on_id;
        request_data['user'] = this.state.user_id;
        request_data['requester'] = this.state.requester_id;
        delete request_data['id']
        delete request_data['error']
        var i = 1;

        for await(var split of this.state.splits) {
            request_data['provider'] = split['provider'];
            request_data['request_type'] = split['request_types'].join(', ');
            if (i == 1) {
                 request_data['page_fee'] = 0;
                 request_data['provider_cost'] = 0;
             }
            else {
                 request_data['internal_number'] = current_internal_number + i
             }

            await new Promise((resolve, reject) => {
                ajaxWrapper('POST', '/api/home/request/', request_data , (response)=> {
                    this.add_notes(response);
                    resolve();
                })
            })
            i += 1;
        }
        ajaxWrapper('POST', '/api/home/request/' + this.props.request_id + '/delete/', {}, this.submitted)
    }

    add_notes(result) {
        for (var index in this.state.notes) {
            var note = Object.assign({}, this.state.notes[index]['notes']);
            delete note['id'];
            note['request'] = result[0]['request']['id']
            note['user'] = note['user_id']
            ajaxWrapper('POST', '/api/home/notes/', note, console.log)
        }
        for (var index in this.state.files) {
            var file = Object.assign({}, this.state.files[index]['file']);
            delete file['id'];
            file['request'] = result[0]['request']['id']
            ajaxWrapper('POST', '/api/home/file/', file, console.log)
        }
    }

    submitted(result) {
        this.setState({ requests_submitted: true })
    }

    render() {

        if (this.state.requests_submitted) {
            var content = <div className="container">
                <div style={{ height: '20px' }} />
                <h3>You just split a request into {this.state.splits.length} providers.</h3>

                <PageBreak />

                <Button href='/dashboard/' text={'Go Back To Dashboard'} type={'primary'} />
            </div>;
        }

        else if (this.state.loaded) {
            var provider = { 'multiple': false, 'name': 'provider', 'label': 'Provider', 'placeholder': 'Provider', 'value': '', 'optionsUrl': '/api/home/provider/?order_by=name', 'optionsUrlMap': { 'text': '{provider.name}', 'value': '{provider.id}' } };




            var request_types = { 'multiple': true, 'name': 'request_types', 'label': 'Request Types', 'optionsUrl': '/api/home/requesttype/?order_by=name', 'optionsUrlMap': { 'text': '{requesttype.name}', 'value': '{requesttype.name}' } }

            var title = <Header text={'Split Request: ' + this.state.provider.name + ' for ' + this.state.patient.full_name} size={2} />

            var splits = [];
            for (var index in this.state.splits) {
                var split = this.state.splits[index];
                splits.push(<Split defaults={split} provider={provider} request_type={request_types} setSplitState={this.setSplitState} id={split.id} removeSplit={this.removeSplit} />)
            }

            var form_error = null;
            if (this.state.error != '') {
                form_error = <Alert text={this.state.error} type="danger" />
            }

            var content = <div className="container-fluid" style={{ position: 'relative' }}>
                {title}
                <div style={{ height: '20px' }} />
                <Button text='Add Split' onClick={this.addSplit} type='primary' />
                <Button text='Split The Request' onClick={this.firstTryRequests} type='success' deleteType={true} />
                {form_error}
                {splits}

                <PageBreak />

                <br />
            </div>;

        }
        else {
            var content = null;
        }



        return (
            <div>
                <Wrapper loaded={this.state.loaded} content={content} />
            </div>
        );
    }
}
export default SplitRequest;

function format_date_string(date_string, date_format, utc=true) {
  if (date_string == '') {
      return date_string;
  }
  var date = new Date(Date.parse(date_string));
  if (utc) {
      return format_date_utc(date, date_format)
  }
  else {
      return format_date(date, date_format)
  }

}

function format_date_utc(date, date_format) {
  var date_dict = {};
  var temp_date = date.getUTCDate();
  if (temp_date < 10) {
    temp_date = '0' + temp_date;
  }
  date_dict['dd'] = temp_date;

  var temp_month = date.getUTCMonth() + 1;
  if (temp_month < 10) {
    temp_month = '0' + temp_month;
  }
  date_dict['mm'] = temp_month;

  date_dict['yyyy'] = date.getUTCFullYear().toString();
  date_dict['yy'] = date.getUTCFullYear().toString().substring(2, 4);

  date_dict['HH'] = date.getUTCHours();
  date_dict['MM'] = date.getUTCMinutes();
  if (date_dict['MM'] < 10) {
    date_dict['MM'] = '0' + date_dict['MM']
  }
  date_dict['SSS'] = date.getUTCMilliseconds();
  date_dict['SS'] = date.getUTCSeconds();


  var keys = ['dd','mm','yyyy','yy','HH','MM','SSS','SS']
  for (var index in keys) {
    var key = keys[index];
    date_format = date_format.replace(key, date_dict[key])
  }

  return date_format

}

function format_date(date, date_format) {
    console.log("Not UTC!!")
  var date_dict = {};
  var temp_date = date.getDate();
  if (temp_date < 10) {
    temp_date = '0' + temp_date;
  }
  date_dict['dd'] = temp_date;

  var temp_month = date.getMonth() + 1;
  if (temp_month < 10) {
    temp_month = '0' + temp_month;
  }
  date_dict['mm'] = temp_month;

  date_dict['yyyy'] = date.getFullYear().toString();
  date_dict['yy'] = date.getFullYear().toString().substring(2, 4);

  date_dict['HH'] = date.getHours();
  date_dict['MM'] = date.getMinutes();
  if (date_dict['MM'] < 10) {
    date_dict['MM'] = '0' + date_dict['MM']
  }
  date_dict['SSS'] = date.getMilliseconds();
  date_dict['SS'] = date.getSeconds();


  var keys = ['dd','mm','yyyy','yy','HH','MM','SSS','SS']
  for (var index in keys) {
    var key = keys[index];
    date_format = date_format.replace(key, date_dict[key])
  }

  return date_format

}

function date_to_django(date) {
  return format_date(date, 'YYYY-MM-DDTHH:MM:SS.SSS')
}


export default format_date_string;

import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import CSVtoArray from 'base/csvToArray.js';
import settings from 'base/settings.js';
import {Header} from 'library';



class FeaturesList extends React.Component {

    render() {

        var titles = CSVtoArray(this.props.titles)
        var descriptions =  CSVtoArray(this.props.descriptions)

        var columns = []
        for (var index in titles) {
          columns.push(<div className="col">
            <h3 style={{'fontSize':'15px', letterSpacing:'2px', fontWeight:'bold'}}>{titles[index] || ''}</h3>
            <p style={{fontSize:'18px', fontWeight:'300'}}>{descriptions[index]}</p>
          </div>)
        }

        return (
          <section className="container" style={{paddingTop:'50px'}}>
            <div className="row" style={{'textAlign':'center'}}>
              {columns}
            </div>
          </section>
        );
    }
}


export default FeaturesList;

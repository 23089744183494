import React, { Component } from 'react';
import ajaxWrapper from "base/ajax.js";
import format_date from 'base/date_format.js';
import format_date_string from 'base/date_string_format.js';
import sort_objects from 'base/sort_objects.js';
import Wrapper from 'base/wrapper.js';
import HelpVideo from 'projectLibrary/help_video.js';
import {Alert, Form, TextInput, Select, PasswordInput, Header, TextArea, NumberInput, DateTimePicker, Button, EmptyModal, FileInput, PageBreak, CheckGroup, FormWithChildren} from 'library';

import AddProvider from 'pages/editProvider.js';
import AddPatient from 'pages/editPatient.js';
import RequestProvider from 'pages/request_provider_flow.js';

class PatientData extends Component {
    constructor(props) {
        super(props);

        this.state = {show_patient: false, patient:null}

        this.toggle_patient = this.toggle_patient.bind(this);
        this.edit_patient = this.edit_patient.bind(this);

    }

    toggle_patient() {
        this.setState({show_patient: !this.state.show_patient})
    }

    edit_patient(patient) {
        console.log("Edit Patient", patient)
        this.setState({show_patient: false, patient: patient[0].patient})
    }

    render() {
        var patient = this.state.patient || this.props.patient;
        return (
            <div>
                <p><b>Date Of Birth:</b> {format_date_string(patient.date_of_birth, 'mm/dd/yyyy')}</p>
                <p><b>SSN:</b> {patient.ssn}</p>
                <p><b>Custom Code:</b> {patient.custom_code}</p>
                <p><b>Address:</b> {patient.address_1} {patient.address_2}, {patient.city}, {patient.state} {patient.zip_code}</p>
                <Button type='danger' text='Edit Client' onClick={this.toggle_patient}/>
                <EmptyModal show={this.state.show_patient} onHide={this.toggle_patient}>
                  <AddPatient redirect={this.edit_patient} company={this.props.company_id} patient_id={patient.id} />
                </EmptyModal>
            </div>
        )
    }

}

class ProviderRequest extends Component {
    constructor(props) {
        super(props);

        this.remove = this.remove.bind(this);
    }

    remove() {
        this.props.remove_request(this.props.request);
    }

    render() {
        var request = this.props.request;
        return (
            <tr>
                <td>{request.provider.name}</td>
                <td>{request.provider.address_1}, {request.provider.city} {request.provider.state} {request.provider.zip_code}</td>
                <td>{request.request_types.join(', ')}</td>
                <td>{request.special_instructions}</td>
                <td><Button type='danger' text='Remove' onClick={this.remove} /></td>
            </tr>
        )
    }
}

class EditRequest extends Component {
    constructor(props) {
        super(props);

        var starting_user_id = this.props.user.id;
        console.log("Starting User", starting_user_id)
        this.state = {
            show_provider:false,
            show_patient:false,
            provider_key:1,
            patient_key:0,
            user: starting_user_id,
            start_date: '',
            end_date: '',
            rush: 'false',
            status: '',
            provider: [],
            request_type: [],
            special_instructions: '',
            attorney_name: '',
            requests_submitted: false,
            error: '',
            updated_documents_error: '',
            intake_form_url: '',
            providers_to_consume: [],
            requests_to_email: [],
            providers:{},
            provider_list: [],
            request_types:[],
            requests_to_providers:{},
            provider_requests: [],
            added_providers: 0,
            patient_data: null,
        };

        console.log("Starting User State", this.state.user)

        this.objectCallback = this.objectCallback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.hideProvider = this.hideProvider.bind(this);
        this.addProvider = this.addProvider.bind(this);
        this.hidePatient = this.hidePatient.bind(this);
        this.addPatient = this.addPatient.bind(this);
        this.firstTryRequests = this.firstTryRequests.bind(this);
        this.createRequests = this.createRequests.bind(this);
        this.checkRequest = this.checkRequest.bind(this);
        this.resubmit = this.resubmit.bind(this);
        this.redirectResubmit = this.redirectResubmit.bind(this);

        this.get_all_providers_updated = this.get_all_providers_updated.bind(this);
        this.get_all_providers_updated_callback = this.get_all_providers_updated_callback.bind(this);
        this.submit_completed_request_by_provider = this.submit_completed_request_by_provider.bind(this);
        this.confirm_submission = this.confirm_submission.bind(this);

        this.get_providers = this.get_providers.bind(this);
        this.get_providers_callback = this.get_providers_callback.bind(this);
        this.setRequestType = this.setRequestType.bind(this);

        this.get_request_type_callback = this.get_request_type_callback.bind(this);
        this.remove_request = this.remove_request.bind(this);

        this.get_patient_data = this.get_patient_data.bind(this);
        this.patient_callback = this.patient_callback.bind(this);
        this.get_company = this.get_company.bind(this);
    }

    componentDidMount(value) {
        ajaxWrapper('GET','/api/home/company/' + this.props.company_id + '/', {}, this.get_company)
        if(this.props.request_id) {
          ajaxWrapper('GET','/api/home/request/' + this.props.request_id + '/', {}, this.objectCallback);
        }
        else {
          this.setState({loaded:true});
          this.get_providers();
        }
    }

    get_company(result) {
      this.setState({company: result[0].company})
    }

    get_providers() {
        ajaxWrapper('GET','/api/home/provider/?order_by=name', {}, this.get_providers_callback);
        ajaxWrapper('GET','/api/home/requesttype/?order_by=name', {}, this.get_request_type_callback);
    }

    get_providers_callback(result) {
        var providers = {};
        var provider_list = []
        for (var index in result) {
            var provider = result[index]['provider']
            providers[provider.id] = provider;
            provider_list.push(provider);
        }
        console.log("Set State Providers", providers)

        var provider_requests = []
        if (this.state.provider && this.state.provider.length != 0) {
            provider_requests.push({'provider': providers[this.state.provider[0]], request_types: this.state.request_type, special_instructions: this.state.special_instructions, intake_form_url: this.state.intake_form_url})
        }
        this.setState({providers:providers, provider_list: provider_list, provider_requests: provider_requests})
    }

    get_request_type_callback(result) {
        var request_types = [];
        for (var index in result) {
            var request_type = result[index].requesttype;
            request_types.push(request_type)
        }

        request_types = sort_objects(request_types, ['name'])

        this.setState({request_types: request_types})
    }

    setRequestType(provider_id, state) {
        var requests_to_providers = this.state.requests_to_providers;

        requests_to_providers[provider_id] = state['request_type'];

        this.setState({requests_to_providers: requests_to_providers})
    }

    objectCallback(result) {
      var request = result[0]['request'];
      request['patient'] = request.patient_id;
      request['provider'] = [request.provider_id];
      request['request_type'] = request.request_type.split(', ');
      request['requests_to_providers'] = {}
      request['requests_to_providers'][request.provider_id] = request['request_type']
      if (request.rush) {
          request['rush'] = 'true';
      }
      else {
          request['rush'] = 'false';
      }

      request['loaded'] = true;
      this.setState(request);
      this.get_providers();
    }

    setGlobalState(name, state) {
      console.log("Set Global State Edit Request", name, state)
      state['last_state_update'] = state;
      this.setState(state);
      if (state.user) {
          console.log("User Update", state.user);
          this.setState({user: state.user, user_id: state.user})
      }
      if (state.patient) {
          console.log("Get Patient Data")
          this.get_patient_data(state.patient);
      }
    }

    hideProvider() {
      this.setState({show_provider: !this.state.show_provider})
    }

    addProvider(provider_request) {
        var provider_requests = this.state.provider_requests;
        provider_requests.push(provider_request)
          this.setState({
              provider_requests: provider_requests,
              show_provider: false,
              added_providers: this.state.added_providers + 1
          });
    }

    hidePatient() {
      this.setState({show_patient: !this.state.show_patient})
    }

    addPatient(result) {
      this.setState({
          patient:result[0]['patient']['id'],
          show_patient:false,
          patient_key: this.state.patient_key - 1
      });
    }

    firstTryRequests() {
        if (this.props.request_id) {
            this.resubmit();
        }
        else {
            var price = this.state.company.request_price;
            if (this.state.rush) {
                price += 7;
            }
            if (this.state.request_type.indexOf('Provider Certification of Received Records') > -1) {
                price = 9.95;
            }

            if (this.state.patient) {
                if (this.state.provider_requests.length > 0) {
                    var request_data = {
                        user:this.state.user,
                        start_date: this.state.start_date,
                        end_date: this.state.end_date,
                        rush: this.state.rush,
                        status: 'Requested By Lawyer',
                        patient: this.state.patient,
                        hipaa_consent_url: this.state.hipaa_consent_url,
                        special_instructions: this.state.special_instructions,
                        requester: this.props.user.id,
                        attorney_name: this.state.attorney_name,
                        price: price
                    }

                      var start_date_split = this.state.start_date.split('-');
                      var end_date_split = this.state.end_date.split('-');

                      if (start_date_split.length != 3 || start_date_split[1].length < 1 || start_date_split[1].length > 2 || start_date_split[2].length < 1 || start_date_split[2].length > 2 || start_date_split[0].length != 4) {
                          console.log("Start Date Error");
                          this.setState({error: "Start Date Must be in the format MM/DD/YYYY to successfully submit."});
                      }
                      else if (end_date_split.length != 3 || end_date_split[1].length < 1 || end_date_split[1].length > 2 || end_date_split[2].length < 1 || end_date_split[2].length > 2 || end_date_split[0].length != 4) {
                          this.setState({error: "End Date Must be in the format MM/DD/YYYY to successfully submit."});
                      }
                      else {
                          ajaxWrapper('GET','/api/home/request/?related=provider&patient=' + this.state.patient + '&user=' + this.state.user + '&start_date=' + this.state.start_date + '&end_date=' + this.state.end_date, {}, this.checkRequest)
                    }
                }
                else {
                    this.setState({error:"There is no provider on this request. Make sure you add at least one."});
                }
            }
            else {
                this.setState({error:"There is no patient on this request. Make sure you add one."});
            }
        }
    }

    resubmit() {

        var price = 35;
        if (this.state.rush) {
            price = 42;
        }
        if (this.state.request_type.indexOf('Provider Certification of Received Records') > -1) {
            price = 9.95
        }

        var request_data = {
            user: this.state.user,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            rush: this.state.rush,
            status: 'Requested By Lawyer',
            patient: this.state.patient,
            request_type: this.state.request_type.join(', '),
            hipaa_consent_url: this.state.hipaa_consent_url,
            special_instructions: this.state.special_instructions,
            requester: this.props.user.id,
            attorney_name: this.state.attorney_name,
            intake_form_url: this.state.intake_form_url,
            price: price
        }

          var start_date_split = this.state.start_date.split('-');
          var end_date_split = this.state.end_date.split('-');

          if (start_date_split.length != 3 || start_date_split[1].length < 1 || start_date_split[1].length > 2 || start_date_split[2].length < 1 || start_date_split[2].length > 2 || start_date_split[0].length != 4) {
              console.log("Start Date Error")
              this.setState({error: "Start Date Must be in the format MM/DD/YYYY to successfully submit."});
          }
          else if (end_date_split.length != 3 || end_date_split[1].length < 1 || end_date_split[1].length > 2 || end_date_split[2].length < 1 || end_date_split[2].length > 2 || end_date_split[0].length != 4) {
              console.log("End Date Error")
              this.setState({error: "End Date Must be in the format MM/DD/YYYY to successfully submit."});
          }
          else {
              ajaxWrapper('POST','/api/home/request/' + this.props.request_id + '/', request_data, this.redirectResubmit);
        }
    }

    redirectResubmit() {
        window.location = '/request/' + this.props.request_id + '/'
    }

    get_patient_data(patient_id) {
        console.log("Get Patient Data Func", patient_id)
        ajaxWrapper('GET','/api/home/patient/' + patient_id + '/', {}, this.patient_callback)
    }

    patient_callback(result) {
        console.log("Patient Callback", result)
        this.setState({patient_data: result[0].patient})
    }

    createRequests() {

      var price = 35;
      if (this.state.rush) {
          price = 42;
      }
      if (this.state.request_type.indexOf('Provider Certification of Received Records') > -1) {
          price = 9.95
      }

      if (this.state.patient) {
          if (this.state.provider_requests.length > 0) {
            var request_data = {
                user:this.state.user,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                rush: this.state.rush,
                status: 'Requested By Lawyer',
                patient: this.state.patient,
                request_type: this.state.request_type.join(', '),
                hipaa_consent_url: this.state.hipaa_consent_url,
                requester: this.props.user.id,
                attorney_name: this.state.attorney_name,
                price: price
            }

                var start_date_split = this.state.start_date.split('-');
                var end_date_split = this.state.end_date.split('-');

                if (start_date_split.length != 3 || start_date_split[1].length < 1 || start_date_split[1].length > 2 || start_date_split[2].length < 1 || start_date_split[2].length > 2 || start_date_split[0].length != 4) {
                    console.log("Start Date Error")
                    this.setState({error: "Start Date Must be in the format MM/DD/YYYY to successfully submit."})
                }
                else if (end_date_split.length != 3 || end_date_split[1].length < 1 || end_date_split[1].length > 2 || end_date_split[2].length < 1 || end_date_split[2].length > 2 || end_date_split[0].length != 4) {
                    console.log("End Date Error")
                    this.setState({error: "End Date Must be in the format MM/DD/YYYY to successfully submit."})
                }
                else {
                    // This is where requests are submitted!
                    var providers_to_consume = this.state.provider.slice();

                    this.setState({
                        providers_to_consume: providers_to_consume,
                        request_data: request_data
                    }, this.submit_completed_request_by_provider);
              }
          }
          else {
              this.setState({error:"There is no provider on this request. Make sure you add at least one."});
          }
      }
      else {
          this.setState({error:"There is no patient on this request. Make sure you add one."});
      }
    }

    submit_completed_request_by_provider(value) {
        var providers = this.state.provider_requests;
        var requests_to_email = this.state.requests_to_email;

        if (typeof(value) != 'undefined'){
            var request_data = value[0]['request'];
            requests_to_email.push(request_data);
        }

        if (providers.length > 0){
            var provider = providers.pop();

            var request_data = this.state.request_data;
            request_data['provider'] = provider.provider.id;
            request_data['request_type'] = provider.request_types.join(', ')
            request_data['special_instructions'] = provider.special_instructions;
            request_data['intake_form_url'] = provider.intake_form_url;

            this.setState({
                provider_requests: providers,
                requests_to_email: requests_to_email
            },function(){
                ajaxWrapper('POST','/api/home/request/?related=provider,patient,user', request_data, this.submit_completed_request_by_provider);
            });

        }
        else {
            this.send_email_to_main_contact(requests_to_email);
        }
    }

    send_email_to_main_contact(requests) {
        var main_request = requests[0];
        console.log("Send Email Requests", requests)

        var rush = 'No';
        if (main_request.rush){
            rush = 'Yes';
        }

        var text = "A request has been succesfully submitted on Direct Docs.<br/>";
        text += "General Info:<br/>";
        text += "Patient: "+ main_request.patient.full_name + "<br/>";
        text += "Start Date: "+ format_date_string(main_request.start_date, 'mm/dd/yyyy') + "<br/>";
        text += "End Date: "+ format_date_string(main_request.end_date, 'mm/dd/yyyy') + "<br/>";
        text += "Rush: "+ rush + "<br/>";
        text += "Special Instructions: "+ main_request.special_instructions + "<br/><br/>";

        text += "Providers:<br/>";
        for (var index in requests) {
            var request = requests[index];
            var request_link = '<a href="https://directdocs.com/request/' + request.id + '/" >View It Here</a>';
            text += request.provider.name +"   " + request_link + "<br/>";
            text += "Request Types: "+ request.request_type + "<br/><br/>";
        }

        var sign_off = "<br/>Reply to support@directdocs.com if you need any changes<br/>";
        text += sign_off;

        var data = {
            "text": text,
            "subject":"DirectDocs: Request Confirmation",
            "to_email": main_request.user.email,
            "from_email":'support@directdocs.com'
        }

        ajaxWrapper('POST', '/api/email/', data, this.confirm_submission);
    }

    confirm_submission() {
         this.setState({'requests_submitted':true});
    }

    checkRequest(result) {
        if (result.length == 0) {
            this.createRequests();
        }
        else {
            var providers = '';
            for (var index in result) {
                if (result[index] && result[index].request && result[index].request.provider) {
                    var provider = result[index].request.provider;
                    if (provider && this.state.provider.indexOf(provider.id) > -1) {
                        providers += result[index]['request']['provider']['name'] + ', ';
                    }
                }
            }
    
            if(providers == '') {
                this.createRequests();
            }
            else {
                var error =  <p>
                    You have requests for the same patient and dates for the following providers: {providers} We suggest you edit your providers. If this is intentional click the following,
                    <Button onClick={this.createRequests} text='Override and Create Anyway' deleteType={true} type='danger' />
                </p>;
                this.setState({error: error});
            }
        }
    }

    get_all_providers_updated() {
        ajaxWrapper('GET','/api/home/request/?related=provider&patient=' + this.state.patient, {}, this.get_all_providers_updated_callback);
    }

    get_all_providers_updated_callback(value){
        console.log(value);
        var new_state = this.state;
        var providers = [];

        for (var index in value){
            var request = value[index]['request'];
            request.request_type = request.request_type.split(', ');
            request.rush = String(request.rush);

            console.log(request.start_date, request.end_date, request.user_id, request.rush, request.request_type);
            var field_map = {
                'end_date': 'start_date',
                'user_id': 'user',
                'rush': 'rush',
                'request_type': 'request_type'
            };

            for (var key in field_map){
                if ((!(new_state[field_map[key]]) || !(new_state[field_map[key]].length)) && request[key]){
                    new_state[field_map[key]] = request[key];
                }
            }

            if (providers.indexOf(request.provider.id) == -1){
                providers.push(request.provider.id);
            }
        }

        if (providers.length == 0){
          new_state.updated_documents_error = 'No providers could be found for this patient.  Please review your selection.';
        }
        var today = format_date(new Date(), 'yyyy-mm-dd');
        new_state.end_date = today;
        new_state.provider = providers;
        console.log("Get All Providers updated callback", new_state);

        this.setState(new_state);
    }

    get_email_context(value) {
        console.log(value);
    }

    remove_request(request) {
        var provider_requests = this.state.provider_requests;
        var new_provider_requests = [];
        for (var index in provider_requests) {
            var temp_request = provider_requests[index];
            if (request.provider.id != temp_request.provider.id) {
                new_provider_requests.push(temp_request)
            }
        }
        this.setState({provider_requests: new_provider_requests})
    }


    render() {
        var Components = [Select, Header , DateTimePicker, DateTimePicker, Select];

        var user_form_disabled = true;
        if (this.props.user.is_staff) {
            user_form_disabled = false;
        }
        var user = {'name': 'user', 'label': 'Who is the main contact for this request?', 'placeholder': 'User', 'value': '', 'optionsUrl': '/api/user/User/?company_id=' + this.props.company_id, 'optionsUrlMap': {'text':'{User.first_name} {User.last_name}','value':'{User.id}'}, required:true, disabled: user_form_disabled};
        var attorney = {'name':'attorney_name', 'label':'Attorney On Request', required: true}
        var start_date = { 'name': 'start_date', 'label': 'Records Request Start Date', 'placeholder': 'MM/DD/YYYY', 'value': false, 'display_time': false, required: true};
        var end_date = {'name': 'end_date', 'label': 'Records Request End Date', 'placeholder': 'MM/DD/YYYY', 'value': false, 'display_time': false, required:true};
        var rush = {'name': 'rush', 'label': 'Should We Rush This Order ($7 Extra Per Provider)', 'placeholder': 'Rush', 'value': false, 'options': [{'value':'true','text':'Yes Please'},{'value':'false','text':'No Thanks'}]};

        var special_instructions = {'name': 'special_instructions', 'label': 'Special Instructions', 'placeholder': '', 'value': ''};
        var intake_form = {'name':'intake_form_url', label:'Upload An Intake Form (Optional)', 'bucket_name': 'hipaaforms',}
        var fake_header ={'text':'', size: 5, header:true}
        var ComponentProps = [user, fake_header, start_date, end_date, rush];

        var patient = {name: 'patient', 'label': '', 'optionsUrl': '/api/home/patient/?order_by=last_name,first_name&company=' + this.props.company_id, 'optionsUrlMap': {'text':'{patient.last_name}, {patient.first_name}','value':'{patient.id}'}, style: {width:'800px'}};
        var provider = {'multiple':true, 'name': 'provider', 'label': '', 'placeholder': 'Provider', 'value': '', 'optionsUrl': '/api/home/provider/?order_by=name', 'optionsUrlMap': {'text':'{provider.name}','value':'{provider.id}'}, style: {width:'800px'}};

        if (this.props.request_id) {
            patient['disabled'] = true;
            provider['disabled'] = true;
        }

        var Patient_Components = [Select];
        var Patient_ComponentProps = [patient];

        var Provider_Components = [Select];
        var Provider_ComponentProps = [provider];

        var defaults = this.state;
        if (defaults['user_id']) {
            defaults['user'] = defaults.user_id;
        }
        if (!defaults['user']) {
            defaults['user'] = this.props.user.id
        }

        var title = <Header text={'Create New Request'} size={2} />;
        if (this.props.request_id) {
            title = <Header text={'Edit Request'} size={2} />;
        }

        if (this.state.requests_submitted) {
            var content = <div className="container">
                <div style={{height:'20px'}} />
                <h3>You just submitted a request for {this.state.requests_to_email.length} providers.</h3>

                <PageBreak />

                <Button href={'/editRequest/' + this.props.company_id + '/'} text={'Submit Another Request'} type={'success'} />
                <h3 style={{marginTop:'30px', marginBottom:'30px'}}>Or</h3>
                <Button href='/dashboard/' text={'Go Back To Dashboard'} type={'primary'} />
            </div>;
        }

        else {
            var updated_documents = null;
            if (this.state.patient){
              updated_documents = <Button onClick={this.get_all_providers_updated} text={'Need Updated Records'} type={'primary'} style={{'marginBottom':'15px'}} />;
            }
            var updated_documents_error = null;
            if (this.state.updated_documents_error != '') {
                updated_documents_error = <Alert text={this.state.updated_documents_error} type="danger" />
            }

            var form_error = null;
            if (this.state.error != '') {
                form_error = <Alert text={this.state.error} type="danger" />
            }

            var provider_list = [];
            for (var index in this.state.provider_requests) {
                var provider_request = this.state.provider_requests[index];

                if (provider_request.provider) {
                    provider_list.push(<ProviderRequest key={provider_request.provider.id} request={provider_request} remove_request={this.remove_request}/>)
                }
            }

            var patient_data = null;
            if (this.state.patient_data) {
                patient_data = <PatientData key={this.state.patient_data.id} patient={this.state.patient_data} />
            }

            var providers_view = <div>
            <h5>Choose The Provider(s) This Request is Being Made To</h5>

            <Button onClick={this.hideProvider} text={'Add Provider'} type={'primary'} />

            <EmptyModal show={this.state.show_provider} onHide={this.hideProvider} contentStyle={{padding:'20px', maxHeight: 'calc(100vh - 100px)', overflowY:'scroll'}}>
              <RequestProvider key={'request_provider_' + this.state.added_providers} providers={this.state.provider_list} request_types={this.state.request_types} addProvider={this.addProvider} />
            </EmptyModal>
            <div style={{height:'20px'}}></div>
            <table className="table">
                <tr>
                    <th>Provider</th>
                    <th>Provider Address</th>
                    <th>Request Types</th>
                    <th>Special Instructions</th>
                </tr>
                {provider_list}
            </table>
            </div>
            if (this.props.request_id) {
                var request_types = [];
                for (var index in this.state.request_types) {
                    var request_type = {'name':this.state.request_types[index].name, 'id':this.state.request_types[index].name}
                    request_types.push(request_type)
                }

                if (this.state.provider_requests[0] && this.state.provider_requests[0].provider) {

                providers_view = <div style={{padding:'40px'}}>
                <h5>Edit the Request Types</h5>
                <p>Provider: {this.state.provider_requests[0].provider.name}</p>
                <FormWithChildren autoSetGlobalState={true} globalStateName={'edit_request_types'} setGlobalState={this.setGlobalState}>
                    <CheckGroup name='request_type' label="Reqest Types" options={request_types} default={this.state.request_type} />
                    <TextArea name='special_instructions' default={this.state.special_instructions} label='Special Instructions' />
                </FormWithChildren>
                </div>
            }
            }


            var content = <div className="container-fluid" style={{position:'relative'}}>
                <HelpVideo src="https://companyforms.s3.us-east-2.amazonaws.com/Submitting+New+Requests+2021.mp4" />
                {title}
                <div style={{height:'20px'}} />
                <div className="row">
                    <div className="col-6">
                        <Form row={true} col_size={6} components={Components} componentProps={ComponentProps} defaults={defaults} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'form'} />
                    </div>
                    <div className="col-6">
                        <h5>Choose The Client This Request is Being Made For</h5>
                        <Form key={this.state.patient_key} components={Patient_Components} componentProps={Patient_ComponentProps} defaults={defaults} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'form'} />
                        {patient_data}
                        <Button onClick={this.hidePatient} text={'Add Client'} type={'primary'} />
                        <EmptyModal show={this.state.show_patient} onHide={this.hidePatient}>
                          <AddPatient redirect={this.addPatient} company={this.props.company_id} />
                        </EmptyModal>
                    </div>
                </div>

                {updated_documents}
                {updated_documents_error}

                <PageBreak />

                {providers_view}

                <div style={{height:'20px'}}></div>
                {form_error}
                <Button onClick={this.firstTryRequests} text={'Submit Request'} type={'success'} />
                <br />
            </div>;
        }

        return (
            <div>
                <Wrapper loaded={this.state.loaded} content={content} />
            </div>
        );
    }
}
export default EditRequest;

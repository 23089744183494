
import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import {Container, Button, Image, Form, TextInput, Navbar, List, Select, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText, PageBreak} from 'library';
import format_date from 'base/date_format.js';

class FirmRow extends Component {
    constructor(props) {
        super(props);

        this.setCompany = this.setCompany.bind(this);
    }

    setCompany() {
        this.props.setCompany(this.props.company.id)
    }

    render() {
        var company = this.props.company;
        var request_dict = this.props.request_dict;

        return (
            <tr>
              <td><Button onClick={this.setCompany} text={'View'} type='success' /></td>
              <td>{company.name}</td>
              <td>{request_dict[company.id][0]}</td>
              <td>{request_dict[company.id][1]}</td>
            </tr>
        )
    }
}

class Firms extends Component {
    constructor(props) {
        super(props);
        this.state = {company_filter:''}

        this.setGlobalState = this.setGlobalState.bind(this);
    }

    setGlobalState(name,state) {
        this.setState(state)
    }

    render() {
      console.log("Here");
      var todo_request = [];
      var all_requests = [];
      var content = null;
      var request_dict = {};
      var today = new Date()

      for (var index in this.props.requests) {

          var request = this.props.requests[index];
          var company = request.user.company_id;
          if (!request_dict[company]) {
              request_dict[company] = [0, 0]
          }

          var open_close = 0;
          if (request.status.indexOf('Closed')) {
              open_close = 1
          }

          request_dict[company][open_close] += 1;

      }

        var company_options = []
        for (var index in this.props.companies) {
            var company = this.props.companies[index]
            company_options.push({'text':company.name, 'value':company.id})
            if ((this.state.company_filter == '' || this.state.company_filter == company.id) && request_dict[company.id]) {
                all_requests.push(<FirmRow company={company} setCompany={this.props.setCompany} request_dict={request_dict} />)
            }
        }

          var componentList = [Select]
          var company_filter = {'name':'company_filter', label:'Law Firm', options: company_options}


          var filter_form = <Form row={true} autoFocus={false} col_size={3} components={componentList} defaults={this.state} componentProps={[company_filter]}
            setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'filter'} />


        var content = <div>

            {filter_form}
            <table className="table">
              <tr>
                <th>View</th>
                <th>Law Firm</th>
                <th>Open Requests</th>
                <th>Closed Requests</th>
              </tr>
            {all_requests}
            </table>
          </div>;


        return (
            <Wrapper loaded={true}  content={content} />
        );
    }
}

export default Firms;

import React, { Component } from 'react';
import ajaxWrapper from '../base/ajax.js';
import {EmptyModal, Icon} from 'library';
import settings from 'base/settings.js';

class HelpVideo extends Component {
    constructor(props) {
        super(props);

        this.state = {video: false}

        this.toggle_video = this.toggle_video.bind(this);
    }

    toggle_video() {
        this.setState({video: !this.state.video})
    }

    render() {



        if (this.state.video) {
            return (
                <EmptyModal show={this.state.video} onHide={this.toggle_video}>
                    <div style={{textAlign:'center'}}>
                        <iframe width="1120" height="630" src={this.props.src} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </EmptyModal>
            )
        }
        else {
            return (
                <div style={{position: 'absolute', top:'20px', right:'20px'}} onClick={this.toggle_video}>
                    <Icon icon='info-circle' />
                </div>
            )
        }


    }

}

export default HelpVideo;

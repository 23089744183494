import React, { Component } from 'react';
import ajaxWrapper from "base/ajax.js";
import Wrapper from 'base/wrapper.js';
import {Form, NumberInput, BooleanInput, TextInput, Select, TextArea, FileInput, Button, Header, Paragraph, CSSInput,ImageInput, ColorPicker} from 'library';
import APIQuery from './apiQuery.js';
import TopContent from 'projectLibrary/pageBuilder/topContent.js';
import FeaturesList from 'projectLibrary/pageBuilder/featuresList.js';
import CTA1 from 'projectLibrary/pageBuilder/CTA1.js';
import MiddleContent from 'projectLibrary/pageBuilder/middleContent.js';
import Customers from 'projectLibrary/pageBuilder/customers.js';
import MiddleContent2 from 'projectLibrary/pageBuilder/middleContent2.js';
import Service from 'projectLibrary/pageBuilder/service.js';
import BottomContent from 'projectLibrary/pageBuilder/bottomContent.js';
import BottomCTA from 'projectLibrary/pageBuilder/bottomCTA.js';
import AboutCTA from 'projectLibrary/pageBuilder/aboutCTA.js';
import StatList from 'projectLibrary/pageBuilder/statList.js';
import ServicesList from 'projectLibrary/pageBuilder/servicesList.js';
import ContactForm from 'projectLibrary/pageBuilder/contactForm.js';
import WhiteSpace from 'projectLibrary/pageBuilder/whiteSpace.js';

let ComponentDict = {
    'Paragraph': [Paragraph, {'text':'Default Text', 'style':{}, className:'container'}, [NumberInput, TextInput, CSSInput], [{'label':'order', name:'order'}, {'label':'text', name:'text'}, {'label':'css', name:'style'}]],
    'Header':[Header, {'text':'Default Text', 'size':2, style:{}}, [NumberInput, TextInput, NumberInput, CSSInput], [{'label':'order', name:'order'}, {'label':'text', name:'text'}, {'label':'size', name:'size'}, {'label':'css', name:'style'}]],

    'TopContent':[TopContent, {'title':'DIGITAL MARKETING THAT GETS RESULTS',
      'description':'Learn about our simple system that gets you highly targeted leads right now',
      'cta':'SIGN UP FOR YOUR FREE INTRODUCTORY CALL','image':'http://leadfinderservices.com/wp-content/uploads/2017/12/sales-systems-lead-generation-for-coaches-computer.jpg'},
       [NumberInput,TextInput, TextInput, TextInput, ImageInput],
       [{'label':'order', name:'order'},{'label':'title', 'name':'title'},{'label':'description', 'name':'description'},{'label':'cta', 'name':'cta'},{'label':'image', 'name':'image', multiple:false}]],

    'FeaturesList':[FeaturesList, {'titles':'STOP WASTING TIME,STOP WASTING TIME,STOP WASTING TIME,STOP WASTING TIME',
     'descriptions':'Stop doing marketing that produces zero results.,Stop doing marketing that produces zero results.,Stop doing marketing that produces zero results.,Stop doing marketing that produces zero results.'},
     [NumberInput,TextInput, TextInput], [{'label':'order', name:'order'},{'label':'titles (separate with commas, if you want to use a comma surround by "")', 'name':'titles'},{'label':'description (separate with commas, if you want to use a comma surround by "")', 'name':'descriptions'}]],

    'CTA1': [CTA1, {'lead':'WHY GET HELP WITH YOUR SERVICE BUSINESS?', title:'BECAUSE DIGITAL MARKETING IS INCREDIBLY DIFFICULT!', description:"As a service business owner, attempting to get into digital marketing can feel like wandering the desert blindfolded. It's for that reason, I created a very simple system that will help you get targeted leads right now. Stop wandering the desert, and start getting more leads and start getting more clients."
    , cta:'CONTACT US!', href:'/contact-us/'}, [NumberInput,TextInput, TextInput, TextArea, TextInput, TextInput],
   [{'label':'order', name:'order'},{'label':'lead', name:'lead'},{'label':'title', name:'title'},{'label':'description', name:'description'},
   {'label':'cta', name:'cta'},{'label':'href', name:'href'}]],

   'MiddleContent': [MiddleContent, {image:'http://rifetheme.com/wp-content/uploads/2018/02/rife-free19.jpg', headerTop:true,
   quote:"Our Emotionally Intelligent Lead Development Program is Like Nothing You've Ever Seen!", who:'LEAD FINDER', fontSize:'51px', fontWeight:'normal'},
    [NumberInput, ImageInput, TextInput, TextInput, TextInput, TextInput, Select], [{'label':'order', name:'order'}, {'label':'image', name:'image'},
     {'label':'quote', name:'quote'}, {'label':'who', name:'who'}, {'label':'fontSize', name:'fontSize'}, {'label':'fontWeight', name:'fontWeight'},
   {'label':'Header Text On Top',name:'headerTop', options:[{'text':'Yes', value:true},{'text':'No', value:false}]}]],

   'Customers':[Customers, {lead:'GET MARKETING THAT JUST WORKS', title:"SEE WHO WE'VE HELPED",
    description:'Our greatest strength is knowing EXACTLY how to help service professionals. From lawyers, to coaches, to janitorial services, we have the formulas that produce results.',
    images:[
    {'url':"https://leadfinderservices.com/wp-content/uploads/apollo13_images/LEAD-FINDER-insurance-companies-clients-top-title-bar2-6o33gyi0tk1kprsl7ws3jjkbzvn7inhkhu.png",'order':0,'filename':'None'},
    {'url':"https://cms.qz.com/wp-content/uploads/2018/05/jack-dorsey-bitcoin-square-cash-consensus.jpg?quality=75&strip=all&w=900&h=900&crop=1",'order':1,'filename':'None'},
    {'url':"https://leadfinderservices.com/wp-content/uploads/apollo13_images/LEAD-FINDER-CLIENTS-executive-cocaches-and-life-coaches-top-title-bar2-6o33geciathd59hfxnatyapf48r5bwycqq.png",'order':2,'filename':'None'},
    ],
    labels:'Payments,Food,Shopping'}, [NumberInput, TextInput, TextInput, TextArea, ImageInput, TextInput],
  [{'label':'order', name:'order'}, {'label':'lead', name:'lead'},{'label':'title', name:'title'},{'label':'description', name:'description'},
  {'label':'images', name:'images', multiple:true},{'label':'labels (separate with commas, if you want to use a comma surround by "")', name:'labels'}]],

  'MiddleContent2': [MiddleContent2, {image:"http://rifetheme.com/wp-content/uploads/2018/02/rife-free21.jpg",
  quote:'YOUR PASSION FOR SALES, OUR PASSION FOR LEAD GENERATION', who:'LEAD FINDER'},
   [NumberInput, ImageInput, TextInput, TextInput], [{'label':'order', name:'order'}, {'label':'image', name:'image'},
    {'label':'quote', name:'quote'}, {'label':'who', name:'who'}]],

  'Service': [Service, {'title':'ARE YOU TIRED OF PRODUCING CONTENT THAT NOBODY READS?',
  description:'You need to scale how many people see you on a daily basis! You need to expand your reach! More people seeing you means more potential clients for your business.',
  image:"https://leadfinderservices.com/wp-content/uploads/2018/02/dai-ke-32162-unsplash.jpg", pictureRight:true},
  [NumberInput,TextInput, TextArea, ImageInput, Select],[{'label':'order', name:'order'},{'label':'title', name:'title'},
  {'label':'description', name:'description'},{'label':'image', name:'image', multiple:false},
  {'label':'Picture On Right?',name:'pictureRight', options:[{'text':'Yes', value:true},{'text':'No', value:false}]}]],

  'BottomContent': [BottomContent, {image:'https://leadfinderservices.com/wp-content/uploads/2018/03/lead-finder-video-rife-free-video-BG2.jpg',
  quote:'In A Fast And Furious World You Need A Clear Message To Get Results', who:'LEAD FINDER'},
   [NumberInput, ImageInput, TextInput, TextInput], [{'label':'order', name:'order'}, {'label':'image', name:'image'},
    {'label':'quote', name:'quote'}, {'label':'who', name:'who'}]],

  'BottomCTA': [BottomCTA, {title:'BUILD YOUR DREAM BUSINESS TODAY.',cta:'SIGN UP FOR YOUR FREE INTRODUCTORY CALL',href:'/contact-us/',startingColor:'#3957ff',endingColor:'#f2295b'},
  [NumberInput,TextInput,TextInput,TextInput, ColorPicker,ColorPicker], [{'label':'order', name:'order'},{'label':'title', name:'title'},
  {'label':'cta', name:'cta'},{'label':'href', name:'href'},{'label':'startingColor', name:'startingColor'},{'label':'endingColor', name:'endingColor'}]],

  'AboutCTA': [AboutCTA, {'title':'WE HELP BUSINESS OWNERS HIT SALES GOALS',
  description:"I'm genuinely passionate about helping my clients increase their bottom line. There's nothing better than when it all comes together for a client, and to see how excited and happy they are with the increase in their business through my efforts!",
  image:"https://leadfinderservices.com/wp-content/uploads/2018/02/dai-ke-32162-unsplash.jpg", pictureRight:true, href:'/contact-us/', cta:'SIGN UP FOR A FREE INTRODUCTORY CALL'},
  [NumberInput,TextInput, TextArea, ImageInput, Select, TextInput, TextInput],[{'label':'order', name:'order'},{'label':'title', name:'title'},
  {'label':'description', name:'description'},{'label':'image', name:'image', multiple:false},
  {'label':'Picture On Right?',name:'pictureRight', options:[{'text':'Yes', value:true},{'text':'No', value:false}]},
   {'label':'cta', name:'cta'}, {'label':'href', name:'href'}]],

   'StatList':[StatList, {'titles':'25+,"11,000+",1',
    'descriptions':'PERSUAVIVE TOOLS TO LAND MORE DEALS,LEADS IN 2017,GREAT SALES FUNNEL IS ALL YOU NEED'},
    [NumberInput,TextInput, TextInput], [{'label':'order', name:'order'},{'label':'titles (separate with commas, if you want to use a comma surround by "")', 'name':'titles'},{'label':'description (separate with commas, if you want to use a comma surround by "")', 'name':'descriptions'}]],

    'ServicesList':[ServicesList, {'titles':'OUR FACEBOOK MARKETING PROCESS,OUR TWITTER MARKETING PROCESS,OUR INSTAGRAM MARKETING PROCESS',
     'descriptions':'Test,Test,Test', icons: 'facebook-f,twitter,instagram', title:'WHAT WE OFFER!', lead: 'OUR SIMPLE SYSTEM OF EMOTIONALLY INTELLIGENT LEAD DEVELOPMENT WILL GET YOU MARKETING THAT GETS RESULTS.'},
     [NumberInput,TextInput, TextInput, TextArea, TextArea, TextInput], [{'label':'order', name:'order'},{'label':'title', name:'title'},{'label':'lead', name:'lead'},
     {'label':'titles (separate with commas, if you want to use a comma surround by "")', 'name':'titles'},
     {'label':'description (separate with commas, if you want to use a comma surround by "")', 'name':'descriptions'},
   {'label':'icons (separate with commas, if you want to use a comma surround by "")', 'name':'icons'}]],

   'ContactForm':[ContactForm, {'to_email':'support@directdocs.com', 'subject':'Email From My Website'},
   [NumberInput,TextInput,TextInput],[{'label':'order', name:'order'},{'label':'Send Email To', name:'to_email'},{'label':'Email Subject Line', name:'subject'}]],

   'WhiteSpace':[WhiteSpace, {'height':'100px'},[NumberInput,TextInput],[{'label':'order', name:'order'},{'label':'White Space Amount', name:'height'}]],

};



function sorter(a,b) {
  var order_a = parseInt(a[1].order)
  var order_b = parseInt(b[1].order)
  if (order_a > order_b) {
    console.log("A Greater", order_a, order_b)
    return 1
  }
  else {
    console.log("B Greater", order_a, order_b)
    return -1
  }
}

class ComponentInstance extends Component {
  constructor(props) {
    super(props);
    this.setComponent = this.setComponent.bind(this);
  }

  setComponent() {
    this.props.setComponent(this.props.index)
  }

  render() {
    var type = 'outline-dark';

    if (this.props.selectedComponent == this.props.index) {
      type = 'primary'
    }


    return (
      <div>
      <Button onClick={this.setComponent} text={this.props.name} type={type}/>
      </div>
    )
  }
}


class AddComponent extends Component {
  constructor(props) {
    super(props);
    this.setComponent = this.setComponent.bind(this);
  }

  setComponent() {
    this.props.addComponent(this.props.name)
  }

  render() {
    var type = 'outline-dark';

    return (
      <div>
      <Button onClick={this.setComponent} text={this.props.name} type={type}/>
      </div>
    )
  }
}

class DisplayInstance extends Component {
  constructor(props) {
    super(props);
    this.setComponent = this.setComponent.bind(this);
  }

  setComponent() {
    if (!this.props.show) {
      this.props.setComponent(this.props.index)
    }
  }

  render() {

    return (
      <div onClick={this.setComponent} style={this.props.style}>{this.props.content}</div>
    )
  }
}

class PageBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {components: [], componentProps:[], selectedComponent: -1, loaded: false, adding:false, name:'', url:''};

        this.addComponent = this.addComponent.bind(this);
        this.setComponent = this.setComponent.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.setGlobalStateName = this.setGlobalStateName.bind(this);
        this.save = this.save.bind(this);
        this.reload = this.reload.bind(this);
        this.load = this.load.bind(this);
    }

    componentDidMount() {
      if (this.props.page_id) {
        ajaxWrapper('GET','/api/modelWebsite/page/' + this.props.page_id + '/', {}, this.load)
      }
      else if (this.props.show) {
        ajaxWrapper('GET','/api/modelWebsite/page/?url=' + this.props.route, {}, this.load)
      }
      else {
        this.setState({loaded:true})
      }
    }

    load(result) {
      console.log("Result",result)
      if (result.length > 0) {
        var page = result[0]['page'];
        var components = JSON.parse(page['components'])
        var componentProps = JSON.parse(page['componentProps'])
        this.setState({components: components, componentProps:componentProps, name:page['name'], url:page['url'], loaded:true})
      }
      else {
        this.setState({loaded:true})
      }
    }

    setGlobalStateName(name,state) {
      this.setState(state)
    }

    setGlobalState(name, state) {
      var components = this.state.components;
      var componentProps = this.state.componentProps;
      componentProps[this.state.selectedComponent] = state;


      var combined = []
      for (var index in components) {
        combined.push([components[index], componentProps[index]])
      }

      combined.sort(sorter)

      var newComponents = []
      var newComponentProps = []

      for (var index in combined) {
        newComponents.push(combined[index][0])
        newComponentProps.push(combined[index][1])
      }

      this.setState({componentProps:newComponentProps, components:newComponents})
    }

    addComponent(componentName) {
      var components = this.state.components;
      var componentProps = this.state.componentProps;

      components.push(componentName)
      var componentPropsInstance = ComponentDict[componentName][1]
      componentPropsInstance['order'] = components.length;
      componentProps.push(componentPropsInstance)

      this.setState({components: components, componentProps:componentProps})
    }

    setComponent(index) {
      this.setState({selectedComponent: index})
    }

    save() {
      var submitUrl = '/api/modelWebsite/page/'
      if (this.props.page_id) {
        submitUrl += this.props.page_id + '/'
      }
      var data = {'components':JSON.stringify(this.state.components), componentProps: JSON.stringify(this.state.componentProps), name:this.state.name, url:this.state.url}

      ajaxWrapper('POST',submitUrl, data, this.reload)

    }

    reload(result) {
      window.location.href = '/pagebuilder/' + result[0]['page']['id'] + '/'
    }

    render() {

      var display = [];
      for (var index in this.state.components) {
        var TempComponent = ComponentDict[this.state.components[index]][0];
        if (this.state.selectedComponent == index) {
          display.push(<DisplayInstance show={this.props.show} content={<TempComponent {...this.state.componentProps[index]} />} index={index} setComponent={this.setComponent} style={{'border':'2px solid #0f0'}}/>)
        }
        else {
          display.push(<DisplayInstance show={this.props.show} content={<TempComponent {...this.state.componentProps[index]} />} index={index} setComponent={this.setComponent} />)
        }

      }

      var componentList = [];
      for (var index in this.state.components) {
        componentList.push(<ComponentInstance adding={this.state.adding} name={this.state.components[index]} index={index} setComponent={this.setComponent} selectedComponent={this.state.selectedComponent} />)
      }

      var componentPropsForm = null;

      if (this.state.selectedComponent > -1) {
        var selected_component_name = this.state.components[this.state.selectedComponent]
        var defaults = this.state.componentProps[this.state.selectedComponent]

        var componentPropsForm = <Form components={ComponentDict[selected_component_name][2]} autoSetGlobalState={true} setGlobalState={this.setGlobalState}
        globalStateName={'form'} componentProps={ComponentDict[selected_component_name][3]}
        defaults={defaults} />
      }

      var componentColumn = <div>
        <h1>Component List</h1>
        <Button text={'Add Component'} type={'success'} onClick={() => this.setState({'adding':true})} />
        {componentList}
      </div>
      if (this.state.adding) {
        var addableComponents = [];
        for (var index in ComponentDict) {
          addableComponents.push(<AddComponent name={index} addComponent={this.addComponent} />)
        }

        var componentColumn = <div>
          <h1>Add A Component</h1>
          <Button text={'Go Back'} type={'success'} onClick={() => this.setState({'adding':false})} />
          {addableComponents}
        </div>
      }

      var nameComponents = [TextInput, TextInput]
      var nameComponentProps = [{'name':'name', label:'name', placeholder:'Page Name'}, {'name':'url', label:'url', 'placeholder':'/'}]
      var content = <p>No page exists here.</p>
      if (this.props.show) {
        if (display.length > 0) {
          var content =
          <div>
              {display}
          </div>;
        }
        else if (this.props.user && this.props.user.is_staff == true){
          var content = <div className="container">
            <h3>This page doesn't exist yet. :(</h3>
            <Button text={'Create Page'} type={'success'} href={'/pages/'} />
          </div>
        }

      }
      else {
        var content =
        <div>
          <div className="container">
            <a href='/pages/'>See All Pages</a>
            <Form components={nameComponents} componentProps={nameComponentProps} defaults={this.state} autoSetGlobalState={true} setGlobalState={this.setGlobalStateName} globalStateName={'form'} />
          </div>
          <div className="row">
            <div className="col-3">
              {componentColumn}
              <Button text={'save'} type={'success'} onClick={this.save} />
            </div>
            <div className="col-3">
              <h1>Edit Props</h1>
              {componentPropsForm}
            </div>
            <div className="col-6" style={{'overflow-y':'scroll', height:'750px'}}>
              {display}
            </div>


          </div>

        </div>;
      }

        return (
                <Wrapper loaded={this.state.loaded} content={content} />
             );
    }
}
export default PageBuilder;

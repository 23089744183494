
import React, { Component } from 'react';
import sort_objects from 'base/sort_objects.js';
import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import {Container, Button, Image, Form, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText, Select, Icon, EmptyModal, NumberInput, FormWithChildren} from 'library';
import format_date from 'base/date_format.js';
import format_date_string from 'base/date_string_format.js';
import HelpVideo from 'projectLibrary/help_video.js';
import { IgrRadialGaugeModule } from "igniteui-react-gauges";
import { IgrRadialGauge, IgrRadialGraphRange, IgrRadialGaugeRange } from "igniteui-react-gauges";
import ReactPaginate from 'react-paginate';

IgrRadialGaugeModule.register();


function compare(a, b) {
    if (a['patient']['last_name'] > b['patient']['last_name']) return 1;
    if (a['patient']['last_name'] < b['patient']['last_name']) return -1;
    return 0;
}

class FilesToDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {loaded: false, downloadable_files:[]}
        this.request_callback = this.request_callback.bind(this);
        this.get_data = this.get_data.bind(this);
    }

    componentDidMount() {
        this.get_data();
    }

    get_data() {
        console.log("Getting Data For Download" , this.props)
        ajaxWrapper('GET','/api/home/request/?related__patient__related__company=' + this.props.company_id + '&related=files,files__file_accesses,patient,provider&user=' + this.props.user.id, {}, this.request_callback)
    }

    request_callback(result) {
        console.log("In the request callback",result)
        this.props.setValues(1,10,result.count)
        var downloadable_files = [];

        for (var index in result.results) {
            var request = result.results[index]['file']
                            downloadable_files.push(
                                <tr>
                                  <td><DownloadButton file={request} user={this.props.user} ip={this.props.ip} /></td>
                                  <td>{request.request.patient.first_name} {request.request.patient.last_name}</td>
                                  <td>{request.request.provider.name}</td>
                                </tr>
                            )
                        }

        this.setState({downloadable_files:downloadable_files, loaded:true, result:result})
    }

    render() {

        var content = <table className="table">
          <tr>
            <th>Download</th>
            <th>Patient</th>
            <th>Provider</th>
          </tr>
          {this.state.downloadable_files}
        </table>;

        return (
            <Wrapper loaded={this.state.loaded} content={content} />
        )
    }
}

class Download_All_Files extends Component {
    constructor(props) {
        super(props);

        this.state = {requests:[]}

        this.download_all_files = this.download_all_files.bind(this);
        this.get_data = this.get_data.bind(this);
    }

    get_data() {
        ajaxWrapper('GET',`/api/download-records/${this.props.user.company_id}/?client=${this.props.patient.patient_id}&user=${this.props.user.id}`, {}, this.download_all_files)
    }

    download_all_files(result) {
        for (var index in result) {
            var request = result[index]['request'];
            console.log("Download files for Request", request, this.props)
            if (request.patient_id == this.props.patient.id) {
                for (var file_index in request.files) {
                    this.fileDownload(request.files[file_index].file);
                }
            }
        }
    }

    fileDownload(file) {
        console.log("File Download", file)
        var file_id = file['id'];

        ajaxWrapper('POST','/api/home/fileaccess/', {'action':'DOWNLOAD', ip: this.props.ip, file: file_id, user: this.props.user.id}, ()=> this.getFileUrl(file['url']))
    }

    getFileUrl(url) {
        ajaxWrapper("POST", "/getFileUrl/", {"url":url}, this.goToFile)
    }

    goToFile(result) {
        window.open(result['url'], '_blank')
    }

    render() {
        console.log(this.props, 'props-----');
        var patient_name = this.props.patient.patient__first_name.trim() + ' ' + this.props.patient.patient__last_name.trim();
        return (
            <tr>
                <td>{patient_name}</td>
                <td><Button type='primary' text='Download All Files' onClick={this.get_data} /></td>
            </tr>
        )
    }
}


class SortableTitle extends Component {
    constructor(props) {
        super(props);

        this.sort = this.sort.bind(this);
    }

    sort() {
        this.props.sort(this.props.name);
    }

    render() {

        var sorting = null;
        if (this.props.name == this.props.sort_by) {
            sorting = <span>({this.props.direction})</span>
        }

        return (
            <th style={{pointer:'cursor'}}><a onClick={this.sort}>{this.props.name} {sorting}</a></th>
        )
    }
}

class DownloadButton extends Component {
    constructor(props) {
        super(props);
        this.state = {downloaded:false};

        this.fileDownload = this.fileDownload.bind(this);
        this.goToFile = this.goToFile.bind(this);
        this.getFileUrl = this.getFileUrl.bind(this);
    }

    fileDownload() {
        var file_id = this.props.file['id'];
        console.log("User Id", this.props.user.id);
        if (window.location.pathname == '/dashboard/e4f3c0a1-5e41-4541-98e4-8288353c7684/') {
            this.getFileUrl(this.props.file['url'])
        }
        else {
            ajaxWrapper('POST','/api/home/fileaccess/', {'action':'DOWNLOAD', ip: this.props.ip, file: file_id, user: this.props.user.id}, ()=> this.getFileUrl(this.props.file['url']))
        }
    }

    getFileUrl(url) {
        ajaxWrapper("POST", "/getFileUrl/", {"url":url}, this.goToFile)
    }

    goToFile(result) {
        this.setState({downloaded:true})
        window.open(result['url'], '_blank')
    }

    render() {
        if (!this.state.downloaded) {
            return (
                <Button type={'primary'} text='Download' onClick={this.fileDownload} />
            )
        }

        else {
            return (
                <Button type={'danger'} text='Downloaded' disabled={true} />
            )
        }

    }
}

class ViewLawyerRequests extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
    this.closedHandler = this.closedHandler.bind(this);
  }

  clickHandler() {
    this.props.setGlobalState('form', {selectedUser: this.props.index, request_status_filter: 'open'})
  }

  closedHandler() {
    this.props.setGlobalState('form', {selectedUser: this.props.index, request_status_filter: 'closed'})
  }

  render() {
      if (this.props.status == 'open') {
        return (
          <Button type='primary' text='View Open' onClick={this.clickHandler} />
        )
    }
    else {
        return (
          <Button type='danger' text='View Closed' onClick={this.closedHandler} />
        )
    }
  }
}

class RequestList extends Component {
    constructor(props) {
        super(props);

        var selectedUser = ''


        this.state = {downloadable_files:[] ,open_request_loader: false ,company:{}, loaded: false, selectedUser: selectedUser, lawyer_filter: '', patient_filter: '', provider_filter:'', status_filter: '', request_status_filter: 'open', files_to_download:[], ip:'', days_old_filter: 0, sort_by:'', direction:'Ascending', all_requests:false, patient_filter_name:'', is_staff: false, total_count: 0, limit: 10, page_offset: 1, open_requests: [], requests_open_attention: [], open_page_offset: 1, open_total_count: 0, open_page_count: 0, open_limit: 10, requests_download_attention: [], download_page_offset: 1, download_total_count: 0, download_page_count: 0, download_limit: 10, requests_needing_attention: [], need_page_offset: 1, need_total_count: 0, need_page_count: 0, need_limit: 10,all_requests_open: "Yes", sort_column:""}

        this.objectCallback = this.objectCallback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.download_files = this.download_files.bind(this);
        this.fileDownload = this.fileDownload.bind(this);
        this.getFileUrl = this.getFileUrl.bind(this);
        this.goToFile = this.goToFile.bind(this);
        this.getIp = this.getIp.bind(this);
        this.sort = this.sort.bind(this);
        this.get_data = this.get_data.bind(this);
        this.toggle_all_requests = this.toggle_all_requests.bind(this);
        this.toggle_all_requests_open = this.toggle_all_requests_open.bind(this);
        this.get_user = this.get_user.bind(this);
        this.getNeedAttention = this.getNeedAttention.bind(this);
        this.get_need_attention = this.get_need_attention.bind(this);
        this.handleNeedPageClick = this.handleNeedPageClick.bind(this);
        this.handleNeedLimitChange = this.handleNeedLimitChange.bind(this);
        this.handleOpenPageClick = this.handleOpenPageClick.bind(this);
        this.handleOpenLimitChange = this.handleOpenLimitChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleLimitChange = this.handleLimitChange.bind(this);
        this.handleDownloadPageClick = this.handleDownloadPageClick.bind(this);
        this.handleDownloadLimitChange = this.handleDownloadLimitChange.bind(this);
        this.objectCallbackNew = this.objectCallbackNew.bind(this);
        this.objectCallbackOpenRequest = this.objectCallbackOpenRequest.bind(this);
        this.request_callback = this.request_callback.bind(this);
        this.getDownloadApi = this.getDownloadApi.bind(this);
    }

    handleApi(limit, page_offset, days_old, patient, lawyer, status, provider, open_request, order_by = '') {
        this.setState({loading : true})
        this.setState({ loader: true })
        if (order_by != '') {
            ajaxWrapper('GET', `/api/requests-company/${this.props.user.company_id}/?page=${decodeURIComponent(page_offset)}&page_size=${limit}&open_request=${open_request}&paralegal=${decodeURIComponent(lawyer)}&client=${decodeURIComponent(patient)}&provider=${decodeURIComponent(provider)}&status=${decodeURIComponent(status)}&days_old=${days_old}&order_by=${order_by}`, {}, this.objectCallbackNew);
        } else if (this.state.sort_column != '') {
            ajaxWrapper('GET', `/api/requests-company/${this.props.user.company_id}/?page=${decodeURIComponent(page_offset)}&page_size=${limit}&open_request=${open_request}&paralegal=${decodeURIComponent(lawyer)}&client=${decodeURIComponent(patient)}&provider=${decodeURIComponent(provider)}&status=${decodeURIComponent(status)}&days_old=${days_old}&order_by=${this.state.sort_column}`, {}, this.objectCallbackNew);
        } else {
            ajaxWrapper('GET', `/api/requests-company/${this.props.user.company_id}/?page=${decodeURIComponent(page_offset)}&page_size=${limit}&open_request=${open_request}&paralegal=${decodeURIComponent(lawyer)}&client=${decodeURIComponent(patient)}&provider=${decodeURIComponent(provider)}&status=${decodeURIComponent(status)}&days_old=${days_old}`, {}, this.objectCallbackNew);
        }
    }

     handleApiOpenRequest(limit, page_offset, days_old, patient, lawyer, status, provider, open_request, order_by = '') {
        this.setState({loading : true , open_request_loader: true})
        this.setState({ loader: true })
         ajaxWrapper('GET', `/api/requests-company/${this.props.user.company_id}/?page=${decodeURIComponent(page_offset)}&page_size=${limit}&open_request=${open_request}&paralegal=${decodeURIComponent(lawyer)}&client=${decodeURIComponent(patient)}&provider=${decodeURIComponent(provider)}&status=${decodeURIComponent(status)}&days_old=${days_old}&return_patients=true`, {}, this.objectCallbackOpenRequest);
    }

    objectCallbackOpenRequest(result) {
        var patient_dict = [];
        this.setState({ open_requests: result })
        this.setState({ open_total_count: result.count })
        for (var request_index in result.results) {
            var request = result.results[request_index]
            var patient = request;
                patient_dict.push(patient);
        }
        this.setState({
            patient_dict: patient_dict,
        })
        this.setState({loading: false, open_request_loader: false})
    }

    objectCallbackNew(result) {
        var requests_by_lawyer = [];
        this.setState({ open_requests: result })
        this.setState({ total_count: result.count })
        for (var request_index in result.results) {
            var request = result.results[request_index]['request'];
            var patient = request.patient;

            request['patient'] = {
                first_name: request['patient']['first_name'],
                last_name: request['patient']['last_name']
            };
            var lawyer = request['user']['full_name'];
            requests_by_lawyer.push(request);
        }
        this.setState({
            requests_by_lawyer: requests_by_lawyer
        })
        this.setState({loading: false})
    }

    handlePageClick = (event) => {
        this.setState({ page_offset: event.selected + 1 })
        this.handleApi(this.state.limit, event.selected + 1, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open);
    }

    handleLimitChange(e) {
        this.setState({ limit: parseInt(e.target.value) });
        if (parseInt(e.target.value) > this.state.total_count) {
            this.setState({ page_offset: 1 });
        }
        if (this.state.page_offset * parseInt(e.target.value) > this.state.total_count) {
            this.setState({ page_offset: parseInt(this.state.total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.total_count / parseInt(e.target.value)) });
        }
    }

    handleDownloadPageClick = (event) => {
        this.setState({ download_page_offset: event.selected + 1 })
        this.getDownloadApi(event.selected + 1, this.state.download_limit)
    }

    handleDownloadLimitChange(e) {
        this.setState({ download_limit: parseInt(e.target.value) });
        this.setState({ download_page_offset: 1 });
    }

    handleOpenPageClick = (event) => {
        this.setState({ open_page_offset: event.selected + 1 })
        this.handleApiOpenRequest(this.state.open_limit, event.selected + 1, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open);
    }

    handleOpenLimitChange(e) {
        this.setState({ open_limit: parseInt(e.target.value) });
        if (parseInt(e.target.value) > this.state.open_total_count) {
            this.setState({ open_page_offset: 1 });
        }
        if (this.state.open_page_offset * parseInt(e.target.value) > this.state.open_total_count) {
            this.setState({ open_page_offset: parseInt(this.state.open_total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.open_total_count / parseInt(e.target.value)) });
        }
    }

    handleNeedPageClick = (event) => {
        this.setState({ need_page_offset: event.selected + 1 })
        this.get_need_attention(this.state.need_limit, event.selected + 1, "Yes", this.state.lawyer_filter);
    }

    handleNeedLimitChange(e) {
        this.setState({ need_limit: parseInt(e.target.value) });
        if (parseInt(e.target.value) > this.state.need_total_count) {
            this.setState({ need_page_offset: 1 });
            this.get_need_attention(parseInt(e.target.value), 1, "Yes", this.state.lawyer_filter);
        }
        if (this.state.need_page_offset * parseInt(e.target.value) > this.state.need_total_count) {
            this.setState({ need_page_offset: parseInt(this.state.need_total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.need_total_count / parseInt(e.target.value)) });
            this.get_need_attention(parseInt(e.target.value),parseInt(this.state.need_total_count / parseInt(e.target.value)) == 0 ? 1 : parseInt(this.state.need_total_count / parseInt(e.target.value)), "Yes", this.state.lawyer_filter);
        }
        else {
            this.setState({ need_page_offset: 1 });
            this.get_need_attention(parseInt(e.target.value), 1, "Yes", this.state.lawyer_filter);
        }
    }

    sort(name) {

        console.log('>>>>>>>>>>>>', name);
        let order_by = '';
        let direction = '';
        let opposite_direction = "-";
        if (name == 'Date Request Submitted') {
            order_by = 'created_at';
        }
        if (name == 'Request To Date') {
            order_by = 'end_date';
        }

        if (name == 'Request From Date') {
            order_by = 'start_date';
        }

        if (name == 'Paralegal') {
            order_by = 'user__full_name';
        }

        if (name == 'Client') {
            order_by = 'patient__full_name';
        }

        if (name == 'Provider') {
            order_by = 'provider__name';
        }

        if (name == 'Request Type') {
            order_by = 'request_type';
        }

        if (name == 'Status') {
            order_by = 'status';
        }

        if (name == 'Days Since Initial Request') {
            order_by = 'days_old';
        }

        if (name == 'Days To Receive') {
            order_by = 'days_to_receive';
        }

        if (name == 'Received Documents') {
            order_by = 'received_document';
        }

        if (name == 'Days To Download') {
            order_by = 'days_to_download';
        }

        if (name == 'Internal Request #') {
            order_by = 'internal_number';
        }

        console.log('>>>>>>>>>>>>', order_by);

        if (this.state.sort_by != name) {
            this.setState({ sort_by: name, direction: 'Ascending' })
        }
        else {
            if (this.state.direction == 'Ascending') {
                    this.setState({ direction: 'Descending' })
                    direction = '-';
            }
            else {
              
                    this.setState({ direction: 'Ascending' })
            }
        }

        if (order_by != '') {
                this.setState({ sort_column: direction + order_by })
                this.setState({page_offset : 1})
                this.handleApi(this.state.limit, 1, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open, direction + order_by);
        }
    }

    componentDidMount() {
        this.getDownloadApi(this.state.download_page_offset , this.state.download_limit);
        ajaxWrapper('GET','/api/user/user/' + this.props.user.id + '/', {}, this.get_user)
        this.handleApiOpenRequest(this.state.open_limit, 1, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open)
        this.handleApi(this.state.limit, this.state.page_offset, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open)
        this.get_need_attention(this.state.need_limit, this.state.need_page_offset, this.state.all_requests_open , this.state.lawyer_filter);
        ajaxWrapper('GET','/api/ip/',{},this.getIp);
    }

    getDownloadApi(page_offset , page_limit) {
        console.log('user is here now');
        ajaxWrapper('GET', `/api/download-request/${this.props.user.company_id}/?page=${page_offset}&page_size=${page_limit}&user=${this.props.user.id}`, {}, this.request_callback)
    }


    request_callback(result) {
        console.log("In the request callback",result)
        this.setState({download_total_count : result.count})
        var downloadable_files = [];

        for (var index in result.results) {
            var request = result.results[index]['file']
                            downloadable_files.push(
                                <tr>
                                  <td><DownloadButton file={request} user={this.props.user} ip={this.props.ip} /></td>
                                  <td>{request.request.patient.first_name} {request.request.patient.last_name}</td>
                                  <td>{request.request.provider.name}</td>
                                </tr>
                            )
                        }

        this.setState({downloadable_files:downloadable_files, loaded:true, result:result})
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.limit !== prevState.limit) {
            this.handleApi(this.state.limit, this.state.page_offset, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open)
        }
        if(this.state.need_limit !== prevState.need_limit) {
            this.get_need_attention(this.state.need_limit, this.state.need_page_offset, this.state.all_requests_open, this.state.lawyer_filter);
        }
        if(this.state.download_limit !== prevState.download_limit) {
            this.getDownloadApi(this.state.download_page_offset , this.state.download_limit)
        }
        if (this.state.name !== "" && prevState.name !== this.state.name) {
            this.setState({ page_offset: 1 });
            this.get_need_attention(this.state.need_limit, 1, this.state.all_requests_open, this.state.lawyer_filter);
            this.handleApi(this.state.limit, 1, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, this.state.all_requests_open);
            this.getDownloadApi(1 , this.state.download_limit)
        }
    }

    get_user(result) {
        var user = result[0]['user']
        var staff = user.is_staff;
        this.setState({is_staff: staff})
        this.get_data(false, staff)
    }

    
    toggle_all_requests() {
        this.setState({ all_requests_open: "Yes" })
        this.handleApi(this.state.limit, this.state.page_offset, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, 'Yes');
        this.handleApiOpenRequest(this.state.open_limit, 1, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, 'Yes')
        this.get_need_attention(this.state.need_limit, this.state.need_page_offset, "Yes", this.state.lawyer_filter);
        this.setState({ all_requests: !this.state.all_requests, loaded: false })
        this.get_data(!this.state.all_requests)
    }

    toggle_all_requests_open() {
        this.setState({ all_requests_open: "No" })
        this.handleApi(this.state.limit, this.state.page_offset, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, 'No');
        this.handleApiOpenRequest(this.state.open_limit, 1, this.state.days_old_filter, this.state.patient_filter, this.state.lawyer_filter, this.state.status_filter, this.state.provider_filter, 'No')
        this.get_need_attention(this.state.need_limit, this.state.need_page_offset, "No", this.state.lawyer_filter);
        console.log(!this.state.all_requests, '!this.state.all_requests');
        this.setState({ all_requests: !this.state.all_requests, loaded: false })
        this.get_data(!this.state.all_requests)
    }

    get_need_attention(need_page_count, need_page_offset, open_request , parallegal) {
        ajaxWrapper('GET', `/api/requests-company/${this.props.user.company_id}?page=${need_page_offset}&page_size=${need_page_count}&status=Need Assistance&open_request=${open_request}&paralegal=${parallegal}&client=&provider=&days_old=0`, {}, this.getNeedAttention);
    }

    getNeedAttention(result) {
        this.setState({ need_total_count: result.count })
        this.setState({ requests_needing_attention: result.results })
    }

    get_data(all_requests, staff) {

        if (!staff) {
            staff = this.state.is_staff;
        }
        var url = '/api/home/request/';
        url += '&order_by=created_at';

        ajaxWrapper('GET',url, {}, this.objectCallback);
    }

    getIp(result) {
        this.setState({ip: result['ip']});
    }

    setGlobalState(name, state) {
    this.setState({
        patient_filter: state.patient_filter ? decodeURIComponent(state.patient_filter) : "",
    })
    this.setState({
        provider_filter: state.provider_filter ? decodeURIComponent(state.provider_filter) : "",
    })
    this.setState({
        status_filter: state.status_filter ? decodeURIComponent(state.status_filter) : ""
    })
    this.setState({
        days_old_filter: state.days_old_filter ? state.days_old_filter : ""
    })
    this.setState({
        lawyer_filter: state.lawyer_filter ? decodeURIComponent(state.lawyer_filter) : "",
    })
    this.get_need_attention(this.state.need_limit, this.state.need_page_offset, this.state.all_requests_open , state.lawyer_filter ? state.lawyer_filter : "");
    this.handleApi(this.state.limit, 1, state.days_old_filter ? state.days_old_filter : "", state.patient_filter? state.patient_filter: "", state.lawyer_filter? state.lawyer_filter: "", state.status_filter ? state.status_filter : "", state.provider_filter ? state.provider_filter : "", this.state.all_requests_open)
    this.handleApiOpenRequest(this.state.open_limit, 1, state.days_old_filter ? state.days_old_filter : "", state.patient_filter? state.patient_filter: "", state.lawyer_filter? state.lawyer_filter: "", state.status_filter ? state.status_filter : "", state.provider_filter ? state.provider_filter : "", this.state.all_requests_open)
    }

    download_files() {
        for (var index in this.state.files_to_download) {
            console.log("Download File", index)
            var file = this.state.files_to_download[index];
            setTimeout(() => this.fileDownload(file), 1000 * index);
        }
    }

    fileDownload(file) {
        var file_id = file['id'];
        ajaxWrapper('POST','/api/home/fileaccess/', {'action':'DOWNLOAD', ip: this.state.ip, file: file_id, user: this.props.user.id}, ()=> this.getFileUrl(file['url']))
    }

    getFileUrl(url) {
        ajaxWrapper("POST", "/getFileUrl/", {"url":url}, this.goToFile)
    }

    goToFile(result) {
        var link = document.createElement("a");
          link.download = result['url'];
          link.href = result['url'];
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    }

    objectCallback(result) {
        var now = new Date()
        var files_to_download = [];
        var patients = [];

        var requests_by_lawyer = {};
        var patient_dict = {};

        this.setState({
            files_to_download:files_to_download,
            loaded:true
        });
    }

    render() {

      var content = null;
      if (this.state.loaded) {

        var requests = [];
        var downloadable_files = [];
        var requests_needing_attention = [];

        var outstanding_requests = 0
        var total_days_since_request = 0
        var total_requests_received = 0;
        var total_time_to_receive = 0;

        for (var index in this.state.requests_by_lawyer) {
            var request = this.state.requests_by_lawyer[index]
            var patient = request['patient']
            var patient_name = patient.first_name.trim() + ' ' + patient.last_name.trim();
            var days_to_receive = '';
            var received_documents = '';
            var days_to_download = '';
            var downloaded = '';

            if (request.files.length > 0) {
                received_documents = request.files[0]['file'].created_at
                days_to_receive = ((Date.parse(request.files[0]['file'].created_at) - Date.parse(request.created_at)) / 86400000);


                var days_since_created = (now - Date.parse(request.created_at)) / 86400000
                if (days_since_created < 90) {
                    if (request.completed_date && request.completed_date != '') {
                        total_time_to_receive += ((Date.parse(request.completed_date) - Date.parse(request.created_at)) / 86400000);
                        total_requests_received += 1;
                    }
                }


                if (request.files[0]['file'].file_accesses.length > 0) {
                    for (var access_index in request.files[0]['file'].file_accesses) {
                        var access = request.files[0]['file'].file_accesses[access_index]['fileaccess'];
                        if (access.action == 'DOWNLOAD') {
                            if (access.user_id == '3f46c2b5-4a69-47de-9df0-cdd898658c30') {
                                console.log("Downloaded By Harlo", access)
                            }
                            else {
                                days_to_download = ((Date.parse(access.created_at) - Date.parse(request.files[0]['file'].created_at)) / 86400000);
                            }
                        }

                    }
                }



            }
            else if (request.status.toLowerCase().indexOf('closed') == -1) {
                console.log("Request Status 2")
                outstanding_requests += 1;
            }

            if (request.active == true) {
                var today = new Date();
                var request_date = Date.parse(request.created_at)
                var days_old = (today - request_date) / 86400000
                if (days_old > this.state.days_old_filter) {
                    if (request.provider) {
                        requests.push({
                            'id': request.id , internal_number : request.internal_number, 'days_old': days_old, 'created_at': request.created_at, days_to_receive: request.days_to_receive, received_documents: request.received_document,
                            days_to_download: request.days_to_download, downloaded: downloaded, patient_name: patient_name, patient_last_name: patient.last_name, provider_name: request.provider.name, status: request.status, request_type: request.request_type, company: patient.company_id , lawyer_requests_name : request.user.full_name, start_date:request.start_date,end_date:request.end_date,received_document:request.received_document
                        })
                    }
                    else {
                        console.log("Request Missing Provider", request)
                    }
                }
            }
        }


        var request_display = [];
        var request_company_check = {}
        for (var index in requests) {
            var request = requests[index];

            if (!request_company_check[request.company]) {
                request_company_check[request.company] = 0
            }
            request_company_check[request.company] += 1;

            var days_to_receive = request.days_to_receive;
            if (days_to_receive) {
                days_to_receive = days_to_receive.toFixed(0)
            }

            var days_to_download = request.days_to_download;
            if (days_to_download) {
                days_to_download = days_to_download.toFixed(0)
            }

            request_display.push(<tr>
                      <td className="no-print"><Button type='primary' text='View' href={"/request/" + request.id + '/'} target={'_blank'} /></td>
                      <td>{request.internal_number}</td>
                      <td>{format_date_string(request.created_at,'mm/dd/yyyy', false)}</td>
                      <td>{request.lawyer_requests_name}</td>
                      <td>{request.patient_name}</td>
                      <td>{request.provider_name}</td>
                      <td>{format_date_string(request.start_date,'mm/dd/yyyy', false)}</td>
                      <td>{format_date_string(request.end_date,'mm/dd/yyyy', false)}</td>
                      <td>{request.request_type}</td>
                      <td>{request.status}</td>
                      </tr>)

        }

        var request_company_check_list = [];
        if (this.props.user.id == '57d0be71-b98c-4360-8a23-1e5ba4a0808b') {
            for (var index in request_company_check) {
                request_company_check_list.push(<p>{index}: {request_company_check[index]}</p>)
            }
        }

        if (outstanding_requests == 0) {
          var average_days_since_request = 0
        }
        else {
          var average_days_since_request = Math.floor(total_days_since_request/outstanding_requests)
        }


        var status_options = [
           {'text': 'All Open', value: 'Open'},
          {'text':'Requested By Attorney ', value:'Requested By Lawyer'},
          {'text':'Sent the Initial Medical Records Request', value:'Sent the Initial Medical Records Request'},
          {'text': 'In Contact With Provider', 'value': 'In Contact With Provider'},
          {'text': 'Provider Is Sending Documents', 'value': 'Provider Is Sending Documents'},
          {'text': 'Need Assistance', value: 'Need Assistance'},
          {'text': 'Completed - Documents Arrived', 'value': 'Completed - Documents Arrived'},
          {'text': 'Completed - No Documents Available', 'value': 'Completed - No Documents Available'},
          {'text': 'Closed - Documents Arrived', 'value': 'Closed - Documents Arrived'},
          {'text': 'Closed - No Documents Available', 'value': 'Closed - No Documents Available'},
          {'text': 'Closed - Cancelled Request', value: 'Closed - Cancelled Request'},
          {'text': 'All Closed', value: 'Closed'},
        ]

          var componentList = [TextInput, TextInput, TextInput, Select, NumberInput]
          var lawyer_filter = {'name':'lawyer_filter', label:'Requestor'}
          var patient_filter = {'name':'patient_filter', label:'Client'}
          var provider_filter = {'name':'provider_filter', label:'Provider'}
          var status_filter = {'name':'status_filter', label:'Status', options: status_options}
          var days_old_filter = {'name':'days_old_filter', label:'Days Old'}


          var filter_form = <Form row={true} col_size={3} components={componentList} defaults={this.state} componentProps={[lawyer_filter,patient_filter, provider_filter,status_filter, days_old_filter]}
            setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'filter'} />
        var patient_requests = null;
        for (var index in this.state.requests_by_lawyer) {
            patient_requests = this.state.requests_by_lawyer[index].requests;
        }

        var patient_list = [];
        for (var index in this.state.patient_dict) {
            var patient = this.state.patient_dict[index]
                patient_name = patient.patient__first_name.trim() + ' ' + patient.patient__last_name.trim()
                if (this.state.patient_filter == '' || patient_name.toLowerCase().indexOf(this.state.patient_filter.toLowerCase()) > -1) {
                    patient_list.push(<Download_All_Files patient={patient} user={this.props.user} ip={this.props.ip} requests={patient_requests} />)
                }

        }

        var all_requests_title = "All Requests"
        var all_requests_button = 'View Only Open Requests';
        var client_title = "All Clients"
        if (!this.state.all_requests) {
            all_requests_title = 'Open Requests';
            client_title = 'Clients With Open Requests';
            all_requests_button = 'View All Requests';
        }

        var content =
          <div className="container-fluid" style={{padding:'10px', position:'relative'}}>
          <div className="no-print">
          <HelpVideo src="https://companyforms.s3.us-east-2.amazonaws.com/Law+Firm+-+Company+Dashboard.mp4" />
          <Button href={'/editRequest/' + this.props.user.company_id + "/"} type='success' text='Start New Request' style={{marginBottom:'20px'}}/>
          </div>

            <div className="row">
            <div className="col-4 no-print">
            <br />
            <div style={{
                margin: "20px",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "0px"
            }}>
                <div 
                    style={{
                    margin: "0px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "0px",
                    marginBottom: "0px"
                }}>
                    Total Records : {this.state.download_total_count}
                    {
                        this.state.download_total_count > 0 ?
                            <select
                                className="form-control"
                                style={{ width: "85px", marginLeft: "10px" }}
                                onChange={this.handleDownloadLimitChange}
                            >
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                                <option>40</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                            : ""
                    }
                </div>
                
                <div style={{
                    display: 'flex',
                    alignItems: "center",
                    justifyContent:"flex-end"
                }}>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next"
                        onPageChange={this.handleDownloadPageClick}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        pageCount={Math.ceil(this.state.download_total_count / this.state.download_limit)}
                        previousLabel="Previous"
                        renderOnZeroPageCount={null}
                        className="custom-pagination-dashboard"
                        forcePage={this.state.download_page_offset - 1}
                    />
                </div>
            </div>
              <Header size={2} text={'Download Records'} style={{'marginBottom':'20px', marginTop:'20px', borderBottom:'1px solid black', textAlign:'left'}} />
              <div style={{maxHeight:'500px', overflowY:'scroll'}}>
                <table className='table'>
              <tr>
            <th>Download</th>
            <th>Patient</th>
            <th>Provider</th>
          </tr>
          {this.state.downloadable_files}
                </table>

              </div>

            </div>

              <div className="col-4 no-print">
              <br />
                    <div style={{
                        margin: "20px",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "0px",
                        marginBottom: "0px"
                    }}>
                        <div 
                          style={{
                           margin: "0px",
                           display: "flex",
                           justifyContent: "flex-end",
                           alignItems: "center",
                           marginTop: "0px",
                           marginBottom: "0px"
                        }}>
                            Total Records : {this.state.need_total_count}
                            {
                                this.state.need_total_count > 0 ?
                                    <select
                                        className="form-control"
                                        style={{ width: "85px", marginLeft: "10px" }}
                                        onChange={this.handleNeedLimitChange}
                                    >
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </select>
                                    : ""
                            }
                        </div>
                        
                        <div style={{
                            display: 'flex',
                            alignItems: "center",
                            justifyContent:"flex-end"
                        }}>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={this.handleNeedPageClick}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                pageCount={Math.ceil(this.state.need_total_count / this.state.need_limit)}
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                className="custom-pagination-dashboard"
                                forcePage={this.state.need_page_offset - 1}
                            />
                        </div>
                    </div>
                <div>
                <Header size={2} text={'Need Your Attention'} style={{'marginBottom':'20px', marginTop:'20px', borderBottom:'1px solid black'}} />
                <div style={{maxHeight:'500px', overflowY:'scroll'}}>
                <table className="table">
                  <tr>
                    <th>View</th>
                    <th>Patient</th>
                    <th>Provider</th>
                  </tr>
                  {
                    this.state.requests_needing_attention && this.state.requests_needing_attention.length > 0 && this.state.requests_needing_attention.map((obj, index) => {
                        return (
                            <tr>
                                <td><Button type='primary' text='View' href={"/request/" + obj.request.id + '/'} target={'_blank'} /></td>
                                <td>{obj.request.patient.first_name} {obj.request.patient.last_name}</td>
                                <td>{obj.request.provider.name}</td>
                            </tr>
                        )
                    })
                 }
                </table>
                </div>
                </div>
              </div>

              <div className="col-4 no-print">
              <br />
                    <div style={{
                        margin: "20px",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "0px",
                        marginBottom: "0px"
                    }}>
                        <div 
                          style={{
                           margin: "0px",
                           display: "flex",
                           justifyContent: "flex-end",
                           alignItems: "center",
                           marginTop: "0px",
                           marginBottom: "0px"
                        }}>
                            Total Records : {this.state.open_total_count}
                            {
                                this.state.open_total_count > 0 ?
                                    <select
                                        className="form-control"
                                        style={{ width: "85px", marginLeft: "10px" }}
                                        onChange={this.handleOpenLimitChange}
                                    >
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </select>
                                    : ""
                            }
                        </div>
                        
                        <div style={{
                            display: 'flex',
                            alignItems: "center",
                            justifyContent:"flex-end"
                        }}>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={this.handleOpenPageClick}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                pageCount={Math.ceil(this.state.open_total_count / this.state.open_limit)}
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                className="custom-pagination-dashboard"
                                forcePage={this.state.open_page_offset - 1}
                            />
                        </div>
                    </div>
                <div>
                <Header size={2} text={client_title} style={{'marginBottom':'20px', marginTop:'20px', borderBottom:'1px solid black'}} />
                <Button text={all_requests_button} onClick={()=> {all_requests_button == "View Only Open Requests" ? this.toggle_all_requests() : this.toggle_all_requests_open() }} type='primary' />
                <FormWithChildren autoSetGlobalState={true} globalStateName={'patient_name'} setGlobalState={this.setGlobalState}>
                    <TextInput name='patient_filter' label='Client Name' />
                </FormWithChildren>
                <div style={{maxHeight:'370px', overflowY:'scroll'}}>
                <table className="table">
                  <tr>
                    <th>Patient</th>
                    <th>Download All Files</th>
                  </tr>
                  {this.state.open_request_loader == true ? <div style={{position:"absolute" , top:"50%" , left:"50%"}}><img style={{width:"50%"}} src='/static/images/loading.gif'/></div> : patient_list}
                  {patient_list.length == 0 ? "No Record Found" : ""}
                </table>
                </div>
                </div>
              </div>

              <div className="col-12">
                  <Header size={2} text={all_requests_title} style={{'marginBottom':'20px', marginTop:'20px', borderBottom:'1px solid black'}} />
                  <Button text={all_requests_button} onClick={all_requests_button == "View Only Open Requests" ? this.toggle_all_requests : this.toggle_all_requests_open } type='primary' />

                  <div className="no-print">
                      {filter_form}
                      <br />
                                <div style={{
                                    margin: "20px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center"
                                }}>
                                    <div>
                                        Total Records : {this.state.total_count}
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: "center",
                                        marginLeft: "20px"
                                    }}>
                                        {
                                            this.state.total_count > 0 ?
                                                <select
                                                    className="form-control"
                                                    style={{ width: "85px", marginRight: "5px" }}
                                                    onChange={this.handleLimitChange}
                                                >
                                                    <option>10</option>
                                                    <option>20</option>
                                                    <option>30</option>
                                                    <option>40</option>
                                                    <option>50</option>
                                                    <option>100</option>
                                                </select>
                                                : ""
                                        }
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="Next"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            marginPagesDisplayed={2}
                                            pageCount={Math.ceil(this.state.total_count / this.state.limit)}
                                            previousLabel="Previous"
                                            renderOnZeroPageCount={null}
                                            className="custom-pagination"
                                            forcePage={this.state.page_offset - 1}
                                        />
                                    </div>
                                </div>
                      <Button onClick={() => window.print()} type='success' text='Print' style={{marginBottom:'20px'}}/>
                  </div>
                  {request_company_check_list}
                  <div style={{height:'600px', overflowY:'scroll'}} class="print">
                  <table className="table">
                    <tr>
                      <th className="no-print">View</th>
                      <SortableTitle name="Internal Request #" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                      <SortableTitle name="Date Request Submitted" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                      <SortableTitle name="Paralegal" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                      <SortableTitle name="Client" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                      <SortableTitle name="Provider" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                      <SortableTitle name="Request From Date" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                      <SortableTitle name="Request To Date" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                      <th>Request Type</th>
                      <SortableTitle name="Status" sort={this.sort} sort_by={this.state.sort_by} direction={this.state.direction} />
                    </tr>
                  {request_display}
                  </table>
                  </div>
              </div>
            </div>
          </div>;
        }
        else if (this.state.loaded) {
          var requests = [];

          if (!this.state.requests_by_lawyer[this.state.selectedUser]) {
              var lawyer_requests = {'requests':[], name: this.props.user.first_name + ' ' + this.props.user.last_name};
          }
          else {
              var lawyer_requests = this.state.requests_by_lawyer[this.state.selectedUser]
          }
          var now = new Date()
          for (var index in lawyer_requests['requests']) {
              var request = lawyer_requests['requests'][index]
              var patient = this.state.patient_dict[request['patient_id']]
              var patient_name = patient.first_name.trim() + ' ' + patient.last_name.trim();
              if (request.active == true) {
                  if (this.state.patient_filter == '' || patient_name.toLowerCase().indexOf(this.state.patient_filter.toLowerCase()) > -1) {
                    if (this.state.provider_filter == '' || request.provider.name.toLowerCase().indexOf(this.state.provider_filter.toLowerCase()) > -1) {
                      if (this.state.status_filter == '' || request.status.indexOf(this.state.status_filter) > -1) {
                        requests.push(<tr>
                          <td><Button type='primary' text='View' href={"/request/" + request.id + '/'} target={'_blank'} /></td>
                          <td>{patient_name}</td>
                          <td>{request.provider.name}</td>
                          <td>{request.status}</td>
                          </tr>)
                      }
                    }
                  }
              }
          }

        var status_options = [
          {'text':'Requested By Attorney', value:'Requested By Lawyer'},
          {'text':'Sent the Initial Medical Records Request', value:'Sent the Initial Medical Records Request'},
          {'text': 'In Contact With Provider', 'value': 'In Contact With Provider'},
          {'text': 'Provider Is Sending Documents', 'value': 'Provider Is Sending Documents'},
          {'text': 'Need Assistance', value: 'Need Assistance'},
          {'text': 'Completed - Documents Arrived', 'value': 'Completed - Documents Arrived'},
          {'text': 'Completed - No Documents Available', 'value': 'Completed - No Documents Available'},
          {'text': 'Closed - Documents Arrived', 'value': 'Closed - Documents Arrived'},
          {'text': 'Closed - No Documents Available', 'value': 'Closed - No Documents Available'},
          {'text': 'Closed - Cancelled Request', value: 'Closed - Cancelled Request'},
        ]

          var componentList = [TextInput, TextInput, Select]
          var patient_filter = {'name':'patient_filter', label:'Client'}
          var provider_filter = {'name':'provider_filter', label:'Provider'}
          var status_filter = {'name':'status_filter', label:'Status', options: status_options}


          var filter_form = <Form row={true} col_size={4} components={componentList} defaults={this.state} componentProps={[patient_filter, provider_filter,status_filter]}
            setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'filter'} />

         var go_back = null;
         if (this.props.user.is_staff) {
             var go_back = <Button type={'info'} onClick={() => this.setState({selectedUser: ''})} text={'Go Back'} />
         }

          var content =
            <div className="container">
              <Header size={2} text={'Requests By ' + lawyer_requests['name']} />
              <p onClick={this.download_files} style={{color:'blue', cursor:'pointer'}}>Download All Files: {this.state.files_to_download.length}</p>
              {go_back}
              <Button href={'/editRequest/' + this.props.user.company_id + "/"} type='success' text='Start New Request' />
              <div style={{marginBottom:'40px'}}></div>
              {filter_form}
              <table className="table">
                <tr>
                  <th>View</th>
                  <th>Client</th>
                  <th>Provider</th>
                  <th>Status</th>
                </tr>
              {requests}
              </table>
            </div>;

        }

        return (
            <Wrapper loaded={this.state.loaded}  content={content} />
        );
    }
}

export default RequestList;


import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import ajaxWrapper from 'base/ajax.js';
import {Container, Button, Image, Form, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header, Card, MultiLineText, Icon} from 'library';

class RequestType extends Component {
    constructor(props) {
        super(props);

        this.delete_request_type = this.delete_request_type.bind(this);
    }

    delete_request_type() {
        ajaxWrapper("POST","/api/home/requesttype/" + this.props.request_type.id + '/delete/', {}, this.props.refreshData)
    }

    render() {
        return (
            <tr>
                <td>{this.props.request_type.name}</td>
                <td><Button type='danger' deleteType={true} text='Delete' onClick={this.delete_request_type} /></td>
            </tr>
        )
    }
}

class RequestTypeList extends Component {
    constructor(props) {
        super(props);
        this.state = {'providers':[], name: ''}

        this.objectCallback = this.objectCallback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.add_request_type = this.add_request_type.bind(this);
    }

    setGlobalState(name, state) {
      this.setState(state);
    }

    add_request_type() {
        ajaxWrapper('POST','/api/home/requesttype/', {'name':this.state.name}, this.refreshData)
    }

    componentDidMount() {
      this.refreshData();
    }

    refreshData() {
        ajaxWrapper('GET','/api/home/requesttype/?order_by=name', {}, this.objectCallback);
    }

    objectCallback(result) {
      var request_types = []
      for (var index in result) {
        var request_type = result[index]['requesttype'];
        request_types.push(request_type)
      }
      this.setState({'request_types':request_types, 'loaded':true})
    }

    render() {
      console.log("Here");

      var request_types = [];
      for (var index in this.state.request_types) {
        var request_type = this.state.request_types[index];
        request_types.push(<RequestType request_type={request_type} refreshData={this.refreshData} />)

      }

      var request_form = <Form components={[TextInput]} componentProps={[{'label':'Name', name:'name'}]} defaults={this.state} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'form'} submit={this.add_request_type} />

      var content = <div className="container-fluid">
          <Header size={2} text={'Request Types'} />
          <br />
          <Header size={4} text={'Add New Request Type'} />
          {request_form}
          <br />
          <table className='table'>
            <tr>
              <th>Name</th>
              <th>Delete?</th>
            </tr>

            {request_types}
          </table>
        </div>;

        return (
            <Wrapper loaded={this.state.loaded}  content={content} />
        );
    }
}

export default RequestTypeList;

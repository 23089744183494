import React, { Component } from 'react';

import Wrapper from 'base/wrapper.js';
import MetaTags from 'react-meta-tags';

import {Container, Button, Image, Form, TextInput, Navbar, List, Link, Accordion, Paragraph, RadioButton, TextArea, Header} from 'library';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true
        };
    }

    setGlobalState() {

    }

    render() {
      var content =
      <div className="root">
        <div className="App">

            <div className="container-fluid" style={{'padding':'0px'}}>
                <div>
                    <div style={{"background": "rgba(0, 0, 0, 0.5)"}}>
                        <section style={{"backgroundImage": 'url("http://rifetheme.com/wp-content/uploads/2018/02/rife-free19.jpg")', "height": "200px", "backgroundSize": "cover", "backgroundRepeat": "no-repeat"}}>
                            <div style={{"height": "100%", "width": "100%", "backgroundColor": "rgba(0, 0, 0, 0.7)", "display": "flex", "alignItems": "center"}}>
                                <div class="container" style={{"textAlign": "center"}}>
                <h1 style={{"color": "white", "fontSize": "75px", "fontWeight": "bold"}}>About Us</h1>
                </div>
                </div>
                </section>
                </div>
                </div>
            </div>

            <div><section class="container" style={{"paddingTop": "100px", paddingBottom:'100px'}}>
            <h4 style={{'textAlign':'center', paddingBottom:'50px'}}>From our offices in Colorado, we bring our services to businesses nationwide. Attorneys, insurance companies, and other firms depend on us to retrieve and deliver records quickly, securely, and affordably. We locate and provide a wide range of records from all over the United States.  Our online portal makes the retrieval process easy and efficient. Simply submit a request, and we do the heavy lifting of communicating with hospitals, healthcare providers, local authorities, businesses, and others to bring you the files you need. Let's get started!</h4>
            </section>
            </div>
        </div>
        </div>;

        return (
            <Wrapper token={this.props.user_id} loaded={this.state.loaded}  content={content} />
        );
    }
}

export default Home;
